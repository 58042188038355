import { BehaviorSubject } from 'rxjs';
import { IMenu } from './models/core/menu';

export const menu$ = new BehaviorSubject<IMenu[]>([
    {
      route: "dashboard",
      name: "Dashboard",
      type: "link",
      icon: "dashboard"
    },
    // {
    //   route: "app-info",
    //   name: "App Info",
    //   type: "link",
    //   icon: "description"
    // },
    {
      route: "atp",
      name: "ATP",
      type: "sub",
      icon: "school",
      auth: true,
      roles: ['Admin'],
      children: [
        {
          route: "/management",
          name: "ATP Management",
          type: "link",
        },
        {
          route: "/students",
          name: "ATP Students",
          type: "link",
        },
        {
          route: "/courses",
          name: "ATP Courses",
          type: "link",
        }
      ]
    },
    {
      route: "firm",
      name: "Firm",
      type: "sub",
      icon: "business",
      auth: true,
      roles: ['Admin'],
      children: [
        {
          route: "/management",
          name: "Firm Management",
          type: "link",
        },
        {
          route: "/applications",
          name: "Firm Application",
          type: "link"
        },
      ]
    },
    {
      route: "individual",
      name: "Individual",
      type: "sub",
      icon: "recent_actors",
      auth: true,
      roles: ['Admin'],
      children: [
        {
          route: "/management",
          name: "Individual Management",
          type: "link",
        },
        {
          route: "/applications",
          name: "Individual Application",
          type: "link"
        },
      ]
    },
    {
      route: "complaint",
      name: "Complaint",
      type: "sub",
      icon: "create_new_folder",
      auth: true,
      roles: ['Admin'],
      children: [
        {
          route: "/list",
          name: "View Complaints",
          type: "link",
        },
      ]
    },
    {
      route: "payments",
      name: "Payments",
      type: "link",
      icon: "attach_money",
      auth: true,
      roles: ['Admin'] //If you want to restrict a route to certain roles include them in a list
    },
    {
      route: "reports",
      name: "Reports",
      type: "link",
      icon: "bar_chart",
      roles: ['Admin']
    },
    {
      route: "user-management",
      name: "User Management",
      type: "link",
      icon: "people",
      auth: true,
      roles: ['Admin'] //If you want to restrict a route to certain roles include them in a list
    },
  ]);
