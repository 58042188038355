import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { IFirm } from 'app/models/firms/firm';
import { FirmService } from 'app/services/firm/firm-service';
import { FirmAddEditComponent } from './add-edit/firm-add-edit.component';
import { FormControl } from '@angular/forms';
import { FIRM_SERVICES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MergeFirmComponent } from './merge-firm/merge-firm.component';
import { IFirmFilter } from 'app/models/filters/firmFilter';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss',]
})

export class FirmComponent implements OnInit {
  public isMobile: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public includeCCB: boolean = false;
  public filterString: string = '';
  public filteredFirms = [];  
  public filteredActive = new FormControl('Both');
  public filteredFirmType = new FormControl('Both');
  public expirationStartDate = new FormControl("");
  public expirationEndDate = new FormControl("");
  public selectedFirms: IFirm[] = [];
  public firmFilter: IFirmFilter = {
    filterString: null,
    filterStatus: null,
    filterType: null,
    expirationStartDate: null,
    expirationEndDate: null,  
    includeCCB: false,  
  }

  processing: boolean = false;
  loading: boolean = true;
  pageSize = 50;  
  firms: IFirm[] = []
  
  public dataSource: MatTableDataSource<any>;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  firmTable = {        
    layout:{
      columns:['select', 'id', 'inactive', 'name', 'ccb', 'ccbExpirationDate','certificationNumber', 'servicesAvailable', 'isPrivate','hasLbpCert','hasRrpCert'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'5'},
        {displayName:'Firm Id',columnName:'id', type:'string', size:'7'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'6'},
        {displayName:'Firm Name',columnName:'name', type:'string', size:'11'},        
        {displayName:'CCB',columnName:'ccb', type:'string', size:'10'},          
        {displayName:'CCB Expiration Date',columnName:'ccbExpirationDate', type:'date', size:'10'},     
        {displayName:'Certification Number',columnName:'certificationNumber', type:'string', size:'10'},          
        {displayName:'Services Available',columnName:'servicesAvailable', type:'string', size:'10'},                            
        {displayName:'Private Firm',columnName:'isPrivate', type:'boolean', size:'8'},
        {displayName:'LBP Firm',columnName:'hasLbpCert', type:'boolean', size:'8'},
        {displayName:'RRP Firm',columnName:'hasRrpCert', type:'boolean', size:'8'},
      ],
      data: []      
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public commonService: CommonDataService, 
    private firmService: FirmService,
    private toastr: ToastrService
  ) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.loadFilters();
    this.loadFirms();
  }

  loadFilters(){
    this.filterString = this.commonService.firmManagementFilter.filterString;
    this.filteredActive = this.commonService.firmManagementFilter.filteredActive;
    this.expirationStartDate = this.commonService.firmManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.firmManagementFilter.expirationEndDate;
    this.filteredFirmType = this.commonService.firmManagementFilter.filteredFirmType;
    this.includeCCB = this.commonService.firmManagementFilter.includeCCB;
  }

  updateSelected(selectedFirm: IFirm){
    const index = this.selectedFirms.findIndex(firm => firm.id === selectedFirm.id);
    if (index !== -1){
      this.selectedFirms.splice(index, 1);
    } else {
      this.selectedFirms.push(selectedFirm);
    }    
  }

  isSelected(selectedFirm: IFirm): boolean {    
    return this.selectedFirms.some(firm => firm.id === selectedFirm.id);
  }

  mergeIndividuals(){
    const dialogRef = this.dialog.open(MergeFirmComponent, {
      width: '90%',
      autoFocus: false,
      data: this.selectedFirms,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      if(result){
        this.selectedFirms = [];
        this.refreshFirms();
      }
    });
  }

  getMergeTooltip(): string {
    if (this.selectedFirms.length > 0) {
        return 'Merge: ' + this.selectedFirms.map(firm => `${firm.id}: ${firm.name}`).join(', ');
    }
    return 'Merges selected Firms';
  }

  loadFirms() {    
    this.commonService.firmManagementFilter.filterString = this.filterString;
    this.commonService.firmManagementFilter.filteredActive = this.filteredActive;
    this.commonService.firmManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.firmManagementFilter.expirationEndDate = this.expirationEndDate;
    this.commonService.firmManagementFilter.filteredFirmType = this.filteredFirmType;
    this.commonService.firmManagementFilter.includeCCB = this.includeCCB;

    this.firmFilter.filterString = this.filterString;
    this.firmFilter.filterStatus = this.filteredActive.value;
    this.firmFilter.filterType = this.filteredFirmType.value;
    this.firmFilter.expirationStartDate = this.expirationStartDate.value;
    this.firmFilter.expirationEndDate = this.expirationEndDate.value;
    this.firmFilter.includeCCB = this.includeCCB;
    this.refreshFirms();    
  }

  refreshFirms(){
    this.firmService.getFilteredFirms(this.firmFilter).subscribe(
      result => {
        this.firms = result.sort((a, b) => b.id - a.id);             
        this.filteredFirms = this.firms;
        this.updateDataSource();
        this.loading = false;
      },
      error => {
        this.toastr.error(error.message);
        this.loading = false;
      },
    ); 
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredFirms);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editFirm(firm: IFirm) {    
    const dialogRef = this.dialog.open(FirmAddEditComponent, {
      width: '90%',
      data: firm,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  getServiceTypes(){
    return Object.values(FIRM_SERVICES);
  }

  getFirmActiveTypes(){
    return ['Active','InActive'];
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  clearFilters(){
    this.filterString = '';
    this.filteredActive.setValue('Active');
    this.expirationStartDate.setValue('');
    this.expirationEndDate.setValue('');
    this.filteredFirmType.setValue('');
    this.includeCCB = false;
    this.loadFirms();
  }
}
