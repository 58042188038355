<div *ngIf="loading">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>



<div fxLayout="column" *ngIf="!loading">
  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
      <div fxFlex="100" class="matero-col" fxLayoutAlign="start">
          <h2 class="noMarginHeader">
              <mat-icon>dashboard</mat-icon> Dashboard
          </h2>
      </div>
  </div>    
  <mat-card>
    <div fxLayout="row" fxLayout.lt-lg="column">
      <mat-card fxFlex="33" fxFlex.lt-lg="100" class="entity-border-mat-card" [ngStyle]="{'border': '2px solid ' + getColor('Firm')}">
        <mat-card [ngStyle]="{'background-color': getColor('Firm')}">
          <mat-card-title>
            <div fxLayout="column">
              <div>
                Firms
              </div>

              <div>
                <mat-button-toggle-group [(ngModel)]="firmFilterInterval" (ngModelChange)="onFirmFilterChanged()">                    
                  <mat-button-toggle value="Weekly" style="font-size: 12px;">Week</mat-button-toggle>
                  <mat-button-toggle value="Monthly" style="font-size: 12px;">Month</mat-button-toggle>
                  <mat-button-toggle value="Quarterly" style="font-size: 12px;">Quarter</mat-button-toggle>
                  <mat-button-toggle value="Yearly" style="font-size: 12px;">Year</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </mat-card-title>
        </mat-card>
        
        <div>
          <div style="padding: 5px" *ngIf="!firmLoading">
            <mat-card-content>
              <div>
                <div>
                  <strong>Active Firms</strong>
                </div>
                <div class="custom-list">   
                  <a class="blue-text" (click)="goToFirms('overdue')">Overdue: {{firmDashboardData.overdueCount}}</a>
                  <a class="blue-text" (click)="goToFirms('expiring')">Expiring: {{firmDashboardData.expiringCount}}</a>                  
                  <a class="blue-text" (click)="goToFirms('active')">Active: {{firmDashboardData.activeCount}}</a>                  
                </div>

                <div>
                  <strong>Applications</strong>
                </div>
                <div class="custom-list">                  
                  <a class="blue-text" (click)="goToFirmApplications('review')">Awaiting Review: {{firmDashboardData.applicationAwaitingReviewCount}}</a>                  
                  <a class="blue-text" (click)="goToFirmApplications('near')">Near Outstanding: {{firmDashboardData.applicationNearOutstandingCount}}</a>
                  <a class="blue-text" (click)="goToFirmApplications('expiring')">Expiring: {{firmDashboardData.applicationExpiringCount}}</a>
                  <br>                  
                  <a class="blue-text" (click)="goToFirmApplications('approved')">Approved Applications: {{firmDashboardData.applicationApprovedCount}}</a>
                  <a class="blue-text" (click)="goToFirmApplications('expired')">Expired Applications: {{firmDashboardData.applicationExpiredCount}}</a>
                  <a class="blue-text" (click)="goToFirmApplications('total')">Total Applications: {{firmDashboardData.applicationTotalCount}}</a>
                </div>
              </div>
            </mat-card-content>
          </div>
          <div class="loading-overlay" *ngIf="firmLoading" style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </mat-card>
        
      <mat-card fxFlex="33" fxFlex.lt-lg="100" class="entity-border-mat-card" [ngStyle]="{'border': '2px solid ' + getColor('Individual')}">
        <mat-card [ngStyle]="{'background-color': getColor('Individual')}">
          <mat-card-title>
            <div fxLayout="column">
              <div>
                Individuals
              </div>

              <div>
                <mat-button-toggle-group [(ngModel)]="individualFilterInterval" (ngModelChange)="onIndividualFilterChanged()">                    
                  <mat-button-toggle value="Weekly" style="font-size: 12px;">Week</mat-button-toggle>
                  <mat-button-toggle value="Monthly" style="font-size: 12px;">Month</mat-button-toggle>
                  <mat-button-toggle value="Quarterly" style="font-size: 12px;">Quarter</mat-button-toggle>
                  <mat-button-toggle value="Yearly" style="font-size: 12px;">Year</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </mat-card-title>
        </mat-card>
        
        <div>
          <div style="padding: 5px" *ngIf="!personLoading">
            <mat-card-content>
              <div>
                <div>
                  <strong>Active Individuals</strong>
                </div>
                <div class="custom-list">
                  <a class="blue-text" (click)="goToIndividuals('overdue')">Overdue: {{personDashboardData.overdueCount}}</a>
                  <a class="blue-text" (click)="goToIndividuals('expiring')">Expiring: {{personDashboardData.expiringCount}}</a>
                  <a class="blue-text" (click)="goToIndividuals('active')">Active: {{personDashboardData.activeCount}}</a>                  
                </div>

                <div>
                  <strong>Applications</strong>
                </div>
                <div class="custom-list">                  
                  <a class="blue-text" (click)="goToIndividualApplications('review')">Awaiting Review: {{personDashboardData.applicationAwaitingReviewCount}}</a>
                  <a class="blue-text" (click)="goToIndividualApplications('near')">Near Outstanding: {{personDashboardData.applicationNearOutstandingCount}}</a>
                  <a class="blue-text" (click)="goToIndividualApplications('expiring')">Expiring: {{personDashboardData.applicationExpiringCount}}</a>
                  <br>                  
                  <a class="blue-text" (click)="goToIndividualApplications('approved')">Approved Applications: {{personDashboardData.applicationApprovedCount}}</a>
                  <a class="blue-text" (click)="goToIndividualApplications('expired')">Expired Applications: {{personDashboardData.applicationExpiredCount}}</a>
                  <a class="blue-text" (click)="goToIndividualApplications('total')">Total Applications: {{personDashboardData.applicationTotalCount}}</a>
                </div>
              </div>
            </mat-card-content>
          </div>
          <div class="loading-overlay" *ngIf="personLoading" style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </mat-card>  

      <mat-card fxFlex="33" fxFlex.lt-lg="100" class="entity-border-mat-card" [ngStyle]="{'border': '2px solid ' + getColor('ATP')}">
        <mat-card [ngStyle]="{'background-color': getColor('ATP')}">
          <mat-card-title>
            <div fxLayout="column">
              <div>
                ATPs
              </div>

              <div>
                <mat-button-toggle-group [(ngModel)]="atpFilterInterval" (ngModelChange)="onATPFilterChanged()">                    
                  <mat-button-toggle value="Weekly" style="font-size: 12px;">Week</mat-button-toggle>
                  <mat-button-toggle value="Monthly" style="font-size: 12px;">Month</mat-button-toggle>
                  <mat-button-toggle value="Quarterly" style="font-size: 12px;">Quarter</mat-button-toggle>
                  <mat-button-toggle value="Yearly" style="font-size: 12px;">Year</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </mat-card-title>
        </mat-card>
        
        <div>
          <div style="padding: 5px" *ngIf="!atpLoading">
            <mat-card-content>
              <div>
                <div>
                  <strong>Active ATPs</strong>
                </div>
                <div class="custom-list">                                                          
                  <a class="blue-text" (click)="goToATPs('overdue')">Overdue: {{atpDashboardData.overdueCount}}</a>
                  <a class="blue-text" (click)="goToATPs('expiring')">Expiring: {{atpDashboardData.expiringCount}}</a>                  
                  <a class="blue-text" (click)="goToATPs('active')">Active: {{atpDashboardData.activeCount}}</a>                  
                </div>
              </div>
              <div>
                <div>
                  <strong>Courses</strong>
                </div>
                <div class="custom-list">
                  <a class="blue-text" (click)="goToAtpCourses('delinquent')">Delinquent: {{atpDashboardData.courseDelinquentCount}}</a>
                  <a class="blue-text" (click)="goToAtpCourses('upcoming')">Upcoming: {{atpDashboardData.courseUpcomingCount}}</a>
                  <a class="blue-text" (click)="goToAtpCourses('recent')">Recent: {{atpDashboardData.courseRecentCount}}</a>                  
                </div>
              </div>
            </mat-card-content>
        
            <mat-card-content>
              <div>
                <div>
                  <strong>Applications</strong>
                </div>
                <div class="custom-list">
                  <a class="blue-text" (click)="goToATPs('incomplete')">Incomplete: {{atpDashboardData.incompleteApplicationCount}}</a>
                  <a class="blue-text" (click)="goToATPs('complete')">Completed: {{atpDashboardData.processedApplicationCount}}</a>                  
                </div>
              </div>
            </mat-card-content>
          </div>
          <div class="loading-overlay" *ngIf="atpLoading" style="display: flex; justify-content: center; align-items: center; height: 100%;">
            <mat-spinner></mat-spinner>
          </div>
        </div>
      </mat-card>  
  </div>
</mat-card>

<section *ngIf="!loading">
  <div fxFlex="100" fxFlex.lt-md="100" fxLayout="row" fxLayout.lt-md="column">
    <div class="matero-col" fxFlex.gt-sm="17" fxFlex.gt-xs="17" fxFlex="17">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': paymentsPending?.length == 0, 'bg-red-400':paymentsPending?.length > 0}" (click)="goToPendingPayments()" style="cursor: pointer;">
        <mat-card-title class="f-s-12 f-w-100">Payments Pending</mat-card-title>
        <mat-card-content>
          <h2>{{paymentsPending?.length}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
    &nbsp;
    <div class="matero-col" fxFlex.gt-sm="17" fxFlex.gt-xs="17" fxFlex="17">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': paymentsAwaitingClearance?.length == 0, 'bg-red-400':paymentsAwaitingClearance?.length > 0}" (click)="goToAwaitingClearancePayments()" style="cursor: pointer;">
        <mat-card-title class="f-s-12 f-w-100">Payments Awaiting Clearance</mat-card-title>
        <mat-card-content>
          <h2>{{paymentsAwaitingClearance?.length}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
    &nbsp;
    <div class="matero-col" fxFlex.gt-sm="18" fxFlex.gt-xs="18" fxFlex="18">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': firmDashboardData.applicationOutstandingCount == 0, 'bg-red-400':firmDashboardData.applicationOutstandingCount > 0}" (click)="goToFirmApplications('outstanding')" style="cursor: pointer;">
        <mat-card-title class="f-s-12 f-w-100">Outstanding Firm Applications</mat-card-title>
        <mat-card-content>
          <h2>{{firmDashboardData.applicationOutstandingCount}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
    &nbsp;
    <div class="matero-col" fxFlex.gt-sm="20" fxFlex.gt-xs="20" fxFlex="20">
      <mat-card
        [ngClass]="{'text-white': true, 'bg-indigo-500': personDashboardData.applicationOutstandingCount == 0, 'bg-red-400':personDashboardData.applicationOutstandingCount > 0}" (click)="goToIndividualApplications('outstanding')" style="cursor: pointer;">
        <mat-card-title class="f-s-12 f-w-100">Outstanding Individual Applications</mat-card-title>
        <mat-card-content>
          <h2>{{personDashboardData.applicationOutstandingCount}}</h2>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
