import {
  Component, ChangeDetectionStrategy, ViewEncapsulation,
} from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer',
  host: {
    class: 'matero-header',
  },
  templateUrl: './footer.component.html',  
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent{
  public environment = environment;
}
