import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { AddEditAtpCourseComponent } from '../add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { AddEditAtpComponent } from '../add-edit-atp/add-edit-atp.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ICourseFilter } from 'app/models/filters/courseFilter';

@Component({
  selector: 'app-atp-course',
  templateUrl: './atp-course.component.html',
  styleUrls: ['./atp-course.component.scss']
})
export class AtpCourseComponent {
  public isMobile: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public loading: boolean = false;
  public pageSize: number = 50;  
  public dataSource: MatTableDataSource<any>;
  public courses: any[] = [];
  public filteredCourses: any[] = [];
  public atpList: any[] = [];
  public filteredAtpList: any[] = [];  

  public filterString: string = '';
  public filterATPString: any = '';  
  public delinquents: boolean = true;    
  public startDate = null;
  public endDate = null;

  public courseFilter: ICourseFilter = {    
    filterATPString: null,         
    filterStartDate: null,
    filterEndDate: null,
    filterDelinquents: true,
  };
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  courseDataFields = {    
    layout:{
      columns:['course', 'atpName', 'startDateTime', 'location','receivedPostNoteDate','payment','students','elapsedDays'],
      container:[
        {displayName:'Course',columnName:'course', type:'string', size:'12'},        
        {displayName:'ATP',columnName:'atpName', type:'string', size:'20'},        
        {displayName:'Course Start Date Time',columnName:'startDateTime', type:'string', size:'10'}, 
        {displayName:'Location',columnName:'location', type:'string', size:'20'}, 
        {displayName:'Post Training Notification Date',columnName:'receivedPostNoteDate', type:'date', size:'10'}, 
        {displayName:'Payment',columnName:'payment', type:'string', size:'13'}, 
        {displayName:'Students',columnName:'students', type:'string', size:'7'}, 
        {displayName:'Days Since Course Ended',columnName:'elapsedDays', type:'string', size:'8'}, 
      ],        
    }
  }  

  private debounceString: Subject<string> = new Subject<string>();
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public atpService: AtpService,    
    public toastr: ToastrService) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });    
    this.initializeDebounceFunction();
    this.loadFilters();
    this.refreshCourses();
    this.loadATPList();
  }

  initializeDebounceFunction(): void {
    this.debounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {           
      this.filterATP(value.trim().toLowerCase());     
    });
  }

  loadFilters(): void {
    this.filterString = this.atpService.filterCourseString;
    this.filterATPString = this.atpService.filterCourseATPString;        
    this.startDate = this.atpService.filterCourseStartDate;
    this.endDate = this.atpService.filterCourseEndDate;
    this.delinquents = this.atpService.filterCourseDelinquents;
    
    this.courseFilter.filterATPString = this.filterATPString;
    this.courseFilter.filterStartDate = this.startDate?.toISOString();
    this.courseFilter.filterEndDate = this.endDate?.toDateString();
    this.courseFilter.filterDelinquents = this.delinquents

  }
  
  refreshCourses(){    
    this.atpService.getAllATPCourses(this.courseFilter).subscribe(result => {
      var remap = result.map(data => ({
        ...data,
        course: data?.id + ': ' + data?.accreditationDiscipline + '\n' + data?.accreditationCourseFormat + '\n' + data?.accreditationCourseLanguage,
        atpName: data?.atp?.name,
        startDateTime: this.formatDate(data?.courseStart.split('T')[0]) + '\n' + data?.courseStart.split('T')[1],
        location: ' \n' + data?.atpCourseAddress?.locationName + '\n' + data?.atpCourseAddress?.address?.street + '\n' + data?.atpCourseAddress?.address?.city + ', ' + data?.atpCourseAddress?.address?.state + ' ' + data?.atpCourseAddress?.address?.zip + '\n ',
        payment: '$' + data?.receivedAmount + '\nControl# ' + data?.controlNumber,
        elapsedDays: data?.courseEnd ? Math.floor((new Date().getTime() - new Date(data?.courseEnd).getTime()) / (1000 * 60 * 60 * 24)) : Math.floor((new Date().getTime() - new Date(data?.courseStart).getTime()) / (1000 * 60 * 60 * 24)),
      }));      
      this.courses = remap;
      this.filterTable();      
    });
  }

  loadATPList(){
    this.atpService.getATPs().subscribe(result=>{
      this.atpList = result.map(atp=>{return {name: atp.name, id: atp.id}});
      this.atpList.sort((a, b) => a.name.localeCompare(b.name));
      this.filteredAtpList = this.atpList;      
    },error=>{this.toastr.error("Error loading ATPs");})
  }
  
  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredCourses);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applySubFilter(event: Event): void {    
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editCourse(row: any){    
    const dialogRef = this.dialog.open(AddEditAtpCourseComponent, {
      width: '90%',
      data: {course: row, atp: row.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      this.refreshCourses();    
    });
  }

  editATP(row: any){    
    const dialogRef = this.dialog.open(AddEditAtpComponent, {
      width: '90%',
      data: {atp: row},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {     
      this.refreshCourses();    
    });
  }

  filterTable() {     
    this.saveFilters();
    this.filteredCourses = this.courses;    
    this.updateDataSource();
  }

  saveFilters(){
    this.atpService.filterCourseString = this.filterString;
    this.atpService.filterCourseATPString = this.filterATPString;
    this.atpService.filterCourseStartDate = this.startDate;
    this.atpService.filterCourseEndDate = this.endDate;
    this.atpService.filterCourseDelinquents = this.delinquents;

    this.courseFilter.filterATPString = this.filterATPString;
    this.courseFilter.filterStartDate = this.startDate?.toISOString();
    this.courseFilter.filterEndDate = this.endDate?.toDateString();
    this.courseFilter.filterDelinquents = this.delinquents
  }

  clearFilters(){
    this.filterString = '';
    this.filterATPString = '';        
    this.startDate = new Date();
    this.endDate = new Date();
    this.delinquents = true;
    
    this.filterTable();
  }

  handleRowClick(event: MouseEvent, row: any) {    
    const isCell = (event.target as HTMLElement).classList.contains('mat-cell');
  
    if (isCell) {
      this.editCourse(row);
      event.stopPropagation();      
    }  
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  formatDate(date: any){
    var dateString = date.split('-');
    return dateString[1] + '/' + dateString[2] + '/' + dateString[0];
  }

  filterATP(value: any) {
    if (value.length < 1) {
      this.resetATPList();      
    }
    else{      
      this.filteredAtpList = this.atpList.filter(atp => atp.name.toLowerCase().includes(value) || atp.id.toString().includes(value));
    }
  }

  updateATPFilter(): void{
    this.filterATPString = this.filterATPString?.name ?? this.filterATPString;
    this.saveFilters();
  }

  updateDebounceString(event: any){        
    this.debounceString.next(event.target.value);     
  }

  resetATPList(): void {    
    this.filteredAtpList = this.atpList
  }

  displayATP(atp: any): string {
    if (atp) {
        if (atp.id === 0) {
            return atp?.name ?? atp;
        } else {
            return `${atp?.id ? atp.id + ': ' + atp.name : atp}`;
        }
    } else {
        return '';
    }
  }

  search(){
    this.saveFilters();
    this.refreshCourses();
  }
}

