<mat-card [style.background-color]="data.type === 'firm' ? uiData.firm.color : uiData.individual.color">
    <h2 mat-dialog-title>
        {{data.title}}
    </h2>
</mat-card>
<mat-dialog-content class="scroll-container">
    <section *ngIf="loading">
        <h2>Loading...</h2>
        <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
        </mat-progress-bar>
    </section>
    <mat-card *ngIf="!loading">
        <div>
            <p color="primary">
                <strong>Note:</strong>
                {{data.message}}
            </p>    
            <br />
            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of data.type === 'firm' ? firmDisplay : peopleDisplay; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">                                         
                        <ng-container *ngIf="cellData.type === 'select'">
                            <div *ngIf="isMobile" fxLayout="row">
                                <mat-radio-group [(ngModel)]="selection" (change)="onChangeSelectedPerson(row)">
                                    <mat-radio-button [value]="row"> 
                                        <!-- Radio button only, no label -->
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="!isMobile">
                                <mat-radio-group [(ngModel)]="selection" (change)="onChangeSelectedPerson(row)">
                                    <mat-radio-button [value]="row"> 
                                        <!-- Radio button only, no label -->
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="cellData.type !== 'select'">
                            <div *ngIf="isMobile" fxLayout="row">                                    
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                            </div>
                            <div *ngIf="!isMobile">
                                {{ displayLimit(row[cellData.columnName], cellData.size) }}
                            </div>  
                        </ng-container>
                    </mat-cell>                    
                </ng-container>                                  
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="data.type === 'firm' ? firmColumns : peopleColumns"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: data.type === 'firm' ? firmColumns : peopleColumns"></mat-row>
            </mat-table>
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card>
</mat-dialog-content>
<mat-card [style.background-color]="data.type === 'firm' ? uiData.firm.color : uiData.individual.color">        
    <div>        
        <button mat-button [disabled]="selection === null" mat-raised-button color="primary"(click)="select()">
            Select
        </button>
            &nbsp;
        <button mat-button mat-raised-button mat-dialog-close="Cancel" aria-label="Cancel" [mat-dialog-close]="false">Cancel</button>
    </div>
</mat-card>