import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { PaymentService } from '../payments/services/payments.service';
import { IPayment } from '../payments/models/payment';
import { Router } from '@angular/router';
import { IDashboardFilter } from 'app/models/filters/dashboardFilter';
import { ToastrService } from 'ngx-toastr';
import { IFirmDashboard } from 'app/models/firms/firmDashboard';
import { IPersonDashboard } from 'app/models/People/personDashboard';
import { CommonDataService } from 'app/services/common/common-data.service';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAtpDashboard } from 'app/models/atp/atpDashboard';
import { AtpService } from 'app/services/atp/atp.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  styles: [],  
  providers: [],
})
export class DashboardComponent implements OnInit {
  public environment = environment;
  public uiData = LEAD_BASED_PAINT;
  public firmFilterInterval: string = 'Weekly';
  public individualFilterInterval: string = 'Weekly';  
  public atpFilterInterval: string = 'Weekly';  

  public firmDashboardData: IFirmDashboard = {activeCount: 0, expiringCount: 0, overdueCount: 0, applicationTotalCount: 0, applicationApprovedCount: 0, applicationExpiredCount: 0, applicationAwaitingReviewCount: 0, applicationNearOutstandingCount: 0, applicationExpiringCount: 0, applicationOutstandingCount: 0}  
  public personDashboardData: IPersonDashboard = {activeCount: 0, expiringCount: 0, overdueCount: 0, applicationTotalCount: 0, applicationApprovedCount: 0, applicationExpiredCount: 0, applicationAwaitingReviewCount: 0, applicationNearOutstandingCount: 0, applicationExpiringCount: 0, applicationOutstandingCount: 0}
  public atpDashboardData: IAtpDashboard = {activeCount: 0, expiringCount: 0, overdueCount: 0, courseUpcomingCount: 0, courseRecentCount: 0, courseDelinquentCount: 0, processedApplicationCount: 0, incompleteApplicationCount: 0}

  public loading: boolean = true;

  public firmLoading: boolean = true;
  public personLoading: boolean = true;
  public atpLoading: boolean = false;

  public paymentsPending: IPayment [] = [];
  public paymentsAwaitingClearance: IPayment [] = [];
  public processing: boolean = false;

  constructor(
    public sharedService: SharedService,
    public authService: AuthService,
    public firmService: FirmService,
    public personService: PersonService,
    private router: Router,
    public paymentService: PaymentService,
    private toastr: ToastrService,
    public commonService: CommonDataService,
    public atpService: AtpService
  ) {}

  ngOnInit(): void {
    this.loadData()
  }

  loadData(): void {
    this.firmFilterInterval = this.commonService.dashboardFilter.firmInterval;
    this.individualFilterInterval = this.commonService.dashboardFilter.individualInterval;
    this.atpFilterInterval = this.commonService.dashboardFilter.atpInterval;

    this.onFirmFilterChanged();
    this.onIndividualFilterChanged();
    this.onATPFilterChanged();

    this.paymentService.getPaymentsPending().subscribe(result=>{
      this.paymentsPending = result;
    }, error =>{this.toastr.error('Error loading pending payments', error)});

    this.paymentService.getPaymentsAwaitingClearance().subscribe(result=>{
      this.paymentsAwaitingClearance = result;
    }, error =>{this.toastr.error('Error loading awaiting clearance payments', error)});

    this.loading= false;
  }

  getColor(value: string): string {
    return (value === 'Firm') ? this.uiData.firm.color : (value === 'Individual') ? this.uiData.individual.color : this.uiData.atp.color;
  }

  goToPendingPayments(): void {
    
    this.paymentService.searchForm.reset();
    this.paymentService.searchForm.get('status').setValue(this.paymentService.paymentPendingStatus);
    this.router.navigateByUrl('/payments');
  }

  goToAwaitingClearancePayments(): void {
    
    this.paymentService.searchForm.reset();
    this.paymentService.searchForm.get('status').setValue(this.paymentService.paymentAwaitingClearanceStatus);
    this.router.navigateByUrl('/payments');
  }

  onFirmFilterChanged() : void {
    this.commonService.dashboardFilter.firmInterval = this.firmFilterInterval;
    this.firmLoading = true;
    let filter:IDashboardFilter = {filterString: this.firmFilterInterval};
    this.firmService.getDashboardFirmData(filter).subscribe(result=>{           
      this.firmDashboardData = result;
      this.firmLoading = false;
    },error=>{this.toastr.error('Error loading firm dashboard data', error)})    
  }
  onIndividualFilterChanged() : void { 
    this.commonService.dashboardFilter.individualInterval = this.individualFilterInterval;
    this.personLoading = true;
    let filter:IDashboardFilter = {filterString: this.individualFilterInterval};
    this.personService.getDashboardPersonData(filter).subscribe(result=>{
      this.personDashboardData = result;
      this.personLoading = false;
    },error=>{this.toastr.error('Error loading individual dashboard data', error)})
  }
  onATPFilterChanged() : void { 
    this.commonService.dashboardFilter.atpInterval = this.atpFilterInterval;
    this.atpLoading = true;
    let filter:IDashboardFilter = {filterString: this.atpFilterInterval};
    this.atpService.getDashboardAtpData(filter).subscribe(result=>{
      this.atpDashboardData = result;
      this.atpLoading = false;
    },error=>{this.toastr.error('Error loading atp dashboard data', error)})
  }

  getDateDifferenceInDays(date: string): number {
    if(date === null || date === undefined || date === '') return 0;
    let dateCreated = new Date(date);
    let currentDate = new Date();      

    let timeDifference = Math.abs(currentDate.getTime() - dateCreated.getTime());
    let daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
    return daysDifference
    
  }

  goToFirms(filterType: string): void {
    let threshold = (this.firmFilterInterval === 'Weekly') ? 7 : (this.firmFilterInterval === 'Monthly') ? 30 : (this.firmFilterInterval === 'Quarterly') ? 90 : 365
    switch (filterType) {
      case "expiring":
        this.commonService.firmManagementFilter.filterString = '';
        this.commonService.firmManagementFilter.filteredActive.setValue('Active');  
        this.commonService.firmManagementFilter.filteredFirmType.setValue(null);  
        this.commonService.firmManagementFilter.expirationStartDate.setValue(new Date());
        this.commonService.firmManagementFilter.expirationEndDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
        this.commonService.firmManagementFilter.includeCCB = true;
        break;
  
      case "active":
        this.commonService.firmManagementFilter.filterString = '';
        this.commonService.firmManagementFilter.filteredActive.setValue('Active');  
        this.commonService.firmManagementFilter.filteredFirmType.setValue(null);  
        this.commonService.firmManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
        this.commonService.firmManagementFilter.expirationEndDate.setValue(null);
        this.commonService.firmManagementFilter.includeCCB = false;
          break;
  
      case "overdue":
        this.commonService.firmManagementFilter.filterString = '';
        this.commonService.firmManagementFilter.filteredActive.setValue('');  
        this.commonService.firmManagementFilter.filteredFirmType.setValue(null);          
        this.commonService.firmManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() - threshold)));
        this.commonService.firmManagementFilter.expirationEndDate.setValue(new Date());
        this.commonService.firmManagementFilter.includeCCB = true;
        break;
  
      default:
          console.log("Unknown Case.");
          break;    
    }
    this.router.navigateByUrl('/firm/management');
  }

  goToFirmApplications(value: string): void {
    let threshold = (this.firmFilterInterval === 'Weekly') ? 7 : (this.firmFilterInterval === 'Monthly') ? 30 : (this.firmFilterInterval === 'Quarterly') ? 90 : 365

    switch(value){
      case 'review':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Submitted','In Review']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'near':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Near Outstanding');
        break;
      case 'expiring':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Expiring');
        break;
      case 'approved':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Approved']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'expired':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));;
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Expired');
        break;
      case 'total':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue([]);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'outstanding':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Outstanding');
        break;
      default:
        console.log("Unknown Case.");
        break;
    }

    this.router.navigateByUrl('/firm/applications');
  }

  goToIndividuals(filterType: string): void {
    this.commonService.individualManagementFilter.showCourses = false;
    let threshold = (this.individualFilterInterval === 'Weekly') ? 7 : (this.individualFilterInterval === 'Monthly') ? 30 : (this.individualFilterInterval === 'Quarterly') ? 90 : 365
    switch (filterType) {
      case "expiring":
        this.commonService.individualManagementFilter.filterString = '';
        this.commonService.individualManagementFilter.filteredActive.setValue('Active');
        this.commonService.individualManagementFilter.filterStatus.setValue([]);
        this.commonService.individualManagementFilter.filterDiscipline.setValue('');
        this.commonService.individualManagementFilter.expirationStartDate.setValue(new Date());
        this.commonService.individualManagementFilter.expirationEndDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
        break;
  
      case "active":
        this.commonService.individualManagementFilter.filterString = '';
        this.commonService.individualManagementFilter.filteredActive.setValue('Active');
        this.commonService.individualManagementFilter.filterStatus.setValue([]);
        this.commonService.individualManagementFilter.filterDiscipline.setValue('');
        this.commonService.individualManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
        this.commonService.individualManagementFilter.expirationEndDate.setValue(null);
        break;
  
      case "overdue":
        this.commonService.individualManagementFilter.filterString = '';
        this.commonService.individualManagementFilter.filteredActive.setValue('');
        this.commonService.individualManagementFilter.filterStatus.setValue([]);
        this.commonService.individualManagementFilter.filterDiscipline.setValue('');
        this.commonService.individualManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() - threshold)));
        this.commonService.individualManagementFilter.expirationEndDate.setValue(new Date());
        break;
  
      default:
        console.log("Unknown Case.");
        break;
    }

    this.router.navigateByUrl('/individual/management');
  }

  goToIndividualApplications(value: string): void {
    let threshold = (this.individualFilterInterval === 'Weekly') ? 7 : (this.individualFilterInterval === 'Monthly') ? 30 : (this.individualFilterInterval === 'Quarterly') ? 90 : 365

    switch(value){
      case 'review':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Submitted','In Review']);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'near':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Near Outstanding');
        break;
      case 'expiring':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Expiring');
        break;
      case 'approved':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Approved']);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'expired':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));;
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Expired');
        break;
      case 'total':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue([]);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'outstanding':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Submitted','In Review','In Progress','Action Needed']);
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Outstanding');
        break;
      default:
        console.log("Unknown Case.");
        break;
    }

    this.router.navigateByUrl('/individual/applications');
  }

  goToATPs(filterType: string): void {
    let threshold = (this.atpFilterInterval === 'Weekly') ? 7 : (this.atpFilterInterval === 'Monthly') ? 30 : (this.atpFilterInterval === 'Quarterly') ? 90 : 365    
    
    switch (filterType) {
      case 'active':
        this.commonService.atpManagementFilter.filterString = '';
        this.commonService.atpManagementFilter.filteredActive.setValue('Active');
        this.commonService.atpManagementFilter.filterDiscipline.setValue('');
        this.commonService.atpManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
        this.commonService.atpManagementFilter.expirationEndDate.setValue(null);
        break;
  
      case 'expiring':
        this.commonService.atpManagementFilter.filterString = '';
        this.commonService.atpManagementFilter.filteredActive.setValue('Active');
        this.commonService.atpManagementFilter.filterDiscipline.setValue('');
        this.commonService.atpManagementFilter.expirationStartDate.setValue(new Date());
        this.commonService.atpManagementFilter.expirationEndDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
        break;
  
      case 'overdue':
        this.commonService.atpManagementFilter.filterString = '';
        this.commonService.atpManagementFilter.filteredActive.setValue('Overdue');
        this.commonService.atpManagementFilter.filterDiscipline.setValue('');
        this.commonService.atpManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() - threshold)));
        this.commonService.atpManagementFilter.expirationEndDate.setValue(new Date());
        break;
    
      case 'complete':
        this.commonService.atpManagementFilter.filterString = '';
        this.commonService.atpManagementFilter.filteredActive.setValue('Complete Applications');
        this.commonService.atpManagementFilter.filterDiscipline.setValue('');
        this.commonService.atpManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() - threshold)));
        this.commonService.atpManagementFilter.expirationEndDate.setValue(new Date());
        break;
  
      case 'incomplete':
        this.commonService.atpManagementFilter.filterString = '';
        this.commonService.atpManagementFilter.filteredActive.setValue('Incomplete Applications');
        this.commonService.atpManagementFilter.filterDiscipline.setValue('');
        this.commonService.atpManagementFilter.expirationStartDate.setValue(new Date(new Date().setDate(new Date().getDate() - threshold)));
        this.commonService.atpManagementFilter.expirationEndDate.setValue(new Date());
        break;
  
      default:
        console.log("Unknown Case");
        break;
    }
    
    this.router.navigateByUrl('/atp/management');
  }

  goToAtpCourses(filterType: string): void {
    let threshold = (this.atpFilterInterval === 'Weekly') ? 7 : (this.atpFilterInterval === 'Monthly') ? 30 : (this.atpFilterInterval === 'Quarterly') ? 90 : 365        
    switch (filterType) {
      case 'upcoming':
        this.atpService.filterCourseATPString = '';
        this.atpService.filterCourseStartDate = new Date();
        this.atpService.filterCourseEndDate = new Date(new Date().setDate(new Date().getDate() + threshold));
        this.atpService.filterCourseDelinquents = false;
        this.atpService.filterCourseString = '';
        break;
  
      case 'recent':
        this.atpService.filterCourseATPString = '';
        this.atpService.filterCourseStartDate = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.atpService.filterCourseEndDate = new Date();
        this.atpService.filterCourseDelinquents = false;
        this.atpService.filterCourseString = '';
        break;
  
      case 'delinquent':
        this.atpService.filterCourseATPString = '';
        this.atpService.filterCourseStartDate = null;
        this.atpService.filterCourseEndDate = new Date(new Date().setDate(new Date().getDate() + threshold));
        this.atpService.filterCourseDelinquents = true;
        this.atpService.filterCourseString = '';
        break;
    
      default:
        console.log("Unknown Case");
        break;
    }
    this.router.navigateByUrl('/atp/courses');
  }
}
