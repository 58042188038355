<mat-card [style.background-color]="uiData.atp.color">
    <h2 mat-dialog-title>
        <mat-icon>group</mat-icon> Match Student Uploads to Existing Individuals
    </h2>
</mat-card>
<mat-dialog-content class="scroll-container">
    <section *ngIf="loading">
        <h2>Loading...</h2>
        <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
        </mat-progress-bar>
    </section>
    <mat-card *ngIf="!loading">
        <div>
            <mat-accordion>
                <ng-container *ngFor="let student of data.students; let i = index">
                    <mat-expansion-panel [disabled]="dataSource[i].data.length === 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ student.firstName }} {{ student.lastName }}
                            </mat-panel-title>
                            <mat-panel-description *ngIf="dataSource[i].data.length === 0">
                                No possible matches found
                            </mat-panel-description>
                            <mat-panel-description *ngIf="student.selectedPerson">
                                <div class="selected-person-container">
                                    <span>
                                        Selected: {{ student.selectedPerson.id }}, {{ student.selectedPerson.firstName }} {{ student.selectedPerson.middleName }} {{ student.selectedPerson.lastName }}, DOB: {{ student.selectedPerson.dateOfBirth | date }}
                                    </span>
                                    <button mat-icon-button class="clear-button" (click)="clearSelection(i, $event)">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                            </mat-panel-description>
                            <mat-panel-description *ngIf="!student.selectedPerson && dataSource[i].data.length > 0">
                                possible matches found: {{ dataSource[i].data.length }}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                            <mat-table [dataSource]="dataSource[i]" matSort class="mat-elevation-z8">
                                <!-- Dynamic Columns -->
                                <ng-container *ngFor="let column of columnNames" [matColumnDef]="column">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header [fxFlex]="columnData[column].displaySize + '%'">{{ columnData[column].displayName }}</mat-header-cell>
                                    <mat-cell *matCellDef="let element" [fxFlex]="columnData[column].displaySize + '%'">
                                        <ng-container [ngSwitch]="columnData[column].displayType">
                                            <span *ngSwitchCase="'boolean'">
                                                <mat-radio-button [checked]="student.selectedPerson === element"></mat-radio-button>
                                            </span>
                                            <span *ngSwitchCase="'date'">{{ element[column] | date }}</span>
                                            <span *ngSwitchDefault>{{ element[column] }}</span>
                                        </ng-container>
                                    </mat-cell>
                                </ng-container>

                                <!-- Header and Row Declarations -->
                                <mat-header-row *matHeaderRowDef="columnNames"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: columnNames" (click)="updateSelection(row, $event, i)"></mat-row>
                            </mat-table>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
    </mat-card>
</mat-dialog-content>
<mat-card [style.background-color]="uiData.atp.color">        
    <div>        
        <button mat-button mat-raised-button (click)="returnResults()">
            Done
        </button>
    </div>
</mat-card>