import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IPerson } from 'app/models/People/person';
import { IAtpPerson } from 'app/models/atp/atpPerson';
import { IFirm } from 'app/models/firms/firm';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { AtpService } from 'app/services/atp/atp.service';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-firm-affiliation',
  templateUrl: './add-firm-affiliation.component.html',
  styleUrls: ['./add-firm-affiliation.component.scss']
})
export class AddFirmAffiliationComponent implements OnInit {
  public entityList:any[] = [];
  public filteredEntityList: any[] = [];
  public selectedEntity:string;  
  public uiData = LEAD_BASED_PAINT;
  
  constructor(private dialogRef: MatDialogRef<AddFirmAffiliationComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{currentEntities:any [],person:IPerson},
    public firmService:FirmService,
    public atpService: AtpService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {    
    this.firmService.getFirms().subscribe(result=>{          
      this.entityList = result;      
      this.atpService.getATPs().subscribe(result=>{
        this.entityList = this.entityList.concat(result);
        this.entityList = this.entityList.filter(entity => 
          !this.data.currentEntities.some(currentEntity => currentEntity.id === entity.id)
        );        
        this.filteredEntityList = this.entityList;   
      },error=>{this.toastr.error("Failed to get ATPs: ", error);})      
    },error=>{this.toastr.error("Failed to get firms: ", error);})
  }

  filterEntities(event: any) {
    const value = event.target.value;
    if (!value) {
      this.filteredEntityList = [...this.entityList];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredEntityList = this.entityList.filter(entity => entity.name.toLowerCase().includes(filterValue));
  }  

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    let filteredEntity = this.entityList.filter(firm=> firm?.name === this.selectedEntity)[0];    
    if (filteredEntity != null){      
      if(filteredEntity.atporId === undefined){
        const firmPerson: IFirmPerson = {
          id: 0,
          firm: filteredEntity,
          person: this.data.person,
          application: null,
          isPOC: false,
        }
        this.firmService.saveFirmPerson(firmPerson).subscribe(result=>{
          this.toastr.success("Firm " + filteredEntity.id + ": " + filteredEntity.name + "was added to the person " + this.data.person.id + ": " + this.data.person.firstName + " " + this.data.person.lastName)
          this.dialogRef.close(true);
        },error=>{
          this.toastr.error("Failed to add the firm person realationship: ", error);
        })   
      }                
      else{
        const atpPerson: IAtpPerson = {
          id: 0,
          atp: filteredEntity,
          person: this.data.person,
          personType: null,
          isContact: false,
          isInstructor: false,
          isPrimaryInstructor: false,
          isTrainingManager: false,          
        }
        this.atpService.saveATPPerson(atpPerson).subscribe(result=>{
          this.toastr.success("ATP " + filteredEntity.id + ": " + filteredEntity.name + "was added to the person " + this.data.person.id + ": " + this.data.person.firstName + " " + this.data.person.lastName)
          this.dialogRef.close(true);
        },error=>{
          this.toastr.error("Failed to add the ATP person realationship: ", error);
        })   
      }
    }
  }
}
