<div fxLayout="column">
    <mat-card [style.background-color]="uiData.individual.color">
        <mat-card-title>
           Select an option
        </mat-card-title>
    </mat-card>
    <div>    
<div class="checkbox-container">
    
    <mat-checkbox [(ngModel)]="selectedCertificates" value="certificate">LBPA Certificate</mat-checkbox>
    <mat-checkbox [(ngModel)]="selectedBadges" value="badge">LBPA Badge</mat-checkbox>
    <mat-checkbox [(ngModel)]="selectedLetters" value="letter">LBPA Letter</mat-checkbox>
</div>
    <mat-card [style.background-color]="uiData.individual.color" fxLayout="row wrap" fxLayoutGap="8px">    
      <button [disabled]="!selectedCertificates && !selectedLetters && !selectedBadges" mat-raised-button color="primary" (click)="onPrintClick()">Print</button>
      <button mat-raised-button (click)="onCancel()">Cancel</button>        
    </mat-card>
  </div>