import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ANIMATIONS, APPLICATION_STATUS, LEAD_BASED_PAINT, getStatusColor } from '@shared/utils/app-static-data';
import { CommonDataService } from 'app/services/common/common-data.service';
import { PersonService } from 'app/services/person/person.service';
import { IPerson } from 'app/models/People/person';
import { ToastrService } from 'ngx-toastr';
import { IPersonContactInformation } from 'app/models/People/personContactInformation';
import { IPersonAddress } from 'app/models/People/personAddress';
import { IPersonApplication } from 'app/models/People/person-application';
import { SharedService } from 'app/services/core/shared.service';
import { IContactInformation } from 'app/models/common/contactInformation';
import { IAddress } from 'app/models/common/address';
import { AddIndividualApplicationComponent } from 'app/components/application/individual/add-individual-application/add-individual-application.component';
import { FirmService } from 'app/services/firm/firm-service';
import { AddFirmAffiliationComponent } from './add-firm-affiliation/add-firm-affiliation.component';
import { FirmAddEditComponent } from 'app/components/firm/add-edit/firm-add-edit.component';
import { AddEditIndividualCertificateComponent } from './add-edit-individual-certificate/add-edit-individual-certificate.component';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IPersonCertificate } from 'app/models/People/person-certificate';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AtpService } from 'app/services/atp/atp.service';
import { AddEditIndividualTestingComponent } from './add-edit-individual-testing/add-edit-individual-testing.component';
import { IPersonTesting } from 'app/models/People/personTesting';
import { IPayment } from 'app/components/payments/models/payment';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IComplaint } from 'app/models/complaint/complaint';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { AddEditComplaintComponent } from 'app/components/complaint/add-edit-complaint/add-edit-complaint.component';
import { AddEditCourseStudentComponent } from 'app/components/atp/add-edit-atp/add-edit-course-student/add-edit-course-student.component';
import { AddEditFirmAddressComponent } from 'app/components/firm/add-edit/add-edit-firm-address/add-edit-firm-address.component';
import { AddEditFirmContactInformationComponent } from 'app/components/firm/add-edit/add-edit-firm-contact-information/add-edit-firm-contact-information.component';
import { PrintCertificateModalComponent } from './print-certificate-modal/print-certificate-modal.component';
import { AddEditAtpComponent } from 'app/components/atp/add-edit-atp/add-edit-atp.component';

@Component({
  selector: 'app-add-edit-individual',
  templateUrl: './add-edit-individual.component.html',
  styleUrls: ['./add-edit-individual.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: ANIMATIONS,
})
export class AddEditIndividualComponent {
  public isMobile: boolean = false;
  public isPrintVisible: boolean = false;
  public showHistory: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public personForm = null;
  public personContactForm = null;
  public personData:IPerson = null;    
  public contactInformation:IContactInformation = {id: 0, phone: '', cell: '', fax: '', email: '', website: '', ext: '', contactType: 'Work', label: ''};
  public address:IAddress = {id: 0, street: '', city: '', state: '', zip: '', county: '', contactType: 'Work'};
  public personContact:IPersonContactInformation = null;
  public personAddress:IPersonAddress = null;
  public changeIndicator: number = 0;
  private unmaskedSSN: string = "";

  public personCertificates:IPersonCertificate[] = [];
  public personFilteredCertificates:IPersonCertificate[] = [];
  private certificateFilterString:string = "";
  public certificateStageFilter: FormControl = new FormControl('Both');
  private certificateDebounceString: Subject<string> = new Subject<string>();
  public certificateDataSource: MatTableDataSource<any>;
  public certificateTable = 
  {
    layout:{
      columns:['id', 'codeType', 'stage', 'certificateNumber', 'controlNumber', 'issueDate', 'expirationDate', 'dateOfLetter'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'10'},       
        {displayName:'Discipline',columnName:'codeType', type:'string', size:'15'}, 
        {displayName:'Stage',columnName:'stage', type:'string', size:'10'},        
        {displayName:'Certificate Number',columnName:'certificateNumber', type:'string', size:'12'},
        {displayName:'Control Number',columnName:'controlNumber', type:'string', size:'10'},
        {displayName:'Issue Date',columnName:'issueDate', type:'date', size:'10'},                
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'10'},
        {displayName:'Date of Letter',columnName:'dateOfLetter', type:'date', size:'10'},
      ],
      data: []      
    }
  };
  public certificateTablePageSize = 5;
  @ViewChild('CertTablePaginator') certificatePaginator: MatPaginator;
  @ViewChild('CertTableSort') certificateSort: MatSort;
  
  public personApplications:IPersonApplication[] = [];
  public personFilteredApplications:IPersonApplication[] = [];
  private applicationFilterString:string = "";
  public applicationStatusFilter: FormControl = new FormControl('All');
  private applicationDebounceString: Subject<string> = new Subject<string>();
  public applicationDataSource: MatTableDataSource<any>;
  public applicationTable = 
  {
    layout:{
      columns:['id','userID', 'status', 'licenseDate', 'lastUpdatedBy', 'lastUpdated','approverEmail','approvalDate'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'10'},        
        {displayName:'User Id',columnName:'userID', type:'string', size:'10'},
        {displayName:'Status',columnName:'status', type:'string', size:'10'},
        {displayName:'Application Date',columnName:'licenseDate', type:'date', size:'13'},
        {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'14'},
        {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'14'},
        {displayName:'Approver Email',columnName:'approverEmail', type:'string', size:'14'},
        {displayName:'Approval Date',columnName:'approvalDate', type:'date', size:'14'},          
      ],
      data: []      
    }
  }
  public applicationTablePageSize = 5;
  @ViewChild('ApplicationTablePaginator') applicationPaginator: MatPaginator;
  @ViewChild('ApplicationTableSort') applicationSort: MatSort;

  public testingTableColumns = ['id', 'codeType', 'testDate', 'testSerial', 'testScore', 'passFail', 'actions']
  public testingTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Discipline',columnName:'codeType', type:'string', size:'25'},
    {displayName:'Test Date',columnName:'testDate', type:'date', size:'15'},
    {displayName:'Test Serial#',columnName:'testSerial', type:'string', size:'15'},
    {displayName:'Test Score',columnName:'testScore', type:'string', size:'13'},
    {displayName:'Pass/Fail',columnName:'passFail', type:'string', size:'15'},
  ];
  public testingTableData:IPersonTesting[] = []
  public testingFilteredTableData:IPersonTesting[] = []
  public testingTablePageSize = 5;
  public testingDataSource: MatTableDataSource<any>;
  @ViewChild('TestingTablePaginator') testingPaginator: MatPaginator;
  @ViewChild('TestingTableSort') testingSort: MatSort;

  public trainingTableColumns = ['id', 'discipline', 'courseStart', 'courseEnd', 'testScore', 'courseCertificateNumber', 'actions']
  public trainingTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Discipline',columnName:'discipline', type:'string', size:'25'},
    {displayName:'Start Date',columnName:'courseStart', type:'date', size:'15'},
    {displayName:'End Date',columnName:'courseEnd', type:'date', size:'15'},
    {displayName:'Test Score',columnName:'testScore', type:'string', size:'13'},
    {displayName:'Certificate Number',columnName:'courseCertificateNumber', type:'string', size:'15'},
  ];
  public trainingTableData = []
  public trainingFilteredTableData = []
  public trainingTablePageSize = 5;
  public trainingDataSource: MatTableDataSource<any>;
  @ViewChild('TrainingTablePaginator') trainingPaginator: MatPaginator;
  @ViewChild('TrainingTableSort') trainingSort: MatSort;
  public cardColor: string = this.sharedService.gold + '20';

  private affiliationFilterString:string = "";  
  public personAffiliations=null;
  public personFilteredAffiliations = [];
  private affiliationDebounceString: Subject<string> = new Subject<string>();
  public affiliationDataSource: MatTableDataSource<any>;
  public affiliationTable = 
  {
    layout:{
      columns:['id','name','type'],
      container:[        
        {displayName:'Id',columnName:'id', type:'string', size:'10'},        
        {displayName:'Affiliation Name',columnName:'name', type:'string', size:'68'},        
        {displayName:'Affiliation Type',columnName:'type', type:'string', size:'15'},        
      ],
      data: []
    }
  }; 
  public affiliationTablePageSize = 5;

  complaintsTable = {
    icon: 'recent_actors',
    title:'Complaints',
    layout:{
      columns:['id', 'complainteeName', 'complainantName', 'status', 'complaintDate', 'lastUpdatedBy', 'lastUpdated'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Complaint Source Name',columnName:'complainteeName', type:'string', size:'20'},
        {displayName:'Complaint Target Name',columnName:'complainantName', type:'string', size:'20'},        
        {displayName:'Status',columnName:'status', type:'string', size:'10'},          
        {displayName:'Complaint Date',columnName:'complaintDate', type:'date', size:'15'},     
        {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'15'},           
        {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'10'},          
      ],
      data: []      
    }
  }

  public complaints: IComplaint[] = [];
  public complaintDataSource: MatTableDataSource<any>;
  
  @ViewChild('AffiliationTablePaginator') affiliationPaginator: MatPaginator;
  @ViewChild('AffiliationTableSort') affiliationSort: MatSort;

  public statusColor = getStatusColor;  

  public addressTableColumns = ['id', 'contactType', 'street', 'city', 'state', 'zip', 'isCurrentPhysical', 'isCurrentMailing', 'actions']
  public addressTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'9'}, 
    {displayName:'Type',columnName:'contactType', type:'string', size:'10'},
    {displayName:'Street',columnName:'street', type:'string', size:'21'},
    {displayName:'City',columnName:'city', type:'string', size:'15'},
    {displayName:'State',columnName:'state', type:'string', size:'7'},
    {displayName:'Zip',columnName:'zip', type:'string', size:'7'},
    {displayName:'Current Physical',columnName:'isCurrentPhysical', type:'bool', size:'15'},
    {displayName:'Current Mailing',columnName:'isCurrentMailing', type:'bool', size:'15'},    
  ];
  public addressTableData = [];
  public addressFilteredTableData = []
  public addressTablePageSize = 5;
  public filterAddressString: string = ''; 
  public filterAddressType = new FormControl([]);
  public filterAddressPhysical = new FormControl([]);
  public filterAddressMailing = new FormControl([]); 
  public addressTypeList = [];
  public addressBoolList = Object.values(['Unchecked','Checked']);
  public addressDataSource: MatTableDataSource<any>;
  @ViewChild('AddressTablePaginator') addressPaginator: MatPaginator;
  @ViewChild('AddressTableSort') addressSort: MatSort;

  public contactInformationTableColumns = ['id', 'contactType', 'label', 'phone', 'cell', 'fax', 'email', 'website', 'actions']
  public contactInformationTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Type',columnName:'contactType', type:'string', size:'15'},
    {displayName:'Name',columnName:'label', type:'string', size:'15'},
    {displayName:'Phone',columnName:'phone', type:'string', size:'15'},
    {displayName:'Cell',columnName:'cell', type:'string', size:'8'},
    {displayName:'Fax',columnName:'fax', type:'string', size:'7'},
    {displayName:'Email',columnName:'email', type:'string', size:'15'},
    {displayName:'Website',columnName:'website', type:'string', size:'15'},     
  ];
  public contactInformationTableData = []
  public contactInformationFilteredTableData = []
  public contactInformationTablePageSize = 5;
  public filterContactInformationString: string = ''; 
  public filterContactInformationType = new FormControl([]);
  public contactInformationTypeList = [];
  public contactInformationDataSource: MatTableDataSource<any>;
  @ViewChild('ContactInformationTablePaginator') contactInformationPaginator: MatPaginator;
  @ViewChild('ContactInformationTableSort') contactInformationSort: MatSort;


  constructor(@Inject(MAT_DIALOG_DATA) public data: {person: IPerson},
  private breakpointObserver: BreakpointObserver,
  public commonService: CommonDataService,
  public sharedService: SharedService,
  public personService: PersonService,
  public atpService: AtpService,
  public firmService: FirmService,
  public dialog: MatDialog,
  public paymentService: PaymentService,
  private dialogRef: MatDialogRef<AddEditIndividualComponent>,
  public complaintService: ComplaintService,
  private toastr: ToastrService,) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.personData = this.data.person;    

    this.initializeDebounceFunctions();
    this.initializeForms();  
    this.personService.getPersonById(this.personData.id).subscribe(result=>{
      this.personData = result;  
      this.fillPersonFormData();
      this.loadAdditionalPersonData();
    },error=>{this.toastr.error("An error occurred loading the person information: ", error)});
  }

  onSSNFocus() {
    this.personForm.get('ssn').setValue(this.unmaskedSSN);
  }

  onSSNBlur() {    
    this.unmaskedSSN = this.personForm.get('ssn').value;    
    this.personForm.get('ssn').setValue(this.maskSSN(this.unmaskedSSN));
  }

  maskSSN(ssn: string): string {
    if (!ssn) return '';
    const visibleDigits = ssn.slice(-4);
    const maskedDigits = '*'.repeat(ssn.length - 4);
    return maskedDigits + visibleDigits;
  }

  initializeDebounceFunctions(): void {
    this.certificateDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {      
      this.certificateFilterString = value.toLowerCase();
      this.filterCertificateTable();      
    });

    this.applicationDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applicationFilterString = value.toLowerCase();
      this.filterApplicationTable();        
    });

    this.affiliationDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.affiliationFilterString = value.toLowerCase();
      this.filterAffiliationTable();        
    });
  }

  initializeForms(): void {
    this.personForm = new UntypedFormGroup({      
      response: new UntypedFormControl(""), 
      inactive: new UntypedFormControl(false), 
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      middleName: new UntypedFormControl(""),
      title: new UntypedFormControl(""),
      ssn: new UntypedFormControl("", [Validators.pattern(/^(?:\*{7}|^\(?([\d]{3})\)?-?([\d]{2})-?([\d]{4})).*$/)]),
      dateOfBirth: new UntypedFormControl(""),             
    }); 

    this.personContactForm = new UntypedFormGroup({            
        street: new UntypedFormControl(""),
        city: new UntypedFormControl(""),
        state: new UntypedFormControl(""),
        zip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
        county: new UntypedFormControl(""),        
  
        phone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{3})-?([\d]{4}).*$/)]),
        cell: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{3})-?([\d]{4}).*$/)]),
        fax: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{3})-?([\d]{4}).*$/)]),
        email: new UntypedFormControl("", [Validators.email]),        
        website: new UntypedFormControl(""),              
    });
  }

  fillPersonFormData(): void {
    for (const controlName in this.personData) {
      if (this.personForm.controls.hasOwnProperty(controlName)) {
        this.personForm.controls[controlName].setValue(this.personData[controlName]);
      }
    } 
    
    this.onSSNBlur();
  }

  fillPersonContactInformationFormData(): void {
    for (const controlName in this.contactInformation) {      
      if (this.personContactForm.controls.hasOwnProperty(controlName)) {
        this.personContactForm.controls[controlName].setValue(this.contactInformation[controlName]);
      }
    }  
  }
  
  fillPersonAddressFormData(): void {
    for (const controlName in this.address) {      
      if (this.personContactForm.controls.hasOwnProperty(controlName)) {
        this.personContactForm.controls[controlName].setValue(this.address[controlName]);
      }
    }    
  }

  loadAdditionalPersonData(): void {    
    this.refreshPersonAddresses();
    this.refreshPersonContactInformations();
    this.refreshPersonApplications();
    this.refreshPersonCertificates();
    this.refreshPersonTrainings();
    
    this.getComplaintAssociations();

    this.firmService.getFirmPeopleByPersonId(this.personData.id).subscribe(result=>{      
      this.personAffiliations = result;
      this.atpService.getATPAffiliationsByPersonId(this.personData.id).subscribe(result=>{        
        this.personAffiliations = this.personAffiliations.concat(result);        
        this.filterAffiliationTable();
      },error=>{
        this.toastr.error("An error occurred loading the person ATP affiliations information: ", error)
      })      
    },error=>{
      this.toastr.error("An error occurred loading the person firm affiliations information: ", error)
    })  
    
    this.refreshPersonTesting();
  }

  getComplaintAssociations(){
    this.complaintService.getComplaintsByAssociations(this.personData.id,"Person").subscribe(result=>{      
      this.complaints = result; 
      this.complaintDataSource = new MatTableDataSource<any>(this.complaints);
    },error=>{})
  }

  refreshPersonApplications(): void {
    this.personService.getAllPersonApplications().subscribe(result=>{
      this.personApplications = result.filter(x=>x.person?.id === this.personData.id);      
      this.filterApplicationTable();      
    },error=>{
      this.toastr.error("An error occurred loading the person applications information: ", error)
    });
  }

  refreshPersonTrainings(): void{
    this.atpService.getATPCourseStudentsByStudentId(this.personData.id).subscribe(result=>{         
      this.trainingTableData = result.map((data) => ({
        ...data,
        discipline: data.atpCourse?.accreditationDiscipline,
        courseStart: data.atpCourse?.courseStart,
        courseEnd: data.atpCourse?.courseEnd
      }));               
      this.filterTrainingTable();
    },error=>{
      this.toastr.error("An error occurred loading the person training records: ", error)
    })    
  }

  refreshPersonContactInformation(): void{
    this.personService.getPersonContactInformationByPersonId(this.personData.id).subscribe(result=>{      
      this.personContact = result;

      if(this.personContact === undefined || this.personContact === null){                
        this.contactInformation = {
          id: 0,
          phone: '',
          cell: '',
          fax: '',
          email: '',
          website: '',
          ext: '',
          contactType: 'Work',
          label: '',
        };
        this.personContact = {
          id: 0, 
          person: this.personData,
          contactInformation: this.contactInformation,          
        };
      }
      else{
        this.contactInformation = this.personContact?.contactInformation ?? this.contactInformation;         
      }             
    },error=>{
      this.toastr.error("An error occurred loading the person contact information: ", error)
    },() => {
      this.fillPersonContactInformationFormData();
    })
  }

  refreshPersonAddress(): void {
    this.personService.getPersonAddressesByPersonId(this.personData.id).subscribe(result=>{
      this.personAddress = result.filter(x=>x.isCurrentMailing)[0];       

      if(this.personAddress === undefined || this.personAddress === null){                
        this.address = {
          id: 0,
          street: '',
          city: '',
          state: '',
          zip: '',
          county: '',
          contactType: 'Work',
        };
        this.personAddress = {
          id: 0, 
          person: this.personData,
          address: this.address,
          isCurrentMailing: true,
          isCurrentPhysical: false,
        };
      }
      else{
        this.address = this.personAddress?.address ?? this.address;        
      }         
    },error=>{
      this.toastr.error("An error occurred loading the person address information: ", error)
    }, () => {
      this.fillPersonAddressFormData();
    });
  }

  refreshPersonAddresses(): void {
    this.personService.getPersonAddressesByPersonId(this.personData.id).subscribe(result=>{        
      if(result != null) {
       this.addressTableData = result.map(a=>{return { original: a, id: a.id, contactType: a.address.contactType, street: a.address.street, city: a.address.city, state: a.address.state, zip: a.address.zip, isCurrentPhysical: a.isCurrentPhysical, isCurrentMailing: a.isCurrentMailing}});
       this.filterAddressTable();
      }},error=>{this.toastr.error("There was an error getting the firm addresses: ", error)}
    );
    this.refreshPersonAddress();
  }

  refreshPersonAffiliations(): void {
    this.firmService.getFirmPeopleByPersonId(this.personData.id).subscribe(result=>{      
      this.personAffiliations = result;
      this.atpService.getATPAffiliationsByPersonId(this.personData.id).subscribe(result=>{        
        this.personAffiliations = this.personAffiliations.concat(result);        
        this.filterAffiliationTable();
      },error=>{
        this.toastr.error("An error occurred loading the person ATP affiliations information: ", error)
      })            
    },error=>{
      this.toastr.error("An error occurred loading the person Firm affiliations information: ", error)
    })
  }

  refreshPersonCertificates(): void {
    this.personService.getPersonCertificates(this.personData.id).subscribe(result=>{
      this.personCertificates = result.sort((a, b) => new Date(b.issueDate).getTime() - new Date(a.issueDate).getTime());
      this.certificateTable.layout.data = this.personCertificates;
      this.filterCertificateTable()           
    })
  }

  refreshPersonTesting(): void {
    this.personService.getPersonTestings(this.personData.id).subscribe(result=>{
      this.testingTableData = result; 
      this.filterTestingTable();           
    })
  }

  refreshPersonContactInformations(): void {
    this.personService.getPersonContactInformationsByPersonId(this.personData.id).subscribe(result=>{           
      this.contactInformationTableData = result.map(a=>{return { original: a, id: a.id, contactType: a.contactInformation.contactType, phone: a.contactInformation.phone, cell: a.contactInformation.cell, fax: a.contactInformation.fax, email: a.contactInformation.email, website: a.contactInformation.website, label:a.contactInformation.label}});;      
      this.filterContactInformationTable();
    },error=>{this.toastr.error("There was an error getting the firm contact information: ", error)});
    this.refreshPersonContactInformation();
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, parseInt(limit)) + "...";
    }    
    return newText;
  }

  getStatusTypeValues() {    
    let typeValues = ["All"].concat(Object.values(APPLICATION_STATUS));
    return typeValues;
  }

  editContactInformationTableEntry(row: any){
    let personContactInformation:IPersonContactInformation = {id: 0, person: this.personData, contactInformation: {id: 0, phone: '', cell: '', fax: '', email: '', website: '', contactType: '', ext: '', label: ''}};
    
    const dialogRef = this.dialog.open(AddEditFirmContactInformationComponent, {    
      width: this.isMobile ? '70%' : '30%',    
      data: {contactInformation: row?.original ?? personContactInformation, entity: 'individual'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshPersonContactInformations();      
    });  
  }
  deleteContactInformationEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Contact Information with ID: <strong>' + row.id + '</strong>?',title: 'Delete the Contact Information'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.personService.deletePersonContactInfo(row.original.id).subscribe(result=>{
          this.commonService.deleteContactInformation(row.original.contactInformation.id).subscribe(result=>{
            this.toastr.success('Contact Information deleted successfully');
            this.refreshPersonContactInformations();
          },error=>{this.toastr.error('Error deleting Contact Information: ', error)});
        },error=>{this.toastr.error('Error deleting Firm Contact Information: ', error)})
      }
    })
  }

  filterContactInformationTable(){
    this.contactInformationFilteredTableData = this.contactInformationTableData;

    this.contactInformationFilteredTableData = this.contactInformationFilteredTableData.filter(x => {      
      return x.id?.toString().includes(this.filterContactInformationString) ||      
        x.phone?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.cell?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.fax?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.email?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase()) ||
        x.website?.toString().toLowerCase().includes(this.filterContactInformationString.toLowerCase())
    });

    if(this.filterContactInformationType.value.length > 0){
      this.contactInformationFilteredTableData = this.contactInformationFilteredTableData.filter(x => this.filterContactInformationType.value.includes(x.contactType));
    }

    this.updateContactInformationDataSource();
  }

  updateContactInformationDataSource(){    
    this.contactInformationDataSource = new MatTableDataSource<any>(this.contactInformationFilteredTableData);
    this.contactInformationDataSource.paginator = this.contactInformationPaginator;
    this.contactInformationDataSource.sort = this.contactInformationSort;    
  }

  editAddressTableEntry(row: any){    
    let personAddress:IPersonAddress  = {id: 0, person: this.personData, address: {id: 0, street: '', city: '', state: '', zip: '', county: '', contactType: ''}, isCurrentPhysical: false, isCurrentMailing: false};
    
    const dialogRef = this.dialog.open(AddEditFirmAddressComponent, {    
      width: this.isMobile ? '70%' : '30%',    
      data: {address: row?.original ?? personAddress, entity: 'individual'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshPersonAddresses();        
      }    
    });  
  }
  deleteAddressEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Address at: <strong>' + row.street + '</strong>?',title: 'Delete the Address'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){        
        this.personService.deletePersonAddress(row.original.id).subscribe(result=>{
          this.commonService.deleteAddress(row.original.address.id).subscribe(result=>{
            this.toastr.success('Address deleted successfully');
            this.refreshPersonAddresses();
          },error=>{this.toastr.error('Error deleting Address: ', error)});
        },error=>{this.toastr.error('Error deleting Address: ', error)})
      }
    })
  }

  filterAddressTable(){
    this.addressFilteredTableData = this.addressTableData;

    this.addressFilteredTableData = this.addressFilteredTableData.filter(x => {      
      return x.id?.toString().includes(this.filterAddressString) ||      
        x.street?.toString().toLowerCase().includes(this.filterAddressString.toLowerCase()) ||
        x.city?.toString().toLowerCase().includes(this.filterAddressString.toLowerCase()) ||
        x.zip?.toString().toLowerCase().includes(this.filterAddressString.toLowerCase())
    });

    if(this.filterAddressType.value.length > 0){
      this.addressFilteredTableData = this.addressFilteredTableData.filter(x => this.filterAddressType.value.includes(x.contactType));
    }

    if(this.filterAddressPhysical.value.length > 0){
      this.addressFilteredTableData = this.addressFilteredTableData.filter(x => 
          (this.filterAddressPhysical.value.includes('Unchecked') && x.isCurrentPhysical === false) ||
          (this.filterAddressPhysical.value.includes('Checked') && x.isCurrentPhysical === true)
      );
    }

    if(this.filterAddressMailing.value.length > 0){
      this.addressFilteredTableData = this.addressFilteredTableData.filter(x => 
          (this.filterAddressMailing.value.includes('Unchecked') && x.isCurrentMailing === false) ||
          (this.filterAddressMailing.value.includes('Checked') && x.isCurrentMailing === true)
      );
    }

    this.updateAddressDataSource();
  }

  updateAddressDataSource(){    
    this.addressDataSource = new MatTableDataSource<any>(this.addressFilteredTableData);
    this.addressDataSource.paginator = this.addressPaginator;
    this.addressDataSource.sort = this.addressSort;    
  }

  saveChanges(){    
    this.updatePersonData();    
    this.personService.savePerson(this.personData).subscribe(result=>{
      this.personData = result;
      this.toastr.success(this.personData.firstName + " " + this.personData.lastName + " was successfully updated");            
    },
    error=>{this.toastr.error("An error occurred saving the Person: ", error)});
    
    this.updateAddressData();    
    this.commonService.saveAddress(this.address).subscribe(result=>{      
      this.address = result;      
      this.personAddress.address = result;

      if(this.personAddress.id === 0){
        this.personService.savePersonAddress(this.personAddress).subscribe(result=>{
          this.personAddress = result;
        },err=>{this.toastr.error("An error occurred saving the Person Address: ", err)})
      }
    },error=>{
      this.toastr.error("An error occurred saving the Address: ", error)
    });

    this.updateContactData();        
    this.commonService.saveContactInformation(this.contactInformation).subscribe(result=>{
      this.contactInformation = result;
      this.personContact.contactInformation = result;

      if(this.personContact.id === 0){
        this.personService.savePersonContactInfo(this.personContact).subscribe(result=>{
          this.personContact = result;
        },err=>{this.toastr.error("An error occurred saving the Person Contact Information: ", err)})
      }
    },error=>{
      this.toastr.error("An error occurred saving the Contact Information: ", error)
    });
  }

  updatePersonData(): void {    
    for (const controlName in this.personData) {
      if (this.personForm.controls.hasOwnProperty(controlName)) {
        this.personData[controlName] = this.personForm.controls[controlName].value;        
      }
    } 
    this.personData.ssn = this.unmaskedSSN;
  }

  updateContactData(): void {    
    for (const controlName in this.contactInformation) {
      if (this.personContactForm.controls.hasOwnProperty(controlName)) {
        this.contactInformation[controlName] = this.personContactForm.controls[controlName].value;        
      }
    } 
  }

  updateAddressData(): void {    
    for (const controlName in this.address) {
      if (this.personContactForm.controls.hasOwnProperty(controlName)) {
        this.address[controlName] = this.personContactForm.controls[controlName].value;        
      }
    } 
  }

  updateResponse(event: any) {    
    this.personForm.get('response').setValue(event.checked ? 'Yes' : 'No');
  }

  updateCertificateDebounceString(event: any){    
    this.certificateDebounceString.next(event.target.value);    
  }
  filterCertificateTable(){
    if (this.showHistory) {      
      this.personFilteredCertificates = this.personCertificates.filter(x =>
        (x.id + ' ' + x.controlNumber + ' ' + x.codeType + ' ' + x.certificateNumber).toLowerCase().includes(this.certificateFilterString)
        && ((this.certificateStageFilter.value === "Both") ? true : (x.stage === this.certificateStageFilter.value))
      );
    } else {
      this.personFilteredCertificates = this.personCertificates
        .filter(x =>
          (x.id + ' ' + x.controlNumber + ' ' + x.codeType + ' ' + x.certificateNumber).toLowerCase().includes(this.certificateFilterString)
          && ((this.certificateStageFilter.value === "Both") ? true : (x.stage === this.certificateStageFilter.value))
        )
        .reduce((acc, curr) => {
          const existing = acc.find(x => x.codeType === curr.codeType);
          if (!existing) {
            acc.push(curr);
          } else {
            if (new Date(curr.expirationDate) > new Date(existing.expirationDate)) {
              const index = acc.indexOf(existing);
              acc[index] = curr;
            }
          }
          return acc;
        }, []);
    }
    
    this.updateCertificateSource();
  }
  updateCertificateSource(){    
    this.certificateDataSource = new MatTableDataSource<any>(this.personFilteredCertificates);
    this.certificateDataSource.sort = this.certificateSort;
    this.certificateDataSource.paginator = this.certificatePaginator;
  }

  editCertificate(row: any){    
    const dialogRef = this.dialog.open(AddEditIndividualCertificateComponent, {        
      data: {certificate: row === null ? null : row, person: this.personData, canEdit: this.canRenewCertificate(row)},
      width: this.isMobile ? '90%' : '30%',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshPersonCertificates();      
    });  
  }
  
  isLbpaCert(codeType: any): boolean{
    return codeType == 'Supervisor' || codeType == 'Risk Assessor' || codeType == 'Project Designer' || codeType == 'Inspector' || codeType == 'Worker'
  }

  canRenewCertificate(personCert: IPersonCertificate){    
    return this.personApplications.some(
      x=>x.previousApplication === personCert?.controlNumber 
      && x.status !== APPLICATION_STATUS.approved 
      && x.status !== APPLICATION_STATUS.cancelled
    )
  }

  canViewPrintCertificate(personCert: IPersonCertificate){        
    return true;
  }

  updateApplicationDebounceString(event: any){    
    this.applicationDebounceString.next(event.target.value);    
  }
  filterApplicationTable(){    
    this.personFilteredApplications = this.personApplications.filter(x=>
      (x.id + ' ' + x.userID).toLowerCase().includes(this.applicationFilterString)
      && ((this.applicationStatusFilter.value === "All") ? true: (x.status === this.applicationStatusFilter.value))
    );    
    this.updateApplicationSource();
  }
  updateApplicationSource(){    
    this.applicationDataSource = new MatTableDataSource<any>(this.personFilteredApplications);
    this.applicationDataSource.sort = this.applicationSort;
    this.applicationDataSource.paginator = this.applicationPaginator;
  }

  viewApplication(event: any, row: any, renewal: boolean = false, cert = null){     
    if(cert !== null && cert !== undefined){
      row = this.personApplications.find(x=>x.previousApplication === cert.controlNumber && x.status !== APPLICATION_STATUS.cancelled && x.status !== APPLICATION_STATUS.approved) ?? null;      
    }
    const dialogRef = this.dialog.open(AddIndividualApplicationComponent, {
      width: '90%',
      data: {application: row, edit: true, renewal: renewal, certificate: cert, person: this.personData, personAddress: this.personAddress, personContact: this.personContact},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshPersonApplications();
      this.refreshPersonCertificates();
      this.refreshPersonContactInformation();
      this.refreshPersonAddress();
      this.changeIndicator++;
    });
  }

  updateAffiliationDebounceString(event: any){    
    this.affiliationDebounceString.next(event.target.value);    
  }
  filterAffiliationTable(){
    const firmObjects = this.personAffiliations.map(item => {
      const affiliation = item.firm || item.atp;
      return {
        ...affiliation,
        type: item.firm ? 'Firm' : 'ATP'
      };
    });
    
    this.personFilteredAffiliations = firmObjects.filter(x => 
      (x?.id + ' ' + x?.name)?.toLowerCase().includes(this.affiliationFilterString)
    );
    this.updateAffiliationSource();
  }
  updateAffiliationSource(){    
    this.affiliationDataSource = new MatTableDataSource<any>(this.personFilteredAffiliations);
    this.affiliationDataSource.sort = this.affiliationSort;
    this.affiliationDataSource.paginator = this.affiliationPaginator;
  }

  deleteAffiliation(row: any){        
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message:'Are you sure you want to remove the ' + row.type + ' Affiliation: <strong>' + row.name + '</strong>?',title:'Delete ' + row.type + ' Affiliation'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        if(row.type === 'ATP'){
          const affiliationToDelete = this.personAffiliations.filter(affiliation => affiliation.atp?.id === row.id)[0]    
          this.atpService.deleteATPPerson(affiliationToDelete.id).subscribe(result=>{
            this.refreshPersonAffiliations();
            this.toastr.success("The Affiliation: " + row.name + " was successfully removed.")
          },error=>{
            this.toastr.error("Failed to remove the Affiliation: ", error)
          })
        }
        else{
          const affiliationToDelete = this.personAffiliations.filter(affiliation => affiliation.firm?.id === row.id)[0]              
          this.firmService.deleteFirmPerson(affiliationToDelete.id).subscribe(result=>{
            this.refreshPersonAffiliations();
            this.toastr.success("The Affiliation: " + row.name + " was successfully removed.")
          },error=>{
            this.toastr.error("Failed to remove the Affiliation: ", error)
          })
        }
      }
    });
  }

  viewAffiliation(row: any){
    if(row !== null){      
      if(row.type === 'ATP'){
        const dialogRef = this.dialog.open(AddEditAtpComponent, {
          width: '90%',
          data: {atp: row},
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {        
          this.refreshPersonAffiliations();
        });
      }
      else{
        const dialogRef = this.dialog.open(FirmAddEditComponent, {
          width: '90%',
          data: row,
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {        
          this.refreshPersonAffiliations();
        });
      }             
    }
    else{
      const dialogRef = this.dialog.open(AddFirmAffiliationComponent, {      
        data: {currentEntities: this.personAffiliations.map(item => item.firm || item.atp), person: this.personData},
        width: this.isMobile ? '90%' : '30%',
        autoFocus: false,
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.refreshPersonAffiliations();
        }        
      });
    }    
  }

  viewPrintCertificateModal(row: any){    
    this.dialog.open(PrintCertificateModalComponent, {      
      data: {person: row, address: this.address, firmName : this.personAffiliations[0]?.firm.name},   
      width: this.isMobile ? '90%' : '30%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }

  exitComponent(){
    this.dialogRef.close();
  }

  editTrainingTableEntry(row: any){
    const dialogRef = this.dialog.open(AddEditCourseStudentComponent, {
      width: '90%',
      autoFocus: false,
      data: {student: row, course: row.atpCourse},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {});
  }
  filterTrainingTable(){
    this.trainingFilteredTableData = this.trainingTableData;    
    this.updateTrainingSource();
  }
  updateTrainingSource(){    
    this.trainingDataSource = new MatTableDataSource<any>(this.trainingFilteredTableData);
    this.trainingDataSource.sort = this.trainingSort;
    this.trainingDataSource.paginator = this.trainingPaginator;
  }

  editTestingTableEntry(row: any){
    const dialogRef = this.dialog.open(AddEditIndividualTestingComponent, {    
      width: '70%',    
      data: {testing: row, person: this.personData},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshPersonTesting();
      }    
    });  
  }

  deleteTestingEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Test Data: <strong>' + row.codeType + '</strong>?',title: 'Delete the Test Data'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.personService.deletePersonTesting(row.id).subscribe(result=>{
          this.toastr.success('Test Data deleted successfully');
          this.refreshPersonTesting();
        },error=>{this.toastr.error('Error deleting Action: ', error)})
      }
    })
  }

  filterTestingTable(){    
    this.testingFilteredTableData = this.testingTableData;    
    this.updateTestingSource();
  }
  updateTestingSource(){    
    this.testingDataSource = new MatTableDataSource<any>(this.testingFilteredTableData);
    this.testingDataSource.sort = this.testingSort;
    this.testingDataSource.paginator = this.testingPaginator;
  }

  editComplaint(complaint: IComplaint) {    
    if(complaint != null && complaint.id > 0){
      this.complaintService.getComplaintById(complaint.id).subscribe(result=>{   
        const dialogRef = this.dialog.open(AddEditComplaintComponent, {
          width: '90%',
          data: {complaint : result, associationId : this.personData.id, associationType: "Person"},
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {  
          this.getComplaintAssociations();    
        });      
      },error=>{  
      });   
      }else{ const dialogRef = this.dialog.open(AddEditComplaintComponent, {
        width: '90%',
        data: {complaint : null, associationId : this.personData.id, associationType: "Person" },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {  
        this.getComplaintAssociations();    
      });
    }   
  }
}
