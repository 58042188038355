import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IDocumentFile } from 'app/models/document/document-file';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.scss']  
})
export class AddDocumentComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT;
  public color = 'white';
  public fileDescription = new FormControl('');
  public documentData: any = {id: 0};
  public fileName: string = '';
  public valid: boolean = false;
  public documentTypes: string[] = [];
  public filteredDocumentTypes: Observable<string[]>;
  public isProcessing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddDocumentComponent>,
    public dialog: MatDialog,    
    private commonService: CommonDataService,
    private personService: PersonService,
    private firmService: FirmService,
    private atpService: AtpService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data:{document: any, entity: string, entityRef: any}
  ) 
  {
    this.setColor();
    if(this.data.document !== null){
      this.documentData = this.data.document;
      this.fileDescription.setValue(this.documentData.fileDescription);
      this.fileName = this.documentData.fileName;
      this.valid = true;
    }
    else{
      switch(this.data.entity){
        case 'individual':      
          this.documentData = {
            id: 0,
            application: this.data.entityRef,
            document: null,
            fileName: "",
            fileType: "",
            fileDescription: "",
          };
          this.documentTypes = ['	Training Certificate','Passport Style Photo','Another State Current Certificate','Experience/Education'];	
          break;
        case 'firm':
          this.documentData = {
            id: 0,
            application: this.data.entityRef,
            document: null,
            fileName: "",
            fileType: "",
            fileDescription: "",
          };
          this.documentTypes = ['Current Individual Training Certificate']
          break;
        case 'atpAudit':
          this.documentData = {
            id: 0,
            atpAudit: this.data.entityRef,
            document: null,
            fileName: "",
            fileType: "",
            fileDescription: "",
          };
          break;
        case 'atpAccreditation':
          this.documentData = {
            id: 0,
            atpAccreditation: this.data.entityRef,
            document: null,
            fileName: "",
            fileType: "",
            fileDescription: "",
          };
          break;
      }     
    }
  }

  ngOnInit(): void {
    this.filteredDocumentTypes = this.fileDescription.valueChanges.pipe(
      startWith(''),
      map(value => this.filterFileTypes(value))
    );
  }

  private filterFileTypes(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.documentTypes.filter(option => option.toLowerCase().includes(filterValue));
  }

  savePrompt(){
    this.isProcessing = true;
    this.documentData.fileDescription = this.fileDescription.value;
    this.saveApplicationDocument(this.documentData)
  }

  setColor(){    
    switch(this.data.entity){
      case 'individual':
        this.color = this.uiData.individual.color;
        break;
      case 'firm':
        this.color = this.uiData.firm.color;
        break;
      case 'atpAudit':
        this.color = this.uiData.atp.color;
        break;
      case 'atpAccreditation':
        this.color = this.uiData.atp.color;
        break;
    }    
  }

  async onFileSelected(event: any) {   
    this.isProcessing = true;
    try{ 
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, '').replace(/.txt$/, ''));

      if (file) {
        const fileData = await this.commonService.getFileSerializedData(dirtyFile);
      
        let docFile: IDocumentFile = {
          id: 0,
          file: fileData.serializedData,
        }

        this.documentData.document = docFile;
        this.documentData.fileName = file.name;
        this.documentData.fileType = fileData.fileType;              
        
        this.fileName = file.name;
        this.valid = true;        
      }   
    } catch (error) {
      this.toastr.error("An error occurred while processing the file:", error);
    } 
    this.isProcessing = false;
  }

  saveApplicationDocument(applicationDocument: any){    
    this.commonService.saveDocumentFile(applicationDocument.document).subscribe(result=>{
      applicationDocument.document = result;
      switch(this.data.entity){
        case 'individual':
          this.personService.savePersonApplicationDocument(applicationDocument).subscribe(result=>{
            this.dialogRef.close(true);
          },error => {
            this.isProcessing = false;
            this.toastr.error("An error occurred while processing the person application file: ", error);
          });
          break;
        case 'firm':
          this.firmService.saveFirmApplicationDocument(applicationDocument).subscribe(result=>{
            this.dialogRef.close(true);
          },error => {
            this.isProcessing = false;
            this.toastr.error("An error occurred while processing the firm application file: ", error);
          });
          break;
        case 'atpAudit':
          this.atpService.saveATPAuditDocument(applicationDocument).subscribe(result=>{
            this.dialogRef.close(true);
          },error => {
            this.isProcessing = false;
            this.toastr.error("An error occurred while processing the firm application file: ", error);
          });
          break;
        case 'atpAccreditation':
          this.atpService.saveATPAccreditationDocument(applicationDocument).subscribe(result=>{
            this.dialogRef.close(true);
          },error => {
            this.isProcessing = false;
            this.toastr.error("An error occurred while processing the firm application file: ", error);
          });
          break;
      }
    },error=>{
      this.isProcessing = false;
      this.toastr.error("An error occurred while processing the file: ", error);
    })
  }
}
