<div fxLayout="column">
    <mat-card [style.background-color]="uiData.atp.color">
        <mat-card-title>
            {{courseStudentData?.id !== 0 ? 'Edit Student Record' :'Add Student Record'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">        
        <span><strong>Search/New Person</strong></span>                           
        <mat-form-field style="width: 100%;">
            <mat-label>Search (type at least 3 characters)</mat-label>            
            <input matInput [(ngModel)]="selectedStudent" [matAutocomplete]="auto" (input)="updateDebounceString($event)">        
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayStudent" (optionSelected)="updateScrollContainerHeight()">
                <mat-option *ngFor="let student of filteredStudentList" [value]="student">
                    {{ student?.id === 0 ? student?.firstName : student?.id + ': ' + student?.firstName + ' ' + student?.lastName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <form [formGroup]="courseStudentDataForm" fxLayout="column" *ngIf="selectedStudent !== null && selectedStudent?.id >= 0">
            <div fxLayout="row" fxLayout.lt-md="column">
                <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                    <mat-card-content>                                                    
                        <div fxLayout="column" fxFlex="100">                                
                            
                            <span><strong>First Name</strong></span>                           
                            <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                <input matInput placeholder="First Name" formControlName="firstName">
                            </mat-form-field>

                            <span><strong>Last Name</strong></span>                           
                            <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                <input matInput placeholder="Last Name" formControlName="lastName">
                            </mat-form-field>

                            <span><strong>Date of Birth</strong></span>                                                            
                            <mat-form-field style="width: 100%;">
                                <input matInput [matDatepicker]="birthDateTimePicker" placeholder="Birth Date" formControlName="dateOfBirth" (focus)="birthDateTimePicker.open()">
                                <mat-datepicker-toggle matSuffix [for]="birthDateTimePicker"></mat-datepicker-toggle>
                                <mat-datepicker #birthDateTimePicker></mat-datepicker>
                            </mat-form-field>

                            <span><strong>Student Address</strong></span>           
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                    <input matInput placeholder="Street Apt/Unit/Suite" formControlName="street">
                                </mat-form-field>                                                  
                            </div>               
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                <mat-form-field fxFlex="60" fxFlex.lt-md="100">
                                    <input matInput placeholder="City"  formControlName="city">
                                </mat-form-field>  

                                <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="state">
                                    <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                    </mat-select>                                
                                </mat-form-field>
                
                                <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                    <input matInput placeholder="Zip" formControlName="zip">
                                </mat-form-field>                    
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                    <mat-card-content>                          
                        <div fxLayout="column" fxFlex="100">                                                                 
                            <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100" fxLayoutAlign="space-between center">
                                <div fxLayout="column">
                                    <span><strong>ATP</strong></span>                                      
                                    <a style="cursor: pointer" (click)="editATP()">{{data?.course?.atp?.name}}</a>
                                </div>

                                <div fxLayout="column">
                                    <span><strong>Course Id/Discipline</strong></span>                                     
                                    <a style="cursor: pointer" (click)="editCourse()">{{data?.course?.id !== null ? data?.course?.id + ': ' + data?.course?.accreditationDiscipline : ''}}</a>
                                </div>

                                <div fxLayout="column">
                                    <span><strong>Course Date</strong></span>                                                                
                                    <span>{{data?.course?.courseStart?.split('T')[0]}}</span>
                                </div>                                
                            </div>                            
                            &nbsp;                            
                            <span><strong>Certificate Number</strong></span>                           
                            <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                <input matInput placeholder="Certificate Number" formControlName="courseCertificateNumber">
                            </mat-form-field>

                            <span><strong>Test Score</strong></span>                           
                            <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                <input matInput placeholder="Test Score" formControlName="testScore">
                            </mat-form-field>

                            <span><strong>Photo Image File Name</strong></span>                           
                            <mat-form-field fxFlex="100" fxFlex.lt-md="100">
                                <input matInput placeholder="File Name" formControlName="photoImage">
                            </mat-form-field>                               
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
        </form>                                                               
    </div>
    <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{courseStudentData?.id !== 0 ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>