<div [ngStyle]="{'--entity-color' : uiData.individual.color}">    
    <mat-card [style.background-color]="uiData.individual.color">
        <h2 class="noMarginHeader">
            <mat-icon>{{uiData.individual.icon}}</mat-icon> {{personData.id + ': ' + personData.firstName + ' ' + personData.lastName}}
        </h2>
    </mat-card>    

    <mat-dialog-content class="scroll-container">
        <br />
        <div fxFlex="100" fxLayout="column">          
          <mat-card>
            <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
              <mat-tab>
                <ng-template mat-tab-label>                
                    <mat-icon>person</mat-icon> &nbsp; Individual Information
                </ng-template>
                <ng-container>
                  <mat-card class="custom-mat-card">
                    <mat-card-title>
                      <div fxLayout="row" fxLayout.lt-lg="column">
                        <span fxFlex>Individual Information</span>
                      </div>
                    </mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">
                        <strong>ID: {{personData.id}}</strong>              
                        <form [formGroup]="personForm">
                          <div fxLayout="column" fxLayoutGap="8px">
                            <div fxLayout="column" fxLayoutGap="8px">
                                <span><strong>Legal Name</strong></span>                
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                    <mat-form-field fxFlex="100%">
                                        <input matInput placeholder="Title" formControlName="title" >                                    
                                    </mat-form-field>
                                
                                    <mat-form-field fxFlex="100%">
                                        <input matInput placeholder="First Name" formControlName="firstName" >                                    
                                    </mat-form-field>                                                                                
                                
                                    <mat-form-field fxFlex="100%">
                                        <input matInput placeholder="Middle Name" formControlName="middleName" >                                    
                                    </mat-form-field>
                                
                                    <mat-form-field fxFlex="100%">
                                        <input matInput placeholder="Last Name" formControlName="lastName" >                                    
                                    </mat-form-field>
                                </div>                               
                            </div> 
                            <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                     
                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
                                    <span><strong>SSN</strong></span>  
                                    <mat-form-field fxFlex="100%">
                                        <input matInput placeholder="XXX-XX-XXXX" formControlName="ssn" (focus)="onSSNFocus()" (blur)="onSSNBlur()">                                    
                                    </mat-form-field>              
                                </div>
                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
                                    <span><strong>Date of Birth</strong></span>  
                                    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                        <input matInput [matDatepicker]="dobPicker" placeholder="Date of Birth" formControlName="dateOfBirth" (focus)="dobPicker.open()">
                                        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #dobPicker></mat-datepicker>
                                        <mat-error *ngIf="personForm.get('dateOfBirth').invalid">
                                            Please enter your Date of Birth
                                        </mat-error>
                                    </mat-form-field>               
                                </div>
                            </div>
                            <div fxFlex="50%" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                              <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
                                <span><strong>Public Listing</strong></span> 
                                <mat-checkbox class="mat-checkbox"[checked]="personForm.get('response').value === 'Yes'" (change)="updateResponse($event)">
                                    <span class="mat-checkbox-label">
                                        Check to publish this individual on the website.
                                    </span>
                                </mat-checkbox>               
                              </div>
                              <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px" *ngIf="false">
                                <span><strong>Inactive</strong></span> 
                                <mat-checkbox class="mat-checkbox" formControlName="inactive">
                                    <span class="mat-checkbox-label">
                                        Set this individual status Inactive/Active.
                                    </span>
                                </mat-checkbox>               
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </mat-tab>
              
              <mat-tab>
                <ng-template mat-tab-label>                
                    <mat-icon>{{uiData.contact.icon}}</mat-icon> &nbsp; Contact Information({{addressTableData.length + contactInformationTableData.length}})
                </ng-template>
                <ng-container>
                  <div fxLayout="row" fxLayout.lt-md="column" fxFlex>
                    <mat-card class="custom-mat-card" fxFlex="100">
                      <mat-accordion multi >
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span><h4>Addresses - {{addressTableData.length}}</h4></span>
                            </mat-panel-title>                                           
                          </mat-expansion-panel-header>
                          <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div fxLayoutGap="5px">
                                        <strong class="noMarginHeader">Results ({{addressTableData.length}})</strong>                                           
                                        <mat-form-field style="width:200px;">
                                          <input (keyup)="filterAddressTable()" placeholder="Filter Results" [(ngModel)]="filterAddressString" matInput/>                            
                                        </mat-form-field> 
                                        
                                        <mat-form-field style="width:150px">
                                          <mat-select multiple placeholder="Filter Address Type" [formControl]="filterAddressType" (selectionChange)="filterAddressTable()">
                                              <mat-option *ngFor="let type of addressTypeList" [value]="type">
                                                  {{ type }}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>

                                      <mat-form-field style="width:150px">
                                        <mat-select multiple placeholder="Filter Physical" [formControl]="filterAddressPhysical" (selectionChange)="filterAddressTable()">
                                            <mat-option *ngFor="let type of addressBoolList" [value]="type">
                                                {{ type }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field style="width:150px">
                                      <mat-select multiple placeholder="Filter Mailing" [formControl]="filterAddressMailing" (selectionChange)="filterAddressTable()">
                                          <mat-option *ngFor="let type of addressBoolList" [value]="type">
                                              {{ type }}
                                          </mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editAddressTableEntry(null)">
                                            <mat-icon>add</mat-icon> Add Address
                                        </button>                                        
                                    </div>
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="addressDataSource" matSort #AddressTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="addressTableColumns"></mat-header-row>                  
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: addressTableColumns" (click)="editAddressTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of addressTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="7">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteAddressEntry(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="addressTablePageSize" [pageSizeOptions]="[5, 10, 50, addressTableData.length]" #AddressTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span><h4>Contact Information - {{contactInformationTableData.length}}</h4></span>
                            </mat-panel-title>                                           
                          </mat-expansion-panel-header>
                          <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div fxLayoutGap="5px">
                                        <strong class="noMarginHeader">Results ({{contactInformationTableData.length}})</strong>                                               
                                        <mat-form-field style="width:200px;">
                                          <input (keyup)="filterContactInformationTable()" placeholder="Filter Results" [(ngModel)]="filterContactInformationString" matInput/>                            
                                        </mat-form-field>      

                                        <mat-form-field style="width:150px">
                                          <mat-select multiple placeholder="Filter Contact Type" [formControl]="filterContactInformationType" (selectionChange)="filterContactInformationTable()">
                                              <mat-option *ngFor="let type of contactInformationTypeList" [value]="type">
                                                  {{ type }}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editContactInformationTableEntry(null)">
                                            <mat-icon>add</mat-icon> Add Contact Information
                                        </button>                                        
                                    </div>
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="contactInformationDataSource" matSort #ContactInformationTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="contactInformationTableColumns"></mat-header-row>                  
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: contactInformationTableColumns" (click)="editContactInformationTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of contactInformationTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="7">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteContactInformationEntry(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="contactInformationTablePageSize" [pageSizeOptions]="[5, 10, 50, contactInformationTableData.length]" #ContactInformationTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>                        
                    </mat-card>
                  </div>                        
                </ng-container>
              </mat-tab>
              
                <mat-tab>
                  <ng-template mat-tab-label>                
                      <mat-icon>{{uiData.certificate.icon}}</mat-icon> &nbsp; Certificates ({{personFilteredCertificates.length}})
                  </ng-template>
                  <ng-container>
                    <mat-card class="custom-mat-card">
                      <mat-card-title>
                          <div fxLayout="row" fxLayout.lt-lg="column">
                              <span fxFlex>Certificates</span>
                          </div>
                      </mat-card-title>
                      <mat-card-content>
                          <div fxLayout="column">
                          <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                            <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                              <div fxLayout="column">
                                <strong class="noMarginHeader">Results ({{personFilteredCertificates.length}})</strong>                                   
                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap = '5px' fxLayoutAlign="space-between start">
                                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap = '5px'>
                                    <mat-form-field style="width:200px;">
                                      <input placeholder="Filter Results" type="text" (input)="updateCertificateDebounceString($event)" matInput/>                            
                                    </mat-form-field>                                        
                                    <mat-form-field style="width:200px;">
                                      <mat-label>Filter Certificate Stage</mat-label>
                                      <mat-select (selectionChange)="filterCertificateTable()" [formControl]="certificateStageFilter">
                                        <mat-option value="Initial">Initial</mat-option>
                                        <mat-option value="Refresher">Refresher</mat-option>
                                        <mat-option value="Both">Both</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                    <mat-checkbox [(ngModel)]="showHistory" (change)="filterCertificateTable()">Show Historical</mat-checkbox>
                                  </div>
                                  
                                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap = '5px'>
                                    <button mat-raised-button color="primary" (click)="editCertificate(null)">
                                      <mat-icon>add</mat-icon>Add Certificate
                                    </button>                
                                  </div>
                                </div>                                
                              </div> 
                            </div>
                              
                              <br />
                  
                              <mat-table [dataSource]="certificateDataSource" matSort #CertTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                  <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="certificateTable.layout.columns.concat('Action')"></mat-header-row>                  
                                  <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: certificateTable.layout.columns.concat('Action');" (click)="editCertificate(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                      
                                  <ng-container *ngFor="let cellData of certificateTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                        <ng-container *ngIf="cellData.type === 'date'">
                                          <div *ngIf="isMobile" fxLayout="row">  
                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                            <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                          </div>
                                          <div *ngIf="!isMobile">  
                                              {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                          </div>
                                        </ng-container>
                                        <ng-container *ngIf="cellData.type !== 'date'">                                                                        
                                          <div *ngIf="isMobile" fxLayout="row">                                    
                                            <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                            <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                          </div>
                                          <div *ngIf="!isMobile">
                                              {{displayLimit(row[cellData.columnName], cellData.size)}}
                                          </div>                                            
                                        </ng-container>
                                    </mat-cell>
                                  </ng-container>
                                                                                                                                                          
                                  <ng-container matColumnDef="Action">
                                      <mat-header-cell *matHeaderCellDef fxFlex="15" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Action</mat-header-cell>
                                      <mat-cell *matCellDef="let row" fxFlex="15" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                        <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                          <span><strong>Action: </strong></span> &nbsp;&nbsp;
                                          <button mat-raised-button color="primary" (click)="viewApplication($event, null, true, row); $event.stopPropagation()" *ngIf="isLbpaCert(row.codeType)"> 
                                            {{canRenewCertificate(row) ? 'View' : 'Renew'}}
                                          </button>
                                          <button mat-raised-button color="primary" (click)="viewPrintCertificateModal(row); $event.stopPropagation()" *ngIf="isLbpaCert(row.codeType)"> 
                                            Print
                                          </button>
                                        </div>
                                        <div *ngIf="!isMobile" style="display: flex; justify-content: space-between; align-items: center;">
                                          <button mat-raised-button color="primary" style="padding: 0px;"(click)="viewApplication($event, null, true, row); $event.stopPropagation()" *ngIf="isLbpaCert(row.codeType)">
                                            {{canRenewCertificate(row) ? 'View' : 'Renew'}}
                                          </button>&nbsp;&nbsp;
                                          <button mat-raised-button color="primary" style="padding: 0px;" (click)="viewPrintCertificateModal(row); $event.stopPropagation()" *ngIf="isLbpaCert(row.codeType)"> 
                                            Print
                                          </button>
                                        </div>
                                      </mat-cell>
                                  </ng-container>
                              </mat-table>
                                              
                              <br />
                              <mat-paginator [pageSize]="certificateTablePageSize" [pageSizeOptions]="[5, 10, 50, personFilteredCertificates.length]" #CertTablePaginator="matPaginator"
                                  showFirstLastButtons></mat-paginator>
                          </div>
                      </div>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>
                </mat-tab>

                <mat-tab>
                  <ng-template mat-tab-label>                
                      <mat-icon>description</mat-icon> &nbsp; Applications ({{personFilteredApplications.length}})
                  </ng-template>
                  <ng-container>
                    <mat-card class="custom-mat-card">
                      <mat-card-title>
                          <div fxLayout="row" fxLayout.lt-lg="column">
                              <span fxFlex>Applications</span>
                          </div>
                      </mat-card-title>
                      <mat-card-content>
                          <div fxLayout="column">
                          <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                              <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                  <div fxLayout="column">
                                    <strong class="noMarginHeader">Results ({{personFilteredApplications.length}})</strong> 
                                    <div fxLayout="row" fxLayout.lt-md="column">    
                                      <mat-form-field style="width:200px;">                                      
                                        <input placeholder="Filter Results" type="text" (input)="updateApplicationDebounceString($event)" matInput/>                            
                                      </mat-form-field>   
                                      &nbsp;&nbsp;
                                      <mat-form-field style="width:200px;">
                                        <mat-label>Filter Application Status</mat-label>
                                        <mat-select (selectionChange)="filterApplicationTable()" [formControl]="applicationStatusFilter">
                                          <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">{{ status }}</mat-option>                                                            
                                        </mat-select>
                                      </mat-form-field>                                                                   
                                    </div>  
                                  </div> 
                                  <div>                        
                                    <button mat-raised-button color="primary" (click)="viewApplication($event, null); $event.stopPropagation()">
                                        <mat-icon>add</mat-icon> Add Application
                                    </button>
                                  </div>                                    
                              </div>
                              
                              <br />
                  
                              <mat-table [dataSource]="applicationDataSource" matSort #ApplicationTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                  <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="applicationTable.layout.columns.concat('Action')"></mat-header-row>                  
                                  <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: applicationTable.layout.columns.concat('Action');" (click)="viewApplication($event, row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                      
                                  <ng-container *ngFor="let cellData of applicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                        <ng-container *ngIf="cellData.type === 'date'">
                                          <div *ngIf="isMobile" fxLayout="row">  
                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                            <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                          </div>
                                          <div *ngIf="!isMobile">  
                                              {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                          </div>
                                        </ng-container>
                                        <ng-container *ngIf="cellData.type !== 'date'">                            
                                          <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                            <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                              <div *ngIf="isMobile" fxLayout="row">  
                                                  <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                  <span>{{row[cellData.columnName]}}</span>
                                              </div>
                                              <div *ngIf="!isMobile">  
                                                  {{row[cellData.columnName]}}
                                              </div>                                    
                                            </div>
                                          </ng-container>
                                          <ng-template #notStatus>
                                            <div *ngIf="isMobile" fxLayout="row">                                    
                                              <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                              <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                            </div>
                                            <div *ngIf="!isMobile">
                                                {{displayLimit(row[cellData.columnName], cellData.size)}}
                                            </div>  
                                          </ng-template>
                                        </ng-container>
                                    </mat-cell>
                                  </ng-container>
                                                                                                                                                          
                                  <ng-container matColumnDef="Action">
                                      <mat-header-cell *matHeaderCellDef fxFlex="7" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Action</mat-header-cell>
                                      <mat-cell *matCellDef="let row" fxFlex="7" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                        <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                          <span><strong>Action: </strong></span>
                                          <button mat-icon-button color="primary" (click)="viewApplication($event, row);  $event.stopPropagation()">
                                              <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                                          </button>
                                        </div>
                                        <div *ngIf="!isMobile">
                                            <button mat-icon-button color="primary" (click)="viewApplication($event, row);  $event.stopPropagation()">
                                                <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                                            </button>
                                        </div>
                                      </mat-cell>
                                  </ng-container>
                              </mat-table>
                                              
                              <br />
                              <mat-paginator [pageSize]="applicationTablePageSize" [pageSizeOptions]="[5, 10, 50, personFilteredApplications.length]" #ApplicationTablePaginator="matPaginator"
                                  showFirstLastButtons></mat-paginator>
                          </div>
                      </div>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>
                </mat-tab>
        
                <mat-tab>
                  <ng-template mat-tab-label>                
                      <mat-icon>{{uiData.testing.icon}}</mat-icon> &nbsp; Testing ({{testingTableData.length}})
                  </ng-template>
                  <ng-container>
                    <div>
                      <mat-card>
                        <mat-card-title>
                            <div fxLayout="row">
                                <span>Testing Records</span>
                            </div>
                        </mat-card-title>
                        <mat-card-content>
                            <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div>
                                        <strong class="noMarginHeader">Results ({{testingTableData.length}})</strong> 
                                        &nbsp;&nbsp;&nbsp;&nbsp;     
                                        <mat-form-field style="width:200px;">
                                        <input (keyup)="filterTestingTable()" placeholder="Filter Results" matInput/>                            
                                        </mat-form-field>      
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editTestingTableEntry(null)">
                                            <mat-icon>add</mat-icon> Add Test Record
                                        </button>                                        
                                    </div>
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="testingDataSource" matSort #TestingTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="testingTableColumns"></mat-header-row>                  
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: testingTableColumns" (click)="editTestingTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of testingTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], '80') }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="7">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteTestingEntry(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="testingTablePageSize" [pageSizeOptions]="[5, 10, 50, testingTableData.length]" #TestingTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        </mat-card-content>
                      </mat-card>        
                    </div>
                  </ng-container>
                </mat-tab>
    
                <mat-tab>
                  <ng-template mat-tab-label>                
                      <mat-icon>{{uiData.training.icon}}</mat-icon> &nbsp; Training ({{trainingTableData.length}})
                  </ng-template>
                  <ng-container>
                    <div>
                      <mat-card>
                        <mat-card-title>
                            <div fxLayout="row">
                                <span>Training Records</span>
                            </div>
                        </mat-card-title>
                        <mat-card-content>
                            <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div>
                                        <strong class="noMarginHeader">Results ({{trainingTableData.length}})</strong> 
                                        &nbsp;&nbsp;&nbsp;&nbsp;     
                                        <mat-form-field style="width:200px;">
                                        <input (keyup)="filterTrainingTable()" placeholder="Filter Results" matInput/>                            
                                        </mat-form-field>      
                                    </div>                                     
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="trainingDataSource" matSort #TrainingTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="trainingTableColumns"></mat-header-row>                  
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: trainingTableColumns" (click)="editTrainingTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of trainingTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], '80') }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="7">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="warn" style="width: 50px;">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="trainingTablePageSize" [pageSizeOptions]="[5, 10, 50, trainingTableData.length]" #TrainingTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        </mat-card-content>
                      </mat-card>        
                    </div>
                  </ng-container>
                </mat-tab>
    
                <mat-tab>
                  <ng-template mat-tab-label>                
                      <mat-icon>{{uiData.firm.icon}}</mat-icon> &nbsp; Affiliations ({{personFilteredAffiliations.length}})
                  </ng-template>
                  <ng-container>
                    <mat-card class="custom-mat-card">
                      <mat-card-title>
                          <div fxLayout="row" fxLayout.lt-lg="column">
                              <span fxFlex>Affiliations</span>
                          </div>
                      </mat-card-title>
                      <mat-card-content>
                          <div fxLayout="column">
                          <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                              <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                  <div fxLayout="column">
                                    <strong class="noMarginHeader">Results ({{personFilteredAffiliations.length}})</strong> 
                                    <div fxLayout="row">
                                      <mat-form-field style="width:200px;">
                                        <input placeholder="Filter Results" type="text" (input)="updateAffiliationDebounceString($event)" matInput/>                            
                                      </mat-form-field>      
                                    </div>
                                  </div>                                     
                                  <div>                        
                                    <button mat-raised-button color="primary" (click)="viewAffiliation(null)">
                                        <mat-icon>add</mat-icon> Add Affiliation
                                    </button>
                                  </div>
                              </div>

                              <br />
                  
                              <mat-table [dataSource]="affiliationDataSource" matSort #AffiliationTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                  <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="affiliationTable.layout.columns.concat('Action')"></mat-header-row>                  
                                  <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: affiliationTable.layout.columns.concat('Action');" (click)="viewAffiliation(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                      
                                  <ng-container *ngFor="let cellData of affiliationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                      <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{ cellData.displayName }}</mat-header-cell>
                                      <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                          <ng-container [ngSwitch]="cellData.type">
                                              <ng-container *ngSwitchCase="'date'">
                                                <div *ngIf="isMobile" fxLayout="row">  
                                                  <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                  <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                </div>
                                                <div *ngIf="!isMobile">  
                                                    {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                </div>
                                              </ng-container>
                                              <ng-container *ngSwitchDefault>
                                                <div *ngIf="isMobile" fxLayout="row">                                    
                                                  <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                  <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                                </div>
                                                <div *ngIf="!isMobile">
                                                    {{displayLimit(row[cellData.columnName], cellData.size)}}
                                                </div>  
                                              </ng-container>
                                          </ng-container>
                                      </mat-cell>
                                      </ng-container>
                                                                                                                                                          
                                  <ng-container matColumnDef="Action">
                                      <mat-header-cell *matHeaderCellDef fxFlex="7" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Action</mat-header-cell>
                                      <mat-cell *matCellDef="let row" fxFlex="7" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                          <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                            <span><strong>Action: </strong></span>
                                            <button title="Delete {{row['Name']}}" mat-icon-button color="warn" style="width: 50px;" aria-label="Delete Person" (click)="deleteAffiliation(row); $event.stopPropagation()">
                                              <mat-icon>delete</mat-icon>
                                            </button>
                                          </div>
                                          <div *ngIf="!isMobile">
                                            <button title="Delete {{row['Name']}}" mat-icon-button color="warn" style="width: 50px;" aria-label="Delete Person" (click)="deleteAffiliation(row); $event.stopPropagation()">
                                              <mat-icon>delete</mat-icon>
                                            </button>
                                          </div>
                                      </mat-cell>
                                  </ng-container>
                              </mat-table>
                                              
                              <br />
                              <mat-paginator [pageSize]="affiliationTablePageSize" [pageSizeOptions]="[5, 10, 50, personFilteredAffiliations.length]" #AffiliationTablePaginator="matPaginator"
                                  showFirstLastButtons></mat-paginator>
                          </div>
                      </div>
                      </mat-card-content>
                    </mat-card>
                  </ng-container>
                </mat-tab>

                                <!-- Payments tab -->   
                                <mat-tab>
                                  <ng-template mat-tab-label>                
                                      <mat-icon>{{uiData.payment.icon}}</mat-icon> &nbsp; Payments
                                  </ng-template>
                                  <ng-container>
                                      <div *ngIf="personData.id === 0" class="text-red-400"><strong><br />Individual Needs to be Saved</strong></div>
                                              <div fxLayout="column" fxLayoutGap="8px" *ngIf="personData.id > 0">
                                                  <app-manage-payments [@slideLeft]
                                                             [entityId]="personData.id.toString()"
                                                             [entityType]="paymentService.IndividualEntityType"
                                                             [allowMultiplePayments]='true'
                                                             [editingPayment]='true'
                                                             [allowDelete]='true'
                                                             [cardColor]="cardColor"
                                                             [changeIndicator]="changeIndicator">
                                        </app-manage-payments>                
                                              </div>
                                  </ng-container>
                              </mat-tab>  
                                <!-- Complaints tab -->   
            <mat-tab>
              <ng-template mat-tab-label>                
                  <mat-icon>{{'create_new_folder'}}</mat-icon> Complaints ({{complaints.length}})
              </ng-template>
              <mat-card class="custom-mat-card">
                  <mat-card-title>
                      <div fxLayout="row">
                          <span>Complaints</span>
                      </div>
                  </mat-card-title>
                  <mat-card-content>
              <ng-container>
                  <div *ngIf="personData.id === 0" class="text-red-400"><strong><br />Firm Needs to be Saved</strong></div>
                          <div fxLayout="column" fxLayoutGap="8px" *ngIf="personData.id > 0">
                            <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                              <div></div>                                                        
                              <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
                                  <button mat-raised-button color="primary" title="Add Complaint" (click)="editComplaint(null)">
                                    <mat-icon>add</mat-icon> Add Complaint
                                  </button>                
                                </div>
                            </div>
                              <mat-table [dataSource]="complaintDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                  <ng-container *ngFor="let cellData of complaintsTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                      <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                      <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                          <ng-container *ngIf="cellData.type === 'date'">
                                              <div *ngIf="isMobile" fxLayout="row">  
                                                  <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                  <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                              </div>
                                              <div *ngIf="!isMobile">  
                                                  {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                              </div>
                                            </ng-container>
                                            <ng-container *ngIf="cellData.type === 'boolean'">
                                              <div *ngIf="isMobile" fxLayout="row">
                                                  <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                  <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                              </div>                            
                                            </ng-container>
                                            <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                              <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                  <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                                      <div *ngIf="isMobile" fxLayout="row">  
                                                          <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                          <span>{{row[cellData.columnName]}}</span>
                                                      </div>
                                                      <div *ngIf="!isMobile">  
                                                          {{row[cellData.columnName]}}
                                                      </div>                                    
                                                  </div>
                                              </ng-container>
                                              <ng-template #notStatus> 
                                              <div *ngIf="isMobile" fxLayout="row">                                    
                                                  <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                  <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  {{displayLimit(row[cellData.columnName], cellData.size)}}
                                              </div> 
                                              </ng-template> 
                                            </ng-container>
                                      </mat-cell>
                                  </ng-container>    
                                  <!-- Column definitions -->
                                  <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintsTable.layout.columns"></mat-header-row>
                                  <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintsTable.layout.columns"
                                  (click)="editComplaint(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>
                                </mat-table>              
                          </div>
              </ng-container>
          </mat-card-content>
          </mat-card>
          </mat-tab>    
            </mat-tab-group>
        </mat-card>
      </div>
    </mat-dialog-content>
          
  <mat-card [style.background-color]="uiData.individual.color">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="saveChanges()">Save Changes</button>
    <button mat-raised-button (click)="exitComponent()">Exit</button>          
  </mat-card>
</div>