import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IAtp } from 'app/models/atp/atp';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { AddEditAtpNoteComponent } from '../add-edit-atp-note/add-edit-atp-note.component';
import { IAtpDeficiency } from 'app/models/atp/atpDeficiency';
import { IAtpAccreditation } from 'app/models/atp/atpAccreditation';

@Component({
  selector: 'app-add-edit-atp-deficiency',
  templateUrl: './add-edit-atp-deficiency.component.html',
  styleUrls: ['./add-edit-atp-deficiency.component.scss']
})
export class AddEditAtpDeficiencyComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT;
  public deficiencyDataForm = null;
  public deficiencyData: IAtpDeficiency = null;  

  constructor(private toastr: ToastrService,
    private atpService: AtpService,
    public commonService: CommonDataService,
    private dialogRef: MatDialogRef<AddEditAtpNoteComponent>,
    public dialog: MatDialog,    
    @Inject(MAT_DIALOG_DATA) public data:{deficiency: IAtpDeficiency, atp: IAtp, accreditation: IAtpAccreditation},
  ) { }

  ngOnInit(): void {
    this.setDataFormFields();
    this.loadData();
  }

  loadData(){
    if(this.data.deficiency !== null){
      this.deficiencyData = this.data.deficiency;      
    }
    else{
      this.deficiencyData = {
        id: 0,
        atp: this.data.atp,   
        atpAccreditation: this.data.accreditation,
        deficiencyType: "",
        description: "",
        date: null,       
        isResolved: false, 
      };     
    }

    for (const controlName in this.deficiencyData) {
      if (this.deficiencyDataForm.controls.hasOwnProperty(controlName)) {        
        this.deficiencyDataForm.controls[controlName].setValue(this.deficiencyData[controlName]);          
      }
    }  
  }

  setDataFormFields(){       
    this.deficiencyDataForm = new UntypedFormGroup({
      deficiencyType: new UntypedFormControl(""),
      description: new UntypedFormControl(""), 
      date: new UntypedFormControl(null),       
      isResolved: new UntypedFormControl(false),  
    }); 
  }

  updateData(): void {    
    for (const controlName in this.deficiencyData) {
      if (this.deficiencyDataForm.controls.hasOwnProperty(controlName)) {
        this.deficiencyData[controlName] = this.deficiencyDataForm.controls[controlName].value;        
      }
    } 
  }

  savePrompt(){
    let isNew = this.deficiencyData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Deficiency?' : 'Are you sure you want to <strong>Save</strong> the Deficiency?',title: isNew ? 'Add the Deficiency' : 'Save the Deficiency'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();        
      }
    })
  }

  saveData(){
    this.updateData();    
    this.atpService.saveATPDeficiency(this.deficiencyData).subscribe(result=>{      
      this.deficiencyData = result;      
      this.toastr.success("Success")
      this.dialogRef.close(true)
    },error=>{
      this.toastr.error("There was an error saving the ATP Deficiency: ", error)
    })
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}
