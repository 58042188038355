import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmation-dialogue-with-field',
  templateUrl: './confirmation-dialogue-with-field.component.html'  
})
export class ConfirmationDialogueWithFieldComponent {
  selectedNumber: string = '';
  disableInput: boolean = false;
  selectedDate: Date | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  
  private sanitizer: DomSanitizer,
  private dialogRef: MatDialogRef<ConfirmationDialogueWithFieldComponent>,
  private personService: PersonService,
  private firmService: FirmService,
  private toastr: ToastrService) {
    if(this.data.controlNumber === null || this.data.controlNumber === undefined || this.data.controlNumber === ''){
      if (data.type === 'firm') {
        this.firmService.getNextFirmCertificateNumber().subscribe(result=>{
          this.selectedNumber = result.toString();
        }, error=>{this.toastr.error("Error getting next firm certificate number", error)});
      } else {
        this.personService.getNextPersonCertificateNumber().subscribe(result=>{
          this.selectedNumber = result.toString();
        }, error=>{this.toastr.error("Error getting next person certificate number", error)});
      }
    }
    else{
      this.selectedNumber = this.data.controlNumber;    
      this.disableInput = true;
    }

    this.selectedDate = this.data?.selectedDate ?? new Date().toISOString();
  }  
  
  sanitizedData(data:string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      data.replace(/\n/g, '<br>')
    );
  }
}
