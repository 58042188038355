import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'app/services/core/shared.service';
import { PrintFirmCertificateComponent } from '../print-firm-certificate/print-firm-certificate.component';
import { IFirm } from 'app/models/firms/firm';

@Component({
  selector: 'app-print-firm-certificate-modal',
  templateUrl: './print-firm-certificate-modal.component.html',
  styleUrls: ['./print-firm-certificate-modal.component.scss']
})
export class PrintFirmCertificateModalComponent {

  public uiData = LEAD_BASED_PAINT;
  public selectedCertificates: boolean = false;
  public selectedLetters: boolean = false;
  
  constructor(private dialogRef: MatDialogRef<PrintFirmCertificateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{ codeType:any, firm:IFirm, firmName:any,  firmAddress: any, firmId:any,expDate:any, issueDate: any, contactName:any, certNumber:any }, 
    private toastr: ToastrService, public dialog: MatDialog,public sharedService: SharedService) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onPrintClick(): void {
    this.dialog.open(PrintFirmCertificateComponent, {
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
        data: {codeType: this.data.codeType, firm: this.data.firm,
              firmAddress: this.data.firmAddress, expDate : this.data.expDate, issueDate: this.data.issueDate,
               contactName : this.data.contactName, selectedCertificates: this.selectedCertificates, selectedLetters: this.selectedLetters, certNumber: this.data.certNumber},
          role: 'dialog',
          ariaLabel: 'Complaint',
          minWidth: !this.sharedService.mobile ? '1000px' : '300px',
          maxWidth: !this.sharedService.mobile ? '980px' : '300px'
        });
  }


}
