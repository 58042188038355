import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ANIMATIONS, COURSE_DISCIPLINES, COURSE_FORMAT, COURSE_LANGUAGE, COURSE_TYPES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IPerson } from 'app/models/People/person';
import { IAtp } from 'app/models/atp/atp';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpCourseAddress } from 'app/models/atp/atpCourseAddress';
import { IAtpPerson } from 'app/models/atp/atpPerson';
import { IAddress } from 'app/models/common/address';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { AtpCourseStudentUploadComponent } from '../atp-course-student-upload/atp-course-student-upload.component';
import { AddEditCourseStudentComponent } from '../add-edit-course-student/add-edit-course-student.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IPayment } from 'app/components/payments/models/payment';

@Component({
  selector: 'app-add-edit-atp-course',
  templateUrl: './add-edit-atp-course.component.html',
  styleUrls: ['./add-edit-atp-course.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: ANIMATIONS
})
export class AddEditAtpCourseComponent implements OnInit {
  public personList:IPerson[] = [];
  public filteredPersonList: IPerson[] = []; 

  public uiData = LEAD_BASED_PAINT;
  public courseDataForm = null;
  public courseData: IAtpCourse = null;  

  public courseLocation: IAtpCourseAddress = null;  
  public courseAddress: IAddress = null;  
  
  public tableColumns = ['id', 'studentName', 'courseCertificateNumber','testScore','actions'];
  public tableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Student Name',columnName:'studentName', type:'string', size:'50'},
    {displayName:'Certificate Number',columnName:'courseCertificateNumber', type:'string', size:'20'},
    {displayName:'Test Score',columnName:'testScore', type:'string', size:'10'},
  ];
  public tableData = []
  public tableDataFiltered = []
  public tablePageSize = 5;
  public filterTableString: string = '';  
  public tableDataSource: MatTableDataSource<any>;
  public changeIndicator: number = 0;
  public cardColor: string = this.sharedService.gold + '20';
  public canEdit: boolean = true;
  @ViewChild('TablePaginator') tablePaginator: MatPaginator;
  @ViewChild('TableSort') tableSort: MatSort;
  
  constructor(public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{course:IAtpCourse, atp:IAtp, atpStaff:IAtpPerson [], courseId: number},
    private toastr: ToastrService,
    private atpService: AtpService,
    private commonService: CommonDataService,
    public paymentService: PaymentService,    
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.setCourseDataFormFields();
    this.loadData();
  }

  loadData(){
    this.courseLocation = {
      id: 0,
      atpCourse: null,
      address: null,
      locationName: "",
      isCurrentMailing: false,
      isCurrentPhysical: true,
    }

    this.courseAddress = {
      id: 0,
      street: "",
      city: "",
      zip: "",
      contactType: "Work",
      county: "",
      state: "OR",
    }
 
    if ((this.data.courseId !== null && this.data.courseId !== undefined && this.data.courseId !== 0) || this.data.course !== null && this.data.course !== undefined){      
      var id = this.data.courseId || this.data.course.id;
      this.atpService.getATPCourseById(id).subscribe(result=>{        
        this.courseData = result;
        this.refreshStudentList();
        this.loadInstructors();
        this.loadCourseDataForm();
        this.loadCourseAddress();
      },error=>{
        this.toastr.error("There was an error getting the ATP course: ", error)
      })
    } 
    else{
      this.courseData = {
        id: 0,
        atp: this.data.atp,
        atpCourseAddress: this.data?.course?.atpCourseAddress,
        accreditationDiscipline: null,
        accreditationCourseFormat: null,
        accreditationCourseLanguage: null,
        stage: null,
        programName: null,
        courseStart: null,
        courseEnd: null,
        cancelled: false,
        isPrivate: false,
        receivedAmount: null,
        receivedPreNote: false,
        receivedPreNoteDate: null,
        receivedPostNote: false,
        receivedPostNoteDate: null,
        receivedStudentFees: false,      
        students: 0,
        controlNumber: null,   
        programInstructor: null,
        programInstructorEmail: null,
        programInstructorPhone: null,    
        webAddress: null, 
        overrideDelinquency: false,
      };
      this.loadInstructors();
      this.loadCourseDataForm();
      this.loadCourseAddress();
    }    
  }
  
  loadInstructors(){
    this.personList = this.data?.atpStaff?.filter(x=>x.isInstructor || x.isPrimaryInstructor || x.isTrainingManager).map(x => x.person);
    this.filteredPersonList = this.personList;    
  }

  loadCourseDataForm(){
    for (const controlName in this.courseData) {
      if (this.courseDataForm.controls.hasOwnProperty(controlName)) {        
        this.courseDataForm.controls[controlName].setValue(this.courseData[controlName]);          
      }
    }    

    if(this.courseData.courseStart !== null){
      this.courseDataForm.controls['courseStartTime'].setValue(this.courseData.courseStart.split('T')[1]);
    }   
    if(this.courseData.courseEnd !== null){
      this.courseDataForm.controls['courseEndTime'].setValue(this.courseData.courseEnd.split('T')[1]);
    }    
  }

  loadCourseAddress(){
    if(this.courseData !== null && this.courseData.id !== 0){
      this.atpService.getATPCourseAddresses(this.courseData.id).subscribe(result=>{        
        if(result.length){          
          this.courseLocation = result[0];
          this.courseAddress = this.courseLocation.address

          for (const controlName in this.courseAddress) {
            if (this.courseDataForm.controls.hasOwnProperty(controlName)) {        
              this.courseDataForm.controls[controlName].setValue(this.courseAddress[controlName]);          
            }
          }    

          this.courseDataForm.controls['locationName'].setValue(this.courseLocation.locationName);
        }
      },error=>{
        this.toastr.error("There was an error getting the ATP course address: ", error)
      })
    }    
  }

  setCourseDataFormFields(){       
    this.courseDataForm = new UntypedFormGroup({
      accreditationDiscipline: new UntypedFormControl(""),
      accreditationCourseFormat: new UntypedFormControl(""),      
      accreditationCourseLanguage: new UntypedFormControl(""),      
      stage: new UntypedFormControl(""),      
      courseStart: new UntypedFormControl(null),      
      courseStartTime: new UntypedFormControl(null),    
      courseEnd: new UntypedFormControl(null),  
      courseEndTime: new UntypedFormControl(null),  
      isPrivate: new UntypedFormControl(false),  
      cancelled: new UntypedFormControl({value: false, disabled: true}),  
      receivedPreNote: new UntypedFormControl(false),  
      receivedPreNoteDate: new UntypedFormControl({value: "", disabled: !this.data?.course?.receivedPreNote}, [Validators.required]),  
      receivedPostNote: new UntypedFormControl(false),  
      receivedPostNoteDate: new UntypedFormControl({value: "", disabled: !this.data?.course?.receivedPreNote}, [Validators.required]),  
      receivedStudentFees: new UntypedFormControl({value: false, disabled: true}),        
      students: new UntypedFormControl({value: "", disabled: true}),  
      controlNumber: new UntypedFormControl({value: "", disabled: true}),        
      locationName: new UntypedFormControl(""), 
      street: new UntypedFormControl(""),
      city: new UntypedFormControl(""),      
      zip: new UntypedFormControl(""),      
      county: new UntypedFormControl(""),      
      state: new UntypedFormControl("OR"),        
      programInstructor: new UntypedFormControl(""), 
      programInstructorEmail: new UntypedFormControl(""), 
      programInstructorPhone: new UntypedFormControl(""), 
      webAddress: new UntypedFormControl(""),          
      overrideDelinquency: new UntypedFormControl(false),   
    }); 
  }

  updatePreNoteDateDisabledStatus(event: any){
    const receivedPreNoteDateControl = this.courseDataForm.get('receivedPreNoteDate');

    if (event.checked) {      
      receivedPreNoteDateControl.enable();
      receivedPreNoteDateControl.setValue(new Date().toISOString());
    } else {      
      receivedPreNoteDateControl.disable();
      receivedPreNoteDateControl.setValue(null);
    }
  }

  updatePostNoteDateDisabledStatus(event: any){
    const receivedPostNoteDateControl = this.courseDataForm.get('receivedPostNoteDate');

    if (event.checked) {      
      receivedPostNoteDateControl.enable();
      receivedPostNoteDateControl.setValue(new Date().toISOString());
    } else {      
      receivedPostNoteDateControl.disable();
      receivedPostNoteDateControl.setValue(null);
    }
  }

  updateCourseData(): void {
    let timeExpression = /^([01]\d|2[0-3]):([0-5]\d)(:([0-5]\d))?$/;
    for (const controlName in this.courseData) {
      if (this.courseDataForm.controls.hasOwnProperty(controlName)) {
        this.courseData[controlName] = this.courseDataForm.controls[controlName].value;        
      }
    } 

    if(this.courseDataForm.controls['courseStart'].value !== null && this.courseDataForm.controls['courseStartTime'].value !== null && timeExpression.test(this.courseDataForm.controls['courseStartTime'].value)){            
      this.courseData.courseStart = typeof this.courseDataForm.controls['courseStart'].value === 'string' ? `${this.courseDataForm.controls['courseStart'].value.split('T')[0]}T${this.courseDataForm.controls['courseStartTime'].value}` : `${this.courseDataForm.controls['courseStart'].value.toISOString().split('T')[0]}T${this.courseDataForm.controls['courseStartTime'].value}`;
    }

    if(this.courseDataForm.controls['courseEnd'].value !== null && this.courseDataForm.controls['courseEndTime'].value !== null && timeExpression.test(this.courseDataForm.controls['courseEndTime'].value)){
      this.courseData.courseEnd = typeof this.courseDataForm.controls['courseEnd'].value === 'string' ? `${this.courseDataForm.controls['courseEnd'].value.split('T')[0]}T${this.courseDataForm.controls['courseEndTime'].value}` : `${this.courseDataForm.controls['courseEnd'].value.toISOString().split('T')[0]}T${this.courseDataForm.controls['courseEndTime'].value}`;
    }
    
    for (const controlName in this.courseAddress) {
      if (this.courseDataForm.controls.hasOwnProperty(controlName)) {
        this.courseAddress[controlName] = this.courseDataForm.controls[controlName].value;        
      }
    }   
    
    this.courseLocation.locationName = this.courseDataForm.controls['locationName'].value;
  }

  getCourseDisciplines(){
    return Object.values(COURSE_DISCIPLINES);
  }

  getCourseTypes(){
    return Object.values(COURSE_TYPES);
  }

  getCourseFormats(){
    return Object.values(COURSE_FORMAT);
  }

  getCourseLanguages(){
    return Object.values(COURSE_LANGUAGE);
  }

  filterPeople(event: any) {    
    const value = event.target.value;
    if (!value) {
      this.filteredPersonList = [...this.personList];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredPersonList = this.personList.filter(person => person.lastName.toLowerCase().includes(filterValue) || person.firstName.toLowerCase().includes(filterValue) || person.id.toString().toLocaleLowerCase().includes(filterValue));    
  } 

  savePrompt(){
    let isNew = this.courseData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Course?' : 'Are you sure you want to <strong>Save</strong> the Course?',title: isNew ? 'Add the Course' : 'Save the Course'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();
      }
    })
  }

  saveData() {
    this.updateCourseData();      
    this.atpService.saveATPCourse(this.courseData).subscribe(result=>{
      this.courseData = result;
      
      this.courseLocation.atpCourse = result;

      this.commonService.saveAddress(this.courseAddress).subscribe(result=>{
        this.courseAddress = result;
        this.courseLocation.address = result

        this.atpService.saveATPCourseAddress(this.courseLocation).subscribe(result=>{
          this.courseLocation = result;
          this.courseAddress = this.courseLocation.address;
          this.changeIndicator++;
          this.toastr.success("Success")          
        },error=>{
          this.toastr.error("There was an error saving the ATP course address: ", error)  
        });
      },error=>{
        this.toastr.error("There was an error saving the address: ", error)  
      })      
    },error=>{
      this.toastr.error("There was an error saving the ATP course: ", error)
    });    
  }

  cancelCourse(cancel: boolean){    
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: cancel ? 'Are you sure you want to <strong>cancel</strong> the Course?' : 'Are you sure you want to <strong>restore</strong> the Course?',title: cancel ? 'Cancel the Course' : 'Restore the Course'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.courseDataForm.controls['cancelled'].setValue(!this.courseDataForm.controls['cancelled'].value)
        this.saveData();
      }
    })
  }

  uploadRoster(){
    const dialogRef = this.dialog.open(AtpCourseStudentUploadComponent, { 
      data:{course:this.courseData},             
      width: '70%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {         
      this.refreshStudentList();           
    });
  }

  filterTable(): void {
    this.tableDataFiltered = this.tableData;

    this.tableDataFiltered = this.tableDataFiltered.filter(x => {      
      return x.id?.toString().includes(this.filterTableString) ||      
        x.studentName.toString().toLowerCase().includes(this.filterTableString.toLowerCase()) ||
        x.courseCertificateNumber.toString().toLowerCase().includes(this.filterTableString.toLowerCase()) ||
        x.testScore.toString().toLowerCase().includes(this.filterTableString.toLowerCase())
    });

    this.updateTableDataSource();
  }
  updateTableDataSource(){    
    this.tableDataSource = new MatTableDataSource<any>(this.tableDataFiltered);
    this.tableDataSource.paginator = this.tablePaginator;
    this.tableDataSource.sort = this.tableSort;    
  }

  editTableEntry(row: any): void {
    const dialogRef = this.dialog.open(AddEditCourseStudentComponent, { 
      data:{student: row,course: this.courseData},             
      width: '70%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshStudentList();     
    });
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  deleteStudent(row: any){    
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the student?',title: 'Delete the Student'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPCourseStudent(row.id).subscribe(result=>{
          this.refreshStudentList();          
        },error=>{
          this.toastr.error("There was an error deleting the course student: ", error);
        })  
      }
    })
  }

  paymentUpdated(payments: IPayment[]) {
    
  }

  refreshStudentList(){
    this.changeIndicator++;
    this.atpService.getATPCourseStudents(this.courseData.id).subscribe(result=>{
      this.courseDataForm.controls['students'].setValue(result.length);      
      this.tableData = result.map((data) => ({
        ...data,
        studentName: data.student.firstName + ' ' + data.student.lastName
      }));
      this.filterTable();
    },error=>{
      this.toastr.error("There was an error getting the course students: ", error);
    })
  }
}
