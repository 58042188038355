import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IPerson } from 'app/models/People/person';
import { IPersonAddress } from 'app/models/People/personAddress';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpCourseStudent } from 'app/models/atp/atpCourseStudent';
import { IAddress } from 'app/models/common/address';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AddEditAtpComponent } from '../add-edit-atp.component';
import { AddEditAtpCourseComponent } from '../add-edit-atp-course/add-edit-atp-course.component';

@Component({
  selector: 'app-add-edit-course-student',
  templateUrl: './add-edit-course-student.component.html',
  styleUrls: ['./add-edit-course-student.component.scss']
})
export class AddEditCourseStudentComponent implements OnInit {
  public courseStudentDataForm = null;
  public courseStudentData: IAtpCourseStudent = null;  
  public address: IAddress = {id: 0, state: '', street: '', city: '', contactType: '', county: '', zip: ''};
  public studentAddress: IPersonAddress = {id: 0, person: null, address: this.address, isCurrentMailing: true, isCurrentPhysical: false}  
  public uiData = LEAD_BASED_PAINT;
  private debounceString: Subject<string> = new Subject<string>();
  
  public studentList: IPerson[] = [];
  public filteredStudentList: IPerson[] = [];
  public selectedStudent: IPerson = null;
  public createNew = 'Create a New Person'
  public personDataForm = null;
  public studentFilter: IPersonFilter = {filterAffiliation: [], filterStatus: '', filterString: '', expirationStartDate: '', expirationEndDate: ''}

  constructor(private personService:PersonService,
    @Inject(MAT_DIALOG_DATA) public data:{student:IAtpCourseStudent, course:IAtpCourse, courseStudents:IAtpCourseStudent[]},
    public sharedService: SharedService,
    private atpService: AtpService,
    private commonService: CommonDataService,
    private toastr: ToastrService,   
    public dialog: MatDialog, 
    private dialogRef: MatDialogRef<AddEditCourseStudentComponent>,
    private renderer: Renderer2, 
    private el: ElementRef) 
  {     
    this.initializeDebounceFunction();    
  }
  
  ngOnInit(): void {
    this.setCourseDataFormFields();
    if(this.data.student !== null && this.data.student !== undefined){
      this.studentList = [this.data.student.student];
      this.filteredStudentList = this.studentList;
      this.selectedStudent = this.data.student.student;   
      this.updateScrollContainerHeight();   
    }
    else{
      this.resetStudentList();
    }
    this.loadData();
  }

  initializeDebounceFunction(): void {
    this.debounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {      
      this.filterStaff(value.trim().toLowerCase());     
    });
  }

  loadData(){
    if(this.selectedStudent?.id >= 0){
      if(this.data.student !== null){
        this.courseStudentData = this.data.student; 
        this.courseStudentData.student = this.selectedStudent;
      }
      else{
        this.courseStudentData = {
          id: 0,
          atpCourse: this.data.course,
          student: this.selectedStudent,
          courseCertificateNumber: "",
          testScore: null,
          photoImage: ""
        };     
      }
  
      this.personService.getPersonAddressesByPersonId(this.selectedStudent.id).subscribe(result=>{
        this.studentAddress = result.length > 0 ? result[0] : {id: 0, person: null, address: null, isCurrentMailing: true, isCurrentPhysical: false};
        this.address = this.studentAddress.id > 0 ? this.studentAddress.address : {id: 0, state: '', street: '', city: '', contactType: '', county: '', zip: ''};;
        for (const controlName in this.studentAddress?.address) {
          if (this.courseStudentDataForm.controls.hasOwnProperty(controlName)) {        
            this.courseStudentDataForm.controls[controlName].setValue(this.studentAddress.address[controlName]);          
          }
        }  
      },error=>{
        this.toastr.error("There was an error getting the student address: ", error);
      })
  
      for (const controlName in this.courseStudentData) {
        if (this.courseStudentDataForm.controls.hasOwnProperty(controlName)) {        
          this.courseStudentDataForm.controls[controlName].setValue(this.courseStudentData[controlName]);          
        }
      }  
  
      for (const controlName in this.courseStudentData.student) {
        if (this.courseStudentDataForm.controls.hasOwnProperty(controlName) && this.courseStudentData.student.id !== 0) {        
          this.courseStudentDataForm.controls[controlName].setValue(this.courseStudentData.student[controlName]);          
        }
      }  
    }    
  }

  setCourseDataFormFields(){       
    this.courseStudentDataForm = new UntypedFormGroup({
      courseCertificateNumber: new UntypedFormControl(""),
      testScore: new UntypedFormControl(""),
      photoImage: new UntypedFormControl(""),
      firstName: new UntypedFormControl(""),
      lastName: new UntypedFormControl(""),
      dateOfBirth: new UntypedFormControl(null),
      street: new UntypedFormControl(""),
      city: new UntypedFormControl(""),
      state: new UntypedFormControl(""),
      zip: new UntypedFormControl(""),
    }); 
  }

  updateCourseStudentData(): void {    
    for (const controlName in this.courseStudentData) {
      if (this.courseStudentDataForm.controls.hasOwnProperty(controlName)) {
        this.courseStudentData[controlName] = this.courseStudentDataForm.controls[controlName].value;        
      }
    } 

    for (const controlName in this.selectedStudent) {
      if (this.courseStudentDataForm.controls.hasOwnProperty(controlName)) {
        this.selectedStudent[controlName] = this.courseStudentDataForm.controls[controlName].value;        
      }
    }    

    for (const controlName in this.address) {
      if (this.courseStudentDataForm.controls.hasOwnProperty(controlName)) {
        this.address[controlName] = this.courseStudentDataForm.controls[controlName].value;        
      }
    }    
  }
  
  savePrompt(){
    let isNew = this.courseStudentData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> the student?' : 'Are you sure you want to <strong>Update</strong> the student?',title: isNew ? 'Add the Student' : 'Update the Student'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.updateCourseStudentData();
        this.saveData();
      }
    })
  }

  saveCourseStudent() {            
    if (this.courseStudentData.testScore !== null && this.courseStudentData.testScore.toString().trim() === "") {
      this.courseStudentData.testScore = null;
    }    
        
    this.atpService.saveATPCourseStudent(this.courseStudentData).subscribe(result=>{
      this.toastr.success("Success")
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("There was an error saving the course student: ", error)
    })
  }

  saveData(){
    if (this.courseStudentData.testScore !== null && this.courseStudentData.testScore.toString().trim() !== '' && (isNaN(this.courseStudentData.testScore) || this.courseStudentData.testScore < 70 || this.courseStudentData.testScore > 100)) {
      this.toastr.error("The student's test score must be between 70 to 100 to save the student.");
    }
    else{
      this.personService.savePerson(this.selectedStudent).subscribe(result=>{
        this.selectedStudent = result;
        this.studentAddress.person = this.selectedStudent;
        this.courseStudentData.student = this.selectedStudent;
  
        this.commonService.saveAddress(this.address).subscribe(result=>{
          this.address = result;
          this.studentAddress.address = this.address;
  
          if(this.studentAddress.id === 0){
            this.personService.savePersonAddress(this.studentAddress).subscribe(result=>{},error=>{this.toastr.error("There was an error saving the person address: ", error);})
          }        
          this.saveCourseStudent();
        },error=>{this.toastr.error("There was an error saving the address: ", error);})
      },error=>{this.toastr.error("There was an error saving the student: ", error);})
    }
  }

  updateDebounceString(event: any){    
    this.debounceString.next(event.target.value); 
    this.updateScrollContainerHeight();
  }
  
  resetStudentList(): void {
    this.studentList = [{id: 0, firstName: this.createNew, lastName: '', middleName: '', title: '', ssn: '', dateOfBirth: '', inactive: false, response: 'Unk', certificates: null}];        
    this.filteredStudentList = this.studentList;
  }

  updateScrollContainerHeight() {
    if (this.selectedStudent.id >= 0) {
      const scrollContainer = this.el.nativeElement.querySelector('.scroll-container');
      this.renderer.setStyle(scrollContainer, 'height', '60vh');
      this.setCourseDataFormFields();
      this.loadData();
    }
    else {
      const scrollContainer = this.el.nativeElement.querySelector('.scroll-container');
      this.renderer.setStyle(scrollContainer, 'height', '10vh');
    }
  }
  
  filterStaff(value: any) {
    if (value.length < 3) {
      this.resetStudentList();      
    }
    else{
      this.studentFilter.filterString = value;
      this.personService.getPeopleFiltered(this.studentFilter).subscribe(result=>{
        this.studentList = result.filter(student => !this.data.courseStudents?.some(currentStudent => currentStudent.student.id === student.id));
        this.filteredStudentList = this.studentList;
      },error=>{this.toastr.error("An error occured getting the student list: ", error);});
    }
  }
  
displayStudent(staffMember: any): string {
    if (staffMember) {
        if (staffMember.id === 0) {
            return staffMember.firstName;
        } else {
            return `${staffMember.id}: ${staffMember.firstName} ${staffMember.lastName}`;
        }
    } else {
        return '';
    }
  }

  editATP(){
    const dialogRef = this.dialog.open(AddEditAtpComponent, {
      width: '90%',
      data: {atp: this.data.course.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  editCourse(){
    const dialogRef = this.dialog.open(AddEditAtpCourseComponent, {
      width: '90%',
      data: {course: this.data.course, atp: this.data.course.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      
    });
  }
}
