<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
</section>

<div [ngStyle]="{'--entity-color' : uiData.firm.color}" *ngIf="!sharedService.loading && !loading">
    <mat-card [style.background-color]="uiData.firm.color">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>
                {{firmApplication.id + ': ' + firmApplication.firmName  + ' - ' + firmApplication.applicationType.toUpperCase()}}
            </mat-card-title>

            <div class="download-container" *ngIf="commonService.downloadCount > 0">
                <div>Pending: {{ commonService.downloadCount }}</div>
                <mat-icon class="download-icon">download</mat-icon>
                <div class="spinner-container">
                    <mat-progress-spinner
                        *ngIf="commonService.progress < 100"
                        diameter="40"
                        mode="determinate"
                        [value]="commonService.progress">
                    </mat-progress-spinner>
                    <div class="progress-text">{{ commonService.progress }}%</div>
                </div>    
            </div>
        </div>
    </mat-card>
    <div *ngIf="data.application.applicationType !== 'Link'" class="scroll-container">
        <form [formGroup]="applicationDataForm">
            <mat-card>
                <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
                    <mat-tab>
                        <ng-template mat-tab-label>                
                            Application Information
                        </ng-template>
                        <ng-container>
                            &nbsp;
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                <mat-card fxFlex="50">
                                    <mat-card-title>
                                        <div fxLayout="row" fxLayout.lt-lg="column">
                                            <span fxFlex>Application Information</span>
                                        </div>
                                    </mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="column" fxLayoutGap="8px">                 
                                            <span class="f-2-20"><strong>Application Status</strong></span>                           
                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">                                
                                                <mat-form-field>
                                                    <mat-label>Status</mat-label>
                                                    <mat-select  formControlName="status">   
                                                        <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">{{ status }}</mat-option>                                                            
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                
                                            <span><strong>License Type</strong></span>
                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">                                
                                                <mat-form-field>
                                                    <mat-label>License Type</mat-label>
                                                    <mat-select (selectionChange)="onApplicationTypeChange($event)" formControlName="applicationType">   
                                                        <mat-option value="lbpa">Lead Based Paint Activities</mat-option>
                                                        <mat-option value="rrp">Renovation Repair and Painting</mat-option>                                                                                                                                           
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">                                
                                                <span><strong>Application Date</strong></span>                                                            
                                                <mat-form-field>                                                                
                                                    <input matInput [matDatepicker]="applicationDatePicker" placeholder="Application Date" formControlName="applicationDate" (focus)="applicationDatePicker.open()">
                                                    <mat-datepicker-toggle matSuffix [for]="applicationDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #applicationDatePicker></mat-datepicker>
                                                </mat-form-field>               
                                            </div>

                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">                                
                                                <span><strong>Viable Application Date</strong></span>                                                             
                                                <mat-form-field>                                                                
                                                    <input matInput [matDatepicker]="viableApplicationDatePicker" placeholder="Viable Application Date" formControlName="applicationViableDate" (focus)="viableApplicationDatePicker.open()">
                                                    <mat-datepicker-toggle matSuffix [for]="viableApplicationDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #viableApplicationDatePicker></mat-datepicker>
                                                </mat-form-field>               
                                            </div>

                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">                                 
                                                <span><strong>Application Approval Date</strong></span>                                                           
                                                <mat-form-field>                                                                
                                                    <input matInput [matDatepicker]="applicationApprovalDatePicker" placeholder="Application Approval Date" formControlName="approvalDate" (focus)="applicationApprovalDatePicker.open()">
                                                    <mat-datepicker-toggle matSuffix [for]="applicationApprovalDatePicker"></mat-datepicker-toggle>
                                                    <mat-datepicker #applicationApprovalDatePicker></mat-datepicker>
                                                </mat-form-field>               
                                            </div>
                
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                
                                <mat-card fxFlex="50">
                                    <div fxLayout="column" *ngIf="this.firmApplication.renewal || this.data?.firmData?.renewal">                                                                                                         
                                        <mat-card-title>
                                            Certificate Information
                                        </mat-card-title>
                                        <span><strong>Control Number</strong></span>
                                        <mat-form-field>
                                            <input matInput formControlName="previousApplication" >                                                        
                                            <mat-placeholder>Affiliated License#</mat-placeholder>
                                        </mat-form-field>

                                        <div fxLayout="column">
                                            <span><strong>Renewal</strong></span>
                                            <div fxLayout="column">
                                                <mat-checkbox formControlName="renewal">
                                                    Is a Renewal
                                                </mat-checkbox>
                                            </div>
                                        </div>    
                                        &nbsp;&nbsp;                                                  
                                    </div>

                                    <form [formGroup]="applicationAttestationForm">
                                    <mat-card-title>
                                    Signature and Acknowledgment
                                    </mat-card-title>
                                    <mat-card-content>
                                    <div fxLayout="column" fxLayoutGap="16px">
                                        <div fxLayout="column" fxLayoutGap="16px" class="p-t-24">
                                        <mat-checkbox class="mat-checkbox" [checked]="applicationAttestationForm.get('attestation').value" formControlName="attestation">
                                            <span class="mat-checkbox-label">
                                                <strong>I understand the application fee is non-refundable.</strong>
                                            </span>
                                        </mat-checkbox>
                                        </div>
                        
                                        <div fxLayout="column" fxLayoutGap="8px">
                                        <span><strong>Name or Initials</strong></span>
                                        <div fxLayout="row">
                                            <mat-form-field fxFlex="60" fxFlex.lt-lg="100">
                                            <input matInput placeholder="Signature" formControlName="esignature">
                                            </mat-form-field>
                                        </div>
                                        </div>
                                    </div>
                                    </mat-card-content>
                                    </form>
                                </mat-card> 
                            </div>                                
                        </ng-container>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>                
                            <mat-icon>warning</mat-icon> &nbsp; Deficiencies ({{filteredApplicationDeficiencies.length}})
                        </ng-template>
                        <ng-container>
                            <div fxLayout="row" fxLayout.lt-lg="column">
                                &nbsp;
                            </div>
                            <div fxLayout="row" fxLayout.lt-lg="column">
                                <span fxFlex style="font-size: 24px; font-weight: 500">Deficiencies </span>
                            </div>                            
                                    <div fxLayout="column">
                                        <div fxLayout="row" fxLayout.lt-md="column">
                                            <div fxLayout="column">
                                                <strong class="noMarginHeader">Results ({{filteredApplicationDeficiencies.length}})</strong>
                                                <div fxLayout="row">
                                                    <mat-form-field style="width:200px;">
                                                        <input placeholder="Filter Application Deficiencies" type="text" (input)="updateApplicationDeficiencyDebounceString($event)" matInput/>                                                                                
                                                    </mat-form-field>
                                                    &nbsp;&nbsp;
                                                    <mat-form-field style="width:200px;">
                                                        <mat-label>Filter Application Deficiencies</mat-label>
                                                        <mat-select (selectionChange)="filterDeficiencyTable()" [formControl]="applicationDeficiencyStatusFilterString">
                                                        <mat-option value="true">Resolved</mat-option>
                                                        <mat-option value="false">Not Resolved</mat-option>
                                                        <mat-option value="both">Both</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>  
                                                </div>    
                                            </div>
                                            <div fxFlex><div *ngIf="firmApplication.id === 0" class="text-red-400"><strong>The Application Needs to be Saved</strong></div></div>
                                            <div>                        
                                                <button mat-raised-button *ngIf="canEdit" color="primary" (click)="editDeficiency(null)" [disabled]="firmApplication.id === 0">
                                                    <mat-icon>add</mat-icon> Add Deficiency
                                                </button>
                                            </div>
                                            <br />
                                        </div>
                                    
                                        <mat-table [dataSource]="deficiencyDataSource" matSort class="mat-elevation-z8">                                            
                                            <ng-container *ngFor="let column of displayedDeficiencyColumnsFormat" [matColumnDef]="column.columnName">
                                              <mat-header-cell *matHeaderCellDef mat-sort-header [fxFlex]="column.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                                {{ column.displayName }}
                                              </mat-header-cell>
                                              <mat-cell *matCellDef="let row" [fxFlex]="column.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                                <ng-container *ngIf="column.type === 'date'">
                                                  <div *ngIf="isMobile" fxLayout="row">
                                                    <span><strong>{{column.displayName}}: </strong></span> &nbsp;
                                                    <span>{{(row[column.columnName] | date:'MM/dd/yyyy')}}</span>
                                                  </div>
                                                  <div *ngIf="!isMobile">
                                                    {{(row[column.columnName] | date:'MM/dd/yyyy')}}
                                                  </div>
                                                </ng-container>
                                                <ng-container *ngIf="column.type === 'boolean'">
                                                  <div *ngIf="isMobile" fxLayout="row">
                                                    <span><strong>{{column.displayName}}: </strong></span> &nbsp;
                                                    <mat-checkbox [(ngModel)]="row[column.columnName]" [disabled]="true"></mat-checkbox>
                                                  </div>
                                                  <div *ngIf="!isMobile">
                                                    <mat-checkbox [(ngModel)]="row[column.columnName]" [disabled]="true"></mat-checkbox>
                                                  </div>
                                                </ng-container>
                                                <ng-container *ngIf="column.type !== 'date' && column.type !== 'boolean'">
                                                  <div *ngIf="isMobile" fxLayout="row">
                                                    <span><strong>{{ column.displayName }}: </strong></span> &nbsp;
                                                    <span>{{ displayLimit(row[column.columnName], column.size) }}</span>
                                                  </div>
                                                  <div *ngIf="!isMobile">
                                                    {{ displayLimit(row[column.columnName], column.size) }}
                                                  </div>
                                                </ng-container>
                                              </mat-cell>
                                            </ng-container>
                                          
                                            <ng-container matColumnDef="action">
                                              <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                                              <mat-cell *matCellDef="let row">
                                                <button mat-icon-button (click)="deleteDeficiency(row); $event.stopPropagation()">
                                                  <mat-icon style="color: red">delete</mat-icon>
                                                </button>
                                              </mat-cell>
                                            </ng-container>
                                          
                                            <mat-header-row *matHeaderRowDef="displayedDeficiencyColumnNames.concat(['action'])"></mat-header-row>
                                            <mat-row *matRowDef="let row; columns: displayedDeficiencyColumnNames.concat(['action']);" (click)="editDeficiency(row)"></mat-row>                                          
                                        </mat-table>                                          
                                                                        
                                        <br />
                                        <mat-paginator [pageSize]="deficiencyPageSize" [pageSizeOptions]="[5, 10, 50, applicationDeficiencies.length]" #deficiencyTablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
                                    </div>
                        </ng-container>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>                
                            <mat-icon>assignment</mat-icon> &nbsp; Firm Information
                        </ng-template>
                        <ng-container>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                <mat-card fxFlex>
                                    <mat-card-title>
                                        <div fxLayout="row" fxLayout.lt-lg="column">
                                            <span fxFlex>Firm Information</span>
                                        </div>
                                    </mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="column" fxLayoutGap="8px">
                                            <span><strong>Firm Name</strong></span>                
                                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">                    
                                                <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                                                    <input matInput placeholder="Name" formControlName="firmName" (keyup)="firmSearch($event)">                                    
                                                </mat-form-field>
                                            </div>
                                
                                            <span><strong>{{firmApplication.applicationType === 'lbpa' ? 'CCB License #' : 'Property Management License #'}}</strong></span>
                                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                                                <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                                                    <input matInput placeholder="{{firmApplication.applicationType === 'lbpa' ? 'CCB License #' : 'Property Management License #'}}" 
                                                    formControlName="ccb"
                                                    (keyup)="firmSearch($event)">
                                                </mat-form-field>
                                            </div>

                                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="" *ngIf="this.firmApplication?.firm !== null || this.data?.application?.firm !== null">
                                                <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                                                        <input matInput placeholder="Linked Firm" [value]="this.firmApplication?.firm !== null ? this.firmApplication?.firm?.id.toString() + ': ' + this.firmApplication?.firm?.name : this.data.application?.firm?.id.toString() + ': ' + this.data.application?.firm?.name" disabled>                                                                                              
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            
                                <mat-card fxFlex="50">
                                    <mat-card-title>
                                        Firm Services
                                    </mat-card-title>
                                    <mat-card-content>
                                        <div fxLayout="column" fxLayoutGap="8px">
                                            <div *ngIf="firmApplication.applicationType === 'lbpa'" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                                <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                                    <span class="f-2-20"><strong>Firm provides the following services</strong></span>
                                                    <div fxLayout="column">
                                                        <ng-container *ngFor="let service of firmServices | keyvalue">
                                                        <mat-checkbox [formControlName]="service.key">
                                                            <span class="mat-checkbox-label">{{ service.value }}</span>
                                                        </mat-checkbox>
                                                        </ng-container>
                                                    </div>                                      
                                                </div>                           
                                            </div>
                
                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                                <span class="f-2-20"><strong>My Firm Provides Services For</strong></span>
                                                <mat-form-field>
                                                    <mat-label>Select an option</mat-label>
                                                    <mat-select  formControlName="firmServices">   
                                                        <mat-option *ngFor="let status of getFirmServiceForTypeValues()" [value]="status">{{ status }}</mat-option>                                                                                 
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>   
                                            
                                            <div fxLayout="column" class="m-t-8">
                                                <span><strong>Private Firm</strong></span>
                                                <mat-checkbox  formControlName="isPublic">
                                                    Private Firm
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </ng-container>
                    </mat-tab>

                    <mat-tab *ngIf="!isApplicationForExistingFirm">
                        <ng-template mat-tab-label>                
                            <mat-icon>{{uiData.address.icon}}</mat-icon> &nbsp; Address
                        </ng-template>
                        <ng-container>
                                    <div fxLayout="row" fxLayout.lt-lg="column">
                                        &nbsp;
                                    </div>
                                    <div fxLayout="row" fxLayout.lt-lg="column">
                                        <span fxFlex style="font-size: 24px; font-weight: 500">Firm Address </span>
                                    </div>
                                    <div fxLayout="column">
                                        <span><strong>Physical Address</strong></span>
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">                    
                                            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                <input matInput placeholder="Street Apt/Unit/Suite"  formControlName="firmPhysicalStreet">
                                            </mat-form-field>
                                                
                                            <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                                                <input matInput placeholder="City"  formControlName="firmPhysicalCity">
                                            </mat-form-field>
                            
                                            <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                                                <mat-label style="color:red" *ngIf="applicationDataForm.get('firmPhysicalState').value == ''">State</mat-label>
                                                <mat-label *ngIf="applicationDataForm.get('firmPhysicalState').value != ''">State</mat-label>
                                                <mat-select formControlName="firmPhysicalState" >
                                                    <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                            
                                            <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                                                <input matInput placeholder="Zip"  formControlName="firmPhysicalZip">
                                            </mat-form-field>
                            
                                            <mat-form-field fxFlex="20" fxFlex.lt-md="100" *ngIf="applicationDataForm.get('firmPhysicalState').value === 'OR'">
                                                <mat-label>County</mat-label>
                                                <mat-select formControlName="firmPhysicalCounty" >
                                                  <mat-option>-- None --</mat-option>
                                                  <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                
                                    <div fxLayout="column" class="m-t-8">
                                        <span><strong>Mailing Address</strong></span>
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">                    
                                            <mat-checkbox  formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                                                Same as physical
                                            </mat-checkbox>                                                          
                                        </div>                
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" *ngIf="!applicationDataForm.get('mailingIsPhysical').value">
                                            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                <input matInput placeholder="Street Apt/Unit/Suite"  formControlName="firmMailingStreet"
                                                (blur)="firmService.validateFieldRequired(applicationDataForm,'firmMailingStreet')">
                                            </mat-form-field>
                                                
                                            <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                                                <input matInput placeholder="City"  formControlName="firmMailingCity" (blur)="firmService.validateFieldRequired(applicationDataForm,'firmMailingCity')">
                                            </mat-form-field>
                            
                                            <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                                                <mat-label>State</mat-label>
                                                <mat-select formControlName="firmMailingState"  (blur)="firmService.validateFieldRequired(applicationDataForm,'firmMailingState')">
                                                  <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                </mat-select>                                
                                            </mat-form-field>
                            
                                            <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                                                <input matInput placeholder="Zip"  formControlName="firmMailingZip" (blur)="firmService.validateFieldRequired(applicationDataForm,'firmMailingZip')">
                                            </mat-form-field>
                            
                                            <mat-form-field fxFlex="20" fxFlex.lt-md="100" *ngIf="applicationDataForm.get('firmMailingState').value === 'OR'">                                
                                                <mat-label>County</mat-label>
                                                <mat-select formControlName="firmMailingCounty" >
                                                  <mat-option>-- None --</mat-option>
                                                  <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                                  </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>                
                                    </div>
                        </ng-container>
                    </mat-tab>

                    <mat-tab *ngIf="!isApplicationForExistingFirm">
                        <ng-template mat-tab-label>                
                            <mat-icon>{{uiData.contact.icon}}</mat-icon> &nbsp; Contact Information
                        </ng-template>
                        <ng-container>
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                <mat-card fxFlex>
                                    <mat-card-title>
                                    Firm Contact Information
                                    </mat-card-title>
                                    <mat-card-content>          
                                        <div fxLayout="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Primary Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Phone"  formControlName="firmPhone">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Alternate Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Alt Phone"  formControlName="firmAlternatePhone">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Fax Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Fax Phone"  formControlName="firmFaxPhone">
                                            </mat-form-field>
                                        </div>
                                        </div>
                    
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Primary Email Address</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Email"  formControlName="firmEmail">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Confirm Email"  formControlName="firmConfirmEmail">
                                            </mat-form-field>
                                        </div>
                                        </div>
                    
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-18"><strong>Website</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Website"  formControlName="firmWebsite">
                                            </mat-form-field>
                                        </div>
                                        </div>
                    
                                    </mat-card-content>
                                </mat-card>
                    
                                <mat-card fxFlex>
                                    <mat-card-title>
                                        Contact Information (Officer, Owner, or Authorized Agent)
                                    </mat-card-title>
                                    <mat-card-content>
                                    <div fxLayout="column" fxLayoutGap="8px">
                                        <span><strong>Legal Name</strong></span>
                                            
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                            <input matInput placeholder="First"  formControlName="contactFirstName">
                                        </mat-form-field>
                    
                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                            <input matInput placeholder="Last"  formControlName="contactLastName">
                                        </mat-form-field>
                                        </div>
                    
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                            <input matInput placeholder="Middle"  formControlName="contactMiddleName">
                                        </mat-form-field>
                    
                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                            <input matInput placeholder="Title"  formControlName="contactTitle">
                                        </mat-form-field>
                                        </div>
                    
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Primary Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Phone"  formControlName="contactPhone">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Alternate Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Alt Phone"  formControlName="contactAlternatePhone">
                                            </mat-form-field>
                                        </div>
                                        </div>
                                        
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Fax Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Fax Phone"  formControlName="contactFaxPhone">
                                            </mat-form-field>
                                        </div>
                                        </div>
                    
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Primary Email Address</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Email"  formControlName="contactEmail">
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Confirm Email"  formControlName="contactConfirmEmail">
                                            </mat-form-field>
                                        </div>
                                        </div>                      
                                    </div>
                                    </mat-card-content>
                                </mat-card>
                                </div>
                        </ng-container>
                    </mat-tab>

                    <mat-tab *ngIf="!isApplicationForExistingFirm">
                        <ng-template mat-tab-label>                
                            <mat-icon>{{uiData.staff.icon}}</mat-icon> &nbsp; Staff Members ({{filteredApplicationPerson.length}})
                        </ng-template>
                        <ng-container>
                                    <div fxLayout="row" fxLayout.lt-lg="column">
                                        &nbsp;
                                    </div>
                                    <div fxLayout="row" fxLayout.lt-lg="column">
                                        <span fxFlex style="font-size: 24px; font-weight: 500">Staff </span>
                                    </div>
                                    <div fxLayout="column">
                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                            <div fxLayout="column">
                                                <strong class="noMarginHeader">Results ({{filteredApplicationPerson.length}})</strong> 
                                                <div fxLayout="row">
                                                    <mat-form-field style="width:200px;">
                                                        <input placeholder="Filter Staff" type="text" (input)="updateApplicationPersonDebounceString($event)" matInput/>                                                                                
                                                    </mat-form-field>     
                                                </div> 
                                            </div>   
                                            <div *ngIf="firmApplication.id === 0" class="text-red-400"><strong>The Application Needs to be Saved</strong></div>
                                            <button title="Add a new person to the application" mat-raised-button *ngIf="canEdit" [disabled]="firmApplication.id === 0" color="primary" aria-label="Add Documents" style="margin-right: 10px;" (click)="editIndividual($event, null);">
                                                <mat-icon>add</mat-icon>
                                                Add Person
                                            </button> 
                                        </div>                                        
                                        <br />                            
                                        <mat-table [dataSource]="personDataSource" matSort #personTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="individualTable.layout.columns.concat('Action')"></mat-header-row>                  
                                            <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: individualTable.layout.columns.concat('Action');" (click)="editIndividual($event, row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                                
                                            <ng-container *ngFor="let cellData of individualTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header [fxFlex]="cellData.size">{{ cellData.displayName }}</mat-header-cell>
                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size">
                                                  <ng-container [ngSwitch]="cellData.type">
                                                    <ng-container *ngSwitchCase="'date'">
                                                      {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'bool'">
                                                      <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="!canEdit" (click)="onCoverFee(row)"></mat-checkbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                      {{ row[cellData.columnName] }}
                                                    </ng-container>
                                                  </ng-container>
                                                </mat-cell>
                                            </ng-container>                                              
                                            
                                            <ng-container matColumnDef="Action">
                                                <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100">Action</mat-header-cell>
                                                <mat-cell *matCellDef="let row" fxFlex="7" fxFlex.lt-sm="100" style="height: 45px">
                                                <button title="Delete {{row['Name']}}" mat-icon-button class="m-8" color="warn" [disabled]="!canEdit" aria-label="Delete Person" (click)="deleteIndividual(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                                </mat-cell>
                                            </ng-container>
                                        </mat-table>
                                                        
                                        <br />
                                        <mat-paginator [pageSize]="personPageSize" [pageSizeOptions]="[5, 10, 50, filteredApplicationPerson.length]" #personTablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
                                    </div>
                                </div>
                        </ng-container>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>                
                            <mat-icon>attachment</mat-icon> &nbsp; Documents ({{tableApplicationDocumentsData.length}})
                        </ng-template>
                        <ng-container>  
                            <mat-card>
                                <mat-card-content>
                                    <div fxLayout="column">
                                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                            <div fxLayoutGap="5px">
                                                <strong class="noMarginHeader">Results ({{tableApplicationDocumentsData.length}})</strong> 
                                                
                                                <mat-form-field style="width:200px;">
                                                    <input (keyup)="filterDocumentTable()" placeholder="Filter Results" [(ngModel)]="filterDocumentString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                </mat-form-field>      
                                            </div>                       
                                            <div *ngIf="firmApplication.id === 0" class="text-red-400"><strong>The Application Needs to be Saved</strong></div>              
                                            <div>                                                                        
                                                <button mat-raised-button color="primary" (click)="editDocument(null)" [disabled]="firmApplication.id === 0">
                                                    <mat-icon>add</mat-icon> Add Document
                                                </button>                                                
                                            </div>
                                        </div>
                    
                                        <br />
                            
                                        <mat-table [dataSource]="documentDataSource" matSort #DocumentTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                            <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="tableApplicationDocumentsColumns"></mat-header-row>                  
                                            <mat-row (click)="editDocument(row)" fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: tableApplicationDocumentsColumns" title="{{row.fileName}}"></mat-row>                                      
                                                                
                                            <ng-container *ngFor="let cellData of tableApplicationDocumentsFormat; let i = index" [matColumnDef]="cellData.columnName">
                                                <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                    <ng-container [ngSwitch]="cellData.type">
                                                        <ng-container *ngSwitchCase="'date'">
                                                            {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                        </ng-container>
                                                        <ng-container *ngSwitchCase="'bool'">
                                                            <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                        </ng-container>
                                                        <ng-container *ngSwitchDefault>
                                                            {{ displayLimit(row[cellData.columnName], '80') }}
                                                        </ng-container>
                                                    </ng-container>
                                                </mat-cell>
                                            </ng-container> 
                    
                                            <ng-container matColumnDef="actions">
                                                <mat-header-cell *matHeaderCellDef fxFlex="20">Actions</mat-header-cell>
                                                <mat-cell *matCellDef="let row" fxFlex="20">
                                                    <div style="display: flex; justify-content: space-between; width: 100px;">
                                                        <button mat-icon-button color="primary" style="width: 50px;" (click)="downloadFile(row); $event.stopPropagation()">
                                                            <mat-icon>save_alt</mat-icon>
                                                        </button>
                                                        <button mat-icon-button color="warn" style="width: 50px;" (click)="clearFile(row); $event.stopPropagation()">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </mat-cell>
                                            </ng-container>
                                        </mat-table>
                                                        
                                        <br />
                                        <mat-paginator [pageSize]="tableApplicationDocumentsPageSize" [pageSizeOptions]="[5, 10, 50, tableApplicationDocumentsData.length]" #DocumentTablePaginator="matPaginator"
                                            showFirstLastButtons></mat-paginator>
                                    </div>
                                </div>
                                </mat-card-content>
                            </mat-card>
                        </ng-container>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>                
                            <mat-icon>{{uiData.payment.icon}}</mat-icon> &nbsp; Payments
                        </ng-template>
                        <ng-container>
                            <div *ngIf="firmApplication.id === 0" class="text-red-400"><strong><br />The Application Needs to be Saved</strong></div>
                                    <div fxLayout="column" fxLayoutGap="8px" *ngIf="firmApplication.id > 0">
                                        <app-manage-payments [@slideLeft]
                                                   [entityId]="firmApplication.id.toString()"
                                                   [entityType]="paymentService.FirmApplicationEntityType"
                                                   [allowMultiplePayments]='true'
                                                   [editingPayment]="canEdit"
                                                   (notifyParent)="paymentUpdated($event)"
                                                   [allowDelete]='true'
                                                   [cardColor]="cardColor"
                                                   [changeIndicator]="changeIndicator">
                              </app-manage-payments>                
                                    </div>
                        </ng-container>
                    </mat-tab>
                </mat-tab-group>
            </mat-card>            
        </form>       
    </div>

    <div *ngIf="data.application.applicationType === 'Link'" class="scroll-container">
        <form [formGroup]="applicationDataForm">
            <div fxLayout="row" fxLayoutGap="16px">
                <mat-card fxFlex>
                    <mat-card-title>
                        <div fxLayout="row" fxLayout.lt-lg="column">
                            <span fxFlex>Firm Link Application Information</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">                 
                            <span class="f-2-20"><strong>Application Status</strong></span>                           
                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">                                
                                <mat-form-field>
                                    <mat-label>Status</mat-label>
                                    <mat-select  formControlName="status">   
                                        <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">{{ status }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card fxFlex>
                    <mat-card-title>
                        <div fxLayout="row" fxLayout.lt-lg="column">
                            <span fxFlex>Firm Information</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                            <span><strong>Firm Name</strong></span>                
                            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">                    
                                <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                                    <input matInput placeholder="Name" formControlName="firmName" >                                    
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </form>
    </div>

    <mat-card [style.background-color]="uiData.firm.color">

                <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.lt-md="column" fxLayout.lt-sm="column" fxLayoutGap="12px">
                    
                    <div fxLayout="column" *ngIf="canEdit" class="text-white">
                        <button mat-raised-button color="primary" (click)="approveFirm()" [disabled]="!(applicationAttestationForm.valid && applicationDataForm.valid  && firmApplication.id !== 0)">Approve</button> 
                    </div>
                    <div fxLayout="column" *ngIf="canEdit" class="text-white">
                        <button mat-raised-button (click)="saveApplication()" color="primary" aria-label="Save">Save</button>
                    </div>
                    <div fxLayout="column" class="text-white">
                        <button mat-raised-button (click)="exitApplication()" aria-label="Exit">Exit</button>
                    </div>

                    <div fxLayout="column" fxFlex="70" class="text-white" *ngIf="!applicationDataForm.valid || firmApplication.id === 0 || !applicationAttestationForm.valid">
                        
                        <mat-card fxLayoutGap="8px" *ngIf="((!applicationAttestationForm.valid || !applicationDataForm.valid) || firmApplication.id === 0) && firmApplication.status !== applicationStatus.approved">                          
                            <ul>
                                <li class="text-red-400" *ngIf="firmApplication.id === 0"  > <span class="text-red-400"><strong>The Application Needs to be Saved</strong></span></li>
                                <li class="text-red-400" *ngIf="!applicationAttestationForm.valid && canEdit"> <span  class="text-red-400"><strong>Signature and Acknowledgement is incomplete</strong></span></li>
                                <li class="text-red-400" *ngIf="(applicationDataForm.get('approvalDate').invalid || applicationDataForm.get('applicationViableDate').invalid || applicationDataForm.get('applicationDate').invalid)"> 
                                    <span  class="text-red-400"><strong>Application Information is incomplete</strong></span>
                                </li>
                                <li class="text-red-400" *ngIf="applicationDataForm.get('firmName').invalid"><strong>Firm Information is incomplete</strong></li>
                                <li class="text-red-400" *ngIf="(applicationDataForm.get('firmPhysicalStreet').invalid || applicationDataForm.get('firmPhysicalState').invalid || applicationDataForm.get('firmPhysicalCity').invalid || applicationDataForm.get('firmPhysicalZip').invalid) 
                                        || (applicationDataForm.get('firmMailingStreet').invalid || applicationDataForm.get('firmMailingState').invalid || applicationDataForm.get('firmMailingCity').invalid || applicationDataForm.get('firmMailingZip').invalid)">
                                    <strong>Firm Address is incomplete</strong></li>
                                <li class="text-red-400" *ngIf="applicationDataForm.get('firmPhone').invalid || applicationDataForm.get('firmEmail').invalid || applicationDataForm.get('firmConfirmEmail').invalid">
                                    <strong>Firm Contact Information is incomplete</strong>
                                    <ul *ngIf="(applicationDataForm.get('firmEmail').errors?.emailMismatch) || (applicationDataForm.get('firmConfirmEmail').errors?.emailMismatch)">
                                        <li class="text-red-400">
                                            <span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;<strong>Email and Confirmation Email do not match</strong>
                                            </span>
                                        </li>
                                    </ul>
                                </li>

                                <li class="text-red-400" *ngIf="applicationDataForm.get('contactFirstName').invalid || applicationDataForm.get('contactLastName').invalid || applicationDataForm.get('contactPhone').invalid
                                || applicationDataForm.get('contactEmail').invalid || applicationDataForm.get('contactConfirmEmail').invalid">
                                    <strong>Contact Information (Owner/Agent) is incomplete</strong>
                                    <ul *ngIf="(applicationDataForm.get('contactEmail').errors?.emailMismatch) || (applicationDataForm.get('contactConfirmEmail').errors?.emailMismatch)">
                                        <li class="text-red-400">
                                            <span>
                                                &nbsp;&nbsp;&nbsp;&nbsp;<strong>Contact Email and Confirmation Email do not match</strong>
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </mat-card>                        
                    </div>
                </div>


    </mat-card>
</div>