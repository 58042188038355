import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPerson } from 'app/models/People/person';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { AddEditIndividualComponent } from './add-edit-individual/add-edit-individual.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { CommonDataService } from 'app/services/common/common-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { MergeIndividualComponent } from './merge-individual/merge-individual.component';
import { LEAD_BASED_PAINT, STUDENT_CODE_TYPES } from '@shared/utils/app-static-data';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent {
  public isMobile: boolean = false;
  public loading: boolean = true;
  public uiData = LEAD_BASED_PAINT;
  public pageSize: number = 50;  
  public individuals: any[] = [];
  public dataSource: MatTableDataSource<any>;
  public filterString: string = '';
  public filteredIndividuals: IPerson[] = [];
  public filteredActive = new FormControl('Active');
  public filterType = new FormControl([]);  
  public filterDiscipline = new FormControl(null);
  public expirationStartDate = new FormControl(new Date());
  public expirationEndDate = new FormControl(new Date());
  public firmStaff: IPerson[] = [];
  public selectedIndividuals: IPerson[] = [];

  public personFilter: IPersonFilter = {
    filterString: '', 
    filterStatus: '', 
    filterAffiliation: [], 
    expirationStartDate: '',
    expirationEndDate: '',
    filterDiscipline: ['']
  };

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['select', 'id', 'inactive', 'firstName', 'lastName', 'affiliations', 'disciplines', 'expirationDate'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'5'},
        {displayName:'Person Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'10'},
        {displayName:'First Name',columnName:'firstName', type:'string', size:'15'},        
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'15'},                  
        {displayName:'Affiliations',columnName:'affiliations', type:'string', size:'13'},           
        {displayName:'Disciplines',columnName:'disciplines', type:'string', size:'10'},   
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'15'},          
      ],
      data: []      
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public personService: PersonService,
    public firmService: FirmService,
    public toastr: ToastrService) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.loadIndividuals();
  }

  loadIndividuals(): void {
    this.filterString = this.commonService.individualManagementFilter.filterString;
    this.filteredActive = this.commonService.individualManagementFilter.filteredActive;
    this.filterType = this.commonService.individualManagementFilter.filterStatus;
    this.expirationStartDate = this.commonService.individualManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.individualManagementFilter.expirationEndDate;
    this.filterDiscipline = this.commonService.individualManagementFilter.filterDiscipline;
    this.filterTable();
  }
  
  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredIndividuals);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;   
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'select':
          return this.isSelected(item) ? 0 : 1;
        default:
          return item[property];
      }
    }; 
  }

  editPerson(person: IPerson) {    
    const dialogRef = this.dialog.open(AddEditIndividualComponent, {
      width: '90%',
      data: {person: person},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }

  filterTable() { 
    this.commonService.individualManagementFilter.filterString = this.filterString;
    this.commonService.individualManagementFilter.filteredActive = this.filteredActive;
    this.commonService.individualManagementFilter.filterStatus = this.filterType;
    this.commonService.individualManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.individualManagementFilter.expirationEndDate = this.expirationEndDate;
    this.commonService.individualManagementFilter.filterDiscipline = this.filterDiscipline;

    this.personFilter.filterString = this.filterString;
    this.personFilter.filterStatus = this.filteredActive.value;
    this.personFilter.filterAffiliation = this.filterType?.value ?? [];
    this.personFilter.expirationStartDate = this.expirationStartDate.value?.toISOString().substring(0,10);
    this.personFilter.expirationEndDate = this.expirationEndDate.value?.toISOString().substring(0,10);    
    this.personFilter.filterDiscipline = this.filterDiscipline?.value ?? [];

    this.personService.getPeopleFiltered(this.personFilter).subscribe(result=>{                   
      this.individuals = result.map(individual => { 
        const today = new Date();
        var discipline = null;
        var expirationDate = null;
        if (individual.certificates && individual.certificates.length > 0) 
        {   
          if(this.filteredActive.value === 'Active'){
            if(this.filterDiscipline.value != null && this.filterDiscipline.value != ''){
              expirationDate = individual.certificates.filter(certificate => new Date(certificate.expirationDate) >= today && certificate.codeType == this.filterDiscipline.value).map(certificate => new Date(certificate.expirationDate)).reduce((a, b) => a > b ? a : b, new Date(0));
              discipline = [...new Set(individual.certificates.filter(certificate => new Date(certificate.expirationDate) >= today && certificate.codeType == this.filterDiscipline.value).map(certificate => certificate.codeType))].join(', ');          
            }
            else{
              expirationDate = individual.certificates.filter(certificate => new Date(certificate.expirationDate) >= today).map(certificate => new Date(certificate.expirationDate)).reduce((a, b) => a > b ? a : b, new Date(0));
              discipline = [...new Set(individual.certificates.filter(certificate => new Date(certificate.expirationDate) >= today).map(certificate => certificate.codeType))].join(', ');          
            }
          }
          else
          {
            if(this.filterDiscipline.value != null && this.filterDiscipline.value != ''){
              expirationDate = individual.certificates.filter(certificate => certificate.codeType == this.filterDiscipline.value).map(certificate => new Date(certificate.expirationDate)).reduce((a, b) => a > b ? a : b);
              discipline = [...new Set(individual.certificates.filter(certificate => certificate.codeType == this.filterDiscipline.value).map(certificate => certificate.codeType))].sort().join(', '); 
            }
            else{
              expirationDate = individual.certificates.map(certificate => new Date(certificate.expirationDate)).reduce((a, b) => a > b ? a : b);
              discipline = [...new Set(individual.certificates.map(certificate => certificate.codeType))].sort().join(', '); 
            }
          }
        }
        return { ...individual, expirationDate: expirationDate, disciplines: discipline } });

      this.filteredIndividuals = this.individuals;
      this.updateDataSource();
      this.loading = false;      
    },error=>{this.toastr.error('Error loading people filtered', error)});    
  }

  getStatusTypes(){
    return ['Active','InActive'];
  }

  getDisciplineTypes(){
    return Object.values(STUDENT_CODE_TYPES).sort();
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  getFilterTypeValues() {    
    return ['Firm','Student','ATP','No Affiliation'];
  }

  clearFilters(){
    this.filterString = '';
    this.filterType.setValue([]);
    this.filteredActive.setValue('Active');
    this.filterDiscipline.setValue(null);
    this.expirationEndDate.reset();
    this.expirationStartDate.reset();
    this.selectedIndividuals = [];
    this.filterTable();
  }

  updateSelected(person: IPerson){
    const index = this.selectedIndividuals.findIndex(individual => individual.id === person.id);
    if (index !== -1){
      this.selectedIndividuals.splice(index, 1);
    } else {
      this.selectedIndividuals.push(person);
    }    
  }
  
  isSelected(person: IPerson): boolean {    
    return this.selectedIndividuals.some(individual => individual.id === person.id);
  }

  mergeIndividuals(){
    const dialogRef = this.dialog.open(MergeIndividualComponent, {
      width: '90%',
      autoFocus: false,
      data: this.selectedIndividuals,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      if(result){
        this.selectedIndividuals = [];
        this.filterTable();
      }
    });
  }

  getMergeTooltip(): string {
    if (this.selectedIndividuals.length > 0) {
        return 'Merge: ' + this.selectedIndividuals.map(individual => `${individual.id}: ${individual.firstName} ${individual.lastName}`).join(', ');
    }
    return 'Merges selected individuals';
  }
}
