<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
        <h1>Loading</h1>
    </div>
</div>

<div fxLayout="column">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <mat-card [style.background-color]="uiData.individual.color" fxFlex="100">
                <h2 class="noMarginHeader">
                    <mat-icon>{{'recent_actors'}}</mat-icon> {{'Individual Management'}}
                </h2>
            </mat-card>            
        </div>
    </div>

    <mat-card>
        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
            <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxLayoutGap="10px">
                <strong class="noMarginHeader">Results ({{filteredIndividuals.length}})</strong>                
                <mat-form-field style="width:180px;">
                    <input placeholder="Filter id or name" [(ngModel)]="filterString" matInput (keydown.enter)="filterTable()"/>                    
                </mat-form-field>                            
                <mat-form-field style="width:180px">
                    <mat-select placeholder="Filter by Inactive Status" [formControl]="filteredActive">
                        <mat-option *ngFor="let status of getFirmActiveTypes()" [value]="status">
                            {{ status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="width:180px">
                    <mat-select multiple placeholder="Filter by Affiliation" [formControl]="filterType">
                        <mat-option *ngFor="let status of getFilterTypeValues()" [value]="status">
                        {{ status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>                
                <mat-form-field>                                                                
                    <input matInput [matDatepicker]="expirationStartPicker" placeholder="Expiring Start Range" [formControl]="expirationStartDate" (keydown.enter)="filterTable()">
                    <mat-datepicker-toggle matSuffix [for]="expirationStartPicker"></mat-datepicker-toggle>
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationStartDate.reset()">
                        <mat-icon style="color: red;">close</mat-icon>
                      </button>  
                    <mat-datepicker #expirationStartPicker></mat-datepicker>
                </mat-form-field>                   
                <mat-form-field>                                                                
                    <input matInput [matDatepicker]="expirationEndPicker" placeholder="Expiring End Range" [formControl]="expirationEndDate" (keydown.enter)="filterTable()">
                    <mat-datepicker-toggle matSuffix [for]="expirationEndPicker"></mat-datepicker-toggle>
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationEndDate.reset()">
                        <mat-icon style="color: red;">close</mat-icon>
                      </button>  
                    <mat-datepicker #expirationEndPicker></mat-datepicker>
                </mat-form-field>                   
                <button mat-raised-button color="primary" (click)="filterTable()">Search</button>                
                <button mat-raised-button (click)="clearFilters()">Reset Filters</button> 
                <button mat-raised-button color="warn" [disabled]="selectedIndividuals.length < 2" (click)="mergeIndividuals()" [matTooltip]="getMergeTooltip()">
                    <mat-icon>call_merge</mat-icon> Merge Individuals {{selectedIndividuals.length > 0 ? '(' + selectedIndividuals.length + ')': ''}}
                </button>
            </div>
            <br />

            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of individualApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                        <ng-container *ngIf="cellData.type === 'date'">
                            <div *ngIf="isMobile" fxLayout="row">  
                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                            </div>
                            <div *ngIf="!isMobile">  
                                {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="cellData.type === 'boolean'">
                            <div *ngIf="isMobile" fxLayout="row">
                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                            </div>
                            <div *ngIf="!isMobile">
                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                            </div>                            
                        </ng-container>
                        
                        <ng-container *ngIf="cellData.columnName === 'affiliations'">
                            <div *ngIf="isMobile" fxLayout="row">                                    
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <ng-container *ngFor="let affiliation of row[cellData.columnName]; let i = index">
                                    <a class="blue-text" >
                                        {{ affiliation.split(':')[1] }}
                                    </a>
                                    <span *ngIf="i < row[cellData.columnName].length - 1">, </span>
                                </ng-container>
                            </div>
                            <div *ngIf="!isMobile">
                                <ng-container *ngFor="let affiliation of row[cellData.columnName]; let i = index">
                                    <a class="blue-text" >
                                        {{ affiliation.split(':')[1] }}
                                    </a>
                                    <span *ngIf="i < row[cellData.columnName].length - 1">, </span>
                                </ng-container>
                            </div>  
                        </ng-container>                        
                    
                        <ng-container *ngIf="cellData.type === 'select'">
                            <div *ngIf="isMobile" fxLayout="row">
                              <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                              <mat-checkbox (change)="updateSelected(row)" [checked]="isSelected(row)"></mat-checkbox>
                            </div>
                            <div *ngIf="!isMobile">
                              <mat-checkbox (change)="updateSelected(row)" [checked]="isSelected(row)"></mat-checkbox>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean' && cellData.columnName !== 'affiliations' && cellData.type !== 'select'">
                            <div *ngIf="isMobile" fxLayout="row">                                    
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                            </div>
                            <div *ngIf="!isMobile">
                                {{ displayLimit(row[cellData.columnName], cellData.size) }}
                            </div>  
                        </ng-container>
                    </mat-cell>
                    
                </ng-container>                    
              
                <!-- Button column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                        <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                            <span><strong>Action: </strong></span>
                            <button mat-icon-button color="primary" (click)="editPerson(row)">
                                <mat-icon>{{'edit'}}</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="!isMobile">
                            <button mat-icon-button color="primary" (click)="editPerson(row)">
                                <mat-icon>{{'edit'}}</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="individualApplicationTable.layout.columns.concat('actions')"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: individualApplicationTable.layout.columns.concat('actions')"></mat-row>
              </mat-table>
              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
