import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IAtp } from 'app/models/atp/atp';
import { IAtpAccreditation } from 'app/models/atp/atpAccreditation';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { ToastrService } from 'ngx-toastr';
import { IAtpNote } from 'app/models/atp/atpNote';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-add-edit-atp-note',
  templateUrl: './add-edit-atp-note.component.html',
  styleUrls: ['./add-edit-atp-note.component.scss']
})
export class AddEditAtpNoteComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT;
  public noteDataForm = null;
  public noteData: IAtpNote = null;  

  constructor(private toastr: ToastrService,
    private atpService: AtpService,
    public commonService: CommonDataService,
    private dialogRef: MatDialogRef<AddEditAtpNoteComponent>,
    public dialog: MatDialog,    
    public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{note: IAtpNote, atp: IAtp, accreditation: IAtpAccreditation},
  ) { }

  ngOnInit(): void {
    this.setDataFormFields();
    this.loadData();
  }

  loadData(){
    if(this.data.note !== null){
      this.noteData = this.data.note;      
    }
    else{
      this.noteData = {
        id: 0,
        atp: this.data.atp,   
        atpAccreditation: this.data.accreditation,
        contactType: "",
        notes: "",
        date: null,        
      };     
    }

    for (const controlName in this.noteData) {
      if (this.noteDataForm.controls.hasOwnProperty(controlName)) {        
        this.noteDataForm.controls[controlName].setValue(this.noteData[controlName]);          
      }
    }  
  }

  setDataFormFields(){       
    this.noteDataForm = new UntypedFormGroup({
      contactType: new UntypedFormControl(""),
      notes: new UntypedFormControl(""), 
      date: new UntypedFormControl(null),       
    }); 
  }

  updateData(): void {    
    for (const controlName in this.noteData) {
      if (this.noteDataForm.controls.hasOwnProperty(controlName)) {
        this.noteData[controlName] = this.noteDataForm.controls[controlName].value;        
      }
    } 
  }

  savePrompt(){
    let isNew = this.noteData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Note?' : 'Are you sure you want to <strong>Save</strong> the Note?',title: isNew ? 'Add the Note' : 'Save the Note'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();        
      }
    })
  }

  saveData(){
    this.updateData();
    this.atpService.saveATPNote(this.noteData).subscribe(result=>{
      this.noteData = result;      
      this.toastr.success("Success")
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("There was an error saving the ATP Note: ", error)
    })
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}