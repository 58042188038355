import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IFirmApplication } from 'app/models/firms/firm-application';
import { IFirmApplicationDeficiency } from 'app/models/firms/firm-application-deficiency';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-firm-application-deficiency',
  templateUrl: './add-firm-application-deficiency.component.html',
  styleUrls: ['./add-firm-application-deficiency.component.scss']
})
export class AddFirmApplicationDeficiencyComponent {
  public loading: boolean = true;
  public deficiencyForm: FormGroup = null;
  public postErrorMessages: string = "";  
  public formFields = {
    description:{name: 'Deficiency Description', type: 'text'},
    isResolved:{name: 'Deficiency Resolved?', type: 'boolean'},
  };
  public deficiency: IFirmApplicationDeficiency = {
    id: 0,
    firmApplication: null,
    applicationId: 0,
    description: '',
    isResolved: false,
  };
  objectKeys = Object.keys;
  public uiData = LEAD_BASED_PAINT;
  
  constructor(
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {deficiency: IFirmApplicationDeficiency, firmApplication: IFirmApplication},
    private dialogRef: MatDialogRef<AddFirmApplicationDeficiencyComponent>,
    public firmService: FirmService,
  ) 
  {    
    this.setFormData();
  }

  onSubmit() {
    if (!this.deficiencyForm.valid) {
      this.postErrorMessages = "Description is required";
      this.toastr.error("Please fix validation errors");
      return;
    }
    
    this.getFormValues();    

    this.firmService.saveFirmApplicationDeficiency(this.deficiency).subscribe(
      result => {
        this.dialogRef.close(true);    
      },
      error => {
        console.log("There was an error saving the deficiency: ", error);
      }
    )
  }

  getFormValues(){
    for (const controlName in this.deficiency) {
      if (this.deficiencyForm.controls.hasOwnProperty(controlName)) {
        this.deficiency[controlName] = this.deficiencyForm.controls[controlName].value;        
      } 
    }  
  }

  setFormData() {
    this.deficiency = (this.data.deficiency) ? this.data.deficiency : this.deficiency;
    this.deficiency.firmApplication = this.data.firmApplication;
    this.deficiency.applicationId = this.data.firmApplication.id;

    this.deficiencyForm = new FormGroup({});    
    
    for (const key in this.formFields) {        
      this.deficiencyForm.addControl(key, new FormControl('', Validators.required));      
    }

    for (const controlName in this.deficiency) {            
      if (this.deficiencyForm.controls.hasOwnProperty(controlName)) {        
        this.deficiencyForm.controls[controlName].setValue(this.deficiency[controlName]);
      } 
    }

    this.loading = false;
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
