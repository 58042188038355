import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { COURSE_DISCIPLINES } from '@shared/utils/app-static-data';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { IAtpStudentFilter } from 'app/models/filters/atpStudentFilter';
import { AddEditAtpCourseComponent } from '../add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { AddEditCourseStudentComponent } from '../add-edit-atp/add-edit-course-student/add-edit-course-student.component';
import { AddEditAtpComponent } from '../add-edit-atp/add-edit-atp.component';

@Component({
  selector: 'app-atp-student',
  templateUrl: './atp-student.component.html',
  styleUrls: ['./atp-student.component.scss']
})
export class AtpStudentComponent {
  public isMobile: boolean = false;
  public loading: boolean = true;
  public pageSize: number = 50;  
  public students: any[] = [];
  public dataSource: MatTableDataSource<any>;
  public filteredStudents: any[] = [];  

  public filterString: string = '';
  public filterATPString: string = '';
  public selectedDisciplines = new FormControl([]);    
  public startDate = new Date(new Date().setDate(new Date().getDate()-90))
  public endDate = new Date();
  public studentFilter: IAtpStudentFilter = {
    filterString: '', 
    filterATPString: '', 
    selectedDisciplines: '', 
    startDate: this.startDate.toISOString(),
    endDate: this.endDate.toISOString()
  };

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['id', 'firstName', 'lastName', 'atpName', 'courseId', 'discipline', 'certificateNumber', 'testScore', 'courseDate'],
      container:[
        {displayName:'Student Record Id',columnName:'id', type:'string', size:'8'},        
        {displayName:'First Name',columnName:'firstName', type:'string', size:'10'},        
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'15'},          
        {displayName:'ATP',columnName:'atpName', type:'string', size:'10'},  
        {displayName:'Course Id',columnName:'courseId', type:'string', size:'10'},  
        {displayName:'Discipline',columnName:'discipline', type:'string', size:'10'},  
        {displayName:'Certificate',columnName:'certificateNumber', type:'string', size:'10'},     
        {displayName:'Test Score',columnName:'testScore', type:'string', size:'10'},           
        {displayName:'Course Date',columnName:'courseDate', type:'date', size:'10'},          
      ],
      data: []      
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public atpService: AtpService,    
    public toastr: ToastrService) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.loadFilters();
    this.loadIndividuals();
  }

  loadFilters(): void {
    this.filterString = this.atpService.atpStudentFilterString;
    this.filterATPString = this.atpService.atpStudentFilterATPString;  
    this.selectedDisciplines = this.atpService.atpStudentSelectedDisciplines;    
    this.startDate = this.atpService.atpStudentStartDate;
    this.endDate = this.atpService.atpStudentEndDate;

    this.studentFilter.filterString = this.filterString;
    this.studentFilter.filterATPString = this.filterATPString;
    this.studentFilter.selectedDisciplines = this.selectedDisciplines.value.join(',');
    this.studentFilter.startDate = this.startDate?.toISOString();
    this.studentFilter.endDate = this.endDate?.toISOString();
  }

  loadIndividuals(): void {  
    this.refreshStudents();
  }
  
  refreshStudents(){
    this.atpService.getAllStudents(this.studentFilter).subscribe(result=>{           
      this.students = result.sort((a, b) => a.student.firstName.localeCompare(b.student.firstName)).map(courseStudent => {
        return {
          ...courseStudent,          
          firstName: courseStudent.student?.firstName,
          lastName: courseStudent.student?.lastName,
          atpName: courseStudent.atpCourse?.atp.name,
          courseId: courseStudent.atpCourse?.id,
          discipline: courseStudent.atpCourse?.accreditationDiscipline,
          certificateNumber: courseStudent?.courseCertificateNumber,
          testScore: courseStudent.testScore,
          courseDate: courseStudent.atpCourse?.courseStart,
        };
      });            
      this.filteredStudents = this.students;  
      this.updateDataSource();
      this.loading = false;
    },error=>{this.toastr.error("Error loading students");})    
  }
  
  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredStudents);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editATP(row: any){
    const dialogRef = this.dialog.open(AddEditAtpComponent, {
      width: '90%',
      data: {atp: row.atpCourse.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {     
      this.refreshStudents(); 
    });
  }

  editStudent(row: any){
    const dialogRef = this.dialog.open(AddEditCourseStudentComponent, {
      width: '90%',
      autoFocus: false,
      data: {student: row, course: row.atpCourse},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {   
      this.refreshStudents();   
    });
  }

  editCourse(row: any){
    const dialogRef = this.dialog.open(AddEditAtpCourseComponent, {
      width: '90%',
      data: {course: row.atpCourse, atp: row.atpCourse.atp},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      this.refreshStudents();    
    });
  }

  filterTable() {     
    this.saveFilters();
    this.refreshStudents();
  }

  saveFilters(){
    this.atpService.atpStudentFilterString = this.filterString;
    this.atpService.atpStudentFilterATPString = this.filterATPString;
    this.atpService.atpStudentSelectedDisciplines = this.selectedDisciplines;
    this.atpService.atpStudentStartDate = this.startDate;    
    this.atpService.atpStudentEndDate = this.endDate;

    this.studentFilter.filterString = this.filterString;
    this.studentFilter.filterATPString = this.filterATPString;
    this.studentFilter.selectedDisciplines = this.selectedDisciplines.value.join(',');
    this.studentFilter.startDate = this.startDate?.toISOString();
    this.studentFilter.endDate = this.endDate?.toISOString();
  }

  clearFilters(){
    this.filterString = '';
    this.filterATPString = '';
    this.selectedDisciplines.setValue([]);
    this.startDate = new Date(new Date().setDate(new Date().getDate()-90));
    this.endDate = new Date();
    
    this.filterTable();
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  getStudentDisciplineValues() {
    return Object.values(COURSE_DISCIPLINES);
  }
}
