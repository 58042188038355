<mat-card [style.background-color]="uiData.individual.color">
    <h2 mat-dialog-title>
        <mat-icon>call_merge</mat-icon> Merge Individuals
    </h2>
</mat-card>
<mat-dialog-content class="scroll-container">
    <section *ngIf="loading">
        <h2>Loading...</h2>
        <mat-progress-bar mode="indeterminate" color="warn" aria-label="Loading...">
        </mat-progress-bar>
    </section>
    <mat-card *ngIf="!loading">
        <div>
            <p color="primary">
                <strong>Note:</strong> This feature will merge all the individuals courses, contact information, certifications, affiliations and test data into the selected individual
                below and set the non-selected individuals to <strong>Inactive</strong>.
    
            </p>
    
            <br />
            <label>Select the Individual to keep below: <br /></label>
            <small>This individual will absorb all courses, contact information, certifications, affiliations and test data from the remaining unselected individuals.</small>
            <br />
            <br />
            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of individualApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                        <ng-container *ngIf="cellData.type === 'date'">
                            <div *ngIf="isMobile" fxLayout="row">  
                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                            </div>
                            <div *ngIf="!isMobile">  
                                {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="cellData.type === 'boolean'">
                            <div *ngIf="isMobile" fxLayout="row">
                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                            </div>
                            <div *ngIf="!isMobile">
                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                            </div>                            
                        </ng-container>
                        
                        <ng-container *ngIf="cellData.columnName === 'affiliations'">
                            <div *ngIf="isMobile" fxLayout="row">                                    
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <ng-container *ngFor="let affiliation of row[cellData.columnName]; let i = index">
                                    <a class="blue-text" >
                                        {{ affiliation.split(':')[1] }}
                                    </a>
                                    <span *ngIf="i < row[cellData.columnName].length - 1">, </span>
                                </ng-container>
                            </div>
                            <div *ngIf="!isMobile">
                                <ng-container *ngFor="let affiliation of row[cellData.columnName]; let i = index">
                                    <a class="blue-text" >
                                        {{ affiliation.split(':')[1] }}
                                    </a>
                                    <span *ngIf="i < row[cellData.columnName].length - 1">, </span>
                                </ng-container>
                            </div>  
                        </ng-container>                        
                    
                        <ng-container *ngIf="cellData.type === 'select'">
                            <div *ngIf="isMobile" fxLayout="row">
                                <mat-radio-group [(ngModel)]="selectedIndividual" (change)="onChangeSelectedPerson(row)">
                                    <mat-radio-button [value]="row"> 
                                        <!-- Radio button only, no label -->
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="!isMobile">
                                <mat-radio-group [(ngModel)]="selectedIndividual" (change)="onChangeSelectedPerson(row)">
                                    <mat-radio-button [value]="row"> 
                                        <!-- Radio button only, no label -->
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </ng-container>
                        

                        <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean' && cellData.columnName !== 'affiliations' && cellData.type !== 'select'">
                            <div *ngIf="isMobile" fxLayout="row">                                    
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                            </div>
                            <div *ngIf="!isMobile">
                                {{ displayLimit(row[cellData.columnName], cellData.size) }}
                            </div>  
                        </ng-container>
                    </mat-cell>                    
                </ng-container>                                  
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="individualApplicationTable.layout.columns"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: individualApplicationTable.layout.columns"></mat-row>
            </mat-table>
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>
    </mat-card>
</mat-dialog-content>
<mat-card [style.background-color]="uiData.individual.color">        
    <div>        
        <section *ngIf="merging">
            <h3>Merging...</h3>
            <mat-progress-bar mode="indeterminate" color="warn" aria-label="Merging...">
            </mat-progress-bar>
            <br>
        </section>
        <button mat-button [disabled]="selectedIndividual === null || merging" mat-raised-button color="primary"(click)="merge()">
            <mat-icon>call_merge</mat-icon> Merge Individuals
        </button>
            &nbsp;
        <button mat-button mat-raised-button mat-dialog-close="Cancel" aria-label="Cancel" [mat-dialog-close]="false" [disabled]='merging'>Cancel</button>
    </div>
</mat-card>