import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFirm } from 'app/models/firms/firm';
import { IFirmCertificate } from 'app/models/firms/firmCertificate';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { AddEditFirmStaffComponent } from '../add-edit-firm-staff/add-edit-firm-staff.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CERTIFICATE_STAGE_TYPES, CODE_TYPES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';

@Component({
  selector: 'app-add-edit-firm-certificate',
  templateUrl: './add-edit-firm-certificate.component.html',
  styleUrls: ['./add-edit-firm-certificate.component.scss']
})
export class AddEditFirmCertificateComponent{
  public codeType: string[] = Object.values(CODE_TYPES);
  public stageType: string[] = Object.values(CERTIFICATE_STAGE_TYPES);
  public certificateData:IFirmCertificate = {id: 0, firm: null, stage: "", codeType: "", controlNumber: "", certificateNumber: "", issueDate: null, expirationDate: null, dateOfLetter: null, application: null};
  public certificateForm:UntypedFormGroup; 
  public uiData = LEAD_BASED_PAINT;
  
  constructor(private dialogRef: MatDialogRef<AddEditFirmStaffComponent>,    
    public firmService: FirmService,
    @Inject(MAT_DIALOG_DATA) public data:{firm:IFirm, certificate:IFirmCertificate, canEdit:boolean, certId:number},      
    private toastr: ToastrService) {
      this.data.certId = this.data.certId || 0;    
      this.certificateForm = new UntypedFormGroup({      
        stage: new UntypedFormControl({ value: "", disabled: this.data.canEdit}, [Validators.required]),
        codeType: new UntypedFormControl({ value: "", disabled: this.data.canEdit}, [Validators.required]),        
        certificateNumber: new UntypedFormControl({value: "", disabled: this.data.canEdit}),
        controlNumber: new UntypedFormControl({ value: "", disabled: this.data.canEdit}),
        issueDate: new UntypedFormControl({ value: "", disabled: this.data.canEdit}, [Validators.required]),      
        expirationDate: new UntypedFormControl({ value: "", disabled: this.data.canEdit}, [Validators.required]),    
        dateOfLetter: new UntypedFormControl({ value: "", disabled: this.data.canEdit}),    
      }); 
      this.fillFormData();
  }

  updateCertificateData(): void {    
    for (const controlName in this.certificateData) {
      if (this.certificateForm.controls.hasOwnProperty(controlName)) {
        const value = this.certificateForm.controls[controlName].value;
        this.certificateData[controlName] = value === '' ? null : value;        
      }
    } 
  }

  fillFormData(): void {    
    if (this.data.certificate !== null && this.data.certificate !== undefined)
    {
      this.certificateData = this.data.certificate;      
      this.certificateData.issueDate = this.certificateData?.issueDate?.substring(0, 10);      
      this.certificateData.expirationDate = this.certificateData?.expirationDate?.substring(0, 10);      
      this.certificateData.dateOfLetter = this.certificateData?.dateOfLetter?.substring(0, 10);
      this.updateFormFields();
    }  
    else if(this.data.certId > 0){
      this.firmService.getFirmCertificate(this.data.certId).subscribe(result=>{
        this.certificateData = result;      
        this.certificateData.issueDate = this.certificateData?.issueDate?.substring(0, 10);      
        this.certificateData.expirationDate = this.certificateData?.expirationDate?.substring(0, 10);      
        this.certificateData.dateOfLetter = this.certificateData?.dateOfLetter?.substring(0, 10);
        this.updateFormFields();
      },error=>{this.toastr.error("An error occured getting the firm certificate: ", error);})
    }  
    else{
      const dateData = new Date();    
      this.certificateData.issueDate = dateData.toISOString().substring(0, 10)
      this.certificateData.dateOfLetter = dateData.toISOString().substring(0, 10)
      dateData.setMonth(dateData.getMonth() + 36)
      this.certificateData.expirationDate = dateData.toISOString().substring(0, 10)      
      this.certificateData.stage = this.stageType[0];
      this.certificateData.firm = this.data.firm;      
      this.updateFormFields();      
    }    
  }

  updateFormFields() {
    for (const controlName in this.certificateData) {
      if (this.certificateForm.controls.hasOwnProperty(controlName)) {
        this.certificateForm.controls[controlName].setValue(this.certificateData[controlName]);
      }
    } 
  }

  clearDate(controlName: string): void {
    this.certificateForm.get(controlName)?.setValue(null);
  }
  
  onCancel(): void {
    this.dialogRef.close(false);
  }
  onSubmit(): void {
    this.updateCertificateData();    
    this.firmService.saveFirmCertificate(this.certificateData).subscribe(result=>{
      this.toastr.success("Succesfully submitted the firm certificate")
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("An error occured saving the firm certificate: " + error.error);
    })    
  }
}
