import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ANIMATIONS, APPLICATION_STATUS, STUDENT_CODE_TYPES, LEAD_BASED_PAINT, CERTIFICATE_STAGE_TYPES } from '@shared/utils/app-static-data';
import { IPerson } from 'app/models/People/person';
import { IPersonApplication } from 'app/models/People/person-application';
import { IPersonAddress } from 'app/models/People/personAddress';
import { IPersonContactInformation } from 'app/models/People/personContactInformation';
import { IAccountAffiliation } from 'app/models/common/accountAffiliation';
import { IContactInformation } from 'app/models/common/contactInformation';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { AddFirmApplicationComponent } from '../../firm/add-firm-application/add-firm-application.component';
import { IPersonApplicationDeficiency } from 'app/models/People/person-application-deficiency';
import { AddIndividualApplicationDeficiencyComponent } from './add-individual-application-deficiency/add-individual-application-deficiency.component';
import { IAddress } from 'app/models/common/address';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogueComponent } from '../../common/confirmation-dialogue/confirmation-dialogue.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { IPayment } from 'app/components/payments/models/payment';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IPersonCertificate } from 'app/models/People/person-certificate';
import { ConfirmationDialogueWithFieldComponent } from '../../common/confirmation-dialogue-with-field/confirmation-dialogue-with-field.component';
import { AddDocumentComponent } from 'app/components/common/document/add-document.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SelectListDialogueComponent } from '../../common/select-list-dialogue/select-list-dialogue.component';
import { IPersonFilter } from 'app/models/filters/personFilter';

@Component({
  selector: 'app-add-individual-application',
  templateUrl: './add-individual-application.component.html',
  styleUrls: ['./add-individual-application.component.scss'],
  animations: ANIMATIONS,
})
export class AddIndividualApplicationComponent implements OnInit {
  public applicationCertificateType: string[] = Object.values(STUDENT_CODE_TYPES);
  public canEdit: boolean = true;
  public loading: boolean = true;
  public isApplicationForExistingPerson: boolean = false;
  public updateOnClose: boolean = false;
  public isMobile: boolean = false;
  public date = new Date();
  public uiData = LEAD_BASED_PAINT;
  public applicationStatus = APPLICATION_STATUS;
  public applicationDataForm = null;
  public applicationAttestationForm = null
  public certificateNumber = null;
  public personApplicationData: IPersonApplication;
  public userID: string;
  public cardColor: string = this.sharedService.gold + '20';
  public issueDate: string = '';
  private unmaskedSSN: string = "";
  public personSearchInput$ = new Subject<string>();

  public applicationDeficiencies:IPersonApplicationDeficiency[] = [];
  public filteredApplicationDeficiencies:IPersonApplicationDeficiency[] = [];
  public applicationDeficiencyStatusFilterString: FormControl = new FormControl('both');
  public applicationDeficiencyFilterString: string = '';
  private applicationDeficiencyDebounceString: Subject<string> = new Subject<string>();
  public deficiencyPageSize: number = 5;
  public displayedDeficiencyColumns: string[] = ['id', 'description', 'isResolved'];
  public displayedDeficiencyColumnsFormat = [
    {displayName:'Id',columnName:'id', type:'number', size:'10'},
    {displayName:'Description',columnName:'description', type:'string', size:'70'},
    {displayName:'Resolved',columnName:'isResolved', type:'boolean', size:'10'},
  ];
  public deficiencyDataSource: MatTableDataSource<any>;
  public changeIndicator: number = 0;
  @ViewChild('DeficiencyTablePaginator') deficiencyPaginator: MatPaginator;
  @ViewChild('DeficiencyTableSort') deficiencySort: MatSort;

  public tableApplicationDocumentsColumns = ['id', 'fileName', 'fileDescription', 'actions']
  public tableApplicationDocumentsFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'File Name',columnName:'fileName', type:'string', size:'30'},
    {displayName:'File Description',columnName:'fileDescription', type:'string', size:'40'},
  ];
  public tableApplicationDocumentsData = []
  public tableApplicationDocumentsFilteredData = []
  public tableApplicationDocumentsPageSize = 5;
  public filterDocumentString: string = '';  
  public documentDataSource: MatTableDataSource<any>;
  @ViewChild('DocumentTablePaginator') documentPaginator: MatPaginator;
  @ViewChild('DocumentTableSort') documentSort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {application: IPersonApplication, edit: boolean, renewal: boolean, certificate: IPersonCertificate, person: IPerson, personAddress: IPersonAddress, personContact: IPersonContactInformation},
    private dialogRef: MatDialogRef<AddFirmApplicationComponent>,
    private dialog: MatDialog,
    public personService: PersonService,
    public userService: UserInformationService,
    private toastr: ToastrService,
    public commonService: CommonDataService,
    public paymentService: PaymentService,
    public sharedService: SharedService,
    private breakpointObserver: BreakpointObserver,) {      
      this.isApplicationForExistingPerson = this.data?.person !== null && this.data?.person !== undefined;      
      this.data?.application? (this.personApplicationData =  this.data.application) : this.setPersonApplicationData();
      this.canEdit = this.personApplicationData.status !== APPLICATION_STATUS.approved;
      this.initializeDebounceFunctions();

      this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      });

      this.personSearchInput$
      .pipe(
        debounceTime(500),          // Wait 300ms after the user stops typing
        distinctUntilChanged(),     // Only emit if the input has changed
        tap((searchTerm) => {        
          return this.performPersonSearch(searchTerm);
        })
      ).subscribe();
  }

  ngOnInit(): void {    
    this.setApplicationDataFormFields();
    this.loadFormFields();    
    this.getUser();
    this.updatePersonApplicationDeficiencies();
    this.refreshTableApplicationDocuments(); 
    this.updateSSNRequirement();
    this.loading = false;
  }

  initializeDebounceFunctions(): void {
    this.applicationDeficiencyDebounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      this.applicationDeficiencyFilterString = value.trim().toLowerCase();
      this.filterDeficiencyTable();     
    });
  }

  personSearch(event: any){         
    const inputElement = event.target as HTMLInputElement;
    this.personSearchInput$.next(inputElement.value);
  }

  performPersonSearch(value: any){         
    if(value.length > 2 && (this.data?.application?.person === null || this.personApplicationData.person === null)){   
      var firstName = this.applicationDataForm.controls['firstName'].value;
      var lastName = this.applicationDataForm.controls['lastName'].value;

      var stringForFilter = (firstName ? firstName : '') + 
                      (firstName && lastName ? ' ' : '') + 
                      (lastName ? lastName : '');
      var personFilter: IPersonFilter = {
        filterString: stringForFilter,
        filterStatus: null,
        filterAffiliation: null,
        expirationStartDate: null,
        expirationEndDate: null,
        filterDiscipline: null
      };
      
      this.personService.getPeopleFiltered(personFilter).subscribe(res=>{        
        if(res.length > 0){
          const dialogRef = this.dialog.open(SelectListDialogueComponent, {
            width: '80%',
            data: {
                    items: res, 
                    message: 'Select an Existing Person to Link this Application to, click Cancel if this is a New Person', 
                    title: 'Select/Link an Existing Person',
                    type: 'person'
                  },
            autoFocus: false,
            panelClass: this.sharedService.darkMode ? "theme-dark" : ""
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result){
              this.applicationDataForm.controls['firstName'].setValue(result.firstName);
              this.applicationDataForm.controls['lastName'].setValue(result.lastName);
              this.applicationDataForm.controls['title'].setValue(result.title);
              this.applicationDataForm.controls['middleName'].setValue(result.middleName);
              this.applicationDataForm.controls['ssn'].setValue(result.ssn);
              this.applicationDataForm.controls['dateOfBirth'].setValue(result.dateOfBirth);
              this.applicationDataForm.controls['isPublic'].setValue(result.response === "Yes");

              this.personService.getPersonAddressesByPersonId(result.id).subscribe(result=>{
                this.applicationDataForm.controls['mailingStreet'].setValue(result[0]?.address.street);
                this.applicationDataForm.controls['mailingCity'].setValue(result[0]?.address.city);
                this.applicationDataForm.controls['mailingState'].setValue(result[0]?.address.state);
                this.applicationDataForm.controls['mailingZip'].setValue(result[0]?.address.zip);
                this.applicationDataForm.controls['mailingCounty'].setValue(result[0]?.address.county);
              },err=>{this.toastr.error("There was an error getting the person addresses: ", err)});

              this.personService.getPersonContactInformationByPersonId(result.id).subscribe(result=>{
                this.applicationDataForm.controls['phone'].setValue(result?.contactInformation.phone);
                this.applicationDataForm.controls['alternatePhone'].setValue(result?.contactInformation.cell);
                this.applicationDataForm.controls['faxPhone'].setValue(result?.contactInformation.fax);
                this.applicationDataForm.controls['email'].setValue(result?.contactInformation.email);
                this.applicationDataForm.controls['confirmEmail'].setValue(result?.contactInformation.email);
                this.applicationDataForm.controls['website'].setValue(result?.contactInformation.website);
              },err=>{this.toastr.error("There was an error getting the person contact information: ", err)});
              this.isApplicationForExistingPerson = true;
              this.personApplicationData.person = result;
            }      
          });
        }
      },err=>{this.toastr.error("There was an error searching for firms: ", err)});
    }
  }

  removePerson(){
    this.personApplicationData.person = null
    this.data.person = null;
    this.data.application.person = null;
    this.personApplicationData.renewal = false;
    this.isApplicationForExistingPerson = false;
  }

  onSSNFocus() {
    this.applicationDataForm.get('ssn').setValue(this.unmaskedSSN);
  }

  onSSNBlur() {    
    this.unmaskedSSN = this.applicationDataForm.get('ssn').value;    
    this.applicationDataForm.get('ssn').setValue(this.maskSSN(this.unmaskedSSN));
  }

  maskSSN(ssn: string): string {
    if (!ssn) return '';
    const visibleDigits = ssn.slice(-4);
    const maskedDigits = '*'.repeat(ssn.length - 4);
    return maskedDigits + visibleDigits;
  }

  getUser(){
    this.userService.getUserInfo().subscribe(result=>{
      this.userID = result.email;       
    },error=>{console.error("An error occurred trying to get the userID: ", error)})
  }

  getStatusTypeValues() {    
    let typeValues = (this.personApplicationData.status === APPLICATION_STATUS.approved) ? Object.values(APPLICATION_STATUS) : Object.values(APPLICATION_STATUS).filter(x=>x!==APPLICATION_STATUS.approved);
    return typeValues;
  }
  
  setApplicationDataFormFields(){
    this.applicationDataForm = new UntypedFormGroup({
      status: new UntypedFormControl(""), 
      isPublic: new UntypedFormControl(false), 
      firstName: new UntypedFormControl("", [Validators.required]),
      lastName: new UntypedFormControl("", [Validators.required]),
      middleName: new UntypedFormControl(""),
      title: new UntypedFormControl(""),
      ssn: new UntypedFormControl("", [Validators.required,Validators.pattern(/^(?:\*{7}|^\(?([\d]{3})\)?-?([\d]{2})-?([\d]{4})).*$/)]),
      dateOfBirth: new UntypedFormControl(""),      
      applicationDate: new UntypedFormControl("", [Validators.required]),   
      approvalDate: new UntypedFormControl(""),   
      applicationViableDate: new UntypedFormControl("", [Validators.required]),   
      renewal: new UntypedFormControl({value: false, disabled: true}),      
      previousApplication: new UntypedFormControl({value: "", disabled: this.personApplicationData.renewal}),      
      mailingStreet: new UntypedFormControl("", [Validators.required]),
      mailingCity: new UntypedFormControl("", [Validators.required]),
      mailingState: new UntypedFormControl("", [Validators.required]),
      mailingZip: new UntypedFormControl("", [Validators.required,Validators.pattern(/^\d{5}(-\d{4})?$/)]),
      mailingCounty: new UntypedFormControl(""),
      mailingIsPhysical: new UntypedFormControl(false),
      certificateType: new UntypedFormControl({value: "", disabled: this.personApplicationData.renewal}, Validators.required),
      phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      faxPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      email: new UntypedFormControl("", [Validators.required, Validators.email]),
      confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]), 
      website: new UntypedFormControl(""),
      ssnExempt: new UntypedFormControl(false),
      ssnExemptStatement: new UntypedFormControl(""),
      repLabel: new UntypedFormControl(""),
      repPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      repAlternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      repFaxPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?[-\s]*([\d]{3})-?([\d]{4}).*$/)]),
      repEmail: new UntypedFormControl("", [Validators.email]),
      repConfirmEmail: new UntypedFormControl("", [Validators.email]), 
      repWebsite: new UntypedFormControl(""),
    }, {
      validators: [
        this.commonService.emailValidator('email', 'confirmEmail'),        
      ]
    }); 

    this.applicationAttestationForm = new UntypedFormGroup({
      attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
      esignature: new UntypedFormControl("", [Validators.required])
    });    
  }

  setPersonApplicationData(){ 
    const date = new Date();
    this.personApplicationData  = {
      id: 0,
      approverEmail: '',
      approvalDate: null,
      userID: null,
      status: APPLICATION_STATUS.inReview,
      applicationDate: date.toISOString(),
      applicationViableDate: this.data?.certificate?.expirationDate ? new Date(new Date(this.data.certificate.expirationDate).setDate(new Date(this.data.certificate.expirationDate).getDate() + 1)).toISOString() : null,
      isPublic: false,
      renewal: this.data.renewal ?? false,
      previousApplication: this.data?.certificate?.controlNumber ?? '',
      dateOfBirth: this.isApplicationForExistingPerson ? this.data.person.dateOfBirth : null,
      ssn: this.isApplicationForExistingPerson ? this.data.person.ssn : '',
      title: this.isApplicationForExistingPerson ? this.data.person.title : '',
      firstName: this.isApplicationForExistingPerson ? this.data.person.firstName : '',
      lastName: this.isApplicationForExistingPerson ? this.data.person.lastName : '',
      middleName: this.isApplicationForExistingPerson ? this.data.person.middleName :'',
      phone: this.isApplicationForExistingPerson ? this.data.personContact.contactInformation.phone : '',
      alternatePhone: this.isApplicationForExistingPerson ? this.data.personContact.contactInformation.cell : '',
      faxPhone: this.isApplicationForExistingPerson ? this.data.personContact.contactInformation.fax : '',
      email: this.isApplicationForExistingPerson ? this.data.personContact.contactInformation.email : '',
      confirmEmail: this.isApplicationForExistingPerson ? this.data.personContact.contactInformation.email : '',
      website: this.isApplicationForExistingPerson ? this.data.personContact.contactInformation.website : '',
      mailingCity: this.isApplicationForExistingPerson ? this.data.personAddress.address.city : '',
      mailingCounty: this.isApplicationForExistingPerson ? this.data.personAddress.address.county : '',
      mailingState: this.isApplicationForExistingPerson ? this.data.personAddress.address.state : '',
      mailingStreet: this.isApplicationForExistingPerson ? this.data.personAddress.address.street : '',
      mailingZip: this.isApplicationForExistingPerson ? this.data.personAddress.address.zip : '',
      certificateType: this.isApplicationForExistingPerson ? this.data?.certificate?.codeType : '',
      person: this.isApplicationForExistingPerson ? this.data.person : null,
      attestation: false,
      esignature: '',
      ssnExempt: false,
      ssnExemptStatement: '',
      repLabel: '',
      repPhone: '',
      repAlternatePhone: '',
      repFaxPhone: '',
      repEmail: '',
      repConfirmEmail: '',
      repWebsite: '',
    };
  }

  loadFormFields(){    
    for (const controlName in this.personApplicationData) {
      if (this.applicationDataForm.controls.hasOwnProperty(controlName)) {
        this.applicationDataForm.controls[controlName].setValue(this.personApplicationData[controlName]);
        if(!this.canEdit)
          this.applicationDataForm.controls[controlName].disable();
      } else if (this.applicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.applicationAttestationForm.controls[controlName].setValue(this.personApplicationData[controlName]);
        if(!this.canEdit)
          this.applicationAttestationForm.controls[controlName].disable();
      }
    }
    this.onSSNBlur();
  }

  updatePersonApplicationData(): void {    
    for (const controlName in this.personApplicationData) {
      if (this.applicationDataForm.controls.hasOwnProperty(controlName)) {
        this.personApplicationData[controlName] = this.applicationDataForm.controls[controlName].value;        
      } else if (this.applicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.personApplicationData[controlName] = this.applicationAttestationForm.controls[controlName].value;
      }
    } 
    this.personApplicationData.ssn = this.unmaskedSSN;
  }

  updateSSNRequirement(): void {
    if(this.applicationDataForm.controls.ssnExempt.value){
      this.applicationDataForm.controls.ssn.clearValidators();      
      this.applicationDataForm.controls.ssn.updateValueAndValidity();
      this.applicationDataForm.controls.ssn.setValue("");
      this.applicationDataForm.controls.ssn.disable();
    } else{
      this.applicationDataForm.controls.ssn.enable();
      this.applicationDataForm.controls.ssn.setValidators([Validators.required,Validators.pattern(/^(?:\*{7}|^\(?([\d]{3})\)?-?([\d]{2})-?([\d]{4})).*$/)]);
      this.applicationDataForm.controls.ssn.updateValueAndValidity();
    }
  }

  savePersonApplication(): void{
    this.personApplicationData.userID = this.personApplicationData.userID??this.userID;
    this.personApplicationData.applicationDate = this.personApplicationData.applicationDate??this.date.toISOString();  
    this.updatePersonApplicationData();  
    this.personService.SavePersonApplication(this.personApplicationData).subscribe(result=>{      
      this.updateOnClose = this.updateOnClose || this.personApplicationData.id === 0;       
      this.toastr.success((this.personApplicationData.id === 0) ? "Application Saved" : "Application Updated");
      this.personApplicationData.id = result.id;     
      this.changeIndicator++;       
    },error=>{
      this.toastr.error("There was an error saving the application: ", error);
      console.error(error);
    })
  }

  refreshTableApplicationDocuments(){
    this.personService.getPersonApplicationDocuments(this.personApplicationData.id).subscribe(result=>{
      this.tableApplicationDocumentsData = result.sort((a, b) => a.fileName.localeCompare(b.fileName));
      this.filterDocumentTable();
    },error=>{
      this.toastr.error("There was an error getting the documents: ", error);
    })
  }

  editDocument(row: any){
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      width: this.isMobile ? '90%' : '30%',
      data: {document: row, entity: 'individual', entityRef: this.personApplicationData},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
        this.refreshTableApplicationDocuments();      
    });
  }

  async downloadFile(row: any) {    
    this.commonService.downloadFileStream(row);
  }  

  clearFile(row: any) {      
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> this Application Document?',title: 'Delete the Application Dcoument'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.personService.deletePersonApplicationDocument(row.id).subscribe(result=>{
          this.commonService.deleteDocumentFile(row.document.id).subscribe(result=>{
            this.refreshTableApplicationDocuments();
          }, error=>{
            this.toastr.error("An error occurred while deleting the document: ", error);
          });
        },
        error=>{
          this.toastr.error("An error occurred while deleting the document: ", error);
        });                 
      }
    })
  }

  filterDocumentTable(): void {    
    this.tableApplicationDocumentsFilteredData = this.tableApplicationDocumentsData;
    
    this.tableApplicationDocumentsFilteredData = this.tableApplicationDocumentsFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterDocumentString) ||      
        x.fileName?.toString().toLowerCase().includes(this.filterDocumentString.toLowerCase() ||
        x.fileDescription?.toString().toLowerCase().includes(this.filterDocumentString.toLowerCase()))        
    });

    this.updateDocumentDataSource();
  }
  updateDocumentDataSource(){    
    this.documentDataSource = new MatTableDataSource<any>(this.tableApplicationDocumentsFilteredData);
    this.documentDataSource.paginator = this.documentPaginator;
    this.documentDataSource.sort = this.documentSort;    
  }

  approvePerson(){    
    const dialogRef = this.dialog.open(ConfirmationDialogueWithFieldComponent, {
      width: '400px',
      autoFocus: false,
      data: {message:'Are you sure you want to <strong>approve</strong> this application?',title:'Approve Application',type:'individual',certificateType: this.applicationDataForm.controls.certificateType.value,controlNumber: this.personApplicationData.previousApplication, selectedDate: this.personApplicationData.renewal ? this.personApplicationData.applicationViableDate : new Date().toISOString()},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
      if(result !== false && result !== undefined && result !== null){ 
        this.certificateNumber = result.certificateNumber;
        this.applicationDataForm.controls.previousApplication.setValue(result.controlNumber);
        this.issueDate = result.issueDate;

        this.updatePersonApplicationData();
        this.personApplicationData.status = APPLICATION_STATUS.approved;
        this.personApplicationData.approverEmail = this.userID;
        
        if(this.personApplicationData.approvalDate === null || this.personApplicationData.approvalDate === undefined || this.personApplicationData.approvalDate === "" || isNaN(new Date(this.personApplicationData.approvalDate).getTime())){
          this.personApplicationData.approvalDate = new Date().toISOString();
        }

        if(this.personApplicationData.person !== null && this.personApplicationData.person !== undefined){
          this.approveExistingPersonApplication();
        }
        else{
          this.approveNewPersonApplication();
        }        
      }
    })
  }

  approveExistingPersonApplication(){
    const dateData = new Date(this.issueDate);    
    dateData.setDate(dateData.getDate() + 1);
    const issueDate = dateData.toISOString().substring(0, 10)    
    dateData.setMonth(dateData.getMonth() + 30)
    const recertDate = dateData.toISOString().substring(0, 10)    
    dateData.setMonth(dateData.getMonth() + 6)
    const expirationDate = dateData.toISOString().substring(0, 10)   

    let person: IPerson = this.personApplicationData.person;
    let personAddress: IPersonAddress = null;
    let personContactInformation: IPersonContactInformation = null;
    let personRepContactInformation: IPersonContactInformation = {
      id: 0,
      person: person,
      contactInformation: null,
    };
    let personCertificate: IPersonCertificate = {
      id: 0, 
      person: person, 
      stage: this.personApplicationData.renewal ? CERTIFICATE_STAGE_TYPES.recert : CERTIFICATE_STAGE_TYPES.initial, 
      certificateNumber: this.certificateNumber,
      codeType: this.personApplicationData.certificateType, 
      controlNumber: this.applicationDataForm.controls.previousApplication.value, 
      issueDate: issueDate,
      recertificationDate: recertDate, 
      expirationDate: expirationDate,
      dateOfLetter: null,
      application: this.personApplicationData,
    };
    let address: IAddress = null;
    let contact: IContactInformation = null;
    let repContact: IContactInformation = {
      label: this.applicationDataForm.controls.repLabel.value, 
      phone: this.applicationDataForm.controls.repPhone.value, 
      cell: this.applicationDataForm.controls.repAlternatePhone.value, 
      fax: this.applicationDataForm.controls.repFaxPhone.value, 
      email: this.applicationDataForm.controls.repEmail.value, 
      website: this.applicationDataForm.controls.repWebsite.value, 
      id: 0, 
      contactType: "representative", 
      ext: ""
    };

    if(repContact.cell !== "" || repContact.email !== "" || repContact.fax !== "" || repContact.phone !== "" || repContact.website !== ""){
      this.commonService.saveContactInformation(repContact).subscribe(result=>{
        personRepContactInformation.contactInformation = result;
        this.personService.savePersonContactInfo(personContactInformation).subscribe(result=>{          
        },error=>{this.toastr.error("An error occurred saving the representative person contact information: ",error)})
      },error=>{this.toastr.error("An error occurred saving the representative contact information: ",error)})
    }    

    this.personService.getPersonAddressesByPersonId(this.personApplicationData.person.id).subscribe(result=>{
      personAddress = result[0];
      address = personAddress.address;

      address.street = this.personApplicationData.mailingStreet;
      address.city = this.personApplicationData.mailingCity;
      address.state = this.personApplicationData.mailingState;
      address.zip = this.personApplicationData.mailingZip;
      address.county = this.personApplicationData.mailingCounty;
      personAddress.isCurrentMailing = true;
      
      this.commonService.saveAddress(address).subscribe(result=>{},error=>{this.toastr.error("An error occurred saving the address: ", error)});
    },error=>{this.toastr.error("An error occurred getting the person addresses: ", error)})

    this.personService.getPersonContactInformationByPersonId(this.personApplicationData.person.id).subscribe(result=>{
      personContactInformation = result;
      contact = personContactInformation.contactInformation;

      contact.phone = this.personApplicationData.phone;
      contact.cell = this.personApplicationData.alternatePhone;
      contact.fax = this.personApplicationData.faxPhone;
      contact.email = this.personApplicationData.email;
      contact.website = this.personApplicationData.website;  

      this.commonService.saveContactInformation(contact).subscribe(result=>{},error=>{this.toastr.error("An error occurred saving the contact information: ", error)});
    },error=>{this.toastr.error("An error occurred getting the person contact information: ", error)})

    this.personService.savePersonCertificate(personCertificate).subscribe(result=>{},error=>{this.toastr.error("An error occurred saving the person certificate: ", error)});

    person.title = this.personApplicationData.title;
    person.firstName = this.personApplicationData.firstName;
    person.middleName = this.personApplicationData.middleName;
    person.lastName = this.personApplicationData.lastName;
    person.ssn = this.personApplicationData.ssn;
    person.dateOfBirth = this.personApplicationData.dateOfBirth;
    person.response = this.personApplicationData.isPublic ? "Yes" : "No";      
        
    this.personService.savePerson(person).subscribe(result=>{},error=>{this.toastr.error("An error occurred saving the person: ", error)});    
    this.personService.SavePersonApplication(this.personApplicationData).subscribe(result=>{
      this.toastr.success("The application was successfully approved")        
      this.exitDialog();
    },error=>{this.toastr.error("An error occurred saving the person application: ",error)})
  }

  approveNewPersonApplication(){
    const dateData = new Date(this.issueDate);    
    dateData.setDate(dateData.getDate() + 1);
    const issueDate = dateData.toISOString().substring(0, 10)    
    dateData.setMonth(dateData.getMonth() + 30)
    const recertDate = dateData.toISOString().substring(0, 10)    
    dateData.setMonth(dateData.getMonth() + 6)
    const expirationDate = dateData.toISOString().substring(0, 10)           
    
    let personRepContactInformation: IPersonContactInformation = {
      id: 0,
      person: null,
      contactInformation: null,
    };
    let repContact: IContactInformation = {
      label: this.applicationDataForm.controls.repLabel.value, 
      phone: this.applicationDataForm.controls.repPhone.value, 
      cell: this.applicationDataForm.controls.repAlternatePhone.value, 
      fax: this.applicationDataForm.controls.repFaxPhone.value, 
      email: this.applicationDataForm.controls.repEmail.value, 
      website: this.applicationDataForm.controls.repWebsite.value, 
      id: 0, 
      contactType: "representative", 
      ext: ""
    };
    
    const person: IPerson = {
      id: 0,
      title: this.personApplicationData.title,
      firstName: this.personApplicationData.firstName,
      middleName: this.personApplicationData.middleName,
      lastName: this.personApplicationData.lastName,
      ssn: this.personApplicationData.ssn,
      dateOfBirth: this.personApplicationData.dateOfBirth,
      response: this.personApplicationData.isPublic ? "Yes" : "No",
      inactive: false,
      certificates: null
    }
    const personContactInformation: IPersonContactInformation = {
      id: 0,
      person: null,
      contactInformation: null,
    }
    const address: IAddress = {
      id: 0,
      contactType: "Work",
      street: this.personApplicationData.mailingStreet,
      city: this.personApplicationData.mailingCity,
      state: this.personApplicationData.mailingState,
      zip: this.personApplicationData.mailingZip,
      county: this.personApplicationData.mailingCounty,
    }    
    const personAddress: IPersonAddress = {
      id: 0,
      person: null,
      address: null,
      isCurrentMailing: true,
      isCurrentPhysical: false,
    }    
    const accountAffiliation: IAccountAffiliation = {
      id: 0,
      userID: this.personApplicationData.userID,
      firm: null,
      atp: null,
      status: 'Active',
      person: null,
    };
    const contactInformation: IContactInformation = {
      id: 0,
      contactType: "Work",
      phone: this.personApplicationData.phone,
      cell: this.personApplicationData.alternatePhone,
      fax: this.personApplicationData.faxPhone,
      ext: "",
      email: this.personApplicationData.email,
      website: this.personApplicationData.website,
      label: "",
    };
    const certificateData: IPersonCertificate = {
      id: 0, 
      person: null, 
      stage: CERTIFICATE_STAGE_TYPES.initial, 
      codeType: this.personApplicationData.certificateType, 
      certificateNumber: this.certificateNumber,
      controlNumber: this.applicationDataForm.controls.previousApplication.value, 
      issueDate: issueDate,
      recertificationDate: recertDate, 
      expirationDate: expirationDate,
      dateOfLetter: null,
      application: this.personApplicationData,
    };

    this.personService.savePerson(person).subscribe(result=>{
      this.toastr.success("The person was successfully created")
      personContactInformation.person = result;
      personAddress.person = result;
      certificateData.person = result;
      personRepContactInformation.person = result;      
      this.personService.savePersonCertificate(certificateData).subscribe(result=>{
        certificateData.id = result.id;        
      },error=>{
        this.toastr.error("There was an error adding the certificate: ", error);
      })

      this.commonService.saveAddress(address).subscribe(add => {
        personAddress.address = add;
        this.personService.savePersonAddress(personAddress).subscribe(pAdd => {
          personAddress.id = pAdd.id;
        },error=>{this.toastr.error("An error occurred saving the person address: ", error)});
      },error=>{this.toastr.error("An error occurred saving the address: ", error)});

      
      this.commonService.saveContactInformation(contactInformation).subscribe(result=>{
        personContactInformation.contactInformation = result;
        this.personService.savePersonContactInfo(personContactInformation).subscribe(result=>{          
        },error=>{this.toastr.error("An error occurred saving the person contact information: ",error)})
      },error=>{this.toastr.error("An error occurred saving the contact information: ",error)}) 
      
      if(repContact.cell !== "" || repContact.email !== "" || repContact.fax !== "" || repContact.phone !== "" || repContact.website !== ""){
        this.commonService.saveContactInformation(repContact).subscribe(result=>{          
          repContact = result;        
          personRepContactInformation.contactInformation = result;
          this.personService.savePersonContactInfo(personRepContactInformation).subscribe(result=>{              
          },error=>{this.toastr.error("An error occurred saving the representative person contact information: ",error)})
        },error=>{this.toastr.error("An error occurred saving the representative contact information: ",error)})
      }            
      
      this.personApplicationData.person = result;
      accountAffiliation.person = result;
      
      this.commonService.saveAccountAffiliation(accountAffiliation).subscribe(result=>{},error=>{this.toastr.error("An error occurred saving the account affiliation: ",error)})
      this.personService.SavePersonApplication(this.personApplicationData).subscribe(result=>{        
        this.exitDialog();
      },error=>{this.toastr.error("An error occurred saving the person application: ",error)})
    },error=>{this.toastr.error("An error occurred saving the application person: ", error)})    
  }

  updateApplicationDeficiencyDebounceString(event: any){    
    this.applicationDeficiencyDebounceString.next(event.target.value);    
  }
  filterDeficiencyTable(){    
    this.filteredApplicationDeficiencies = this.applicationDeficiencies.filter(x=>
      (x.id + ' ' + x.description).toLowerCase().includes(this.applicationDeficiencyFilterString)
      && ((this.applicationDeficiencyStatusFilterString.value === "both") ? true : (x.isResolved.toString() === this.applicationDeficiencyStatusFilterString.value)) 
    );            
    this.updateDeficiencySource();  
  }

  updateDeficiencySource(){
    this.deficiencyDataSource = new MatTableDataSource<any>(this.filteredApplicationDeficiencies);
    this.deficiencyDataSource.sort = this.deficiencySort;
    this.deficiencyDataSource.paginator = this.deficiencyPaginator;
  }

  editDeficiency(deficiencyToEdit: any){
    let dialogRef = this.dialog.open(AddIndividualApplicationDeficiencyComponent, {
      width: this.isMobile ? '90%' : '40%',
      data: {deficiency: deficiencyToEdit, personApplication: this.personApplicationData},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.updatePersonApplicationDeficiencies();
      }        
    });
  }
  
  deleteDeficiency(deficiency: IPersonApplicationDeficiency) {
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message:'Are you sure you want to remove the application deficiency with ID: <strong>' + deficiency.id + '</strong>?',title:'Delete Application Deficiency'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.personService.deletePersonApplicationDeficiency(deficiency.id).subscribe(result=>{
          this.updatePersonApplicationDeficiencies();
        },error=>{})
      }
    });
  }
  toggleDeficiencyResolved(deficiencyToEdit: IPersonApplicationDeficiency, isChecked: boolean){
    deficiencyToEdit.isResolved = isChecked;
    this.personService.savePersonApplicationDeficiency(deficiencyToEdit).subscribe(result=>{
      this.filterDeficiencyTable();
    },error=>{console.error("There was an error saving the deficiency:", error)});
  }
  updatePersonApplicationDeficiencies(){
    this.personService.getPersonApplicationDeficiencies(this.personApplicationData.id).subscribe(result=>{      
      this.applicationDeficiencies = result.sort((a, b) => {
        // Check if both items have the same IsResolved status
        if (a.isResolved === b.isResolved) {
          // If so, sort by id in descending order
          return b.id - a.id;
        } else {
          // If not, ensure items with IsResolved as false come first
          return a.isResolved ? 1 : -1;
        }
      });
      this.filterDeficiencyTable();      
    },error=>{});
  }
  exitDialog(){
    this.dialogRef.close(this.updateOnClose);
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}
