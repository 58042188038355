import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IComplaint } from 'app/models/complaint/complaint';
import { IComplaintDetail } from 'app/models/complaint/complaintDetail';
import { IComplaintOwnerTenant } from 'app/models/complaint/complaintOwnerTenant';
import { IComplaintAction } from 'app/models/complaint/complaintAction';
import { IComplaintInspection } from 'app/models/complaint/complaintInspection';
import { IComplaintJurisdiction } from 'app/models/complaint/complaintJurisdiction';
import { IComplaintEnforcementCategory } from 'app/models/complaint/complaintEnforcementCategory';
import { IComplaintReferral } from 'app/models/complaint/complaintReferral';
import { IComplaintAssociation } from 'app/models/complaint/complaintAssociation';
import { IComplaintFilter } from 'app/models/filters/complaintFilter';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {
  private api: string = environment.privateApi;
  
  constructor(private http?: HttpClient) { }

 
  getComplaints(filter: IComplaintFilter): Observable<IComplaint[]> {    
    return this.http.post<IComplaint[]>(this.api + 'Complaint/GetComplaints/', filter, { withCredentials: true });
  }

  getComplaintById(complaintId: number): Observable<IComplaint> {    
    return this.http.get<IComplaint>(this.api + 'Complaint/GetComplaintById/'+ complaintId,  { withCredentials: true });
  }

  saveComplaint(complaint: IComplaint): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaint', complaint, httpOptions);
  }

  saveComplaintJurisdiction(complaintJurisdiction: IComplaintJurisdiction[]): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintJurisdiction', complaintJurisdiction, httpOptions);
  }

  getComplaintJurisdictions(complaintId: number): Observable<any> {
    return this.http.get(this.api + 'Complaint/GetComplaintJurisdictions/' + complaintId,  httpOptions);
  }

  getComplaintEnforcementCategories(complaintId: number): Observable<any> {
    return this.http.get(this.api + 'Complaint/GetComplaintEnforcementCategories/' + complaintId,  httpOptions);
  }

  saveComplaintEnforcementCategory(complaintEnforcementCategory: IComplaintEnforcementCategory[]): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintEnforcementCategory', complaintEnforcementCategory, httpOptions);
  }

  getComplaintOwnerTenants(complaintId: number): Observable<IComplaintOwnerTenant[]> {
    return this.http.get<IComplaintOwnerTenant[]>(this.api + 'Complaint/GetComplaintOwnerTenants/' + complaintId, httpOptions);
  }

  saveComplaintOwnerTenant(complaintOwnerTenant: IComplaintOwnerTenant): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintOwnerTenant', complaintOwnerTenant, httpOptions);
  }

  getComplaintActions(complaintId: number): Observable<IComplaintAction[]> {
    return this.http.get<IComplaintAction[]>(this.api + 'Complaint/GetComplaintActions/' + complaintId, httpOptions);
  }

  getComplaintInspections(complaintId: number): Observable<IComplaintInspection[]> {
    return this.http.get<IComplaintInspection[]>(this.api + 'Complaint/GetComplaintInspections/' + complaintId, httpOptions);
  }

  saveComplaintInspection(complaintInspection: IComplaintInspection): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintInspection', complaintInspection, httpOptions);
  }

  deleteComplaintInspection(id: number): Observable<any> {    
    return this.http.get(this.api + 'Complaint/DeleteComplaintInspection/' + id, httpOptions);
  }

  saveComplaintAction(complaintAction: IComplaintAction): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintAction', complaintAction, httpOptions);
  }

  saveComplaintJurisdictionEnforcementCategories(complaintDetail: IComplaintDetail): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintJurisdictionEnforcementCategory', complaintDetail, httpOptions);
  }

  deleteComplaintAction(id: number): Observable<any> {    
    return this.http.get(this.api + 'Complaint/DeleteComplaintAction/' + id, httpOptions);
  }

  saveComplaintReferral(complaintReferral: IComplaintReferral): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintReferral', complaintReferral, httpOptions);
  }

  getComplaintReferrals(complaintId: number): Observable<IComplaintReferral[]> {
    return this.http.get<IComplaintReferral[]>(this.api + 'Complaint/GetComplaintReferrals/' + complaintId, httpOptions);
  }

  deleteComplaintReferral(id: number): Observable<any> {    
    return this.http.get(this.api + 'Complaint/DeleteComplaintReferrals/' + id, httpOptions);
  }

  getComplaintAssociations(complaintId: number): Observable<IComplaintAssociation[]> {
    return this.http.get<IComplaintAssociation[]>(this.api + 'Complaint/GetComplaintAssociations/' + complaintId, httpOptions);
  }

  saveComplaintAssociation(complaintAssociation: IComplaintAssociation[]): Observable<any> {
    return this.http.post(this.api + 'Complaint/SaveComplaintAssociation', complaintAssociation, httpOptions);
  }

  deleteComplaintAssociation(complaintAssociation: IComplaintAssociation): Observable<any> {    
    return this.http.post(this.api + 'Complaint/DeleteComplaintAssociation/', complaintAssociation, httpOptions);
  }

  getComplaintsByAssociations(id: number,type: string): Observable<any> {    
    const params = new HttpParams()
   .set('associationId', id)
   .set('associationType', type);
    return this.http.get<IComplaint[]>(this.api + 'Complaint/GetComplaintsByAssocations', { params, withCredentials: true });
  }

  deleteComplaintOwnerTenant(id: number): Observable<any> {    
    return this.http.get(this.api + 'Complaint/DeleteComplaintOwnerTenant/' + id, httpOptions);
  }
}
