import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAtp } from 'app/models/atp/atp';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { AddEditAtpComponent } from './add-edit-atp/add-edit-atp.component';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAtpFilter } from 'app/models/filters/atpFilter';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AddEditAtpAccreditationComponent } from './add-edit-atp/add-edit-atp-accreditation/add-edit-atp-accreditation.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-atp',
  templateUrl: './atp.component.html',
  styleUrls: ['./atp.component.scss']
})
export class AtpComponent {
  public isMobile: boolean = false;
  public loading: boolean = true;
  public uiData = LEAD_BASED_PAINT;
  public pageSize: number = 50;  
  public trainingProviders: IAtp[] = [];
  public dataSource: MatTableDataSource<any>;    
  public filteredTrainingProviders = [];
  public filterString: string = '';
  public filteredActive = new FormControl('Active');
  public filterDiscipline = new FormControl(null);
  public expirationStartDate = new FormControl(new Date());
  public expirationEndDate = new FormControl(new Date());

  public atpFilter: IAtpFilter = {
    filterString: null,
    filterStatus: null,
    filterType: null,
    expirationStartDate: null,
    expirationEndDate: null,      
  }

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['id', 'inactive', 'name', 'nameAKA','atporId','atpAccreditations'],
      container:[
        {displayName:'ATP Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'7'},        
        {displayName:'ATP Name',columnName:'name', type:'string', size:'17'},                
        {displayName:'AKA',columnName:'nameAKA', type:'string', size:'15'},                
        {displayName:'ATP OR Id',columnName:'atporId', type:'string', size:'10'},
        {displayName:'Accreditations',columnName:'atpAccreditations', type:'subtable', size:'41'},     
      ],
      data: []      
    }
  }
  
  subtableColumns = ['accreditationEndorsementType', 'expirationDate', 'actions'];
  subtableDisplayData = [
    {displayName:'Type',columnName:'accreditationEndorsementType', type:'string', size:'56'},
    {displayName:'Expiration',columnName:'expirationDate', type:'date', size:'30'},            
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public commonService: CommonDataService,
    public atpService: AtpService,    
    public toastr: ToastrService
  ) { 
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.loadFilters();    
    this.loadATPs();
  }

    exportToExcel() {
      const exportData = [];
        
      this.filteredTrainingProviders.forEach(atp => {
        atp.accreditations.forEach(accreditation => {        
          const row = {
            Name: atp.name,     
            AtpOrId: atp.atporId,            
            Accreditation: accreditation.accreditationEndorsementType,       
            Expiration: accreditation.expirationDate?.split('T')[0],
          };          
          exportData.push(row);
        });
      });
        
      exportData.sort((a, b) => a.Name.localeCompare(b.Name));
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        
      ws['!cols'] = [
        { wpx: 400 },
        { wpx: 80 },
        { wpx: 200 },
        { wpx: 80 },
      ];
  
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.filterString !== '' ? this.filterString.replace(/\s+/g, '') + '.xlsx' : 'exportedATPAccrediations.xlsx');
    }

  loadFilters(){
    this.filterString = this.commonService.atpManagementFilter.filterString;
    this.filteredActive = this.commonService.atpManagementFilter.filteredActive;    
    this.filterDiscipline = this.commonService.atpManagementFilter.filterDiscipline;
    this.expirationStartDate = this.commonService.atpManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.atpManagementFilter.expirationEndDate;

    this.atpFilter.filterString = this.filterString;
    this.atpFilter.filterStatus = this.filteredActive.value;  
    this.atpFilter.filterType = this.filterDiscipline.value;
    this.atpFilter.expirationStartDate = this.expirationStartDate?.value?.toISOString();
    this.atpFilter.expirationEndDate = this.expirationEndDate?.value?.toISOString();  
  }
  
  loadATPs(): void {    
    this.atpService.getFilteredATPs(this.atpFilter).subscribe(result=>{      
      this.trainingProviders = result.map(atp => {        
        const provider = {
          ...atp,
          atpAccreditations: new MatTableDataSource(atp.accreditations || []),
        };
        return provider;
      });   
      this.filteredTrainingProviders = this.trainingProviders;  
      this.updateDataSource();
      this.loading = false;
    },error=>{this.toastr.error("Error loading ATPs: " + error.message); this.loading = false;});    
  }
    
  updateDataSource(){    
    this.dataSource = new MatTableDataSource<any>(this.filteredTrainingProviders);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;    
  }

  editATP(atp: IAtp) {    
    const dialogRef = this.dialog.open(AddEditAtpComponent, {
      width: '95%',
      data: {atpId: atp?.id ?? 0},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      this.loadATPs();    
    });
  }

  editAccreditation(row: any){      
    const dialogRef = this.dialog.open(AddEditAtpAccreditationComponent, {
      width: '70%',
      data: {accredId: row?.id ?? 0},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      this.loadATPs();    
    });
  }

  getDisciplineTypes(){
    return ['RRP','LBPA'];
  }

  filterTable(){
    this.commonService.atpManagementFilter.filterString = this.filterString;
    this.commonService.atpManagementFilter.filteredActive = this.filteredActive;
    this.commonService.atpManagementFilter.filterDiscipline = this.filterDiscipline;
    this.commonService.atpManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.atpManagementFilter.expirationEndDate = this.expirationEndDate;

    this.atpFilter.filterString = this.filterString;
    this.atpFilter.filterStatus = this.filteredActive.value;    
    this.atpFilter.filterType = this.filterDiscipline.value;
    this.atpFilter.expirationStartDate = this.expirationStartDate?.value?.toISOString();
    this.atpFilter.expirationEndDate = this.expirationEndDate?.value?.toISOString();
    this.loadATPs();
  }

  getFirmActiveTypes(){
    return ['Active','InActive','Overdue','Incomplete Applications','Complete Applications','Rejected Applications'];
  }

  useReceivedDate(): boolean{
    return this.filteredActive?.value?.includes('Applications');
  }

  clearFilters(){
    this.filterString = '';
    this.filteredActive.setValue('Active');
    this.filterDiscipline.setValue(null);
    this.expirationStartDate.reset();
    this.expirationEndDate.reset();
    this.filterTable();
  }

  isObjectOrArray(value: any): boolean {
    return value && (Array.isArray(value) || typeof value === 'object');
  }
  
  handleStudentRowClick(row: any, event: any){         
    if(!event.target.className.includes("header")){
      this.editATP(row);
    }
  }
}
