<h2 mat-dialog-title [innerHTML]="sanitizedData(data.title)"></h2>
<div mat-dialog-content>
  <div [innerHTML]="sanitizedData(data.message)"></div>
  &nbsp;
  <mat-form-field style="width: 100%;">
    <mat-label>Control Number</mat-label>
    <input matInput [(ngModel)]="selectedControlNumber" [disabled]="disableInput">
  </mat-form-field>

  <div>    
    <mat-form-field style="width: 100%;">
      <mat-label>Certificate Number</mat-label>
      <input matInput [(ngModel)]="selectedCertificateNumber" [disabled]="disableInput">
    </mat-form-field>
  </div>

  <mat-form-field style="width: 100%;">
    <mat-label>Certificate Issue Date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" (ngModelChange)="dateChange($event)" [disabled]="disableInput">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>  
</div>
<div mat-dialog-actions fxLayoutAlign="center" style="margin-top: 4px;">
  <button mat-raised-button [mat-dialog-close]="false">No</button>  
  <button mat-raised-button color="primary" [mat-dialog-close]="{controlNumber: selectedControlNumber, issueDate: selectedDate, certificateNumber: selectedCertificateNumber}">Yes</button>
</div>