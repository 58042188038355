import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-dialogue',
  templateUrl: './confirmation-dialogue.component.html'  
})
export class ConfirmationDialogueComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  
  private sanitizer: DomSanitizer) {}    
  
  sanitizedData(data:string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      data.replace(/\n/g, '<br>')
    );
  }
}
