import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IComplaintReferral } from 'app/models/complaint/complaintReferral';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-add-edit-referral',
    templateUrl: './add-edit-referral.component.html'
})
export class AddEditReferralComponent {
  public defaultDate: Date = new Date();
  @ViewChild('datePicker') datePicker: MatDatepicker<any>;
    public complaintReferral: IComplaintReferral = {
        id: 0,
        complaintId: 0,
        complaintNumber: '',
        firstName: '',
        lastName: '',
        agencyName: '',
        agencyType: '',
        referralDate: '',
        agencyEmail: '',
        email: '',
        phoneNumber: '',
        fax: ''
    };
    public complaintReferralForm = null;
    constructor(private dialogRef: MatDialogRef<AddEditReferralComponent>,
        public complaintService: ComplaintService,
        @Inject(MAT_DIALOG_DATA) public data:{complaintId:number,  selected:IComplaintReferral}, 
        public sharedService: SharedService,     
        private toastr: ToastrService) {
        this.setComplaintReferralFields();
        this.complaintReferral = this.data.selected ?? this.complaintReferral;    
        this.loadComplaintReferralFields();
        this.defaultDate =  this.data.selected ? new Date(this.data.selected.referralDate) : new Date();
    }

    loadComplaintReferralFields(){
        for (const controlName in this.complaintReferral) {      
            if (this.complaintReferralForm.controls.hasOwnProperty(controlName)) {
              this.complaintReferralForm.controls[controlName].setValue(this.complaintReferral[controlName]);        
            }
          }
      }

      onDateSelected(event: MatDatepickerInputEvent<Date>) {
        const selectedDate = event.value.toISOString();
        this.complaintReferralForm.inspectionDate = selectedDate;    
      }

    setComplaintReferralFields(){
        this.complaintReferralForm = new UntypedFormGroup(
            {
              firstName: new UntypedFormControl("", [Validators.required]), 
              lastName: new UntypedFormControl(""),
              phoneNumber: new UntypedFormControl(""),
              email: new UntypedFormControl(""),
              complaintNumber: new UntypedFormControl(""),
              agencyName: new UntypedFormControl(""),   
              agencyType: new UntypedFormControl(""),             
              referralDate: new UntypedFormControl(0),
              agencyEmail:new UntypedFormControl(false),
              fax: new UntypedFormControl("")
            }, 
            {
              validators: [     
              ]
            }
          );       
      }

      onCancel(): void {
        this.dialogRef.close(false);
      }
      updateComplaintReferral(): void {    
        for (const controlName in this.complaintReferral) {
            if (this.complaintReferralForm.controls.hasOwnProperty(controlName)) {
              this.complaintReferral[controlName] = this.complaintReferralForm.controls[controlName].value;                
            }
          } 
      }
      onSubmit(): void {
        if(this.complaintReferralForm &&this.complaintReferralForm.valid){   
            this.updateComplaintReferral();
            this.complaintReferral.complaintId = this.data.complaintId;
            this.complaintReferral.referralDate = this.complaintReferral.referralDate != null && this.complaintReferral.referralDate != "" ? this.complaintReferral.referralDate : new Date().toISOString(); 
            this.complaintService.saveComplaintReferral(this.complaintReferral).subscribe(result=>{
                this.toastr.success("Successfully added complaint Referral details")
                this.dialogRef.close(true);
              },error=>{
                this.toastr.error("An error occured saving the complaint Referral details: ", error);
              }); 
        }
        else{
          this.toastr.error("please enter complaint Referral details")
        }
      }
}