<div *ngIf="isProcessing" class="overlay-blocker">
    <div class="loading-overlay" style="display: flex; justify-content: center; align-items: center; height: 100%;">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div fxLayout="column">
    <mat-card [style.background-color]="color">
        <mat-card-title>
            {{documentData.id !== 0 ? 'Edit Document ' + documentData.id : 'Add Document'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">
        <div fxFlex="auto" fxLayout="column">
            <div fxFlex="auto" fxLayout="column">                                                                                                        
                <span><strong>Selected File</strong></span>                
                <mat-form-field fxFlex="100">
                    <input matInput [(ngModel)]="fileName" disabled>                                                        
                    <mat-placeholder>File Name</mat-placeholder>
                </mat-form-field>
            </div>

            <div fxFlex="auto" fxLayout="column">                                                                                                        
                <span><strong>File Description</strong></span>
                <mat-form-field>
                    <mat-label>File Description</mat-label>
                    <input type="text" matInput [formControl]="fileDescription" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let status of filteredDocumentTypes | async" [value]="status">
                            {{ status }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>        
    </div>
    <mat-card [style.background-color]="color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()" [disabled]="!this.valid">
            {{documentData.id !== 0 ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button color="primary" aria-label="Select Document" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon> Select Document
        </button>
        <input type="file" #fileUpload style="display:none" class="stop-propagation" (change)="onFileSelected($event)">                
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>