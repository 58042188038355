<div fxLayout="column">
    <mat-card [style.background-color]="uiData.firm.color">
        <mat-card-title>
            {{'Edit Firm Certificate'}}
        </mat-card-title>
    </mat-card>
    <div class="scroll-container">                         
        <form [formGroup]="certificateForm">
            <div fxLayout="column" fxFlex>
                <mat-form-field style="width: 100%;">
                    <mat-label>Stage</mat-label>
                    <mat-select formControlName="stage">
                    <mat-option *ngFor="let stage of stageType" [value]="stage">{{ stage }}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <mat-form-field style="width: 100%;">
                    <mat-label>Code Type</mat-label>
                    <mat-select formControlName="codeType">
                    <mat-option *ngFor="let codeType of codeType" [value]="codeType">{{ codeType }}</mat-option>
                    </mat-select>
                </mat-form-field>          
            
                <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Certificate Number" formControlName="certificateNumber">
                </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <input matInput placeholder="Control Number" formControlName="controlNumber">
                </mat-form-field>
            
                <mat-form-field style="width: 100%;">
                    <div fxLayout="row" fxLayoutAlign="start center" style="align-items: center;">
                        <input matInput type="date" placeholder="Issue Date" formControlName="issueDate">
                        <button mat-icon-button matSuffix (click)="clearDate('issueDate')" aria-label="Clear Issue Date">
                            <mat-icon class="clear-icon" color="warn">clear</mat-icon>
                        </button>
                    </div>
                </mat-form-field>

                <mat-form-field style="width: 100%;">
                    <div fxLayout="row" fxLayoutAlign="start center" style="align-items: center;">
                        <input matInput type="date" placeholder="Expiration Date" formControlName="expirationDate">
                        <button mat-icon-button matSuffix (click)="clearDate('expirationDate')" aria-label="Clear Expiration Date">
                            <mat-icon class="clear-icon" color="warn">clear</mat-icon>
                        </button>
                    </div>
                </mat-form-field> 
                
                <mat-form-field style="width: 100%;">
                    <div fxLayout="row" fxLayoutAlign="start center" style="align-items: center;">
                        <input matInput type="date" placeholder="Date of Letter" formControlName="dateOfLetter">
                        <button mat-icon-button matSuffix (click)="clearDate('dateOfLetter')" aria-label="Clear Date of Letter">
                            <mat-icon class="clear-icon" color="warn">clear</mat-icon>
                        </button>
                    </div>
                </mat-form-field> 
            </div>  
        </form>
    </div>
    <mat-card [style.background-color]="uiData.firm.color" fxLayout="row wrap" fxLayoutGap="8px">            
        <span class="text-red-400" *ngIf="data.canEdit">Renewal in progress. Editing Disabled</span>        
        <button mat-raised-button color="primary" style="margin-right: 10px;" [disabled]="!certificateForm.valid" (click)="onSubmit()">Submit</button>
        <button mat-raised-button (click)="onCancel()">Cancel</button>
    </mat-card>    
</div>

  