import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { RoutesModule } from './routes.module';
import { SharedModule } from './shared/shared.module';
import { FormlyConfigModule } from './configuration/angular/formly-config.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { DefaultLayoutComponent } from '../app/layout/default-layout/default-layout.component';
import { SidebarComponent } from '../app/layout/sidebar/sidebar.component';
import { AccordionDirective } from '../app/layout/sidebar/accordion.directive';
import { AccordionItemDirective } from '../app/layout/sidebar/accordionItem.directive';
import { AccordionAnchorDirective } from '../app/layout/sidebar/accordionanchor.directive';
import { NavbarComponent } from '../app/layout/navbar/navbar.component';
import { FooterComponent } from '../app/layout/footer/footer.component';
import { TranslateComponent } from '../app/layout/widgets/translate.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppTimeoutComponent, TimeoutDialog } from './layout/widgets/timeout.component';

import { environment } from '@env/environment';
import { BASE_URL } from '@core/interceptors/base-url-interceptor';
import { httpInterceptorProviders } from '@core/interceptors';
import { appInitializerProviders } from '@core/initializers';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule, MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from '../app/auth/auth-config';
import { FirmComponent } from './components/firm/firm.component';
import { FirmAddEditComponent } from './components/firm/add-edit/firm-add-edit.component';
import { FirmApplicationListComponent } from './components/application/firm/firm-application-list.component';
import { AddFirmApplicationComponent } from './components/application/firm/add-firm-application/add-firm-application.component';
import { AddFirmApplicationPersonComponent } from './components/application/firm/add-firm-application/add-firm-application-person/add-firm-application-person.component';
import { ConfirmationDialogueComponent } from './components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { PaymentsModule } from './components/payments/paymentsmodule';
import { AddFirmApplicationDeficiencyComponent } from './components/application/firm/add-firm-application/add-firm-application-deficiency/add-firm-application-deficiency.component';
import { IndividualApplicationListComponent } from './components/application/individual/individual-application-list.component';
import { IndividualComponent } from './components/individual/individual.component';
import { AddIndividualApplicationComponent } from './components/application/individual/add-individual-application/add-individual-application.component';
import { AddIndividualApplicationDeficiencyComponent } from './components/application/individual/add-individual-application/add-individual-application-deficiency/add-individual-application-deficiency.component';
import { AddIndividualApplicationTrainingComponent } from './components/application/individual/add-individual-application/add-individual-application-training/add-individual-application-training.component';
import { AddEditIndividualComponent } from './components/individual/add-edit-individual/add-edit-individual.component';
import { AddEditIndividualAddressComponent } from './components/individual/add-edit-individual/add-edit-individual-address/add-edit-individual-address.component';
import { AddFirmAffiliationComponent } from './components/individual/add-edit-individual/add-firm-affiliation/add-firm-affiliation.component';
import { AddEditIndividualCertificateComponent } from './components/individual/add-edit-individual/add-edit-individual-certificate/add-edit-individual-certificate.component';
import { AddEditFirmAdministratorComponent } from './components/firm/add-edit/add-edit-firm-administrator/add-edit-firm-administrator.component';
import { AddEditFirmStaffComponent } from './components/firm/add-edit/add-edit-firm-staff/add-edit-firm-staff.component';
import { AddEditFirmCertificateComponent } from './components/firm/add-edit/add-edit-firm-certificate/add-edit-firm-certificate.component';
import { AddEditFirmCredentialComponent } from './components/firm/add-edit/add-edit-firm-credential/add-edit-firm-credential.component';
import { AtpComponent } from './components/atp/atp.component';
import { AddEditAtpComponent } from './components/atp/add-edit-atp/add-edit-atp.component';
import { AddEditAtpStaffComponent } from './components/atp/add-edit-atp/add-edit-atp-staff/add-edit-atp-staff.component';
import { AddEditAtpCourseComponent } from './components/atp/add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { AddEditAtpAccreditationComponent } from './components/atp/add-edit-atp/add-edit-atp-accreditation/add-edit-atp-accreditation.component';
import { AtpCourseStudentUploadComponent } from './components/atp/add-edit-atp/atp-course-student-upload/atp-course-student-upload.component';
import { AddEditCourseStudentComponent } from './components/atp/add-edit-atp/add-edit-course-student/add-edit-course-student.component';
import { ComplaintComponent } from './components/complaint/complaint.component';
import { AddEditComplaintComponent } from './components/complaint/add-edit-complaint/add-edit-complaint.component'
import { AddEditAssociationComponent } from './components/complaint/add-edit-association/add-edit-association.component'
import { AddEditOwnerTenantComponent } from './components/complaint/add-edit-ownertenant/add-edit-ownertenant.component';
import { AddEditAtpAuditComponent } from './components/atp/add-edit-atp/add-edit-atp-audit/add-edit-atp-audit.component';
import { AddEditAuditActionComponent } from './components/atp/add-edit-atp/add-edit-audit-action/add-edit-audit-action.component';
import { AddEditIndividualTestingComponent } from './components/individual/add-edit-individual/add-edit-individual-testing/add-edit-individual-testing.component';
import { AddEditInspectionComponent } from './components/complaint/add-edit-inspection/add-edit-inspection.component';
import { AddEditActionComponent } from './components/complaint/add-edit-action/add-edit-action.component';
import { AddEditReferralComponent } from './components/complaint/add-edit-referral/add-edit-referral.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { AtpStudentComponent } from './components/atp/atp-student/atp-student.component';
import { AddEditAtpNoteComponent } from './components/atp/add-edit-atp/add-edit-atp-note/add-edit-atp-note.component';
import { AddEditAtpDeficiencyComponent } from './components/atp/add-edit-atp/add-edit-atp-deficiency/add-edit-atp-deficiency.component';
import { PaymentDashboardComponent } from './components/payments/components/dashboard/payment-dashboard.component';
import { AddEditFirmAddressComponent } from './components/firm/add-edit/add-edit-firm-address/add-edit-firm-address.component';
import { AddEditFirmContactInformationComponent } from './components/firm/add-edit/add-edit-firm-contact-information/add-edit-firm-contact-information.component';
import { AddEditFirmContactPersonComponent } from './components/firm/add-edit/add-edit-firm-contact-person/add-edit-firm-contact-person.component';
import { PrintComplaintComponent } from './components/complaint/print-complaint/print-complaint.component';
import { ConfirmationDialogueWithFieldComponent } from './components/application/common/confirmation-dialogue-with-field/confirmation-dialogue-with-field.component';
import { AddDocumentComponent } from './components/common/document/add-document.component';

import { PrintCertificateComponent } from './components/individual/add-edit-individual/print-certificate/print-certificate.component';
import { PrintCertificateModalComponent } from './components/individual/add-edit-individual/print-certificate-modal/print-certificate-modal.component';
import { PrintFirmCertificateComponent } from './components/firm/add-edit/print-firm-certificate/print-firm-certificate.component';
import { PrintFirmCertificateModalComponent } from './components/firm/add-edit/print-firm-certificate-modal/print-firm-certificate-modal.component';
import { MergeIndividualComponent } from './components/individual/merge-individual/merge-individual.component';
import { AtpCourseComponent } from './components/atp/atp-course/atp-course.component';
import { MergeFirmComponent } from './components/firm/merge-firm/merge-firm.component';
import { SelectListDialogueComponent } from './components/application/common/select-list-dialogue/select-list-dialogue.component';
import { MatchStudentUploadsToExistingComponent } from './components/atp/add-edit-atp/match-student-uploads-to-existing/match-student-uploads-to-existing.component';
/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.sampleApi.endpoint, protectedResources.sampleApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    TimeoutDialog,
    AppTimeoutComponent,
    SidebarComponent,
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    NavbarComponent,
    FooterComponent,
    TranslateComponent,
    FirmComponent,
    FirmAddEditComponent,    
    FirmApplicationListComponent,
    AddFirmApplicationComponent,
    AddFirmApplicationPersonComponent,
    ConfirmationDialogueComponent,
    AddFirmApplicationDeficiencyComponent,    
    IndividualApplicationListComponent,
    IndividualComponent,
    AddIndividualApplicationComponent,
    AddIndividualApplicationDeficiencyComponent,
    AddIndividualApplicationTrainingComponent,
    AddEditIndividualComponent,
    AddEditIndividualAddressComponent,
    AddFirmAffiliationComponent,
    AddEditIndividualCertificateComponent,
    AddEditFirmAdministratorComponent,
    AddEditFirmStaffComponent,
    AddEditFirmCertificateComponent,
    AddEditFirmCredentialComponent,
    AtpComponent,
    AddEditAtpComponent,
    AddEditAtpStaffComponent,           
    AddEditAtpCourseComponent,
    AddEditAtpAccreditationComponent,
    AtpCourseStudentUploadComponent,
    AddEditCourseStudentComponent,
    ComplaintComponent,
    AddEditComplaintComponent, 
    AddEditAssociationComponent,
    AddEditOwnerTenantComponent,
    AddEditInspectionComponent,
    AddEditActionComponent,
    AddEditAtpAuditComponent,
    AddEditAuditActionComponent,
    AddEditIndividualTestingComponent, 
    AddEditReferralComponent   ,
    AddEditIndividualTestingComponent,
    AtpStudentComponent,
    AddEditAtpNoteComponent,
    AddEditAtpDeficiencyComponent,  
    PaymentDashboardComponent, AddEditFirmAddressComponent, AddEditFirmContactInformationComponent, AddEditFirmContactPersonComponent ,  
    PrintComplaintComponent, ConfirmationDialogueWithFieldComponent, PrintCertificateComponent, PrintCertificateModalComponent, AddDocumentComponent, PrintFirmCertificateComponent, PrintFirmCertificateModalComponent, MergeIndividualComponent, AtpCourseComponent, MergeFirmComponent, SelectListDialogueComponent, MatchStudentUploadsToExistingComponent
  ],
  imports: [
    BrowserModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    RoutesModule,
    SharedModule,
    MsalModule,
    PaymentsModule,
    FormlyConfigModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },  
  { provide: MAT_DATE_FORMATS, useValue: DateFormats },  
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: BASE_URL, useValue: environment.baseUrl },
    httpInterceptorProviders,
    appInitializerProviders,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
