<div fxLayout="column" [ngStyle]="{'--entity-color' : uiData.atp.color}">
    <mat-card [style.background-color]="uiData.atp.color">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>
                {{auditData.id !== 0 ? 'Edit Audit ' + auditData.id + ': ' + auditData.auditType : 'Add New Audit'}}
            </mat-card-title>

            <div class="download-container" *ngIf="commonService.downloadCount > 0">
                <div>Pending: {{ commonService.downloadCount }}</div>
                <mat-icon class="download-icon">download</mat-icon>
                <div class="spinner-container">
                    <mat-progress-spinner
                        *ngIf="commonService.progress < 100"
                        diameter="40"
                        mode="determinate"
                        [value]="commonService.progress">
                    </mat-progress-spinner>
                    <div class="progress-text">{{ commonService.progress }}%</div>
                </div>    
            </div>
        </div>
    </mat-card>
    <div class="scroll-container">
        <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
            <mat-tab>
                <ng-template mat-tab-label>                
                    Audit Information
                </ng-template>
                <ng-container>                    
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                            <mat-card-content> 
                                <form [formGroup]="auditDataForm" fxLayout="column">
                                <div fxLayout="column" fxFlex="100">                                                 
                                    <span><strong>Audit Date</strong></span>                           
                                    <mat-form-field>
                                        <input matInput [matDatepicker]="auditDatePicker" placeholder="Audit Date" formControlName="auditDate" (focus)="auditDatePicker.open()">
                                        <mat-datepicker-toggle matSuffix [for]="auditDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #auditDatePicker></mat-datepicker>
                                    </mat-form-field>                                                                      
                                                                
                                    <span><strong>Audit Organization</strong></span>                           
                                    <mat-form-field>
                                        <mat-label>Audit Organization</mat-label>
                                        <mat-select  formControlName="auditByOrganization">   
                                            <mat-option *ngFor="let status of getAuditOrganizations()" [value]="status">{{ status }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
        
                                    <span><strong>Audit Type</strong></span>
                                    <mat-form-field>
                                        <mat-label>Audit Type</mat-label>                                
                                        <mat-select formControlName="auditType">   
                                            <mat-option *ngFor="let status of getAuditTypes()" [value]="status">{{ status }}</mat-option>                                                                                                                                         
                                        </mat-select>
                                    </mat-form-field>        
        
                                    <span><strong>Auditor</strong></span>
                                    <mat-form-field>
                                        <input matInput placeholder="Auditor"  formControlName="auditor">
                                    </mat-form-field>
                                    
                                    <span><strong>Location Type</strong></span>
                                    <mat-form-field>
                                        <mat-label>Location Type</mat-label>                                    
                                        <mat-select formControlName="auditLocationType">   
                                            <mat-option *ngFor="let status of getAuditLocationTypes()" [value]="status">{{ status }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>         
                                </form>           
                            </mat-card-content>
                        </mat-card>
                        &nbsp;&nbsp;
                        <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                            <mat-card-content>    
                                <div fxLayout="column" fxFlex="100">
                                    <span><strong>Audited Course</strong></span> 
                                    <mat-form-field>
                                        <mat-label>Audited Course</mat-label>
                                        <input matInput type="text" placeholder="Audited Course" [(ngModel)]="selectedCourse" [matAutocomplete]="auto" name="selectedPerson" required (input)="filterCourses($event)" (ngModelChange)="onCourseChange()">
                                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                          <mat-option *ngFor="let course of filteredCourseList" [value]="course">{{displayFn(course)}}</mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>

                                    <div *ngIf="this.selectedCourse !== null && this.selectedCourse !== undefined" fxLayout="column">
                                        <div fxFlex fxLayoutGap="10px" fxLayout="row" fxLayout.lt-md="column">
                                            <mat-form-field fxFlex="33" fxFlex.lt-md="100">
                                                <input matInput placeholder="Course Type" [(ngModel)]="selectedCourse.stage" disabled>
                                            </mat-form-field>      

                                            <mat-form-field fxFlex="33" fxFlex.lt-md="100">
                                                <input matInput placeholder="Course Format" [(ngModel)]="selectedCourse.accreditationCourseFormat" disabled>
                                            </mat-form-field>  
                                                                                    
                                            <mat-form-field fxFlex="33" fxFlex.lt-md="100">
                                                <input matInput placeholder="Course Language" [(ngModel)]="selectedCourse.accreditationCourseLanguage" disabled>
                                            </mat-form-field>  
                                        </div>

                                        <mat-form-field>
                                            <input matInput placeholder="Course Instructor" [(ngModel)]="selectedCourse.programInstructor" disabled>
                                        </mat-form-field>                                        

                                        <mat-form-field>
                                            <input matInput placeholder="Training Manager" [(ngModel)]="trainingManager.firstName" disabled>
                                        </mat-form-field>

                                        <span><strong>Course Address</strong></span>
                                        <div fxFlex fxLayoutGap="10px" fxLayout="column" *ngIf="courseAddress !== null && courseAddress !== undefined">
                                            <mat-form-field fxFlex="100">
                                                <input matInput placeholder="Course Street" [(ngModel)]="courseAddress.street" disabled>
                                            </mat-form-field>      

                                            <div fxFlex fxLayoutGap="10px" fxLayout="row" fxLayout.lt-md="column">
                                                <mat-form-field fxFlex="60" fxFlex.lt-md="100">
                                                    <input matInput placeholder="Course City" [(ngModel)]="courseAddress.city" disabled>
                                                </mat-form-field>  
                                                                                        
                                                <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                                    <input matInput placeholder="Course State" [(ngModel)]="courseAddress.state" disabled>
                                                </mat-form-field>  

                                                <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                                    <input matInput placeholder="Course Zip" [(ngModel)]="courseAddress.zip" disabled>
                                                </mat-form-field>  
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>                                  
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <mat-card fxFlex="100" fxFlex.lt-md="100" fxLayout="column">
                            <mat-card-content>    
                                <form [formGroup]="auditDataForm" fxLayout="column">
                                <div fxLayout="column" fxFlex="100">   
                                    <span><strong>Summary and Recommendations</strong></span>                                  
                                    <mat-form-field>
                                        <mat-label>Summary and Recommendations</mat-label>
                                        <textarea matInput placeholder="Enter Text Here" formControlName="auditFindings"></textarea>
                                    </mat-form-field>
                                </div>
                                </form>
                            </mat-card-content>
                        </mat-card>                            
                    </div>                    
                </ng-container>
            </mat-tab>

            <mat-tab *ngIf="this.auditData.id !== 0">
                <ng-template mat-tab-label>                
                    Audit Actions ({{tableAuditActionData.length}})
                </ng-template>
                <ng-container>  
                    <mat-card>
                        <mat-card-content>
                            <div fxLayout="column">
                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                        <div fxLayoutGap="5px">
                                            <strong class="noMarginHeader">Results ({{tableAuditActionData.length}})</strong>                                             
                                            <mat-form-field style="width:200px;">
                                                <input (keyup)="filterAuditActionTable()" placeholder="Filter Results" [(ngModel)]="filterAuditActionString" matInput/>                            
                                            </mat-form-field>  
                                            
                                            <mat-form-field style="width:100px">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="startAuditActionPicker" placeholder="Date Range Start" [(ngModel)]="filterAuditActionStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterAuditActionTable()" (focus)="startAuditActionPicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="startAuditActionPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startAuditActionPicker></mat-datepicker>
                                            </mat-form-field>

                                            <mat-form-field style="width:100px">
                                                <mat-label>End Date</mat-label>
                                                <input matInput [matDatepicker]="endAuditActionPicker" placeholder="Date Range End" [(ngModel)]="filterAuditActionEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterAuditActionTable()" (focus)="endAuditActionPicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="endAuditActionPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endAuditActionPicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>                                     
                                        <div>                        
                                            <button mat-raised-button color="primary" (click)="editAuditAction(null)">
                                                <mat-icon>add</mat-icon> Add Action
                                            </button>                                            
                                        </div>
                                    </div>
                
                                    <br />

                                    <mat-table [dataSource]="tableAuditActionDataSource" matSort #AuditActionTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="tableAuditActionColumns"></mat-header-row>                  
                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: tableAuditActionColumns" (click)="editAuditAction(row); $event.stopPropagation()"></mat-row>                                      
                                                            
                                        <ng-container *ngFor="let cellData of tableAuditActionFormat; let i = index" [matColumnDef]="cellData.columnName">
                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                <ng-container [ngSwitch]="cellData.type">
                                                    <ng-container *ngSwitchCase="'date'">
                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'bool'">
                                                        <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                    </ng-container>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container> 

                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef fxFlex="10">Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let row" fxFlex="10">
                                                <div style="display: flex; justify-content: space-between; width: 100px;">
                                                    <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteAuditAction(row); $event.stopPropagation()">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                    </mat-table>
                                                    
                                    <br />
                                    <mat-paginator [pageSize]="tableAuditActionPageSize" [pageSizeOptions]="[5, 10, 50, tableAuditActionData.length]" #AuditActionTablePaginator="matPaginator"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>                        
                </ng-container>
            </mat-tab>
            <mat-tab *ngIf="this.auditData.id !== 0">
                <ng-template mat-tab-label>                
                    Audit Documents ({{tableAuditDocumentsData.length}})
                </ng-template>
                <ng-container>  
                    <mat-card>
                        <mat-card-content>
                            <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div fxLayoutGap="5px">
                                        <strong class="noMarginHeader">Results ({{tableAuditDocumentsData.length}})</strong> 
                                        
                                        <mat-form-field style="width:200px;">
                                            <input (keyup)="filterDocumentTable()" placeholder="Filter Results" [(ngModel)]="filterAuditDocumentString" matInput/>                            
                                        </mat-form-field>      
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editDocument(null)">
                                            <mat-icon>add</mat-icon> Add Document
                                        </button>                                        
                                    </div>
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="auditDocumentDataSource" matSort #AuditDocumentTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="tableAuditDocumentsColumns"></mat-header-row>                  
                                    <mat-row (click)="editDocument(row)" fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: tableAuditDocumentsColumns" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of tableAuditDocumentsFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], '80') }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="20">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="20">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="primary" style="width: 50px;" (click)="downloadFile(row); $event.stopPropagation()">
                                                    <mat-icon>save_alt</mat-icon>
                                                </button>
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="clearFile(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="tableAuditDocumentsPageSize" [pageSizeOptions]="[5, 10, 50, tableAuditDocumentsData.length]" #AuditDocumentTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        </mat-card-content>
                    </mat-card>
                </ng-container>
            </mat-tab>
        </mat-tab-group>        
    </div>
    <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" [disabled]="disableAddButton()" (click)="savePrompt()">
            {{auditData.id !== 0 ? 'Save' : 'Add'}}
        </button>        
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>
