<div *ngIf="loading">
  <div id="globalLoader" class="global-loader" [ngStyle]="{ 'background-color': sharedService.darkMode ? '#333' : '#fff' }">
        <h1>Loading</h1>
    </div>
</div>

<div fxLayout="column">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <mat-card [style.background-color]="uiData.atp.color" fxFlex="100">
                <h2 class="noMarginHeader">
                    <mat-icon>school</mat-icon> {{'Accredited Training Provider Management'}}
                </h2>
            </mat-card>
        </div>
    </div>

    <mat-card>
        <div [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
          <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between start">
            <div fxLayoutGap="5px">
                <strong class="noMarginHeader">Results ({{filteredTrainingProviders.length}})</strong>
            
                <mat-form-field style="width:200px;">
                    <input (keydown.enter)="filterTable()" placeholder="Filter id or name" [(ngModel)]="filterString" matInput/>                    
                </mat-form-field>                                
                <mat-form-field style="width:200px">
                    <mat-select placeholder="Filter by Status" [formControl]="filteredActive">
                        <mat-option [value]="null">-- Select --</mat-option>
                        <mat-option *ngFor="let status of getFirmActiveTypes()" [value]="status">
                            {{ status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>                        
                <mat-form-field style="width:180px">
                    <mat-select placeholder="Filter by Type" [formControl]="filterDiscipline">                      
                      <mat-option [value]="null">-- Select --</mat-option>
                      <mat-option *ngFor="let status of getDisciplineTypes()" [value]="status">
                        {{ status }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> 
                <mat-form-field>                                                                
                    <input matInput [matDatepicker]="expirationStartPicker" placeholder="Start Date Range" [formControl]="expirationStartDate" (keydown.enter)="filterTable()">
                    <mat-datepicker-toggle matSuffix [for]="expirationStartPicker"></mat-datepicker-toggle>
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationStartDate.reset()">
                        <mat-icon style="color: red;">close</mat-icon>
                      </button>  
                    <mat-datepicker #expirationStartPicker></mat-datepicker>
                </mat-form-field>                   
                <mat-form-field>                                                                
                    <input matInput [matDatepicker]="expirationEndPicker" placeholder="End Date Range" [formControl]="expirationEndDate" (keydown.enter)="filterTable()">
                    <mat-datepicker-toggle matSuffix [for]="expirationEndPicker"></mat-datepicker-toggle>
                    <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationEndDate.reset()">
                        <mat-icon style="color: red;">close</mat-icon>
                      </button>  
                    <mat-datepicker #expirationEndPicker></mat-datepicker>
                </mat-form-field> 
            </div>
                                    
            <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxFlex>
                <button mat-raised-button color="primary" (click)="filterTable()">Search</button>                
                <button mat-raised-button (click)="clearFilters()">Reset Filters</button>
                <button mat-raised-button color="primary" title="Add Individual Application" (click)="editATP(null)">
                  <mat-icon>add</mat-icon> Add Accredited Training Provider
                </button>   
                <div fxFlex="1 1 auto"></div>
                <button (click)="exportToExcel()" mat-raised-button color="primary"><mat-icon style="transform: rotate(270deg);">save_alt</mat-icon> Export</button>             
            </div>
        </div>
            <br />

            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of individualApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                  <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size">{{cellData.displayName}}</mat-header-cell>
                  <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [matTooltip]="isObjectOrArray(row[cellData.columnName]) ? '' : row[cellData.columnName]">
                    <ng-container *ngIf="cellData.type === 'subtable'">
                        <mat-table [dataSource]="row.atpAccreditations" matSort #NestedTableSort="matSort" fxFlex="100%">
                          
                          <ng-container *ngFor="let subCellData of subtableDisplayData; let j = index" [matColumnDef]="subCellData.columnName">
                            
                            <!-- Conditionally change the header name -->
                            <mat-header-cell *matHeaderCellDef [fxFlex]="subCellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                              {{ useReceivedDate() ? 'Received Date' : subCellData.displayName }}
                            </mat-header-cell>
                            
                            <mat-cell *matCellDef="let nestedRow" [fxFlex]="subCellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                              <ng-container *ngIf="subCellData.type === 'date'">
                                {{nestedRow[subCellData.columnName] | date:'MM/dd/yyyy'}}
                              </ng-container>
                              <ng-container *ngIf="subCellData.type !== 'date'">
                                {{nestedRow[subCellData.columnName]}}
                              </ng-container>
                            </mat-cell>
                          </ng-container>
                      
                          <!-- Actions column -->
                          <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                            <mat-cell *matCellDef="let nestedRow" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                              <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                <span><strong>Action: </strong></span>
                                <button mat-icon-button color="primary" (click)="editAccreditation(nestedRow); $event.stopPropagation()">
                                  <mat-icon>{{'edit'}}</mat-icon>
                                </button>
                              </div>
                              <div *ngIf="!isMobile">
                                <button mat-icon-button color="primary" (click)="editAccreditation(nestedRow); $event.stopPropagation()">
                                  <mat-icon>{{'edit'}}</mat-icon>
                                </button>
                              </div>
                            </mat-cell>
                          </ng-container>
                          
                          <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="subtableColumns"></mat-header-row>
                          <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editAccreditation(nestedRow); $event.stopPropagation()" *matRowDef="let nestedRow; index as i; columns: subtableColumns"></mat-row>
                        </mat-table>
                      </ng-container>
                      
                    <ng-container *ngIf="cellData.type === 'date'">
                      {{row[cellData.columnName] | date:'MM/dd/yyyy'}}
                    </ng-container>
                    <ng-container *ngIf="cellData.type === 'boolean'">
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean' && cellData.type !== 'subtable'">
                      {{row[cellData.columnName]}}
                    </ng-container>
                  </mat-cell>
                </ng-container>

                <!-- Column definitions -->
                <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="individualApplicationTable.layout.columns"></mat-header-row>
                <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="handleStudentRowClick(row, $event); $event.stopPropagation()" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: individualApplicationTable.layout.columns"></mat-row>
              </mat-table>
              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
