import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { environment } from '@env/environment';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public environment = environment;
  title: string = "Healthy Environments Portal Administration";

  constructor(
    public sharedService: SharedService,
    private router: Router,
    public authService: AuthService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  public get authorized(): boolean{
    return this.authService.isAuthorized();
  }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()){
      this.router.navigateByUrl('/dashboard');
    }
    else{
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe(() => {
        this.router.navigateByUrl('/dashboard');
      });
    }
  }

  login(): void {
    this.authService.login();
  }

  
}
