import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IFirmApplication } from 'app/models/firms/firm-application';
import { SharedService } from 'app/services/core/shared.service';
import { AddFirmApplicationComponent } from './add-firm-application/add-firm-application.component';
import { FirmService } from 'app/services/firm/firm-service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { APPLICATION_STATUS, FIRM_APPLICATION_TYPES, LEAD_BASED_PAINT, getStatusColor } from '@shared/utils/app-static-data';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-firm-application-list',
  templateUrl: './firm-application-list.component.html',
  styleUrls: ['./firm-application-list.component.scss']
})
export class FirmApplicationListComponent implements OnInit {
  public isMobile: boolean = false;
  public statusColor = getStatusColor;
  public appStatus = APPLICATION_STATUS;

  public filteredApplications = [];  
  public filterString: string = '';
  public selectedStatus = new FormControl([]);
  public selectedType = new FormControl([]);
  public applicationDateFilterStart = new FormControl("");
  public applicationDateFilterEnd = new FormControl("");
  public selectedRenewal = new FormControl("");
  public dataSource: MatTableDataSource<any>;

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  loading: boolean = true;
  pageSize: number = 50;  
  uiData = LEAD_BASED_PAINT;  
  firmApplicationTable = {
    icon: 'business',
    title:'Firm Applications',
    layout:{
      columns:['id','firmName', 'status', 'applicationType', 'applicationDate', 'lastUpdatedBy', 'lastUpdated','approverEmail','approvalDate'],
      container:[
        {displayName:'Application Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Type',columnName:'applicationType', type:'string', size:'5'},
        {displayName:'Firm Name',columnName:'firmName', type:'string', size:'15'},
        {displayName:'Status',columnName:'status', type:'string', size:'10'},
        {displayName:'Application Date',columnName:'applicationDate', type:'date', size:'8'},
        {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'15'},
        {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'8'},
        {displayName:'Approver Email',columnName:'approverEmail', type:'string', size:'15'},
        {displayName:'Approval Date',columnName:'approvalDate', type:'date', size:'8'},          
      ],
      data: []      
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public firmService: FirmService,
    public commonService: CommonDataService,
    public dialog: MatDialog,
    ) 
    {
      this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      });
    }

  ngOnInit(): void {
    this.loadData()
  }

  loadData(){    
    this.filterString = this.commonService.firmApplicationFilter.filterString;
    this.selectedStatus = this.commonService.firmApplicationFilter.selectedStatus;
    this.selectedType = this.commonService.firmApplicationFilter.selectedType;
    this.applicationDateFilterStart.setValue(this.commonService.firmApplicationFilter.applicationDateFilterStart === null ? null : new Date(this.commonService.firmApplicationFilter.applicationDateFilterStart).toISOString().substring(0,10));
    this.applicationDateFilterEnd.setValue(this.commonService.firmApplicationFilter.applicationDateFilterEnd === null ? null : new Date(this.commonService.firmApplicationFilter.applicationDateFilterEnd).toISOString().substring(0,10));
    this.selectedRenewal = this.commonService.firmApplicationFilter.selectedRenewal;
    
    this.firmService.getAllFirmApplications().subscribe(
      res=>{        
        this.firmApplicationTable.layout.data = res;
        this.filteredApplications = res;        
        this.filterTable();
        this.loading = false;
      },
      err=>{}
    )
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredApplications);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;    
  }

  openApplication(application: IFirmApplication, edit: boolean){
      const dialogRef = this.dialog.open(AddFirmApplicationComponent, {
        width: '90%',
        data: {application,edit,firmData:{}},
        autoFocus: false,
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loadData();        
      });    
  }

  getStatusTypeValues() {    
    return Object.values(APPLICATION_STATUS);
  }
  
  getApplicationTypeValues() {
    return Object.values(FIRM_APPLICATION_TYPES);
  }

  getApplicationFilterValues() {
    return [null, 'Expiring', 'Expired', 'Near Outstanding', 'Outstanding'];
  }

  filterTable() {
    this.commonService.firmApplicationFilter.selectedStatus = this.selectedStatus;
    this.commonService.firmApplicationFilter.selectedType = this.selectedType;
    this.commonService.firmApplicationFilter.filterString = this.filterString;    
    this.commonService.firmApplicationFilter.applicationDateFilterStart = this.applicationDateFilterStart.value === null ? null : new Date(this.applicationDateFilterStart.value);    
    this.commonService.firmApplicationFilter.applicationDateFilterEnd = this.applicationDateFilterEnd.value === null ? null :  new Date(this.applicationDateFilterEnd.value);
    this.commonService.firmApplicationFilter.selectedRenewal = this.selectedRenewal;
    
    this.filteredApplications = this.firmApplicationTable.layout.data.filter(x=>(x.firmName + ' ' + x.id + ' ').toLowerCase().includes(this.filterString.trim().toLowerCase()));    
    if (this.selectedStatus.value.length > 0){
      this.filteredApplications = this.filteredApplications.filter(x=>this.selectedStatus.value.includes(x.status));
    }  
    if (this.selectedType.value.length > 0){
      this.filteredApplications = this.filteredApplications.filter(x=>this.selectedType.value.includes(x.applicationType));
    } 
    if (this.applicationDateFilterStart.value){
      this.filteredApplications = this.filteredApplications.filter(x => {
        let applicationDate = new Date(x.applicationDate);
        applicationDate.setHours(0, 0, 0, 0);
    
        let filterStartDate = new Date(this.applicationDateFilterStart.value);
        filterStartDate.setHours(0, 0, 0, 0);
    
        return applicationDate >= filterStartDate;
      });      
    }
    if (this.applicationDateFilterEnd.value){
      this.filteredApplications = this.filteredApplications.filter(x => {
        let applicationDate = new Date(x.applicationDate);
        applicationDate.setHours(0, 0, 0, 0);
    
        let filterEndDate = new Date(this.applicationDateFilterEnd.value);
        filterEndDate.setHours(0, 0, 0, 0);
    
        return applicationDate <= filterEndDate;
    });      
    } 
    if (this.selectedRenewal.value !== ''){
      if(this.selectedRenewal.value == 'Near Outstanding'){
        this.filteredApplications = this.filteredApplications.filter(
          x=>x.status !== 'Approved' && 
          x.status !== 'Cancelled' && 
          (x.applicationViableDate !== null && x.applicationViableDate !== '') && 
          (new Date().getTime() - new Date(x.applicationViableDate).getTime()) / (1000 * 60 * 60 * 24) <= 30 && 
          (new Date().getTime() - new Date(x.applicationViableDate).getTime()) / (1000 * 60 * 60 * 24) >= 20);
      }
      else if(this.selectedRenewal.value == 'Expired'){
        this.filteredApplications = this.filteredApplications.filter(x => {
          let currentDate = new Date();
          let applicationDate = new Date(x.applicationDate);
          let diffInTime = currentDate.getTime() - applicationDate.getTime();
          let diffInDays = diffInTime / (1000 * 3600 * 24);      
          return diffInDays > 90 && x.status !== 'Approved' && x.status !== 'Cancelled';
        });
      }      
      else if(this.selectedRenewal.value == 'Expiring'){        
        this.filteredApplications = this.filteredApplications.filter(x => {
          let currentDate = new Date();
          let applicationDate = new Date(x.applicationDate);
          let diffInTime = currentDate.getTime() - applicationDate.getTime();
          let diffInDays = diffInTime / (1000 * 3600 * 24);       
          return diffInDays <= 90 && diffInDays >= 60 && x.status !== 'Approved' && x.status !== 'Cancelled';
      });
      } else if(this.selectedRenewal.value == 'Outstanding'){             
        this.filteredApplications = this.filteredApplications.filter(x => {
          return x.status !== 'Approved' && 
          x.status !== 'Cancelled' && 
          (x.applicationViableDate !== null && x.applicationViableDate !== '') &&           
          (new Date().getTime() - new Date(x.applicationViableDate).getTime()) / (1000 * 60 * 60 * 24) > 30;          
        });
      }
    }   
    this.updateDataSource();  
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, parseInt(limit)) + "...";
    }    
    return newText;
  }

  clearFilters(){
    this.filterString = '';
    this.selectedStatus.setValue(['In Progress','Submitted','In Review','Action Needed']);
    this.selectedType.setValue(['rrp','lbpa']);
    this.selectedRenewal.setValue('');
    this.applicationDateFilterStart.setValue(null);
    this.applicationDateFilterEnd.setValue(null);
    this.filterTable();
  }
}
