import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from 'app/services/core/shared.service';
import { AddIndividualApplicationComponent } from './add-individual-application/add-individual-application.component';
import { PersonService } from 'app/services/person/person.service';
import { APPLICATION_STATUS, getStatusColor, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IPersonApplication } from 'app/models/People/person-application';
import { FormControl } from '@angular/forms';
import { CommonDataService } from 'app/services/common/common-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-individual-application-list',
  templateUrl: './individual-application-list.component.html',
  styleUrls: ['./individual-application-list.component.scss']
})
export class IndividualApplicationListComponent implements OnInit {
  public isMobile: boolean = false;
  public loading: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public appStatus = APPLICATION_STATUS;
  public pageSize: number = 50;  
  public dataSource: MatTableDataSource<any>;
  public filteredApplications = [];  
  public filterString: string = '';
  public selectedStatus = new FormControl([]);  
  public applicationDateFilterStart = new FormControl("");
  public applicationDateFilterEnd = new FormControl("");
  public selectedRenewal = new FormControl("");

  
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['id', 'firstName', 'lastName', 'status', 'applicationDate', 'lastUpdatedBy', 'lastUpdated','approverEmail','approvalDate'],
      container:[
        {displayName:'Application Id',columnName:'id', type:'string', size:'10'},                
        {displayName:'First Name',columnName:'firstName', type:'string', size:'10'},
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'15'},
        {displayName:'Status',columnName:'status', type:'string', size:'10'},
        {displayName:'Application Date',columnName:'applicationDate', type:'date', size:'10'},
        {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'10'},
        {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'10'},
        {displayName:'Approver Email',columnName:'approverEmail', type:'string', size:'10'},
        {displayName:'Approval Date',columnName:'approvalDate', type:'date', size:'10'},          
      ],
      data: []      
    }
  }
  public statusColor = getStatusColor;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public commonService: CommonDataService,
    public personService: PersonService,
    public dialog: MatDialog) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){
    this.filterString = this.commonService.individualApplicationFilter.filterString;
    this.selectedStatus = this.commonService.individualApplicationFilter.selectedStatus;    
    this.applicationDateFilterStart.setValue(this.commonService.individualApplicationFilter.applicationDateFilterStart === null ? null : new Date(this.commonService.individualApplicationFilter.applicationDateFilterStart).toISOString().substring(0,10));
    this.applicationDateFilterEnd.setValue(this.commonService.individualApplicationFilter.applicationDateFilterEnd === null ? null : new Date(this.commonService.individualApplicationFilter.applicationDateFilterEnd).toISOString().substring(0,10));
    this.selectedRenewal = this.commonService.individualApplicationFilter.selectedRenewal;

    this.loading = true;
    this.personService.getAllPersonApplications().subscribe(
      res=>{                        
        this.individualApplicationTable.layout.data = res;
        this.filteredApplications = res;
        this.filterTable();
        this.loading = false;
      },
      err=>{}
    )
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredApplications);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getStatusTypeValues() {    
    return Object.values(APPLICATION_STATUS);
  }

  filterTable() {
    this.commonService.individualApplicationFilter.selectedStatus = this.selectedStatus;    
    this.commonService.individualApplicationFilter.filterString = this.filterString;    
    this.commonService.individualApplicationFilter.applicationDateFilterStart = this.applicationDateFilterStart.value === null ? null : new Date(this.applicationDateFilterStart.value);
    this.commonService.individualApplicationFilter.applicationDateFilterEnd = this.applicationDateFilterEnd.value === null ? null : new Date(this.applicationDateFilterEnd.value);
    this.commonService.individualApplicationFilter.selectedRenewal = this.selectedRenewal;

    this.filteredApplications = this.individualApplicationTable.layout.data.filter(x=>(x.userID + ' ' + x.id + ' ' + x.firstName + ' ' + x.lastName).toLowerCase().includes(this.filterString.trim().toLowerCase()));    
    if (this.selectedStatus.value.length > 0){
      this.filteredApplications = this.filteredApplications.filter(x=>this.selectedStatus.value.includes(x.status));
    }  
    if (this.applicationDateFilterStart.value){
      this.filteredApplications = this.filteredApplications.filter(x => {
        let applicationDate = new Date(x.applicationDate);
        applicationDate.setHours(0, 0, 0, 0);
    
        let filterStartDate = new Date(this.applicationDateFilterStart.value);
        filterStartDate.setHours(0, 0, 0, 0);
    
        return applicationDate >= filterStartDate;
      });      
    }
    if (this.applicationDateFilterEnd.value){
      this.filteredApplications = this.filteredApplications.filter(x => {
        let applicationDate = new Date(x.applicationDate);
        applicationDate.setHours(0, 0, 0, 0);
    
        let filterEndDate = new Date(this.applicationDateFilterEnd.value);
        filterEndDate.setHours(0, 0, 0, 0);
    
        return applicationDate <= filterEndDate;
    });      
    } 
    if (this.selectedRenewal.value !== ''){
      if(this.selectedRenewal.value == 'Renewal'){
        this.filteredApplications = this.filteredApplications.filter(x=>x.renewal);
      }
      else if(this.selectedRenewal.value == 'Initial'){
        this.filteredApplications = this.filteredApplications.filter(x=>!x.renewal);
      }      
      else if(this.selectedRenewal.value == 'Expiring'){        
        this.filteredApplications = this.filteredApplications.filter(x => {
          let currentDate = new Date();
          let applicationDate = new Date(x.applicationDate);
          let diffInTime = currentDate.getTime() - applicationDate.getTime();
          let diffInDays = diffInTime / (1000 * 3600 * 24);
          let thresholdDate = new Date(this.applicationDateFilterEnd.value);
          let diffInThresholdTime = thresholdDate.getTime() - applicationDate.getTime();
          let diffinThresholdDays = diffInThresholdTime / (1000 * 3600 * 24);
          diffInDays += diffinThresholdDays;          
          return diffInDays >= 90;
      });
      } else if(this.selectedRenewal.value == 'Outstanding'){        
        this.filteredApplications = this.filteredApplications.filter(x => {
          return x.status !== 'Approved' && x.status !== 'Cancelled' && (x.applicationViableDate !== null && x.applicationViableDate !== '') && (x.approvalDate === null || x.approvalDate === '') && (new Date().getTime() - new Date(x.applicationViableDate).getTime()) / (1000 * 60 * 60 * 24) > 30;
        });
      }
    }   
    this.updateDataSource();  
  }

  editIndividualApplication(application: IPersonApplication){    
    const dialogRef = this.dialog.open(AddIndividualApplicationComponent, {
      width: '90%',
      data: {application: application, edit: true},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.loadData();
      }      
    });
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  getApplicationFilterValues() {
    return [null,'Renewal', 'Initial', 'Expiring', 'Outstanding'];
  }

  clearFilters(){
    this.filterString = '';
    this.selectedStatus.setValue(['In Progress','Submitted','In Review','Action Needed']);    
    this.selectedRenewal.setValue('');
    this.applicationDateFilterStart.setValue(new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substring(0, 10));
    this.applicationDateFilterEnd.setValue(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substring(0, 10));
  
    this.filterTable();
  }
}
