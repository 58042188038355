import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { MergeIndividualComponent } from 'app/components/individual/merge-individual/merge-individual.component';
import { IFirm } from 'app/models/firms/firm';
import { IFirmMerge } from 'app/models/firms/firmMerge';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-merge-firm',
  templateUrl: './merge-firm.component.html',
  styleUrls: ['./merge-firm.component.scss']
})
export class MergeFirmComponent implements AfterViewInit{
  public uiData = LEAD_BASED_PAINT;
  public isMobile: boolean = false;
  public loading: boolean = false;
  public merging: boolean = false;  

  public selectedFirm: IFirm = null;
  public pageSize: number = 5;  
  public dataSource: MatTableDataSource<any>;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;  
  individualApplicationTable = {
    layout:{
      columns:['select', 'id', 'inactive', 'name', 'ccb', 'ccbExpirationDate','certificationNumber'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'10'},
        {displayName:'Firm Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'10'},
        {displayName:'Firm Name',columnName:'name', type:'string', size:'25'},        
        {displayName:'CCB/PML',columnName:'ccb', type:'string', size:'15'},          
        {displayName:'CCB Expiration Date',columnName:'ccbExpirationDate', type:'date', size:'15'},     
        {displayName:'Certification Number',columnName:'certificationNumber', type:'string', size:'15'},          
      ]      
    }
  };

  constructor(private firmService: FirmService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: IFirm[],
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MergeIndividualComponent>,
  ) {     
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'select':
          return this.isSelected(item) ? 0 : 1;
        default:
          return item[property];
      }
    };
  }

  merge(){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to merge <strong>' + (this.data.length - 1) 
        + '</strong> Firms records into the selected Firm <strong>' 
        + this.selectedFirm.id + ':' + this.selectedFirm.name
        + '</strong>?',title: 'Merge Firms'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.merging = true; 
        let firmMerge:IFirmMerge = {
          targetFirmId: this.selectedFirm.id,
          sourceFirmIds: this.data.filter(x=>x.id !== this.selectedFirm.id).map(x => x.id)
        }
        this.firmService.mergeFirm(firmMerge).subscribe(result => {          
          this.toastr.success(this.data.length - 1 + ' Firms merged successfully');
          this.dialogRef.close(true);
        }, error => {
          this.toastr.error('An error occurred while merging Firms', error);          
          this.merging = false;
        });        
      }
    });
  }

  onChangeSelectedPerson(individual: IFirm) {
    this.selectedFirm = individual;    
  }

  isSelected(person: IFirm): boolean {
    return this.selectedFirm.id === person.id;
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}
