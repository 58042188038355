import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IPerson } from 'app/models/People/person';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { AddEditIndividualComponent } from './add-edit-individual/add-edit-individual.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { CommonDataService } from 'app/services/common/common-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { MergeIndividualComponent } from './merge-individual/merge-individual.component';
import { LEAD_BASED_PAINT, STUDENT_CODE_TYPES } from '@shared/utils/app-static-data';
import { AddEditIndividualCertificateComponent } from './add-edit-individual/add-edit-individual-certificate/add-edit-individual-certificate.component';
import { IPersonCertificate } from 'app/models/People/person-certificate';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss']
})
export class IndividualComponent {
  public isMobile: boolean = false;
  public loading: boolean = true;
  public uiData = LEAD_BASED_PAINT;
  public pageSize: number = 50;  
  public individuals: any[] = [];
  public dataSource: MatTableDataSource<any>;
  public filterString: string = '';
  public filteredIndividuals: IPerson[] = [];
  public filteredActive = new FormControl('Active');
  public filterType = new FormControl([]);  
  public filterDiscipline = new FormControl(null);
  public expirationStartDate = new FormControl(new Date());
  public expirationEndDate = new FormControl(new Date());
  public firmStaff: IPerson[] = [];
  public selectedIndividuals: IPerson[] = [];
  public showCourses: boolean = false;

  public personFilter: IPersonFilter = {
    filterString: '', 
    filterStatus: '', 
    filterAffiliation: [], 
    expirationStartDate: '',
    expirationEndDate: '',
    filterDiscipline: ['']
  };

  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  individualApplicationTable = {
    icon: 'recent_actors',
    title:'Individual Applications',
    layout:{
      columns:['select', 'id', 'inactive', 'firstName', 'lastName', 'affiliations', 'certificates'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'5'},
        {displayName:'Person Id',columnName:'id', type:'string', size:'8'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'7'},
        {displayName:'First Name',columnName:'firstName', type:'string', size:'15'},        
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'15'},                  
        {displayName:'Affiliations',columnName:'affiliations', type:'string', size:'13'},
        {displayName:'Certificates',columnName:'certificates', type:'subtable', size:'37'},              
      ],
      data: []      
    }
  }

  subtableColumns = ['codeType', 'expirationDate', 'actions'];
  subtableDisplayData = [
    {displayName:'Discipline',columnName:'codeType', type:'string', size:'56'},
    {displayName:'Expiration',columnName:'expirationDate', type:'date', size:'30'},            
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public commonService: CommonDataService,
    public personService: PersonService,
    public firmService: FirmService,
    public toastr: ToastrService) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.loadIndividuals();
  }

  exportToExcel() {
    const exportData = [];
      
    this.filteredIndividuals.forEach(person => {
      person.certificates.forEach(cert => {        
        const row = {
          LastName: person.lastName,          
          FirstName: person.firstName,        
          MiddleName: person.middleName,      
          Certification: cert.codeType,       
          Expiration: cert.expirationDate?.split('T')[0],
        };          
        exportData.push(row);
      });
    });
      
    exportData.sort((a, b) => a.LastName.localeCompare(b.LastName));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      
    ws['!cols'] = [
      { wpx: 150 },  // LastName width in pixels
      { wpx: 150 },  // FirstName width in pixels
      { wpx: 80 },  // MiddleName width in pixels
      { wpx: 150 },  // Certification width in pixels
      { wpx: 80 },  // Expiration width in pixels
    ];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.filterString !== '' ? this.filterString.replace(/\s+/g, '') + '.xlsx' : 'exportedPersonCertificates.xlsx');
  }
  
  loadIndividuals(): void {
    this.filterString = this.commonService.individualManagementFilter.filterString;
    this.filteredActive = this.commonService.individualManagementFilter.filteredActive;
    this.filterType = this.commonService.individualManagementFilter.filterStatus;
    this.expirationStartDate = this.commonService.individualManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.individualManagementFilter.expirationEndDate;
    this.filterDiscipline = this.commonService.individualManagementFilter.filterDiscipline;
    this.showCourses = this.commonService.individualManagementFilter.showCourses;
    this.filterTable();
  }
  
  updateFilteredAffiliation(){
    this.commonService.individualManagementFilter.showCourses = this.showCourses;
  }

  displayAffiliation(affiliation: string){    
    var displayName = affiliation.split(':')[1];
    if(affiliation.split(':')[0].includes('CourseId') && !this.showCourses){      
      displayName = '';
    }
    
    return displayName;
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredIndividuals);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;   
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'select':
          return this.isSelected(item) ? 0 : 1;
        default:
          return item[property];
      }
    }; 
  }

  editPerson(person: IPerson) {    
    const dialogRef = this.dialog.open(AddEditIndividualComponent, {
      width: '90%',
      data: {person: person},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }

  editCertificate(certificate: IPersonCertificate, person: IPerson) {   
    let certPerson: IPerson = {
      id: person.id,
      firstName: person.firstName,
      lastName: person.lastName,
      middleName: person.middleName,
      title: person.title,
      inactive: person.inactive,
      ssn: person.ssn,
      dateOfBirth: person.dateOfBirth,
      response: person.response,
      certificates: null
    };

    certificate.person = certPerson;
    const dialogRef = this.dialog.open(AddEditIndividualCertificateComponent, {
      width: this.isMobile ?'90%':'30%',
      data: {certificate: certificate},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }

  filterTable() { 
    this.commonService.individualManagementFilter.filterString = this.filterString;
    this.commonService.individualManagementFilter.filteredActive = this.filteredActive;
    this.commonService.individualManagementFilter.filterStatus = this.filterType;
    this.commonService.individualManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.individualManagementFilter.expirationEndDate = this.expirationEndDate;
    this.commonService.individualManagementFilter.filterDiscipline = this.filterDiscipline;
    this.commonService.individualManagementFilter.showCourses = this.showCourses;

    this.personFilter.filterString = this.filterString;
    this.personFilter.filterStatus = this.filteredActive?.value;
    this.personFilter.filterAffiliation = this.filterType?.value ?? [];
    this.personFilter.expirationStartDate = this.expirationStartDate?.value?.toISOString().substring(0,10);
    this.personFilter.expirationEndDate = this.expirationEndDate?.value?.toISOString().substring(0,10);    
    this.personFilter.filterDiscipline = this.filterDiscipline?.value ?? [];

    this.personService.getPeopleFilteredManagement(this.personFilter).subscribe(result=>{                   
      this.individuals = result;
      this.filteredIndividuals = this.individuals;
      this.updateDataSource();
      this.loading = false;      
    },error=>{this.toastr.error('Error loading people filtered', error)});    
  }

  getStatusTypes(){
    return ['Active','InActive'];
  }

  getDisciplineTypes(){
    return Object.values(STUDENT_CODE_TYPES).sort();
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  getFilterTypeValues() {    
    return ['Firm','Student','ATP','No Affiliation'];
  }

  clearFilters(){
    this.filterString = '';
    this.filterType.setValue([]);
    this.filteredActive.setValue('Active');
    this.filterDiscipline.setValue(null);
    this.expirationEndDate.reset();
    this.expirationStartDate.reset();
    this.showCourses = false;
    this.selectedIndividuals = [];
    this.filterTable();
  }

  updateSelected(person: IPerson){
    const index = this.selectedIndividuals.findIndex(individual => individual.id === person.id);
    if (index !== -1){
      this.selectedIndividuals.splice(index, 1);
    } else {
      this.selectedIndividuals.push(person);
    }    
  }
  
  isSelected(person: IPerson): boolean {    
    return this.selectedIndividuals.some(individual => individual.id === person.id);
  }

  mergeIndividuals(){
    const dialogRef = this.dialog.open(MergeIndividualComponent, {
      width: '90%',
      autoFocus: false,
      data: this.selectedIndividuals,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      if(result){
        this.selectedIndividuals = [];
        this.filterTable();
      }
    });
  }

  getMergeTooltip(): string {
    if (this.selectedIndividuals.length > 0) {
        return 'Merge: ' + this.selectedIndividuals.map(individual => `${individual.id}: ${individual.firstName} ${individual.lastName}`).join(', ');
    }
    return 'Merges selected individuals';
  }

  isObjectOrArray(value: any): boolean {
    return value && (Array.isArray(value) || typeof value === 'object');
  }
  
  handlePersonRowClick(row: any, event: any){    
    if(!event.target.className.includes("header")){
      this.editPerson(row);
    }
  }
}
