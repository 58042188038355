export const environment = {
  production: false,
  baseUrl: '',
  useHash: false,
  hmr: false,
  showThemeToggle: true,
  translateText: false,
  collapsibleSidebar: true,
  useTimeout: false,
  idleInterval: 900,
  build: 'Test',
  version: '1.5.0.0',
  theme: 'light',
  displaySidebar: true,
  authEntireSite: false,
  useAdIdentity: false,
  allowAddUsers: false,
  autoRegister: true,
  publicApi: 'https://test-staff-healthy-environments.oregon.gov/api/public/',
  /*
    Azure AD values
    privateApi: endpoint msal should append Bearer tokens to
    clientId: Azure AD application client id
    scopes: Exposed API scope from Azure AD
    groups: Azure AD group guids that are authorized - if empty no groups required
    authority: https://login.microsoftonline.com/<Azure AD application tenant id>/
    redirectUrl: Url to redirect to after login or logout
  */
    privateApi: 'https://test-staff-healthy-environments.oregon.gov/api/private/',
    clientId: '6cdb1906-48ca-479f-977b-3e4f21ef0d36',
    scopes: ['api://6cdb1906-48ca-479f-977b-3e4f21ef0d36/user.access'],
    groups: ['4da35628-4b80-4e36-886d-fa969e5c0461'],
    authority: 'https://login.microsoftonline.com/658e63e8-8d39-499c-8f48-13adc9452f4c/',
    redirectUri: 'https://test-staff-healthy-environments.oregon.gov',
    powerbiLink: "https://app.powerbigov.us/groups/a6dd9a2a-8bf9-4269-a2ba-8d942eae869d/list"
};
