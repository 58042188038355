<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
</section>

<div *ngIf="!sharedService.loading && !loading" [ngStyle]="{'--entity-color' : uiData.individual.color}">
    <mat-card [style.background-color]="uiData.individual.color">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>
                {{ personApplicationData.id + ': ' + personApplicationData.firstName + ' ' + personApplicationData.lastName + ' - ' + personApplicationData.certificateType }}
            </mat-card-title>

            <div class="download-container" *ngIf="commonService.downloadCount > 0">
                <div>Pending: {{ commonService.downloadCount }}</div>
                <mat-icon class="download-icon">download</mat-icon>
                <div class="spinner-container">
                    <mat-progress-spinner
                        *ngIf="commonService.progress < 100"
                        diameter="40"
                        mode="determinate"
                        [value]="commonService.progress">
                    </mat-progress-spinner>
                    <div class="progress-text">{{ commonService.progress }}%</div>
                </div>    
            </div>
        </div>
    </mat-card>
    <div class="scroll-container">
        <form [formGroup]="applicationDataForm">
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxLayout="column" fxFlex="100">

                    <mat-card>
                        <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    Application Information
                                </ng-template>
                                <ng-container>
                                    <mat-card class="custom-mat-card">
                                        <mat-card-content fxLayout="row" fxLayout.lt-md="column">
                                            <mat-card class="custom-mat-card" fxFlex="50">                                                
                                                <mat-card-title>
                                                    <div>
                                                        <span>Application Information</span>
                                                    </div>
                                                </mat-card-title>
                                                <div>
                                                    <span><strong>Application Status</strong></span>
                                                </div>                                                
                                                <mat-card-content>                                                    
                                                    <div fxLayout="column">                                
                                                        <mat-form-field>
                                                            <mat-label>Status</mat-label>
                                                            <mat-select  formControlName="status">   
                                                                <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">{{ status }}</mat-option>                                                            
                                                            </mat-select>
                                                        </mat-form-field> 
                                                    </div>                                                                                   
                                                </mat-card-content>
                                                
                                                <div>                                                        
                                                    <span><strong>Certificate Type</strong></span>
                                                </div>
                                                
                                                <mat-card-content>
                                                    <div fxLayout="column">                                                                  
                                                        <div fxLayout="column">                                
                                                            <mat-form-field>
                                                                <mat-label>Certificate Type</mat-label>
                                                                <mat-select formControlName="certificateType">
                                                                  <mat-option *ngFor="let certificateType of applicationCertificateType" [value]="certificateType">{{ certificateType }}</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>   
                                                        </div>

                                                        <div fxLayout="column">  
                                                            <span><strong>Application Date</strong></span>                                                            
                                                            <mat-form-field>                                                                
                                                                <input matInput [matDatepicker]="applicationDatePicker" placeholder="Application Date" formControlName="applicationDate" (focus)="applicationDatePicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="applicationDatePicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #applicationDatePicker></mat-datepicker>
                                                            </mat-form-field>               
                                                        </div>

                                                        <div fxLayout="column"> 
                                                            <span><strong>Viable Application Date</strong></span>                                                             
                                                            <mat-form-field>                                                                
                                                                <input matInput [matDatepicker]="viableApplicationDatePicker" placeholder="Viable Application Date" formControlName="applicationViableDate" (focus)="viableApplicationDatePicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="viableApplicationDatePicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #viableApplicationDatePicker></mat-datepicker>
                                                            </mat-form-field>               
                                                        </div>

                                                        <div fxLayout="column">   
                                                            <span><strong>Application Approval Date</strong></span>                                                           
                                                            <mat-form-field>                                                                
                                                                <input matInput [matDatepicker]="applicationApprovalDatePicker" placeholder="Application Approval Date" formControlName="approvalDate" (focus)="applicationApprovalDatePicker.open()">
                                                                <mat-datepicker-toggle matSuffix [for]="applicationApprovalDatePicker"></mat-datepicker-toggle>
                                                                <mat-datepicker #applicationApprovalDatePicker></mat-datepicker>
                                                            </mat-form-field>               
                                                        </div>
                                                    </div>                                                                                                                                                                 
                                                </mat-card-content>
                                            </mat-card>
                                            <mat-card class="custom-mat-card" fxFlex="50">
                                                <div fxLayout="column" *ngIf="this.personApplicationData.renewal || this.data?.renewal">                                                                                                        
                                                    <mat-card-title>
                                                        Renewal Certificate Information
                                                    </mat-card-title>
                                                    <span><strong>Control Number</strong></span>
                                                    <mat-form-field>
                                                        <input matInput formControlName="previousApplication" >                                                        
                                                        <mat-placeholder>Affiliated License#</mat-placeholder>
                                                    </mat-form-field>

                                                    <div fxLayout="column">
                                                        <span><strong>Renewal</strong></span>
                                                        <div fxLayout="column">
                                                            <mat-checkbox formControlName="renewal">
                                                                Is a Renewal
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>    
                                                    &nbsp;&nbsp;                                                  
                                                </div>

                                                <form [formGroup]="applicationAttestationForm">
                                                    <mat-card-title>
                                                        Signature and Acknowledgment
                                                    </mat-card-title>
                                                    <mat-card-content>
                                                        <div fxLayout="column">
                                                            <div fxLayout="column">
                                                                <mat-checkbox class="mat-checkbox" [checked]="applicationAttestationForm.get('attestation').value" formControlName="attestation">
                                                                    <span class="mat-checkbox-label">
                                                                        <strong>I understand the application fee is non-refundable.</strong>
                                                                    </span>
                                                                </mat-checkbox>
                                                            </div>
                                            
                                                            <div fxLayout="column">
                                                                <mat-form-field>
                                                                    <input matInput placeholder="Signature" formControlName="esignature">
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </mat-card-content>
                                                </form>
                                            </mat-card>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </mat-tab>
                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    <mat-icon>warning</mat-icon> &nbsp; Deficiencies ({{filteredApplicationDeficiencies.length}})
                                </ng-template>
                                <ng-container>
                                    <mat-card class="custom-mat-card">
                                        <mat-card-title>
                                            <div fxLayout="row">
                                                <span>Application Deficiencies</span>
                                            </div>
                                        </mat-card-title>
                                        <mat-card-content>                                                    
                                            <div fxLayout="column">
                                                <div fxLayout="row" fxLayout.lt-md="column">
                                                    <div fxLayout="column">
                                                        <strong class="noMarginHeader">Results ({{filteredApplicationDeficiencies.length}})</strong>
                                                        <div fxLayout="row" fxLayout.lt-md="column">
                                                            <mat-form-field style="width:200px;">
                                                                <input placeholder="Filter Deficiencies" type="text" (input)="updateApplicationDeficiencyDebounceString($event)" matInput/>                                                                                
                                                            </mat-form-field>     
                                                            &nbsp;&nbsp;
                                                            <mat-form-field style="width:200px;">
                                                                <mat-label>Filter Application Deficiencies</mat-label>
                                                                <mat-select (selectionChange)="filterDeficiencyTable()" [formControl]="applicationDeficiencyStatusFilterString">
                                                                  <mat-option value="true">Resolved</mat-option>
                                                                  <mat-option value="false">Not Resolved</mat-option>
                                                                  <mat-option value="both">Both</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>                                                              
                                                        </div>
                                                    </div>
                                                    <div fxFlex><div *ngIf="personApplicationData.id === 0" class="text-red-400"><strong>The Application Needs to be Saved</strong></div></div>
                                                    <div>                        
                                                        <button mat-raised-button *ngIf="canEdit" color="primary" (click)="editDeficiency(null)" [disabled]="personApplicationData.id === 0">
                                                            <mat-icon>add</mat-icon> Add Deficiency
                                                        </button>
                                                    </div>
                                                    <br />
                                                </div>
                                            
                                                <mat-table [dataSource]="deficiencyDataSource" matSort class="mat-elevation-z8">                                            
                                                    <ng-container *ngFor="let column of displayedDeficiencyColumnsFormat" [matColumnDef]="column.columnName">
                                                      <mat-header-cell *matHeaderCellDef mat-sort-header [fxFlex]="column.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                                        {{ column.displayName }}
                                                      </mat-header-cell>
                                                      <mat-cell *matCellDef="let row" [fxFlex]="column.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                                        <ng-container *ngIf="column.type === 'date'">
                                                          <div *ngIf="isMobile" fxLayout="row">
                                                            <span><strong>{{column.displayName}}: </strong></span> &nbsp;
                                                            <span>{{(row[column.columnName] | date:'MM/dd/yyyy')}}</span>
                                                          </div>
                                                          <div *ngIf="!isMobile">
                                                            {{(row[column.columnName] | date:'MM/dd/yyyy')}}
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="column.type === 'boolean'">
                                                          <div *ngIf="isMobile" fxLayout="row">
                                                            <span><strong>{{column.displayName}}: </strong></span> &nbsp;
                                                            <mat-checkbox [(ngModel)]="row[column.columnName]" [disabled]="true"></mat-checkbox>
                                                          </div>
                                                          <div *ngIf="!isMobile">
                                                            <mat-checkbox [(ngModel)]="row[column.columnName]" [disabled]="true"></mat-checkbox>
                                                          </div>
                                                        </ng-container>
                                                        <ng-container *ngIf="column.type !== 'date' && column.type !== 'boolean'">
                                                          <div *ngIf="isMobile" fxLayout="row">
                                                            <span><strong>{{ column.displayName }}: </strong></span> &nbsp;
                                                            <span>{{ displayLimit(row[column.columnName], column.size) }}</span>
                                                          </div>
                                                          <div *ngIf="!isMobile">
                                                            {{ displayLimit(row[column.columnName], column.size) }}
                                                          </div>
                                                        </ng-container>
                                                      </mat-cell>
                                                    </ng-container>
                                                  
                                                    <ng-container matColumnDef="action">
                                                      <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
                                                      <mat-cell *matCellDef="let row">
                                                        <button mat-icon-button (click)="deleteDeficiency(row); $event.stopPropagation()">
                                                          <mat-icon style="color: red">delete</mat-icon>
                                                        </button>
                                                      </mat-cell>
                                                    </ng-container>
                                                  
                                                    <mat-header-row *matHeaderRowDef="displayedDeficiencyColumns.concat(['action'])"></mat-header-row>
                                                    <mat-row *matRowDef="let row; columns: displayedDeficiencyColumns.concat(['action']);" (click)="editDeficiency(row)"></mat-row>                                          
                                                </mat-table>  
                                                <br />
                                                <mat-paginator [pageSize]="deficiencyPageSize" [pageSizeOptions]="[10, 50, applicationDeficiencies.length]" #DeficiencyTablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </mat-tab>     
                            
                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    <mat-icon>assignment</mat-icon> &nbsp; Individual Information
                                </ng-template>
                                <ng-container>
                                    <mat-card class="custom-mat-card" fxFlex>
                                        <mat-card-title>
                                            <div fxLayout="row" fxLayout.lt-lg="column">
                                                <span fxFlex>Individual Information</span>
                                            </div>
                                        </mat-card-title>
                                        <mat-card-content>
                                            <div fxLayout="column" fxLayoutGap="8px">
                                                <div fxLayout="column" fxLayoutGap="8px">
                                                    <span><strong>Legal Name</strong></span>                
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                    
                                                        <mat-form-field fxFlex="100%">
                                                            <input matInput placeholder="Title" formControlName="title" >                                    
                                                        </mat-form-field>
                                                    
                                                        <mat-form-field fxFlex="100%">
                                                            <input matInput placeholder="First" formControlName="firstName">                                    
                                                        </mat-form-field>                                                                                
                                                    
                                                        <mat-form-field fxFlex="100%">
                                                            <input matInput placeholder="Middle" formControlName="middleName" >                                    
                                                        </mat-form-field>
                                                    
                                                        <mat-form-field fxFlex="100%">
                                                            <input matInput placeholder="Last" formControlName="lastName" (keyup)="personSearch($event)">                                    
                                                        </mat-form-field>
                                                    </div>                               
                                                </div> 
                                                <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">                     
                                                    <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
                                                        <span><strong>SSN</strong></span>  
                                                        <div fxFlex fxLayout="row" fxLayout.lt-md="column" style="padding-top: 12px;">
                                                            <div fxLayout="column" fxLayoutGap="8px">
                                                                <mat-checkbox class="mat-checkbox" formControlName="ssnExempt" (change)="updateSSNRequirement()">
                                                                    <span class="mat-checkbox-label">
                                                                        Exempt
                                                                    </span>
                                                                </mat-checkbox>  
                                                            </div>

                                                            <mat-form-field fxFlex="100%" style="margin-top: -12px;" *ngIf="!applicationDataForm.controls.ssnExempt.value">
                                                                <input matInput placeholder="XXX-XX-XXXX" formControlName="ssn" (focus)="onSSNFocus()" (blur)="onSSNBlur()">                                    
                                                            </mat-form-field>                                                                           
                                                        </div>
                                                    </div>
                                                    <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
                                                        <span><strong>Date of Birth</strong></span>  
                                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                            <input matInput [matDatepicker]="dobPicker" placeholder="Date of Birth" formControlName="dateOfBirth" (focus)="dobPicker.open()">
                                                            <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                                                            <mat-datepicker #dobPicker></mat-datepicker>
                                                            <mat-error *ngIf="applicationDataForm.get('dateOfBirth').invalid">
                                                                Please enter your Date of Birth
                                                            </mat-error>
                                                        </mat-form-field>               
                                                    </div>
                                                </div>
                                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px">
                                                    <span><strong>Public Listing</strong></span> 
                                                    <mat-checkbox class="mat-checkbox" formControlName="isPublic">
                                                        <span class="mat-checkbox-label">
                                                            Check this box if you want to be published on the website.
                                                        </span>
                                                    </mat-checkbox>               
                                                </div>
                                                <div fxFlex="50%" fxLayout="column" fxLayoutGap="8px" *ngIf="this.personApplicationData?.person !== null || this.data?.application?.person !== null">
                                                    <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                                                        <input matInput placeholder="Linked Person" [value]="this.personApplicationData?.person !== null ? this.personApplicationData?.person?.id.toString() + ': ' + this.personApplicationData?.person?.firstName + ' ' + this.personApplicationData?.person?.lastName : this.data.application?.person?.id.toString() + ': ' + this.data.application?.person?.firstName + ' ' + this.data.application?.person?.lastName" disabled>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </mat-tab> 

                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    <mat-icon>{{uiData.contact.icon}}</mat-icon> &nbsp; Contact Information
                                </ng-template>
                                <ng-container>
                                    <mat-card class="custom-mat-card" fxFlex="100%">
                                        <mat-card-title>
                                            <div fxLayout="row">
                                                <span fxFlex>Contact Information</span>
                                            </div>
                                        </mat-card-title>
                                        <mat-card-content>
                                            <div fxFlex fxLayout="column">
                                                <div fxLayout="column" fxLayoutGap="8px" fxFlex="100%">
                                                    <h3><strong>Applicant Contact Information</strong></h3>
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Primary Phone</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Primary Phone" formControlName="phone">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Alternate Phone</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Alternate Phone" formControlName="alternatePhone">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Fax Phone</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Fax Phone" formControlName="faxPhone">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
            
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Primary Email Address</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Email" formControlName="email">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Confirmation Email Address</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Website</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Website" formControlName="website">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div fxLayout="column" fxLayoutGap="8px" fxFlex="100%">
                                                    <h3><strong>Representative Contact Information</strong></h3>
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Representative Name</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Full Name" formControlName="repLabel">
                                                            </mat-form-field>
                                                        </div>                                                        
                                                    </div>
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Primary Phone</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Primary Phone" formControlName="repPhone">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Alternate Phone</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Alternate Phone" formControlName="repAlternatePhone">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Fax Phone</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Fax Phone" formControlName="repFaxPhone">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
            
                                                    <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Primary Email Address</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Email" formControlName="repEmail">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Confirmation Email Address</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Confirm Email" formControlName="repConfirmEmail">
                                                            </mat-form-field>
                                                        </div>
                                                        <div fxFlex="33%" fxLayout="column" fxLayoutGap="8px">
                                                            <span><strong>Website</strong></span>
                                                            <mat-form-field fxFlex="100%">
                                                                <input matInput placeholder="Website" formControlName="repWebsite">
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-card-content>
                                    </mat-card>        
                                </ng-container>
                            </mat-tab> 

                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    <mat-icon>{{uiData.address.icon}}</mat-icon> &nbsp; Address
                                </ng-template>
                                <ng-container>
                                    <mat-card class="custom-mat-card">
                                        <mat-card-title>Address</mat-card-title>
                                        <mat-card-content>                                                    
                                            <div fxLayout="column" class="m-t-8">
                                                <span><strong>Mailing Address</strong></span>           
                                                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" *ngIf="!applicationDataForm.get('mailingIsPhysical').value">
                                                    <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet">
                                                    </mat-form-field>
                                                        
                                                    <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                                                        <input matInput placeholder="City"  formControlName="mailingCity">
                                                    </mat-form-field>
                                    
                                                    <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                                                        <mat-label>State</mat-label>
                                                        <mat-select formControlName="mailingState">
                                                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                                        </mat-select>                                
                                                    </mat-form-field>
                                    
                                                    <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                                                        <input matInput placeholder="Zip" formControlName="mailingZip">
                                                    </mat-form-field>
                                    
                                                    <mat-form-field fxFlex="20" fxFlex.lt-md="100" *ngIf="applicationDataForm.get('mailingState').value === 'OR'">                                
                                                        <mat-label>County</mat-label>
                                                        <mat-select formControlName="mailingCounty" >
                                                        <mat-option>-- None --</mat-option>
                                                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                                        </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>                
                                            </div>
                                        </mat-card-content>
                                    </mat-card>        
                                </ng-container>
                            </mat-tab>

                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    <mat-icon>attachment</mat-icon> &nbsp; Documents ({{tableApplicationDocumentsData.length}})
                                </ng-template>
                                <ng-container>  
                                    <mat-card>
                                        <mat-card-content>
                                            <div fxLayout="column">
                                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                                    <div fxLayoutGap="5px">
                                                        <strong class="noMarginHeader">Results ({{tableApplicationDocumentsData.length}})</strong> 
                                                            
                                                        <mat-form-field style="width:200px;">
                                                            <input (keyup)="filterDocumentTable()" placeholder="Filter Results" [(ngModel)]="filterDocumentString" [ngModelOptions]="{standalone: true}" matInput/>                            
                                                        </mat-form-field>      
                                                    </div>                       
                                                    <div *ngIf="personApplicationData.id === 0" class="text-red-400"><strong>The Application Needs to be Saved</strong></div>              
                                                    <div>                                                                        
                                                        <button mat-raised-button color="primary" (click)="editDocument(null)" [disabled]="personApplicationData.id === 0">
                                                            <mat-icon>add</mat-icon> Add Document
                                                        </button>
                                                    </div>
                                                </div>
                            
                                                <br />
                                    
                                                <mat-table [dataSource]="documentDataSource" matSort #DocumentTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="tableApplicationDocumentsColumns"></mat-header-row>                  
                                                    <mat-row (click)="editDocument(row)" fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: tableApplicationDocumentsColumns" title="{{row.fileName}}"></mat-row>                                      
                                                                        
                                                    <ng-container *ngFor="let cellData of tableApplicationDocumentsFormat; let i = index" [matColumnDef]="cellData.columnName">
                                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                            <ng-container [ngSwitch]="cellData.type">
                                                                <ng-container *ngSwitchCase="'date'">
                                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                                </ng-container>
                                                                <ng-container *ngSwitchCase="'bool'">
                                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                                </ng-container>
                                                                <ng-container *ngSwitchDefault>
                                                                    {{ displayLimit(row[cellData.columnName], '80') }}
                                                                </ng-container>
                                                            </ng-container>
                                                        </mat-cell>
                                                    </ng-container> 
                            
                                                    <ng-container matColumnDef="actions">
                                                        <mat-header-cell *matHeaderCellDef fxFlex="20">Actions</mat-header-cell>
                                                        <mat-cell *matCellDef="let row" fxFlex="20">
                                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                                <button mat-icon-button color="primary" style="width: 50px;" (click)="downloadFile(row); $event.stopPropagation()">
                                                                    <mat-icon>save_alt</mat-icon>
                                                                </button>
                                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="clearFile(row); $event.stopPropagation()">
                                                                    <mat-icon>delete</mat-icon>
                                                                </button>
                                                            </div>
                                                        </mat-cell>
                                                    </ng-container>
                                                </mat-table>
                                                                
                                                <br />
                                                <mat-paginator [pageSize]="tableApplicationDocumentsPageSize" [pageSizeOptions]="[5, 10, 50, tableApplicationDocumentsData.length]" #DocumentTablePaginator="matPaginator"
                                                    showFirstLastButtons></mat-paginator>
                                            </div>
                                        </div>
                                        </mat-card-content>
                                    </mat-card>
                                </ng-container>
                            </mat-tab>        
                            
                            <mat-tab>
                                <ng-template mat-tab-label>                
                                    <mat-icon>{{uiData.payment.icon}}</mat-icon> &nbsp; Payments
                                </ng-template>
                              
                        <ng-container>
                            <div *ngIf="personApplicationData.id === 0" class="text-red-400"><strong>The Application Needs to be Saved</strong></div>
                                    <div fxLayout="column" fxLayoutGap="8px" *ngIf="personApplicationData.id > 0">
                                        <app-manage-payments [@slideLeft]
                                                   [entityId]="personApplicationData.id.toString()"
                                                   [entityType]="paymentService.IndividualApplicationEntityType"
                                                   [allowMultiplePayments]='true'
                                                   [editingPayment]="canEdit"                                                   
                                                   [allowDelete]='true'
                                                   [cardColor]="cardColor"
                                                   [changeIndicator]="changeIndicator">
                              </app-manage-payments>                
                                    </div>
                        </ng-container>
                            </mat-tab> 

                            
                        </mat-tab-group>
                    </mat-card>
                </div>              
            </div>
        </form>
    </div>
    <mat-card [style.background-color]="uiData.individual.color">
        <div fxLayout="row" fxLayout.lt-lg="column" fxLayout.lt-md="column" fxLayout.lt-sm="column" fxLayoutGap="12px">
            
            <div fxLayout="column" class="text-white" *ngIf="canEdit">
                <button mat-raised-button color="primary" (click)="approvePerson()" [disabled]="!(applicationAttestationForm.valid && applicationDataForm.valid  && personApplicationData.id !== 0)">Approve</button>
            </div>
            <div fxLayout="column" *ngIf="canEdit" class="text-white">
                <button mat-raised-button color="primary" aria-label="Save" (click)="savePersonApplication()">Save</button>
            </div>
            <div fxLayout="column" class="text-white">
                <button mat-raised-button aria-label="Exit" (click)="exitDialog()">Exit</button>
            </div>
            <div fxLayout="column" fxFlex="70" class="text-white" *ngIf="!applicationDataForm.valid || personApplicationData.id === 0 || !applicationAttestationForm.valid">
                <mat-card *ngIf="((!applicationAttestationForm.valid || !applicationDataForm.valid) || personApplicationData.id === 0) && personApplicationData.status !== applicationStatus.approved" fxLayoutGap="8px">
                    <ul>
                        <li class="text-red-400" *ngIf="personApplicationData.id === 0"  > 
                            <span class="text-red-400"><strong>The Application Needs to be Saved</strong></span>
                        </li>

                        <li class="text-red-400" *ngIf="(!applicationAttestationForm.valid && canEdit)">                                     
                            <span  class="text-red-400"><strong>Signature and Acknowledgement is incomplete</strong></span>
                        </li>
                        
                        <li class="text-red-400" *ngIf="(applicationDataForm.get('approvalDate').invalid || applicationDataForm.get('applicationViableDate').invalid || applicationDataForm.get('certificateType').invalid || applicationDataForm.get('applicationDate').invalid)"> 
                            <span  class="text-red-400"><strong>Application Information is incomplete</strong></span>
                        </li>

                        <li *ngIf="(applicationDataForm.get('firstName').invalid || applicationDataForm.get('lastName').invalid || applicationDataForm.get('ssn').invalid)" class="text-red-400"><span  >
                            <strong>Individual Information is incomplete</strong></span>
                        </li>
                        <li class="text-red-400" *ngIf="applicationDataForm.get('phone').invalid || applicationDataForm.get('email').invalid || applicationDataForm.get('confirmEmail').invalid">
                            <span>
                                <strong>Contact Information is incomplete</strong>
                                <ul *ngIf="(applicationDataForm.get('email').errors?.emailMismatch) || (applicationDataForm.get('confirmEmail').errors?.emailMismatch)">
                                    <li class="text-red-400">
                                        <span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;<strong>Email and Confirmation Email do not match</strong>
                                        </span>
                                    </li>
                                </ul>
                            </span>
                        </li>
                        <li class="text-red-400" *ngIf="(applicationDataForm.get('mailingStreet').invalid || applicationDataForm.get('mailingState').invalid || applicationDataForm.get('mailingCity').invalid || applicationDataForm.get('mailingZip').invalid)">
                            <span>
                                <strong>Address is incomplete</strong>
                            </span>
                        </li>
                    
                    </ul>
                </mat-card>
            </div>
        </div>       
    </mat-card>
</div>