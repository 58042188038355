import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { MergeIndividualComponent } from 'app/components/individual/merge-individual/merge-individual.component';
import { IFirm } from 'app/models/firms/firm';
import { IFirmMerge } from 'app/models/firms/firmMerge';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogueComponent } from '../confirmation-dialogue/confirmation-dialogue.component';

@Component({
  selector: 'app-select-list-dialogue',
  templateUrl: './select-list-dialogue.component.html',
  styleUrls: ['./select-list-dialogue.component.scss']
})
export class SelectListDialogueComponent implements AfterViewInit{
  public uiData = LEAD_BASED_PAINT;
  public isMobile: boolean = false;
  public loading: boolean = false;  

  public selection: IFirm = null;
  public pageSize: number = 5;  
  public dataSource: MatTableDataSource<any>;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;  
  
  public firmColumns = ['select', 'id', 'name', 'ccb'];
  public firmDisplay = [
    {displayName:'',columnName:'select', type:'select', size:'10'},
    {displayName:'Firm Id',columnName:'id', type:'string', size:'10'},
    {displayName:'Firm Name',columnName:'name', type:'string', size:'50'},        
    {displayName:'CCB',columnName:'ccb', type:'string', size:'30'},          
  ];

  public peopleColumns = ['select', 'id', 'firstName', 'lastName'];
  public peopleDisplay = [
    {displayName:'',columnName:'select', type:'select', size:'10'},
    {displayName:'Person Id',columnName:'id', type:'string', size:'10'},
    {displayName:'First Name',columnName:'firstName', type:'string', size:'40'},        
    {displayName:'Last Name',columnName:'lastName', type:'string', size:'40'},          
  ];

  constructor(private firmService: FirmService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {items: any[], message: string, title: string, type: string},
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MergeIndividualComponent>,
  ) {     
    this.dataSource = new MatTableDataSource(this.data.items);
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'select':
          return this.isSelected(item) ? 0 : 1;
        default:
          return item[property];
      }
    };
  }

  select(){
    this.dialogRef.close(this.selection);
  }

  onChangeSelectedPerson(individual: IFirm) {
    this.selection = individual;    
  }

  isSelected(person: IFirm): boolean {
    return this.selection.id === person.id;
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}

