import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IPerson } from 'app/models/People/person';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { IFirm } from 'app/models/firms/firm';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-firm-staff',
  templateUrl: './add-edit-firm-staff.component.html',
  styleUrls: ['./add-edit-firm-staff.component.scss']
})
export class AddEditFirmStaffComponent implements OnInit {    
  public staffList: IPerson[] = [];
  public filteredStaffList: IPerson[] = [];
  public selectedStaffMember: IPerson;  
  public isPOC: boolean = false;
  public firmPersonToAdd: IFirmPerson = {id: 0, isPOC: false, firm: null, person: null, application: null};
  public peopleFilter: IPersonFilter = {filterAffiliation: [], filterStatus: '', filterString: '', expirationStartDate: '', expirationEndDate: '', filterDiscipline: []};
  private debounceString: Subject<string> = new Subject<string>();
  public uiData = LEAD_BASED_PAINT;
  
  public createNew = 'Create a New Person'
  public personDataForm = null;

  constructor(private dialogRef: MatDialogRef<AddEditFirmStaffComponent>,
    public personService: PersonService,
    public firmService: FirmService,
    @Inject(MAT_DIALOG_DATA) public data:{firm:IFirm, firmPeople:IFirmPerson[]},      
    private toastr: ToastrService,
    private renderer: Renderer2, 
    private el: ElementRef) 
    { 
      this.initializeDebounceFunction();
      this.setPersonDataFormFields();
    }

  ngOnInit(): void {
    this.resetStaffList();
    this.firmPersonToAdd.firm = this.data.firm;    
  }

  initializeDebounceFunction(): void {
    this.debounceString.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {      
      this.filterStaff(value.trim().toLowerCase());     
    });
  }

  updateDebounceString(event: any){    
    this.debounceString.next(event.target.value); 
    this.updateScrollContainerHeight();
  }
  
  resetStaffList(): void {
    this.staffList = [{id: 0, firstName: this.createNew, lastName: '', middleName: '', title: '', ssn: '', dateOfBirth: '', inactive: false, response: 'Unk', certificates: null}];        
    this.filteredStaffList = this.staffList;
  }

  updateScrollContainerHeight() {
    if (this.selectedStaffMember.id === 0) {
      const scrollContainer = this.el.nativeElement.querySelector('.scroll-container');
      this.renderer.setStyle(scrollContainer, 'height', '62vh');
    }
    else {
      const scrollContainer = this.el.nativeElement.querySelector('.scroll-container');
      this.renderer.setStyle(scrollContainer, 'height', '15vh');
    }
  }
  
  filterStaff(value: any) {
    if (value.length < 3) {
      this.resetStaffList();      
    }
    else{
      this.peopleFilter.filterString = value;
      this.personService.getPeopleFiltered(this.peopleFilter).subscribe(result=>{
        this.staffList = result.filter(person => !this.data.firmPeople.some(firmPerson => firmPerson.person.id === person.id));
        this.filteredStaffList = this.staffList;
      },error=>{this.toastr.error("An error occured getting the staff list: ", error);});
    }
  }
  
displayStaffMember(staffMember: any): string {
    if (staffMember) {
        if (staffMember.id === 0) {
            return staffMember.firstName;
        } else {
            return `${staffMember.id}: ${staffMember.firstName} ${staffMember.lastName}`;
        }
    } else {
        return '';
    }
  }
  
  setPersonDataFormFields(){
    this.personDataForm = new UntypedFormGroup({      
      firstName: new UntypedFormControl("", [Validators.required]),
      lastName: new UntypedFormControl("", [Validators.required]),
      middleName: new UntypedFormControl(""),
      title: new UntypedFormControl(""),
      ssn: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{2})-?([\d]{4}).*$/)]),
      dateOfBirth: new UntypedFormControl(null),            
    }); 
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onSubmit(): void {
    if(this.filteredStaffList.some(person => person.id === this.selectedStaffMember?.id)){   
      if(this.selectedStaffMember.id === 0){
        this.savePersonData();
      }
      else{
        this.saveStaffMember();      
      }                  
    }
    else{
      this.toastr.error("The selected staff member can not be found")
    }
  }

  savePersonData(){
    this.selectedStaffMember = {id: 0, firstName: this.personDataForm.controls.firstName.value, lastName: this.personDataForm.controls.lastName.value, middleName: this.personDataForm.controls.middleName.value, title: this.personDataForm.controls.title.value, ssn: this.personDataForm.controls.ssn.value, dateOfBirth: this.personDataForm.controls.dateOfBirth.value, inactive: false, response: 'Unk', certificates: null};        
    this.personService.savePerson(this.selectedStaffMember).subscribe(result=>{
      this.selectedStaffMember = result;
      this.saveStaffMember();
    },error=>{this.toastr.error("An error occured saving the new person: ", error);});    
  }

  saveStaffMember(): void {
    this.firmPersonToAdd.person = this.selectedStaffMember;
    this.firmPersonToAdd.isPOC = this.isPOC;
    this.firmService.saveFirmPerson(this.firmPersonToAdd).subscribe(result=>{
      this.toastr.success("Successfully saved staff member")
      this.dialogRef.close(true);
    },error=>{
      this.toastr.error("An error occured saving the staff member: ", error);
    });      
  }
}
