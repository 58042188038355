import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IPerson } from 'app/models/People/person';
import { IPersonMerge } from 'app/models/People/personMerge';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-merge-individual',
  templateUrl: './merge-individual.component.html',
  styleUrls: ['./merge-individual.component.scss']
})
export class MergeIndividualComponent implements AfterViewInit{
  public uiData = LEAD_BASED_PAINT;
  public isMobile: boolean = false;
  public loading: boolean = false;
  public merging: boolean = false;  

  public selectedIndividual: IPerson = null;
  public pageSize: number = 5;  
  public dataSource: MatTableDataSource<any>;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;  
  individualApplicationTable = {
    layout:{
      columns:['select', 'id', 'inactive', 'firstName', 'lastName', 'affiliations'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'5'},
        {displayName:'Person Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'10'},
        {displayName:'First Name',columnName:'firstName', type:'string', size:'20'},        
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'25'},                  
        {displayName:'Affiliations',columnName:'affiliations', type:'string', size:'30'},                   
      ]         
    }
  };

  constructor(private personService: PersonService,
    public sharedService: SharedService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: IPerson[],
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MergeIndividualComponent>,
  ) {     
    this.dataSource = new MatTableDataSource(this.data);
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'select':
          return this.isSelected(item) ? 0 : 1;
        default:
          return item[property];
      }
    };
  }

  merge(){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
      width: '400px',      
      data: {message: 'Are you sure you want to merge <strong>' + (this.data.length - 1) 
        + '</strong> individuals records into the selected individual <strong>' 
        + this.selectedIndividual.id + ':' + this.selectedIndividual.firstName + ' ' + this.selectedIndividual.lastName
        + '</strong>?',title: 'Merge Individuals'
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.merging = true; 
        let personMerge:IPersonMerge = {
          targetPersonId: this.selectedIndividual.id,
          sourcePeopleIds: this.data.filter(x=>x.id !== this.selectedIndividual.id).map(x => x.id)
        }
        this.personService.mergePeople(personMerge).subscribe(result => {          
          this.toastr.success(this.data.length - 1 + ' Individuals merged successfully');
          this.dialogRef.close(true);
        }, error => {
          this.toastr.error('An error occurred while merging individuals', error);
          this.merging = false;
        });        
      }
    });
  }

  onChangeSelectedPerson(individual: IPerson) {
    this.selectedIndividual = individual;    
  }

  isSelected(person: IPerson): boolean {
    return this.selectedIndividual.id === person.id;
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }
}
