import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IAddress } from 'app/models/common/address';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class AddressService {  
  actionType: Array<string>[] = [];
  errorMessages = [];

  private stateAbbreviations: { [key: string]: string } = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Pennsylvania': 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY',
  };


  constructor(private http?: HttpClient) {}

  getAddresses(): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(environment.privateApi + 'Common/All', { withCredentials: true });
  }

  getAddress(firmDetailsId: number): Observable<IAddress> {
    return this.http.get<IAddress>(environment.privateApi + 'Common/Get/' + firmDetailsId, { withCredentials: true });
  }

  deleteAddress(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Common/Delete/' + id, httpOptions);
  }

  saveAddress(address: IAddress): Observable<any> {
    return this.http.post(environment.privateApi + 'Common/SaveAddress', address, httpOptions);
  }

  // Method to get state abbreviation by full name
  getStateAbbreviation(stateName: string): string | undefined {
    if (stateName) {
      // Handle case-insensitive matching
      return this.stateAbbreviations[stateName.trim()];
    }
    return undefined;
  }
}