import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IAtp } from 'app/models/atp/atp';
import { IAtpAudit } from 'app/models/atp/atpAudit';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpPerson } from 'app/models/atp/atpPerson';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { AddEditAtpCourseComponent } from '../add-edit-atp-course/add-edit-atp-course.component';
import { AUDIT_LOCATION_TYPES, AUDIT_ORGANIZATIONS, AUDIT_TYPES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IPerson } from 'app/models/People/person';
import { IAddress } from 'app/models/common/address';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { AddEditAuditActionComponent } from '../add-edit-audit-action/add-edit-audit-action.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AddDocumentComponent } from 'app/components/common/document/add-document.component';

@Component({
  selector: 'app-add-edit-atp-audit',
  templateUrl: './add-edit-atp-audit.component.html',
  styleUrls: ['./add-edit-atp-audit.component.scss']
})
export class AddEditAtpAuditComponent implements OnInit {
  public isMobile: boolean = false;
  public auditDataForm = null;
  public auditData: IAtpAudit = null;  
  public uiData = LEAD_BASED_PAINT;
  
  public courseList: IAtpCourse[] = [];
  public filteredCourseList: IAtpCourse[] = [];
  public selectedCourse: IAtpCourse;  
  public courseAddress: IAddress = null;

  public trainingManager: IPerson;
  
  public tableAuditActionColumns = ['id', 'auditAction', 'actionDate','actions'];
  public tableAuditActionFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Audit Action',columnName:'auditAction', type:'string', size:'63'},
    {displayName:'Action Date',columnName:'actionDate', type:'date', size:'20'},    
  ];
  public tableAuditActionData = []
  public tableFilteredAuditActionData = []
  public tableAuditActionPageSize = 5;
  public filterAuditActionString: string = '';  
  public filterAuditActionStartDate = null;
  public filterAuditActionEndDate = null;  
  public tableAuditActionDataSource: MatTableDataSource<any>;
  @ViewChild('AuditActionTablePaginator') auditActionPaginator: MatPaginator;
  @ViewChild('AuditActionTableSort') auditActionSort: MatSort;

  public tableAuditDocumentsColumns = ['id', 'fileName', 'fileDescription', 'actions']
  public tableAuditDocumentsFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'},     
    {displayName:'File Name',columnName:'fileName', type:'string', size:'30'},
    {displayName:'File Description',columnName:'fileDescription', type:'string', size:'40'},
  ];
  public tableAuditDocumentsData = []
  public tableAuditDocumentsFilteredData = []
  public tableAuditDocumentsPageSize = 5;
  public filterAuditDocumentString: string = '';  
  public auditDocumentDataSource: MatTableDataSource<any>;
  @ViewChild('AuditDocumentTablePaginator') auditDocumentPaginator: MatPaginator;
  @ViewChild('AuditDocumentTableSort') auditDocumentSort: MatSort;

  constructor(public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{audit:IAtpAudit, atp:IAtp, courses:IAtpCourse [], staff:IAtpPerson []},
    private toastr: ToastrService,
    private atpService: AtpService,
    public commonService: CommonDataService,
    private dialogRef: MatDialogRef<AddEditAtpCourseComponent>,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,) {
      this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      });
     }

  ngOnInit(): void {
    this.setAuditDataFormFields();
    this.loadData();
  }

  disableAddButton(): boolean {    
    if (this.selectedCourse?.id && this.courseList?.some(course => course.id === this.selectedCourse.id)) {
      return false;
    }
    else{
      return true;
    }    
  }

  refreshAuditActionList(){
    this.atpService.getATPAuditActions(this.auditData.id).subscribe(result=>{
      this.tableAuditActionData = result;
      this.filterAuditActionTable();
    },error=>{this.toastr.error('Error loading Audit Actions: ', error)})
  
  }

  loadData(){
    this.courseList = this.data.courses.sort((a, b) => new Date(b.courseStart).getTime() - new Date(a.courseStart).getTime());
    this.filteredCourseList = this.courseList;
    
    this.trainingManager = {id: 0, firstName: '', lastName: '', title: '', middleName: '', ssn: '', dateOfBirth: '', inactive: false, response: '', certificates: null};
    let result = this.data.staff.find(person => person?.isTrainingManager);
    this.trainingManager = result ? result.person : this.trainingManager;        
    
    if(this.data.audit !== null && this.data.audit !== undefined){
      this.auditData = this.data.audit;
      this.refreshAuditActionList();
      this.refreshTableDocuments();
    }
    else{
      this.auditData = {
        id: 0,
        atp: null,
        atpCourse: null,
        auditType: '',
        auditByOrganization: '',
        auditLocationType: '',
        auditor: '',
        auditFindings: '',
        penaltyAmount: null,
        entryDate: null,
        auditDate: null
      }
    }
    this.loadAuditDataForm();
  }

  setAuditDataFormFields(){       
    this.auditDataForm = new UntypedFormGroup({
      auditType: new UntypedFormControl(""),
      auditByOrganization: new UntypedFormControl(""),
      auditLocationType: new UntypedFormControl(""),
      auditor: new UntypedFormControl(""),
      auditFindings: new UntypedFormControl(""),
      auditDate: new UntypedFormControl(null),      
    }); 
  }

  loadAuditDataForm(){
    for (const controlName in this.auditData) {
      if (this.auditDataForm.controls.hasOwnProperty(controlName)) {        
        this.auditDataForm.controls[controlName].setValue(this.auditData[controlName]);          
      }
    }

    this.selectedCourse = this.auditData.atpCourse;
    this.onCourseChange();
  }

  filterCourses(event: any) {
    const value = event.target.value;
    if (!value) {
      this.filteredCourseList = [...this.courseList];
      return;
    }    
    this.filteredCourseList = this.courseList
  }  

  getAuditOrganizations(){
    return Object.values(AUDIT_ORGANIZATIONS);
  }

  getAuditLocationTypes(){
    return Object.values(AUDIT_LOCATION_TYPES)
  }

  getAuditTypes(){
    return Object.values(AUDIT_TYPES);
  }

  savePrompt(){
    let isNew = this.auditData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Audit?' : 'Are you sure you want to <strong>Save</strong> the Audit?',title: isNew ? 'Add the Audit' : 'Save the Audit'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();
      }
    })
  }

  saveData() {
    if(this.auditDataForm.valid){      
      this.auditData.auditType = this.auditDataForm.value.auditType;
      this.auditData.auditByOrganization = this.auditDataForm.value.auditByOrganization;
      this.auditData.auditLocationType = this.auditDataForm.value.auditLocationType;
      this.auditData.auditor = this.auditDataForm.value.auditor;
      this.auditData.auditFindings = this.auditDataForm.value.auditFindings;
      this.auditData.auditDate = this.auditDataForm.value.auditDate;
      this.auditData.atp = this.data.atp;
      this.auditData.atpCourse = this.selectedCourse;
      this.auditData.entryDate = new Date().toISOString();      

      this.atpService.saveATPAudit(this.auditData).subscribe(result=>{
        this.auditData = result;
        this.toastr.success('Audit saved successfully!');        
      },error=>{this.toastr.error('Error saving Audit: ', error)})
    }
    else{
      this.toastr.error('Please fill in all required fields before saving');
    }
  }

  displayFn(course: any): string {
    if (course) {
      const startDate = new Date(course.courseStart);
      const formattedDate = (startDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
                             startDate.getDate().toString().padStart(2, '0') + '/' +
                             startDate.getFullYear();
      return `${course.id}: ${course.accreditationDiscipline}, Start Date: ${formattedDate}`;
    }
    return '';
  }
  
  onCourseChange(){    
    if(this.selectedCourse?.id){     
      this.atpService.getATPCourseAddresses(this.selectedCourse?.id).subscribe(result=>{
        this.courseAddress = result[0]?.address;
      },error=>{this.toastr.error('Error loading course addresses: ', error)})
    }
    else{
      this.selectedCourse = null;
      this.courseAddress = null;
    }
  }

  filterAuditActionTable(): void {
    this.tableFilteredAuditActionData = this.tableAuditActionData;

    this.tableFilteredAuditActionData = this.tableFilteredAuditActionData.filter(x => {      
      return x.id?.toString().includes(this.filterAuditActionString) ||      
        x.auditAction.toString().toLowerCase().includes(this.filterAuditActionString.toLowerCase())
    });

    if (this.filterAuditActionStartDate) {     
      this.tableFilteredAuditActionData = this.tableFilteredAuditActionData.filter(x => new Date(x.actionDate) >= this.filterAuditActionStartDate);
    }

    if (this.filterAuditActionEndDate) {
      this.tableFilteredAuditActionData = this.tableFilteredAuditActionData.filter(x => new Date(x.actionDate) <= this.filterAuditActionEndDate);
    } 

    this.updateAuditActionTableDataSource()
  }
  updateAuditActionTableDataSource(){    
    this.tableAuditActionDataSource = new MatTableDataSource<any>(this.tableFilteredAuditActionData);
    this.tableAuditActionDataSource.paginator = this.auditActionPaginator;
    this.tableAuditActionDataSource.sort = this.auditActionSort;    
  }
  editAuditAction(row: any): void {
    const dialogRef = this.dialog.open(AddEditAuditActionComponent, { 
      data:{action: row, audit: this.auditData},             
      width: this.isMobile ? '90%' : '25%',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshAuditActionList();     
    });
  }

  deleteAuditAction(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Action: <strong>' + row.auditAction + '</strong>?',title: 'Delete the Action'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPAuditAction(row.id).subscribe(result=>{
          this.toastr.success('Action deleted successfully');
          this.refreshAuditActionList();
        },error=>{this.toastr.error('Error deleting Action: ', error)})
      }
    })
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  filterDocumentTable(){
    this.tableAuditDocumentsFilteredData = this.tableAuditDocumentsData;
    
    this.tableAuditDocumentsFilteredData = this.tableAuditDocumentsFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterAuditDocumentString) ||      
        x.fileName?.toString().toLowerCase().includes(this.filterAuditDocumentString.toLowerCase())        
    });

    this.updateDocumentDataSource();
  }
  updateDocumentDataSource(){    
    this.auditDocumentDataSource = new MatTableDataSource<any>(this.tableAuditDocumentsFilteredData);
    this.auditDocumentDataSource.paginator = this.auditDocumentPaginator;
    this.auditDocumentDataSource.sort = this.auditDocumentSort;    
  }
  refreshTableDocuments(){
    this.atpService.getATPAuditDocuments(this.auditData.id).subscribe(result=>{
      this.tableAuditDocumentsData = result;
      this.filterDocumentTable();
    },error=>{
      this.toastr.error("There was an error getting the documents: ", error);
    })
  }

  editDocument(row: any){
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      width: this.isMobile ? '90%' : '30%',
      data: {document: row, entity: 'atpAudit', entityRef: this.auditData},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
        this.refreshTableDocuments();      
    });
  }    

  async downloadFile(row: any) {    
    this.commonService.downloadFileStream(row);
  }  

  clearFile(row: any) {      
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> this Audit Document?',title: 'Delete the Audit Dcoument'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){        
        this.atpService.deleteATPAuditDocument(row.id).subscribe(result=>{
          this.commonService.deleteDocumentFile(row.document.id).subscribe(result=>{
            this.refreshTableDocuments();
          }, error=>{
            this.toastr.error("An error occurred while deleting the document: ", error);
          });
        },
        error=>{
          this.toastr.error("An error occurred while deleting the audit document: ", error);
        });                 
      }
    })
  }
}
