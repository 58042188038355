import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidatorFn, ValidationErrors, FormControl, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IAccountAffiliation } from 'app/models/common/accountAffiliation';
import { IAddress } from 'app/models/common/address';
import { IContactInformation } from 'app/models/common/contactInformation';
import { IDocumentFile } from 'app/models/document/document-file';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {
  public progress = 0;
  public downloadCount = 0;  
  private api: string = environment.privateApi;
  private documentTimeout: number = 1200000;  

  actionType: Array<string>[] = [];
  errorMessages = [];
  firmApplicationFilter = {
    filterString:'',
    selectedStatus: new FormControl(['Submitted', 'In Review','Action Needed','In Progress']),
    selectedType: new FormControl(['rrp','lbpa']), 
    applicationDateFilterStart: new Date(new Date().setDate(new Date().getDate() - 30)), 
    applicationDateFilterEnd: new Date(new Date().setDate(new Date().getDate() + 1)),
    selectedRenewal: new FormControl("")    
  }
  firmManagementFilter = {
    filterString:'',
    filteredActive: new FormControl('Active'), 
    expirationStartDate: new FormControl(""), 
    expirationEndDate: new FormControl(""),
    filteredFirmType: new FormControl(''),
    includeCCB: false
  }  
  individualApplicationFilter = {
    filterString:'',
    selectedStatus: new FormControl(['Submitted', 'In Review','Action Needed','In Progress']), 
    applicationDateFilterStart: new Date(new Date().setDate(new Date().getDate() - 30)), 
    applicationDateFilterEnd: new Date(new Date().setDate(new Date().getDate() + 1)),
    selectedRenewal: new FormControl("")    
  }
  individualManagementFilter = {
    filterString:'',
    filteredActive: new FormControl('Active'), 
    filterStatus: new FormControl(['']), 
    expirationStartDate: new FormControl(null),
    expirationEndDate: new FormControl(null),
    filterDiscipline: new FormControl(null) 
  }  
  atpManagementFilter = {
    filterString:'',filteredActive: new FormControl('Active'), 
    filterRRPAccreditationStatus: new FormControl([]), 
    filterLBPAccreditationStatus: new FormControl([])
  }  
  complaintsFilter = {filterString:'',filteredStatus: new FormControl('Open'), filteredComplaintType: new FormControl('All')};  
  
  constructor(private toastr: ToastrService, private dialog: MatDialog, private http?: HttpClient) {}

  getAccountAffiliation(userId: string): Observable<IAccountAffiliation> {
    return this.http.get<IAccountAffiliation>(environment.privateApi + 'Common/GetAccountAffiliation/' + userId, { withCredentials: true });
  }

  getFirmAffiliations(firmID: number): Observable<IAccountAffiliation[]> {    
    return this.http.get<IAccountAffiliation[]>(this.api + 'Common/GetFirmAffiliations/' + firmID, { withCredentials: true });
  }

  saveAccountAffiliation(accountAffiliation: IAccountAffiliation): Observable<any> {    
    return this.http.post(environment.privateApi + 'Common/SaveAccountAffiliation', accountAffiliation, httpOptions);
  }

  openConfirmationDialog(message: string): MatDialogRef<ConfirmationDialogueComponent> {
    return this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: message
    });
  }
  
  saveAddress(address: IAddress): Observable<any> {
    return this.http.post(environment.privateApi + 'Common/SaveAddress', address, httpOptions);
  }

  deleteAddress(id: number): Observable<any> {    
    return this.http.get(this.api + 'Common/DeleteAddress/' + id, httpOptions);
  } 

  saveDocumentFile(document: IDocumentFile): Observable<IDocumentFile> {    
    return this.http.post<IDocumentFile>(this.api + 'Common/SaveDocumentFile', document, httpOptions).pipe(
      timeout(this.documentTimeout),
      catchError((error) => {return throwError(error);})
    );
  }

  deleteDocumentFile(id: number): Observable<any> {    
    return this.http.get(this.api + 'Common/DeleteDocumentFile/' + id, httpOptions);
  } 

  getDocumentFile(id: number): Observable<IDocumentFile> {
    return this.http.get<IDocumentFile>(environment.privateApi + 'Common/GetDocumentFile/' + id, { withCredentials: true }).pipe(
      timeout(this.documentTimeout),
      catchError((error) => {return throwError(error);})
    );
  }

getDocumentFileStream(id: number): Observable<HttpEvent<Blob>> { 
    const url = `${environment.privateApi}Common/GetDocumentFileStream/${id}`;
    this.progress = 1;
    this.downloadCount++; // Increment download count

    return this.http.get(url, {
      headers: new HttpHeaders({ 'Accept': 'application/octet-stream' }),
      responseType: 'blob',
      observe: 'events',
      reportProgress: true,
      withCredentials: true
    }).pipe(
      timeout(this.documentTimeout),
      tap(event => {
        if (event.type === HttpEventType.DownloadProgress && event.total) {
          this.progress = Math.round((event.loaded / event.total) * 100);          
        } else if (event instanceof HttpResponse) {
          this.downloadCount--;
          if (this.downloadCount > 0){
            this.progress = 1;
          }
        }
      }),
      catchError((error) => {
        this.progress = 0;
        this.downloadCount--; // Decrement download count on error
        console.error('Error fetching document file', error);
        return throwError(error);
      })
    );
  }

  saveContactInformation(contactInformation: IContactInformation): Observable<IContactInformation> {    
    return this.http.post<IContactInformation>(this.api + 'Common/SaveContactInformation', contactInformation, httpOptions);
  }

  deleteContactInformation(id: number): Observable<any> {    
    return this.http.get(this.api + 'Common/DeleteContactInformation/' + id, httpOptions);
  } 
  
emailValidator(emailControlName: string, confirmEmailControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.get(emailControlName);
    const confirmEmail = control.get(confirmEmailControlName);

    if (!email || !confirmEmail) {      
      return null;
    }

    if (confirmEmail.errors && !confirmEmail.errors['emailMismatch']) {      
      return null;
    }

    if (email.value.toLowerCase() !== confirmEmail.value.toLowerCase()) {
      confirmEmail.setErrors({ emailMismatch: true });
      return { emailMismatch: true }; // Return error object with emailMismatch key.
    } else {      
      confirmEmail.setErrors(null);
      return null; // No errors.
    }
  };
}
  
  async getFileSerializedData(file: File): Promise<{ serializedData: string, fileType: string }> {
    return new Promise<{ serializedData: string, fileType: string }>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event: any) => {
        if (event.target) {
          const arrayBuffer = event.target.result;
          if (arrayBuffer instanceof ArrayBuffer) {
            const serializedData = this.base64ArrayBuffer(arrayBuffer);
            resolve({ serializedData, fileType: file.type });
          } else {
            reject(new Error("Failed to read the file."));
          }
        } else {
          reject(new Error("Failed to read the file."));
        }
      };
  
      reader.readAsArrayBuffer(file);
    });
  }  

  base64ArrayBuffer(arrayBuffer: ArrayBuffer): string {
    const byteArray = new Uint8Array(arrayBuffer);
    const byteCharacters = Array.from(byteArray);
    return btoa(byteCharacters.map(charCode => String.fromCharCode(charCode)).join(''));
  }

  async undoFileSerialization(serializedData: string, fileName: string, fileType: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      try {
        const byteCharacters = atob(serializedData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
        const file = new File([blob], fileName, { type: fileType });
        resolve(file);
      } catch (error) {
        reject(error);
      }
    });
  } 
  
  downloadFile(row: any) {
    this.getDocumentFile(row.document.id).subscribe(result=>{
      row.document = result;      
      this.convertFileAndDownload(row);
    },error=>{this.toastr.error("Error getting document file for download: ", error)});
  }

  downloadFileStream(row: any): void {    
      this.getDocumentFileStream(row.document.id).subscribe((event: HttpEvent<Blob>) => {
        if (event.type === HttpEventType.Response) {
          const blob = event.body;
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = row.fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }
      }, error => {
        console.error('Error downloading file', error);
      });
    }

  async convertFileAndDownload(row: any) {            
    let rawData;
    let fileName;
    let fileType;
            
    rawData = row.document.file;
    fileName = row.fileName;
    fileType = row.fileType;

    try {
      const file = await this.undoFileSerialization(rawData, fileName, fileType);
  
      if (file) {        
        const fileUrl = URL.createObjectURL(file);
          
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = file.name; 
        a.style.display = 'none';
  
        document.body.appendChild(a);
        a.click();
          
        document.body.removeChild(a);
        URL.revokeObjectURL(fileUrl);
      } else {
        this.toastr.error("File object is null; unable to download.");
      }
    } catch (error) {
      this.toastr.error("Error undoing serialization:", error);
    }
  }  
}
