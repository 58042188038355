import { Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getStatusColor, ACCREDITATION_ENDORSEMENT, ACCREDITATION_STAGE, ACCREDITATION_STATUS, ANIMATIONS, ATP_ACCREDITATION_STATUS, ATP_ENDORSEMENT, AUDIT_ORGANIZATIONS, AUDIT_TYPES, COURSE_DISCIPLINES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAtp } from 'app/models/atp/atp';
import { IAtpAddress } from 'app/models/atp/atpAddress';
import { IAtpContactInformation } from 'app/models/atp/atpContactInformation';
import { IAtpPerson } from 'app/models/atp/atpPerson';
import { IAddress } from 'app/models/common/address';
import { IContactInformation } from 'app/models/common/contactInformation';
import { IFirm } from 'app/models/firms/firm';
import { AtpService } from 'app/services/atp/atp.service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { ToastrService } from 'ngx-toastr';
import { AddEditAtpStaffComponent } from './add-edit-atp-staff/add-edit-atp-staff.component';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IAtpEndorsement } from 'app/models/atp/atpEndorsement';
import { AddEditAtpCourseComponent } from './add-edit-atp-course/add-edit-atp-course.component';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { AddEditAtpAccreditationComponent } from './add-edit-atp-accreditation/add-edit-atp-accreditation.component';
import { IAtpAccreditation } from 'app/models/atp/atpAccreditation';
import { IAtpAudit } from 'app/models/atp/atpAudit';
import { AddEditAtpAuditComponent } from './add-edit-atp-audit/add-edit-atp-audit.component';
import { AddEditAtpNoteComponent } from './add-edit-atp-note/add-edit-atp-note.component';
import { AddEditAtpDeficiencyComponent } from './add-edit-atp-deficiency/add-edit-atp-deficiency.component';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IPayment } from 'app/components/payments/models/payment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { IComplaint } from 'app/models/complaint/complaint';
import { MatTableDataSource } from '@angular/material/table';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AddEditComplaintComponent } from 'app/components/complaint/add-edit-complaint/add-edit-complaint.component';
import { AddEditIndividualComponent } from 'app/components/individual/add-edit-individual/add-edit-individual.component';

@Component({
  selector: 'app-add-edit-atp',
  templateUrl: './add-edit-atp.component.html',
  styleUrls: ['./add-edit-atp.component.scss'],
  animations: ANIMATIONS,
  encapsulation: ViewEncapsulation.None
})
export class AddEditAtpComponent {
  public statusColor = getStatusColor;
  public loading: boolean = true;
  public isMobile: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public accreditationStatusOptions = Object.values(ACCREDITATION_STATUS);
  public firms:IFirm[] = [];
  public filteredFirms:IFirm[] = [];
  public atp: IAtp = {id: 0, atporId: '', name: '', nameAKA: '', privateCompany: false, inactive: false, rrpAccredStatus: ACCREDITATION_STATUS.none, lbpaAccredStatus: ACCREDITATION_STATUS.none, firm: null};
  public atpForm = null;
  public addressForm = null;  
  public addressInformation: IAddress = {id:0, contactType:'Work', street:'',city:'',state:'',county:'',zip:''};
  public atpAddressInformation: IAtpAddress = {id: 0, address: this.addressInformation, atp: null, isCurrentMailing: true, isCurrentPhysical: true};
  public contactForm = null;
  public contactInformation: IContactInformation = {id: 0,contactType:'Work',phone:'',fax:'',cell:'',website:'',ext:'',email:'', label:''};
  public atpContactInformation: IAtpContactInformation = {id: 0,atp:null,contactInformation: this.contactInformation};
  public complaints: IComplaint[] = [];
  public complaintDataSource: MatTableDataSource<any>;
    
  public atpCourses: IAtpCourse[] = [];
  public atpCourseTable = 
  {
    layout:{
      columns:['id', 'location','accreditationDiscipline', 'courseStart', 'courseEnd', 'receivedPreNote','receivedPostNote','cancelled'],
      container:[        
        {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
        {displayName:'Location',columnName:'location', type:'number', size:'15'}, 
        {displayName:'Discipline',columnName:'accreditationDiscipline', type:'string', size:'15'},
        {displayName:'Start Date',columnName:'courseStart', type:'date', size:'15'},           
        {displayName:'End Date',columnName:'courseEnd', type:'date', size:'15'},           
        {displayName:'Pre Note Received',columnName:'receivedPreNote', type:'bool', size:'10'},
        {displayName:'Post Note Received',columnName:'receivedPostNote', type:'bool', size:'10'},            
        {displayName:'Cancelled',columnName:'cancelled', type:'bool', size:'10'},            
      ],
      data: []      
    }
  };
  public atpCourseTablePageSize = 5;
  public atpCourseDataSource: MatTableDataSource<any>;
  public filterATPCourseString: string = '';  
  public filterATPCourseDisciplines = new FormControl([]);
  public filterATPCoursePreNote = new FormControl([]);
  public filterATPCoursePostNote = new FormControl([]);
  public filterATPCourseCancelled = new FormControl([]);
  public filterATPDateType = new FormControl('Course Start');
  public filterStartDate = null;
  public filterEndDate = null;
  public atpCourseDisciplines = Object.values(COURSE_DISCIPLINES);
  public atpCourseNoteBooleans = Object.values(['Not Received','Received']);
  public atpCourseCancelled = Object.values(['Cancelled','Active']);
  public changeIndicator: number = 0;
  @ViewChild('CourseTablePaginator') atpCoursePaginator: MatPaginator;
  @ViewChild('CourseTableSort') atpCourseSort: MatSort;

  public atpAudits: IAtpAudit[] = [];
  public atpAuditTable = 
  {
    layout:{
      columns:['id', 'atpCourseIdentifier', 'auditType', 'auditDate', 'auditor', 'auditByOrganization','actions'],
      container:[        
        {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
        {displayName:'Audited Course',columnName:'atpCourseIdentifier', type:'string', size:'20'},
        {displayName:'Audit Type',columnName:'auditType', type:'string', size:'18'},
        {displayName:'Auditor',columnName:'auditor', type:'string', size:'15'},
        {displayName:'Audit Organization',columnName:'auditByOrganization', type:'string', size:'15'},
        {displayName:'Audit Date',columnName:'auditDate', type:'date', size:'15'},   
      ],
      data: []      
    }
  };
  public atpAuditTablePageSize = 5;
  public filterATPAuditString: string = '';
  public filterATPAuditType = new FormControl([]);
  public filterATPAuditOrganization = new FormControl([]);
  public filterAuditStartDate = null;
  public filterAuditEndDate = null;  
  public atpAuditType = Object.values(AUDIT_TYPES);
  public atpAuditOrganization = Object.values(AUDIT_ORGANIZATIONS);
  public atpAuditDataSource: MatTableDataSource<any>;
  @ViewChild('AuditTablePaginator') atpAuditPaginator: MatPaginator;
  @ViewChild('AuditTableSort') atpAuditSort: MatSort;

  public atpEndorsements: IAtpEndorsement[] = [];
  public atpEndorsementTable = 
  {
    layout:{
      columns:['id','endorsementType','expirationDate'],
      container:[        
        {displayName:'Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Endorsement Type',columnName:'endorsementType', type:'string', size:'70'},
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'20'},        
      ],
      data: []      
    }
  };
  public atpEndorsementTablePageSize = 5;
  public filterATPEndorsementString: string = '';
  public filterATPEndorsementType = new FormControl([]);
  public filterEndorsementStartDate = null;
  public filterEndorsementEndDate = null;  
  public filterEndorsementShowExpired = new FormControl(false);
  public atpEndorsementType = Object.values(ATP_ENDORSEMENT);
  public atpEndorsementDataSource: MatTableDataSource<any>;
  @ViewChild('EndorsementTablePaginator') atpEndorsementPaginator: MatPaginator;
  @ViewChild('EndorsementTableSort') atpEndorsementSort: MatSort;

  public atpStaff: IAtpPerson[] = [];
  public atpStaffTable = 
  {
    layout:{
      columns:['displayName', 'isContact', 'isInstructor','isPrimaryInstructor','isTrainingManager'],
      container:[                 
        {displayName:'Name',columnName:'displayName', type:'string', size:'25'},
        {displayName:'Point of Contact',columnName:'isContact', type:'bool', size:'15'},
        {displayName:'Instructor',columnName:'isInstructor', type:'bool', size:'15'},
        {displayName:'Primary Instructor',columnName:'isPrimaryInstructor', type:'bool', size:'15'},
        {displayName:'Training Manager',columnName:'isTrainingManager', type:'bool', size:'15'},        
      ],
      data: []      
    }
  };
  public atpStaffTablePageSize = 5;
  public filterATPStaffString: string = '';
  public filterATPStaffPOC = new FormControl([]);
  public filterATPStaffInstructor = new FormControl([]);
  public filterATPStaffPrimaryInstructor = new FormControl([]);
  public filterATPStaffTrainingManager = new FormControl([]);
  public atpStaffBoolList = Object.values(['Unchecked','Checked']);
  public atpStaffDataSource: MatTableDataSource<any>;
  @ViewChild('StaffTablePaginator') atpStaffPaginator: MatPaginator;
  @ViewChild('StaffTableSort') atpStaffSort: MatSort;

  public atpAccreditation: IAtpAccreditation[] = [];
  public atpAccreditationTable = 
  {
    layout:{
      columns:['id', 'accreditationEndorsementType', 'accreditationStage', 'status', 'applicationReceivedDate','dateApplicationCompleted','applicationDateSigned', 'expirationDate', 'dateOfLetter', 'actions'],
      container:[        
        {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
        {displayName:'Endorsement',columnName:'accreditationEndorsementType', type:'string', size:'15'},
        {displayName:'Stage',columnName:'accreditationStage', type:'string', size:'15'},
        {displayName:'Status',columnName:'status', type:'string', size:'10'},
        {displayName:'Date Received',columnName:'applicationReceivedDate', type:'date', size:'10'},             
        {displayName:'Date Completed',columnName:'dateApplicationCompleted', type:'date', size:'10'},             
        {displayName:'Date Signed',columnName:'applicationDateSigned', type:'date', size:'10'},       
        {displayName:'Expiration Date',columnName:'expirationDate', type:'date', size:'10'},             
        {displayName:'Date of Letter',columnName:'dateOfLetter', type:'date', size:'10'},                      
      ],
      data: []      
    }
  };
  public atpAccreditationTablePageSize = 5;
  public filterATPAccreditationString: string = '';
  public filterATPAccreditationEndorsement = new FormControl([]);
  public filterATPAccreditationStage = new FormControl([]);
  public filterATPAccreditationStatus = new FormControl([]);
  public filterATPAccreditationDateType = new FormControl();
  public filterAccreditationStartDate = null;
  public filterAccreditationEndDate = null;  
  public atpAccredtiationEndorsementList = Object.values(ACCREDITATION_ENDORSEMENT);
  public atpAccredtiationStageList = Object.values(ACCREDITATION_STAGE);
  public atpAccredtiationStatusList = Object.values(ATP_ACCREDITATION_STATUS);
  public atpAccredtiationDateTypeList = [null,'Date Received','Date Completed','Date Signed','Expiration Date','Date of Letter'];
  public atpAccreditationDataSource: MatTableDataSource<any>;
  @ViewChild('AccreditationTablePaginator') atpAccreditationPaginator: MatPaginator;
  @ViewChild('AccreditationTableSort') atpAccreditationSort: MatSort;
  public cardColor: string = this.sharedService.gold + '20';

  public noteTableColumns = ['id', 'contactType', 'notes', 'date', 'actions']
  public noteTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Contact Type',columnName:'contactType', type:'string', size:'15'},
    {displayName:'Description',columnName:'notes', type:'string', size:'53'},
    {displayName:'Date',columnName:'date', type:'date', size:'15'},    
  ];
  public noteTableData = []
  public noteTableFilteredData = []
  public noteTablePageSize = 5;
  public filterATPNoteString: string = '';  
  public filterNoteStartDate = null;
  public filterNoteEndDate = null;  
  public atpNoteDataSource: MatTableDataSource<any>;
  @ViewChild('NoteTablePaginator') atpNotePaginator: MatPaginator;
  @ViewChild('NoteTableSort') atpNoteSort: MatSort;

  public deficiencyTableColumns = ['id', 'deficiencyType', 'description', 'isResolved', 'date', 'actions']
  public deficiencyTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Deficiency Type',columnName:'deficiencyType', type:'string', size:'15'},
    {displayName:'Description',columnName:'description', type:'string', size:'38'},
    {displayName:'Resolved',columnName:'isResolved', type:'bool', size:'15'},
    {displayName:'Date',columnName:'date', type:'date', size:'15'},    
  ];
  public deficiencyTableData = []
  public deficiencyTableFilteredData = []
  public deficiencyTablePageSize = 5;
  public filterATPDeficiencyString: string = '';  
  public filterATPDeficiencyResolved = new FormControl([]);
  public filterDeficiencyStartDate = null;
  public filterDeficiencyEndDate = null;  
  public atpDeficiencyBoolList = Object.values(['Unchecked','Checked']);
  public atpDeficiencyDataSource: MatTableDataSource<any>;
  @ViewChild('DeficiencyTablePaginator') atpDeficiencyPaginator: MatPaginator;
  @ViewChild('DeficiencyTableSort') atpDeficiencySort: MatSort;

  complaintsTable = {
    icon: 'recent_actors',
    title:'Complaints',
    layout:{
      columns:['id', 'complainteeName', 'complainantName', 'status', 'complaintDate', 'lastUpdatedBy', 'lastUpdated'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'10'},
        {displayName:'Complaint Source Name',columnName:'complainteeName', type:'string', size:'20'},
        {displayName:'Complaint Target Name',columnName:'complainantName', type:'string', size:'20'},        
        {displayName:'Status',columnName:'status', type:'string', size:'10'},          
        {displayName:'Complaint Date',columnName:'complaintDate', type:'date', size:'15'},     
        {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'15'},           
        {displayName:'Last Updated',columnName:'lastUpdated', type:'date', size:'10'},          
      ],
      data: []      
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {atp: IAtp},
    private dialogRef: MatDialogRef<AddEditAtpComponent>,
    public commonService: CommonDataService,
    private toastr: ToastrService,
    private atpService: AtpService,
    private firmService: FirmService,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    public dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    public complaintService: ComplaintService
    ) 
    {     
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });

    this.setAtpFormFields();        
    this.atp = this.data.atp ?? this.atp;    
    this.loadATPFields();
    this.firmService.getFirms().subscribe(result=>{            
      this.firms = [this.atp.firm].concat(result.filter(x => x.id !== this.atp.firm?.id).sort((a, b) => a.name?.localeCompare(b.name)))
      if(this.atp.firm !== null)
        this.firms = [null].concat(this.firms.sort((a, b) => a.name?.localeCompare(b.name)));
        this.filteredFirms = this.firms;
    },error=>{
      this.toastr.error("There was an error getting the available firms: ", error)
    })

    if (this.atp.id !== 0){
      this.atpService.getATPAddresses(this.atp.id).subscribe(result=>{        
        this.atpAddressInformation = result.length === 0 ? this.atpAddressInformation : result[0];
        this.addressInformation = this.atpAddressInformation.address;        
        this.loadAddressFields();
      },error=>{
        this.toastr.error("There was an error getting the ATP Address: ", error);
      });    
      
      this.atpService.getATPContactInformation(this.atp.id).subscribe(result=>{             
        this.atpContactInformation = result ?? this.atpContactInformation;
        this.contactInformation = this.atpContactInformation.contactInformation;             
        this.loadContactFields();
      },error=>{
        this.toastr.error("There was an error getting the ATP Address: ", error);
      }); 

      this.refreshStaffMembers();            
      this.refreshCourses();      
      this.refreshAccreditation();

      this.getComplaintsByAssociation();

      this.refreshAudits();
      this.refreshNotes();
      this.refreshDeficiencies();
    }    

    this.loading = false;
  }

  getComplaintsByAssociation(){
    this.complaintService.getComplaintsByAssociations(this.atp.id,"Atp").subscribe(result=>{      
      this.complaints = result; 
      this.complaintDataSource = new MatTableDataSource<any>(this.complaints);
    },error=>{})  
  }

  setAtpFormFields(){
    this.atpForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl("", [Validators.required]), 
        nameAKA: new UntypedFormControl(""),
        atporId: new UntypedFormControl(""),
        firm: new UntypedFormControl(""),
        rrpAccredStatus: new UntypedFormControl(""),
        lbpaAccredStatus: new UntypedFormControl(""),   
        privateCompany: new UntypedFormControl(false), 
        inactive: new UntypedFormControl(false), 
      }, 
      {
        validators: []
      }
    ); 
    this.addressForm = new UntypedFormGroup({
      street: new UntypedFormControl(""),
      city: new UntypedFormControl(""),
      state: new UntypedFormControl(""),
      zip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
      county: new UntypedFormControl(""),
    })
    this.contactForm = new UntypedFormGroup({               
      phone: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{3})-?([\d]{4}).*$/)]),
      cell: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{3})-?([\d]{4}).*$/)]),
      fax: new UntypedFormControl("", [Validators.pattern(/^\(?([\d]{3})\)?-?([\d]{3})-?([\d]{4}).*$/)]),
      email: new UntypedFormControl("", [Validators.email]),        
      website: new UntypedFormControl(""),              
    });
  }

  filterStaff(event: any) {    
    if (event.target.value.length === 0) {
      this.filteredFirms = this.firms;    
    }
    else{      
      this.filteredFirms = this.firms.filter(firm => firm?.name.toLowerCase().includes(event.target.value.toLowerCase()) || firm?.id.toString().includes(event.target.value.toLowerCase()));
    }
  }

  displayFirm(firm: any): string {
    if (firm) {
        if (firm.id === 0) {
            return firm.name;
        } else {
            return `${firm.id}: ${firm.name}`;
        }
    } else {
        return '';
    }
  }

  loadATPFields(){
    for (const controlName in this.atp) {      
      if (this.atpForm.controls.hasOwnProperty(controlName)) {
        this.atpForm.controls[controlName].setValue(this.atp[controlName]);        
      }
    }
  }

  loadAddressFields(){
    for (const controlName in this.addressInformation) {
      if (this.addressForm.controls.hasOwnProperty(controlName)) {
        this.addressForm.controls[controlName].setValue(this.addressInformation[controlName]);
      }
    } 
  }

  loadContactFields(){
    for (const controlName in this.contactInformation) {
      if (this.contactForm.controls.hasOwnProperty(controlName)) {
        this.contactForm.controls[controlName].setValue(this.contactInformation[controlName]);
      }
    }         
  }

  updateAtp(): void {      
    for (const controlName in this.atp) {
      if (this.atpForm.controls.hasOwnProperty(controlName)) {
        this.atp[controlName] = this.atpForm.controls[controlName].value;                
      }
    }     
  }

  updateContactData(): void {
    for (const controlName in this.contactInformation) {
      if (this.contactForm.controls.hasOwnProperty(controlName)) {
        this.contactInformation[controlName] = this.contactForm.controls[controlName].value;        
      }
    } 
  }

  updateAddressData(): void {
    for (const controlName in this.addressInformation) {
      if (this.addressForm.controls.hasOwnProperty(controlName)) {
        this.addressInformation[controlName] = this.addressForm.controls[controlName].value;        
      }
    } 
  }

  saveAtp() {
    this.updateAtp();
    this.atpService.saveATP(this.atp).subscribe(result=>{
      this.atp.id = result.id
      this.toastr.success("Successfully saved ATP");
      
      this.updateAddressData();      
      this.commonService.saveAddress(this.addressInformation).subscribe(result=>{
        this.addressInformation.id = result.id
        if(this.atpAddressInformation.id === 0){
          this.atpAddressInformation.address = this.addressInformation;
          this.atpAddressInformation.atp = this.atp;
          this.atpService.saveATPAddress(this.atpAddressInformation).subscribe(result=>{
            this.atpAddressInformation.id = result.id
          },error=>{
            this.toastr.error("There was an error saving the atp address: ", error);
          })
        }
      },error=>{
        this.toastr.error("There was an error saving the address: ", error);
      });

      this.updateContactData();      
      this.commonService.saveContactInformation(this.contactInformation).subscribe(result=>{
        this.contactInformation.id = result.id;
        if(this.atpContactInformation.id === 0){
          this.atpContactInformation.contactInformation = this.contactInformation;
          this.atpContactInformation.atp = this.atp;
          this.atpService.saveATPContactInformation(this.atpContactInformation).subscribe(result=>{
            this.atpContactInformation.id = result.id
          },error=>{
            this.toastr.error("There was an error saving the atp contact information: ", error)
          })
        }
      },error=>{
        this.toastr.error("There was an error saving the contact information: ", error);
      });
    },error=>{
      this.toastr.error("An error occurred when trying to save the ATP: ", error);
    })    
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  refreshStaffMembers(){
    this.atpService.getATPPeople(this.atp.id).subscribe(result=>{        
      this.atpStaff = result.sort((a, b) => a.person.firstName.localeCompare(b.person.firstName));
      this.filterStaffTable();                               
    },error=>{
      this.toastr.error("There was an error getting the ATP People: ", error);
    })
  }

  refreshEndorsements(){
    this.atpEndorsements = this.atpAccreditation.filter(item => item.status.toLowerCase() === ATP_ACCREDITATION_STATUS.complete.toLowerCase()).map(item => ({
      id: item.id,
      endorsementType: item.accreditationEndorsementType,
      expirationDate: item.expirationDate})
    ).sort((a, b) => new Date(b.expirationDate).getTime() - new Date(a.expirationDate).getTime());
    this.filterEndorsementTable();
  }

  refreshCourses(){
    this.atpService.getATPCourses(this.atp.id).subscribe(result=>{      
      this.atpCourses = result.map(course => ({
        ...course,
        location: ' \n' + course?.atpCourseAddress?.locationName + '\n' + 
                  course?.atpCourseAddress?.address?.street + '\n' + 
                  course?.atpCourseAddress?.address?.city + ', ' + 
                  course?.atpCourseAddress?.address?.state + ' ' + 
                  course?.atpCourseAddress?.address?.zip + '\n '
      })).sort((a, b) => new Date(b.courseStart).getTime() - new Date(a.courseStart).getTime());      
      this.filterCourseTable();
    }, error=>{
      this.toastr.error("There was an error getting the ATP Courses: ", error)
    })
  }

  refreshAudits(){
    this.atpService.getATPAudits(this.atp.id).subscribe(result=>{
      this.atpAudits = result.sort((a, b) => new Date(b.auditDate).getTime() - new Date(a.auditDate).getTime()).map(item => ({
        ...item,
        atpCourseIdentifier: item.atpCourse?.id + ': ' + item.atpCourse?.accreditationDiscipline
      }));
      this.filterAuditsTable();
    }, error=>{
      this.toastr.error("There was an error getting the ATP Courses: ", error)
    })
  }

  refreshNotes(){
    this.atpService.getATPNotes(this.atp.id).subscribe(result=>{
      this.noteTableData = result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());   
      this.filterNoteTable();      
    }, error=>{
      this.toastr.error("There was an error getting the ATP Notes: ", error)
    })
  }

  refreshDeficiencies(){
    this.atpService.getATPDeficiencies(this.atp.id).subscribe(result=>{
      this.deficiencyTableData = result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());  
      this.filterDeficiencyTable();    
    }, error=>{
      this.toastr.error("There was an error getting the ATP Deficiencies: ", error)
    })
  }

  refreshAccreditation(){
    this.atpService.getATPAccreditations(this.atp.id).subscribe(result=>{
      this.atpAccreditation = result.sort((a, b) => new Date(b.applicationReceivedDate).getTime() - new Date(a.applicationReceivedDate).getTime());
      this.filterAccreditationTable(); 
      this.refreshEndorsements();     
    }, error=>{
      this.toastr.error("There was an error getting the ATP Accreditations: ", error)
    })
  }

  deleteStaff(row: any): void {          
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message:'Are you sure you want to remove the ATP staff member: <strong>' + row.firstName + ' ' + row.lastName + '</strong>?',title:'Delete ATP Staff Member'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPPerson(this.atpStaff.filter(staff=>staff.person.id === row.id)[0].id).subscribe(result=>{
          this.refreshStaffMembers();
          this.toastr.success("Successfully removed staff member");
        },error=>{
          this.toastr.error("There was an error removing the staff member: ", error);
        })
      }
    })  
  }

  filterCourseTable(): void {
    this.atpCourseTable.layout.data = this.atpCourses;
    
    this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => {      
      return ( x.id?.toString().includes(this.filterATPCourseString) || 
      x.location?.toLowerCase().includes(this.filterATPCourseString.toLowerCase())
    )});

    if(this.filterATPCourseDisciplines.value.length > 0){
      this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => this.filterATPCourseDisciplines.value.includes(x.accreditationDiscipline));
    }

    if(this.filterATPCoursePreNote.value.length > 0){
      this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => 
          (this.filterATPCoursePreNote.value.includes('Not Received') && x.receivedPreNote === false) ||
          (this.filterATPCoursePreNote.value.includes('Received') && x.receivedPreNote === true)
      );
    }

    if(this.filterATPCoursePostNote.value.length > 0){
      this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => 
          (this.filterATPCoursePostNote.value.includes('Not Received') && x.receivedPostNote === false) ||
          (this.filterATPCoursePostNote.value.includes('Received') && x.receivedPostNote === true)
      );
    }

    if(this.filterATPCourseCancelled.value.length > 0){
      this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => 
          (this.filterATPCourseCancelled.value.includes('Cancelled') && x.cancelled === true) ||
          (this.filterATPCourseCancelled.value.includes('Active') && x.cancelled === false)
      );
    }
    
    if (this.filterStartDate) { 
      if(this.filterATPDateType.value === 'Course Start'){    
        this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => new Date(x.courseStart) >= this.filterStartDate);
      } else {
        this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => new Date(x.courseEnd) >= this.filterStartDate);
      }
    }

    if (this.filterEndDate) {
      if(this.filterATPDateType.value === 'Course Start'){
        this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => new Date(x.courseStart) <= this.filterEndDate);
      }      
      else{
        this.atpCourseTable.layout.data = this.atpCourseTable.layout.data.filter(x => new Date(x.courseEnd) <= this.filterEndDate);
      }
    }

    this.updateCourseDataSource();
  }
  editCourse(row: any): void {
    const dialogRef = this.dialog.open(AddEditAtpCourseComponent, {        
      data: {course:row, atp:this.atp, atpStaff: this.atpStaff},
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
      width: '90%',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshCourses();      
    });
  }
  updateCourseDataSource(){    
    this.atpCourseDataSource = new MatTableDataSource<any>(this.atpCourseTable.layout.data);
    this.atpCourseDataSource.paginator = this.atpCoursePaginator;
    this.atpCourseDataSource.sort = this.atpCourseSort;    
  }

  filterAuditsTable(): void {
    this.atpAuditTable.layout.data = this.atpAudits;

    this.atpAuditTable.layout.data = this.atpAuditTable.layout.data.filter(x => {      
      return x.id?.toString().includes(this.filterATPAuditString) ||
      x.auditor.toLowerCase().includes(this.filterATPAuditString.toLowerCase()) ||
      x.atpCourseIdentifier.toLowerCase().includes(this.filterATPAuditString.toLowerCase())
    });

    if(this.filterATPAuditType.value.length > 0){
      this.atpAuditTable.layout.data = this.atpAuditTable.layout.data.filter(x => this.filterATPAuditType.value.includes(x.auditType));
    }

    if(this.filterATPAuditOrganization.value.length > 0){
      this.atpAuditTable.layout.data = this.atpAuditTable.layout.data.filter(x => this.filterATPAuditOrganization.value.includes(x.auditByOrganization));
    }

    if (this.filterAuditStartDate) {     
      this.atpAuditTable.layout.data = this.atpAuditTable.layout.data.filter(x => new Date(x.auditDate) >= this.filterAuditStartDate);
    }

    if (this.filterAuditEndDate) {
      this.atpAuditTable.layout.data = this.atpAuditTable.layout.data.filter(x => new Date(x.auditDate) <= this.filterAuditEndDate);
    }

    this.updateAuditDataSource();
  }
  updateAuditDataSource(){    
    this.atpAuditDataSource = new MatTableDataSource<any>(this.atpAuditTable.layout.data);
    this.atpAuditDataSource.paginator = this.atpAuditPaginator;
    this.atpAuditDataSource.sort = this.atpAuditSort;    
  }
  editAudit(row: any): void {
    const dialogRef = this.dialog.open(AddEditAtpAuditComponent, {        
      data: {audit:row, atp:this.atp, courses: this.atpCourses, staff: this.atpStaff},
      panelClass: this.sharedService.darkMode ? "theme-dark" : "",
      width: '90%',
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {          
      this.refreshAudits();      
    });
  }
  deleteAuditEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Audit with ID: <strong>' + row.id + '</strong>?',title: 'Delete the Audit'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPAudit(row.id).subscribe(result=>{
          this.toastr.success('Audit deleted successfully');
          this.refreshAudits();
        },error=>{this.toastr.error('Error deleting Audit: ', error)})
      }
    })
  }

  filterEndorsementTable(): void {
    this.atpEndorsementTable.layout.data = this.atpEndorsements;

    this.atpEndorsementTable.layout.data = this.atpEndorsementTable.layout.data.filter(x => {      
      return x.id?.toString().includes(this.filterATPEndorsementString)        
    });

    if(this.filterATPEndorsementType.value.length > 0){
      this.atpEndorsementTable.layout.data = this.atpEndorsementTable.layout.data.filter(x => this.filterATPEndorsementType.value.includes(x.endorsementType));
    }

    if (this.filterEndorsementStartDate) {     
      this.atpEndorsementTable.layout.data = this.atpEndorsementTable.layout.data.filter(x => new Date(x.expirationDate) >= this.filterEndorsementStartDate);
    }

    if (this.filterEndorsementEndDate) {
      this.atpEndorsementTable.layout.data = this.atpEndorsementTable.layout.data.filter(x => new Date(x.expirationDate) <= this.filterEndorsementEndDate);
    } 
    
    if (!this.filterEndorsementShowExpired.value) {      
      this.atpEndorsementTable.layout.data = this.atpEndorsementTable.layout.data.filter(x => new Date(x.expirationDate) >= new Date());
    }
    this.updateEndorsementDataSource();
  }
  updateEndorsementDataSource(){    
    this.atpEndorsementDataSource = new MatTableDataSource<any>(this.atpEndorsementTable.layout.data);
    this.atpEndorsementDataSource.paginator = this.atpEndorsementPaginator;
    this.atpEndorsementDataSource.sort = this.atpEndorsementSort;    
  }
  viewEndorsement(row: any): void {    
    this.editAccreditation(this.atpAccreditation.filter(x=>x.id === row.id)[0]);
  }  

  filterStaffTable(): void {
    this.atpStaffTable.layout.data = this.atpStaff.map(staff=>{return {
        ...staff.person,
        isContact: staff.isContact,
        isInstructor: staff.isInstructor,
        isPrimaryInstructor: staff.isPrimaryInstructor,
        isTrainingManager: staff.isTrainingManager,
      };
    })
    this.atpStaffTable.layout.data.forEach(person=>{person.displayName = `${person.id}: ${person.firstName} ${person.lastName}`;})

    this.atpStaffTable.layout.data = this.atpStaffTable.layout.data.filter(x => {      
      return x.displayName?.toString().toLowerCase().includes(this.filterATPStaffString.toLowerCase())        
    });

    if(this.filterATPStaffPOC.value.length > 0){
      this.atpStaffTable.layout.data = this.atpStaffTable.layout.data.filter(x => 
          (this.filterATPStaffPOC.value.includes('Unchecked') && x.isContact === false) ||
          (this.filterATPStaffPOC.value.includes('Checked') && x.isContact === true)
      );
    }

    if(this.filterATPStaffInstructor.value.length > 0){
      this.atpStaffTable.layout.data = this.atpStaffTable.layout.data.filter(x => 
          (this.filterATPStaffInstructor.value.includes('Unchecked') && x.isInstructor === false) ||
          (this.filterATPStaffInstructor.value.includes('Checked') && x.isInstructor === true)
      );
    }

    if(this.filterATPStaffPrimaryInstructor.value.length > 0){
      this.atpStaffTable.layout.data = this.atpStaffTable.layout.data.filter(x => 
          (this.filterATPStaffPrimaryInstructor.value.includes('Unchecked') && x.isPrimaryInstructor === false) ||
          (this.filterATPStaffPrimaryInstructor.value.includes('Checked') && x.isPrimaryInstructor === true)
      );
    }

    if(this.filterATPStaffTrainingManager.value.length > 0){
      this.atpStaffTable.layout.data = this.atpStaffTable.layout.data.filter(x => 
          (this.filterATPStaffTrainingManager.value.includes('Unchecked') && x.isTrainingManager === false) ||
          (this.filterATPStaffTrainingManager.value.includes('Checked') && x.isTrainingManager === true)
      );
    }
    this.updateStaffDataSource();
  }
  updateStaffDataSource(){    
    this.atpStaffDataSource = new MatTableDataSource<any>(this.atpStaffTable.layout.data);
    this.atpStaffDataSource.paginator = this.atpStaffPaginator;
    this.atpStaffDataSource.sort = this.atpStaffSort;    
  }
  editStaff(row: any): void {
    const dialogRef = this.dialog.open(AddEditAtpStaffComponent, {        
      data: {currentPeople: this.atpStaff.map(staff => staff.person), atp: this.atp, person: this.atpStaff.filter(x=>x.person.id === row?.id)[0]},
      width: this.isMobile ? '90%' : '30%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){        
        this.refreshStaffMembers();
      }    
    });
  }

  filterAccreditationTable(): void {
    this.atpAccreditationTable.layout.data = this.atpAccreditation;

    this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => {      
      return x.id?.toString().includes(this.filterATPAccreditationString)        
    });

    if(this.filterATPAccreditationEndorsement.value.length > 0){
      this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => this.filterATPAccreditationEndorsement.value.includes(x.accreditationEndorsementType));
    }

    if(this.filterATPAccreditationStage.value.length > 0){
      this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => this.filterATPAccreditationStage.value.toString().toLowerCase().includes(x.accreditationStage.toLowerCase()));
    }

    if(this.filterATPAccreditationStatus.value.length > 0){
      this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => this.filterATPAccreditationStatus.value.includes(x.status));
    }

    if(this.filterATPAccreditationDateType !== null){
      switch (this.filterATPAccreditationDateType.value) {
        case 'Date Received':
          if (this.filterAccreditationStartDate) {     
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.applicationReceivedDate) >= this.filterAccreditationStartDate);
          }

          if (this.filterAccreditationEndDate) {
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.applicationReceivedDate) <= this.filterAccreditationEndDate);
          }          
          break;
        case 'Date Completed':
          if (this.filterAccreditationStartDate) {     
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.dateApplicationCompleted) >= this.filterAccreditationStartDate);
          }

          if (this.filterAccreditationEndDate) {
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.dateApplicationCompleted) <= this.filterAccreditationEndDate);
          }                    
          break;
        case 'Date Signed':
          if (this.filterAccreditationStartDate) {     
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.applicationDateSigned) >= this.filterAccreditationStartDate);
          }

          if (this.filterAccreditationEndDate) {
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.applicationDateSigned) <= this.filterAccreditationEndDate);
          }           
          break;
        case 'Expiration Date':
          if (this.filterAccreditationStartDate) {     
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.expirationDate) >= this.filterAccreditationStartDate);
          }

          if (this.filterAccreditationEndDate) {
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.expirationDate) <= this.filterAccreditationEndDate);
          } 
          break;
        case 'Date of Letter':
          if (this.filterAccreditationStartDate) {     
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.dateOfLetter) >= this.filterAccreditationStartDate);
          }

          if (this.filterAccreditationEndDate) {
            this.atpAccreditationTable.layout.data = this.atpAccreditationTable.layout.data.filter(x => new Date(x.dateOfLetter) <= this.filterAccreditationEndDate);
          } 
          break;
        default:
          break;
      }
    }

    this.updateAccreditationDataSource();
  }
  updateAccreditationDataSource(){    
    this.atpAccreditationDataSource = new MatTableDataSource<any>(this.atpAccreditationTable.layout.data);
    this.atpAccreditationDataSource.paginator = this.atpAccreditationPaginator;
    this.atpAccreditationDataSource.sort = this.atpAccreditationSort;    
  }
  editAccreditation(row: any): void {
    const dialogRef = this.dialog.open(AddEditAtpAccreditationComponent, {        
      data:{atp:this.atp, accreditation:row},
      width: '70%',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {            
      this.refreshAccreditation();    
      this.changeIndicator++;    
    });
  }
  deleteAccreditationEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Accreditation with ID: <strong>' + row.id + '</strong>?',title: 'Delete the Accreditation'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPAccreditation(row.id).subscribe(result=>{
          this.toastr.success('Accreditation deleted successfully');
          this.refreshAccreditation();
          this.changeIndicator++;    
        },error=>{this.toastr.error('Error deleting Accreditation: ', error)})
      }
    })
  }

  editNoteTableEntry(row: any){
    const dialogRef = this.dialog.open(AddEditAtpNoteComponent, {    
      width: '70%',    
      data: {note: row, atp: this.atp, accreditation: null},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshNotes();
      }    
    });  
  }
  deleteNoteEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Note with ID: <strong>' + row.id + '</strong>?',title: 'Delete the Note'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPNote(row.id).subscribe(result=>{
          this.toastr.success('Note deleted successfully');
          this.refreshNotes();
        },error=>{this.toastr.error('Error deleting Note: ', error)})
      }
    })
  }

  filterNoteTable(){
    this.noteTableFilteredData = this.noteTableData

    this.noteTableFilteredData = this.noteTableFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterATPNoteString) ||      
        x.contactType.toString().toLowerCase().includes(this.filterATPNoteString.toLowerCase()) ||
        x.notes.toString().toLowerCase().includes(this.filterATPNoteString.toLowerCase())
    });

    if (this.filterNoteStartDate) {     
      this.noteTableFilteredData = this.noteTableFilteredData.filter(x => new Date(x.date) >= this.filterNoteStartDate);
    }

    if (this.filterNoteEndDate) {
      this.noteTableFilteredData = this.noteTableFilteredData.filter(x => new Date(x.date) <= this.filterNoteEndDate);
    } 

    this.updateNoteDataSource();
  }

  updateNoteDataSource(){    
    this.atpNoteDataSource = new MatTableDataSource<any>(this.noteTableFilteredData);
    this.atpNoteDataSource.paginator = this.atpNotePaginator;
    this.atpNoteDataSource.sort = this.atpNoteSort;    
  }

  editDeficiencyTableEntry(row: any){
    const dialogRef = this.dialog.open(AddEditAtpDeficiencyComponent, {    
      width: '70%',    
      data: {deficiency: row, atp: this.atp, accreditation: null},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshDeficiencies();
      }    
    });  
  }
  deleteDeficiencyEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Deficiency: <strong>' + row.id + '</strong>?',title: 'Delete the Deficiency'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPDeficiency(row.id).subscribe(result=>{
          this.toastr.success('Deficiency deleted successfully');
          this.refreshDeficiencies();
        },error=>{this.toastr.error('Error deleting deficiency: ', error)})
      }
    })
  }

  filterDeficiencyTable(){
    this.deficiencyTableFilteredData = this.deficiencyTableData;

    this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterATPDeficiencyString) ||      
        x.deficiencyType.toString().toLowerCase().includes(this.filterATPDeficiencyString.toLowerCase()) ||
        x.description.toString().toLowerCase().includes(this.filterATPDeficiencyString.toLowerCase())
    });

    if(this.filterATPDeficiencyResolved.value.length > 0){
      this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => 
          (this.filterATPDeficiencyResolved.value.includes('Unchecked') && x.isResolved === false) ||
          (this.filterATPDeficiencyResolved.value.includes('Checked') && x.isResolved === true)
      );
    }

    if (this.filterDeficiencyStartDate) {     
      this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => new Date(x.date) >= this.filterDeficiencyStartDate);
    }

    if (this.filterDeficiencyEndDate) {
      this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => new Date(x.date) <= this.filterDeficiencyEndDate);
    } 

    this.updateDeficiencyDataSource();
  }
  updateDeficiencyDataSource(){    
    this.atpDeficiencyDataSource = new MatTableDataSource<any>(this.deficiencyTableFilteredData);
    this.atpDeficiencyDataSource.paginator = this.atpDeficiencyPaginator;
    this.atpDeficiencyDataSource.sort = this.atpDeficiencySort;    
  }

  editComplaint(complaint: IComplaint) {    
    if(complaint != null && complaint.id > 0){
      this.complaintService.getComplaintById(complaint.id).subscribe(result=>{   
        const dialogRef = this.dialog.open(AddEditComplaintComponent, {
          width: '90%',
          data: {complaint : result, associationId : this.atp.id, associationType: "ATP"},
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {  
          this.getComplaintsByAssociation();    
        });      
      },error=>{  
      });   
      }else{ const dialogRef = this.dialog.open(AddEditComplaintComponent, {
        width: '90%',
        data: {complaint : null, associationId : this.atp.id, associationType: "ATP" },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {  
        this.getComplaintsByAssociation();    
      });
    }   
  }

  editPerson(person: any) {    
    const dialogRef = this.dialog.open(AddEditIndividualComponent, {
      width: '90%',
      data: {person: person},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }
}
