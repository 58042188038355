import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmation-dialogue-with-field',
  templateUrl: './confirmation-dialogue-with-field.component.html'  
})
export class ConfirmationDialogueWithFieldComponent {
  selectedControlNumber: string = '';
  selectedCertificateNumber: string = '';
  disableInput: boolean = false;
  selectedDate: Date | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  
  private sanitizer: DomSanitizer,  
  private personService: PersonService,
  private firmService: FirmService,
  private toastr: ToastrService) {    
    this.selectedDate = this.data?.selectedDate ? new Date(this.data.selectedDate) : new Date();

    if (data.type === 'firm') {
      this.firmService.getNextFirmCertificateNumber().subscribe(result=>{
        this.selectedControlNumber = result.toString();
      }, error=>{this.toastr.error("Error getting next firm certificate number", error)});
    } else {
      this.personService.getNextPersonCertificateNumber().subscribe(result=>{
        this.selectedControlNumber = result.toString();
        this.selectedCertificateNumber = this.data?.certificateType ? this.data.certificateType.split(' ').map(word => word.charAt(0).toUpperCase()).join('') : '';
        this.selectedCertificateNumber += '-' + this.selectedDate?.getFullYear().toString() + '-' + this.selectedControlNumber;
      }, error=>{this.toastr.error("Error getting next person certificate number", error)});
    }        
  }  
  
  sanitizedData(data:string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      data.replace(/\n/g, '<br>')
    );
  }
}
