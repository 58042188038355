<section class="p-b-16">
    <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
      <button type="button" mat-stroked-button color="primary" (click)="exportToPdf(pdfCert, pdfLetter)">
        Export to PDF
      </button>  
      <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
    </div>
  </section>
  <mat-dialog-content>
    <kendo-pdf-export #pdfCert paperSize="letter" [scale]="1.05" margin="1cm" forcePageBreak=".page-break" [landscape]=true>
                      <div *ngIf="selectedCertificates" fxLayout="column" class="body">
                                <div class="middle-border">
                                    <div class="certificate">
                                    <div class="inner-border">
                                      <br><br>
                                    <h1>State of Oregon</h1>
                                    <h1>Oregon Health Authority</h1>
                                   <p style="font-size:20px"><strong >{{firmName}}</strong></p>
                                    <p>is certified by the Oregon Health Authority to conduct {{codeTypeName}}</p>
                                    <div class="signature">                                      
                                        <p style="margin-bottom:0;">Certification Number : {{firmId}}</p>
                                        <p style="margin : 0; padding-top:0;">Issuance Date : {{issueDate  | date: 'MM/dd/yyyy'}} </p>
                                        <p style="margin : 0; padding-top:0;">Expiration Date : {{expDate | date: 'MM/dd/yyyy'}}</p>
                                    </div> 
                                    <div class="wrap">
                                        <img src="../../../../../assets/images/ORSeal1.png" style="width: 12%;left: 4%" alt="Official Seal of the Organization"/>
                                        <img src="../../../../../assets/images/ohaLogoTransparent.png" style="width: 37%; right: 1%" alt="Official Logo of the Organization"/>
                                    </div>
                            </div>
                        </div>
                    </div>
                  </div> 
    </kendo-pdf-export>
    <div *ngIf="selectedLetters && selectedCertificates">
      <br><br>
    </div>  
    <kendo-pdf-export #pdfLetter paperSize="letter" [scale]="0.75" margin="1cm" forcePageBreak=".page-break">
      <div *ngIf="selectedLetters">
        <div class="letterhead">
          <div class="address">
              PUBLIC HEALTH DIVISION<br>
              Center for Health Protection<br>
              <hr/>
              <span style="font-size: smaller;">Tina Kotek, Governor</span><br>
          </div>
          <div class="logo">
            <img src="../../../../../assets/images/ohaLogoTransparent.png" style="width: 20%;" alt="OHA Logo"/>
          </div>
      </div>
      <div class="bodyhead">
        <div class="persondetail">
            <p>{{ todayDate }}</p>
            Sent by email<br>
            {{firmName}}<br>
            {{personAddress.street}}<br>
            {{personAddress.city}} {{personAddress.state}} {{personAddress.zip}}<br>
        </div>
        <div class="bodyaddress">                          
            800 NE Oregon Street, Suite 640<br>
            Portland, OR 97232<br>
            Phone: (971)673-0440<br>
            Fax: (971)673-0457<br>
            TTY Nonvoice: (971)673-0372<br>
        </div>
    </div>
      <div class="letter-content">

        <p><strong>Attention: {{contactName}}</strong></p>
        
        <p><strong>RE: {{codeTypeName}}, FIRM CERTIFICATION No. {{firmId}}</strong></p>
          
          <p>
            This letter is your notification that  {{firmName}} has met certification requirements under Oregon Administrative Rules (OAR) 333-069 to conduct lead-based paint activities in Oregon. 
            Please note that the enclosed certificate will expire on {{expDate | date: 'MM/dd/yyyy'}}. 
          </p>
          <p>
            In addition to certification by the Oregon Health Authority (Authority), {{firmName}} is required to be licensed annually by the 
            Construction Contractors Board (CCB) prior to conducting lead-based paint activities in Oregon. 
            This license is different than, and in addition to, the standard contractor license issued by CCB. Both individuals and firms must obtain this license. 
            For more information on CCB lead-based paint activities licensing, visit their web site at www.oregon.gov/CCB or call 503-378-4621.
          </p>                            
          <div style="position: relative;">
            <img src="../../../../../assets/images/transparentSeal.png" alt="Watermark" class="watermark" />
          </div>                  
          <p>
            As a firm certified to conduct lead-based paint activities in Oregon, {{firmName}}  must meet all requirements set forth in OAR 333-069. 
            It must comply with work practice standards for conducting lead-based paint activities in target housing and child-occupied facilities and 
            employ only certified individuals to conduct regulated activities.
             In addition, {{firmName}}  must notify the Authority of any lead-based paint abatement activity at least five business days prior to 
             project commencement by submitting a Notice of Abatement form (a copy enclosed) to the Authority.                        
          </p>
          <p>
            As a public service, the Authority publishes a list of firms performing lead-based paint activities in Oregon. 
            The list is distributed statewide to consumers interested in lead-based paint services. 
            Only those firms that are both certified by the Authority and licensed by the CCB are eligible to be on this list.
          </p>     
          <br><br>                     
          <p>
            If you have any questions concerning this certification or other aspects of the Lead-Based Paint Program, please contact us at (971) 673-0440.
          </p>
          <br>  
          <p>Sincerely,</p>
          <p>Lead-Based Paint Program<br>
          Oregon Health Authority</p>
          <p>Enclosure: Certificate</p>
      </div>
    </div>
    </kendo-pdf-export>
  </mat-dialog-content>