import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { IAzureAdUser } from 'app/models/user-info/azure-ad-user';
import { SharedService } from 'app/services/core/shared.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html'
})
export class UserInfoComponent implements OnInit {
 public user: IAzureAdUser = {
   principalName: null,
   fullName: null,
   firstName: null,
   lastName: null,
   email: null,
   groups: null,
   roles: []
 };
 showGroups: boolean = false;

  constructor(private service: UserInformationService, public sharedService: SharedService, public authService: AuthService) { }

  ngOnInit(): void {
    this.service.getUserInfo().subscribe(
      (response) => this.user = response,
      (error) => console.log('error', error)
    );
    this.authService.getUserRoles().subscribe(
      (response) => this.user.roles = response,
      (error) => console.log('error', error)
    );
  }
}
