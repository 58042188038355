import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { AddEditAtpCourseComponent } from 'app/components/atp/add-edit-atp/add-edit-atp-course/add-edit-atp-course.component';
import { IPerson } from 'app/models/People/person';
import { IPersonFilter } from 'app/models/filters/personFilter';
import { IFirm } from 'app/models/firms/firm';
import { IFirmPerson } from 'app/models/firms/firmPerson';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-firm-contact-person',
  templateUrl: './add-edit-firm-contact-person.component.html',
  styleUrls: ['./add-edit-firm-contact-person.component.scss']
})
export class AddEditFirmContactPersonComponent implements OnInit {  
  public firmPersonContactData: IFirmPerson = {id: 0, person: null, firm: null, isPOC: true, application: null};

  public selectedStaffMember: IPerson;
  public staffList: IPerson[] = [];
  public filteredStaffList: IPerson[] = [];
  public peopleFilter: IPersonFilter = {filterAffiliation: [], filterStatus: '', filterString: '', expirationStartDate: '', expirationEndDate: '', filterDiscipline: []};
  private debounceString: Subject<string> = new Subject<string>();
  public uiData = LEAD_BASED_PAINT;  

  constructor(public sharedService: SharedService,
    @Inject(MAT_DIALOG_DATA) public data:{firm: IFirm, firmPeople:IFirmPerson[]},      
    private toastr: ToastrService,
    private firmService: FirmService,
    private personService: PersonService, 
    private commonService: CommonDataService,   
    private dialogRef: MatDialogRef<AddEditAtpCourseComponent>,
    public dialog: MatDialog) 
    { 
      this.initializeDebounceFunction();
    }

    initializeDebounceFunction(): void {
      this.debounceString.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(value => {      
        this.filterStaff(value.trim().toLowerCase());     
      });
    }
  
    updateDebounceString(event: any){    
      this.debounceString.next(event.target.value);    
    }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){  
    this.firmPersonContactData.firm = this.data.firm;        
  }

  filterStaff(value: any) {    
    if (value.length < 3) {
      return;
    }
    else{
      this.peopleFilter.filterString = value;
      this.personService.getPeopleFiltered(this.peopleFilter).subscribe(result=>{
        this.staffList = result.filter(person => !this.data.firmPeople.some(firmPerson => firmPerson.person.id === person.id));
        this.filteredStaffList = this.staffList;
      },error=>{this.toastr.error("An error occured getting the staff list: ", error);});
    }
  }
  
  displayStaffMember(staffMember: any): string {
    return staffMember ? `${staffMember.id}: ${staffMember.firstName} ${staffMember.lastName}` : '';
  }

  savePrompt(){
    let isNew = this.firmPersonContactData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Contact Person?' : 'Are you sure you want to <strong>Update</strong> the Contact Information?',title: isNew ? 'Add the Contact Person' : 'Update the Contact Person'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();
      }
    })
  }

  saveData() {        
    if(this.filteredStaffList.some(person => person.id === this.selectedStaffMember?.id)){   
      this.firmPersonContactData.person = this.selectedStaffMember;
      this.firmService.saveFirmPerson(this.firmPersonContactData).subscribe(result=>{
        this.toastr.success("Successfully added contact person")
        this.dialogRef.close(true);
      },error=>{
        this.toastr.error("An error occured saving the contact person: ", error);
      });      
    }
    else{
      this.toastr.error("The selected person can not be found")
    }
  }
}
