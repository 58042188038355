import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IPerson } from 'app/models/People/person';
import { IPersonAddress } from 'app/models/People/personAddress';
import { IAtpCourse } from 'app/models/atp/atpCourse';
import { IAtpCourseStudent } from 'app/models/atp/atpCourseStudent';
import { IAddress } from 'app/models/common/address';
import { IPossiblePersonMatchFilter } from 'app/models/filters/possiblePersonMatchFilter';
import { AtpService } from 'app/services/atp/atp.service';
import { AddressService } from 'app/services/common/address-service';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { MatchStudentUploadsToExistingComponent } from '../match-student-uploads-to-existing/match-student-uploads-to-existing.component';

interface StudentData {
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  courseCertificateNum: string;
  courseTestScore: string;
  photoImageFileName: string;
  programName: string;
  programContactName: string;
  programAccreditationNum: string;
  programMailingAddress: string;
  programPhoneNumber: string;
  courseDiscipline: string;
  courseType: string;
  courseLeadInstructor: string;
  courseStartDate: string;
  courseEndDate: string;
  courseLocation: string;
}

@Component({
  selector: 'app-atp-course-student-upload',
  templateUrl: './atp-course-student-upload.component.html'  
})
export class AtpCourseStudentUploadComponent {
  public uiData = LEAD_BASED_PAINT;
  public toUploadCount = 0;
  public successfullyUploadedCount = 0;
  public failedToUploadCount = 0;
  public didUpload = false;
  public fileProcessed = false;
  public failedToProcess = true;
  public file: File | null = null;
  private studentDataList: any[] = [];  
  public possibleMatches: { [key: string]: IPerson[] } = {};

  constructor(private personService:PersonService,
    @Inject(MAT_DIALOG_DATA) public data:{course:IAtpCourse},
    public dialog: MatDialog, 
    private atpService:AtpService,
    private commonService:CommonDataService,
    private addressService:AddressService,
    private toastr: ToastrService,
    public sharedService: SharedService,) { }
  
  onFileChange(event: any) {
    this.didUpload = false;
    this.fileProcessed = false;
    this.failedToProcess = true;
    this.successfullyUploadedCount = 0;
    this.toUploadCount = 0;    
    this.studentDataList = [];
    this.file = event.target.files[0];
    this.processFile();     
  }

  getStudentMatches(){
    var filter:IPossiblePersonMatchFilter[] = [];
    for (let studentData of this.studentDataList) {
      var possibleMatch:IPossiblePersonMatchFilter = {
        firstName: studentData.firstName,
        lastName: studentData.lastName
      }
      filter.push(possibleMatch);
    }

    this.personService.getMatchingPeople(filter).subscribe(result=>{
      this.possibleMatches = this.groupByFilter(result);
      if(result.length !== 0){
        this.showStudentMatches();
      }
    },error=>{
      this.toastr.error("Failed to get student matches:", error);
    });
  }

  private groupByFilter(responses: IPerson[]): { [key: string]: IPerson[] } {
    return responses.reduce((acc, response) => {
      const key = `${response.firstName}${response.lastName}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(response);
      return acc;
    }, {});
  }

  showStudentMatches() {
    const dialogRef = this.dialog.open(MatchStudentUploadsToExistingComponent, {
      width: '90%',
      data: {students: this.studentDataList, possibleMatches: this.possibleMatches},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
      this.studentDataList = result.students;      
    });
  }

  resetFileInput(event: Event): void {    
    (event.target as HTMLInputElement).value = '';
  }

  processFile() {
    this.studentDataList = [];
    this.toUploadCount = 0;
    try{
      if (this.file) {            
        const reader: FileReader = new FileReader();    
        reader.onload = (e: any) => {
          const data: string = e.target.result;
          const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary' });
          const sheetName: string = workbook.SheetNames[0];
          const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
          const excelData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    
          // Skip the first 20 rows to get to the header
          const headerRowIndex = 20;
          const headerRow = excelData[headerRowIndex - 1]; // Header row

          if (!Array.isArray(headerRow)) {
            this.toUploadCount = 0;
            this.studentDataList = [];
            this.failedToProcess = true;
            this.toastr.error("Failed to process file. Please make sure the file is in the correct format.");
            throw new Error("Header row is not an array");
          }

          const headers: string[] = headerRow.slice(1); // Skip the first cell
          this.failedToProcess = false;

          for (let i = headerRowIndex; i < excelData.length; i++) {
            const rowData = excelData[i];
            const studentData: StudentData = {} as StudentData;
            let skipRow = false; // Flag to skip adding data
  
            headers.forEach((header, index) => {
              const columnName = this.mapColumnName(header);
              if (columnName === 'dateOfBirth' || columnName === 'courseStartDate' || columnName === 'courseEndDate') {
                studentData[columnName] = this.convertToDate(rowData[index + 1]); // Skip the first cell
              } else if (columnName === 'courseTestScore') {              
                studentData[columnName] = this.convertTestScore(rowData[index + 1]);              
              }else if (columnName === 'state'){
                studentData[columnName] = this.validateState(rowData[index + 1]);
              }else {
                studentData[columnName] = rowData[index + 1] || ''; // Skip the first cell
              }
  
              // Check if first name or last name is empty
              if ((columnName === 'firstName' || columnName === 'lastName') && !studentData[columnName]) {
                skipRow = true;
              }
            });
            
            if (!skipRow) {
              if(studentData.courseTestScore === null || studentData.courseTestScore === undefined || studentData.courseTestScore === "" || Number(studentData.courseTestScore) < 70 || Number(studentData.courseTestScore) > 100 || isNaN(Number(studentData.courseTestScore))){
                this.failedToProcess = true;
              }
              else{
                this.studentDataList.push(studentData);              
              }            
            }
          }  
          this.toUploadCount = this.studentDataList.length;   
          this.fileProcessed = true;    
          if(this.failedToProcess){        
            this.toastr.error("Failed to process.");
            this.toUploadCount = 0;
            this.studentDataList = [];            
            this.fileProcessed = false; 
          } else{
            this.getStudentMatches();
          }
        };
        reader.onerror = (e: any) => {
          this.failedToProcess = true;
          this.toastr.error("An error occurred while reading the file.");
          console.error("File reading error:", e);
        };
        reader.readAsArrayBuffer(this.file);      
      }    
    }
    catch(error){
      this.failedToProcess = true;
      this.toastr.error("Failed to process file. Please make sure the file is in the correct format.");
    }
  }  

  mapColumnName(excelColumnName: string): keyof StudentData {
    excelColumnName = excelColumnName.trim().replace(/\s/g, '').toLowerCase();
    switch (excelColumnName) {
      case 'lastname':
        return 'lastName';
      case 'firstname':
        return 'firstName';
      case 'dateofbirth':
        return 'dateOfBirth';
      case 'streetaddress':
        return 'streetAddress';
      case 'city':
        return 'city';
      case 'state':
        return 'state';
      case 'zipcode':
        return 'zipCode';
      case 'coursecertificate#':
        return 'courseCertificateNum';
      case 'testscore':
        return 'courseTestScore';
      case 'photofilename(forrenovationonly)':
        return 'photoImageFileName';
      default:
        return '' as keyof StudentData;
    }
  }
  
  validateState(value:any): string {
    if(value !== undefined && value !== null){  
      value = value.trim();
      if (value.length > 2) {
        var abbreviation = this.addressService.getStateAbbreviation(value);
        if (abbreviation === undefined) {
          this.toastr.error("Invalid state abbreviation detected. Please use the 2-letter abbreviation.");
          this.failedToProcess = true;
        }        
        return abbreviation;
      }
      return value;
    }
  }

  convertToDate(value: any): string {  
    if(value !== undefined && value !== null){
      var formattedDate = null;
      if (typeof value === 'number') {
        const excelEpoch = new Date(Date.UTC(1899, 11, 30)).getTime();
        const milliseconds = (value) * 24 * 3600 * 1000 + excelEpoch;    
        const date = new Date(milliseconds);      
        formattedDate = date.toISOString().slice(0, 19);
      }

      else if (typeof value === 'string') {        
        const sanitizedValue = value
          .replace(/[/\\]+/g, '/')
          .replace(/-+/g, '-')
          .trim();
          
        formattedDate = new Date(sanitizedValue);
          
        if (!isNaN(formattedDate.getTime())) {          
          return formattedDate.toISOString().slice(0, 19);
        } else {     
          this.toastr.error("Invalid date format detected. Please use the format MM/DD/YYYY.");      
          this.failedToProcess = true;
          return "";
        }
      }

      return formattedDate;
    }
    return null;
  }
  
  convertTestScore(value: any): string {    
    if (value !== undefined && value !== null) {
      let numericValue: number;
  
      if (typeof value === 'string' && value.trim().endsWith('%')) {
        numericValue = parseFloat(value.trim().slice(0, -1));
      } else {
        numericValue = parseFloat(value);
      }  
      if (isNaN(numericValue)) {
        return null;
      }
      if (numericValue <= 1 && !value.toString().includes('%')) {
        numericValue *= 100;
      }
  
      return numericValue.toString();
    }
    return null;
  }

  uploadPrompt(){    
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Upload</strong> the Roster?',title: 'Upload the Roster'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveStudentRosterData();        
      }
    })    
  }

  async saveStudentRosterData() {
    try {
        const result = await this.personService.getPeople().toPromise();
        for (let studentData of this.studentDataList) {
          if (studentData.selectedPerson !== null && studentData.selectedPerson !== undefined) {
            this.saveCourseStudent(studentData.selectedPerson, studentData);
          } else {
              const newPerson: IPerson = {
                  id: 0,
                  firstName: studentData.firstName,
                  lastName: studentData.lastName,
                  dateOfBirth: studentData.dateOfBirth,
                  title: "",
                  middleName: "",
                  ssn: "",
                  inactive: false,
                  response: "",
                  certificates: null,
              };

              const newAddress: IAddress = {
                  id: 0,
                  state: studentData.state,
                  street: studentData.streetAddress,
                  city: studentData.city,
                  contactType: "Work",
                  county: "",
                  zip: studentData.zipCode.toString()
              };

              const newPersonAddress: IPersonAddress = {
                  id: 0,
                  person: null,
                  address: null,
                  isCurrentMailing: true,
                  isCurrentPhysical: false,
              };

              try {
                  const savedPerson = await this.personService.savePerson(newPerson).toPromise();
                  newPersonAddress.person = savedPerson;
                  this.saveCourseStudent(savedPerson, studentData);

                  const savedAddress = await this.commonService.saveAddress(newAddress).toPromise();
                  newPersonAddress.address = savedAddress;

                  await this.personService.savePersonAddress(newPersonAddress).toPromise();
              } catch (error) {
                  this.failedToUploadCount++;
                  this.toastr.error("Failed to save person or address data:", error);
              }
          }
        }
        this.toastr.success("Roster Upload Complete");
        this.didUpload = true;
        this.studentDataList = [];
    } catch (error) {
        this.toastr.error("Failed to save the upload roster:", error);
    }
  }

  saveCourseStudent(student: IPerson, studentData: any) {    
    let atpCourseStudent:IAtpCourseStudent = {
      id: 0,
      atpCourse: this.data.course,
      student: student, 
      photoImage: studentData.photoImageFileName,
      courseCertificateNumber: studentData.courseCertificateNum.toString(),
      testScore: parseInt(studentData.courseTestScore),      
    }

    this.atpService.saveATPCourseStudent(atpCourseStudent).subscribe(result=>{
      this.successfullyUploadedCount++;
    },error=>{
      this.failedToUploadCount++;
    })
  }
}
