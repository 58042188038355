<div fxLayout="column" [ngStyle]="{'--entity-color' : uiData.atp.color}">
    <mat-card [style.background-color]="uiData.atp.color">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title>
                {{accreditationData.id !== 0 ? 'Edit Accreditation ' + accreditationData.id + ': ' + accreditationData.accreditationEndorsementType : 'Add Accreditation'}}
            </mat-card-title>

            <div class="download-container" *ngIf="commonService.downloadCount > 0">
                <div>Pending: {{ commonService.downloadCount }}</div>
                <mat-icon class="download-icon">download</mat-icon>
                <div class="spinner-container">
                    <mat-progress-spinner
                        *ngIf="commonService.progress < 100"
                        diameter="40"
                        mode="determinate"
                        [value]="commonService.progress">
                    </mat-progress-spinner>
                    <div class="progress-text">{{ commonService.progress }}%</div>
                </div>    
            </div>
        </div>
    </mat-card>
    <div class="scroll-container">
        <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
            <mat-tab>
                <ng-template mat-tab-label>                
                    Accreditation Information
                </ng-template>
                <ng-container>                    
                    <form [formGroup]="accreditationDataForm" fxLayout="column">
                        <div fxLayout="row" fxLayout.lt-md="column">
                            <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                                <mat-card-content>                                                    
                                    <div fxLayout="column" fxFlex="100">                                     
                                        <span><strong>Stage</strong></span>                           
                                        <mat-form-field>
                                            <mat-label>Stage</mat-label>
                                            <mat-select  formControlName="accreditationStage">   
                                                <mat-option *ngFor="let status of getAccreditationStages()" [value]="status">{{ status }}</mat-option>                                    
                                            </mat-select>
                                        </mat-form-field>

                                        <span><strong>Endorsement</strong></span>                                                            
                                        <mat-form-field>
                                            <mat-label>Type</mat-label>
                                            <mat-select  formControlName="accreditationEndorsementType">   
                                                <mat-option *ngFor="let status of getAccreditationEndorsements()" [value]="status">{{ status }}</mat-option>                                    
                                            </mat-select>
                                        </mat-form-field>

                                        <span><strong>Status</strong></span>                           
                                        <mat-form-field>
                                            <mat-label>Status</mat-label>
                                            <mat-select  formControlName="status">   
                                                <mat-option *ngFor="let status of getAccreditationStatus()" [value]="status">{{ status }}</mat-option>                                    
                                            </mat-select>
                                        </mat-form-field>
                                        
                                        <span *ngIf="this.accreditationData.id == 0">                       
                                            <mat-checkbox class="mat-checkbox" [checked]="accreditationDataForm.get('addPaymentForNew').value" formControlName="addPaymentForNew">
                                                <span class="mat-checkbox-label">
                                                    <strong>Add Payment</strong>
                                                </span>
                                            </mat-checkbox>    
                                        </span>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                            <mat-card fxFlex="50" fxFlex.lt-md="100" fxLayout="column">
                                <mat-card-content>                                                    
                                    <div fxLayout="column" fxFlex="100">      
                                        <span><strong>Date Application Received</strong></span>                                                            
                                        <mat-form-field style="width: 100%;">
                                            <input matInput [matDatepicker]="receivedDateTimePicker" placeholder="Received Date" formControlName="applicationReceivedDate" (focus)="receivedDateTimePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="receivedDateTimePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #receivedDateTimePicker></mat-datepicker>
                                        </mat-form-field>

                                        <span><strong>Date Application Completed</strong></span>                                                            
                                        <mat-form-field style="width: 100%;">
                                            <input matInput [matDatepicker]="completeDateTimePicker" placeholder="Completed Date" formControlName="dateApplicationCompleted" (focus)="completeDateTimePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="completeDateTimePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #completeDateTimePicker></mat-datepicker>
                                        </mat-form-field>
                                        
                                        <span><strong>Date Signed</strong></span>                                                            
                                        <mat-form-field style="width: 100%;">
                                            <input matInput [matDatepicker]="signedDateTimePicker" placeholder="Signed Date" formControlName="applicationDateSigned" (focus)="signedDateTimePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="signedDateTimePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #signedDateTimePicker></mat-datepicker>
                                        </mat-form-field>

                                        <span><strong>Expiration Date</strong></span>                                                            
                                        <mat-form-field style="width: 100%;">
                                            <input matInput [matDatepicker]="expireDateTimePicker" placeholder="Expiration Date" formControlName="expirationDate" (focus)="expireDateTimePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="expireDateTimePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #expireDateTimePicker></mat-datepicker>
                                        </mat-form-field>

                                        <span><strong>Date of Letter</strong></span>                                                            
                                        <mat-form-field style="width: 100%;">
                                            <input matInput [matDatepicker]="letterDateTimePicker" placeholder="Letter Date" formControlName="dateOfLetter" (focus)="letterDateTimePicker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="letterDateTimePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #letterDateTimePicker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </form>        
                </ng-container>
            </mat-tab>

            <mat-tab *ngIf="this.accreditationData.id !== 0">
                <ng-template mat-tab-label>                
                    Documents ({{documentTableData.length}})
                </ng-template>
                <ng-container>     
                    <mat-card>
                        <mat-card-title>
                            <div fxLayout="row">
                                <span>Documents</span>
                            </div>
                        </mat-card-title>
                        <mat-card-content>
                            <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div fxLayoutGap="5px">
                                        <strong class="noMarginHeader">Results ({{documentTableData.length}})</strong> 
                                        
                                        <mat-form-field style="width:200px;">
                                            <input (keyup)="filterDocumentTable()" placeholder="Filter Results" [(ngModel)]="filterDocumentString" matInput/>                            
                                        </mat-form-field>      
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editDocument(null)">
                                            <mat-icon>add</mat-icon> Add Document
                                        </button>                                        
                                    </div>
                                </div>

                                <br />
                    
                                <mat-table [dataSource]="documentDataSource" matSort #DocumentTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="documentTableColumns"></mat-header-row>                  
                                    <mat-row (click)="editDocument(row)" fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: documentTableColumns" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of documentTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], '80') }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 

                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="20">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="20">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="primary" style="width: 50px;" (click)="downloadFile(row); $event.stopPropagation()">
                                                    <mat-icon>save_alt</mat-icon>
                                                </button>
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="clearFile(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="documentTablePageSize" [pageSizeOptions]="[5, 10, 50, documentTableData.length]" #DocumentTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                        </mat-card-content>
                    </mat-card>   
                </ng-container>
            </mat-tab>

            <mat-tab *ngIf="this.accreditationData.id !== 0">
                <ng-template mat-tab-label>                
                    Notes and Deficiencies ({{noteTableData.length + deficiencyTableData.length}})
                </ng-template>
                <ng-container>     
                    <mat-accordion multi >
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span><h4>ATP Accreditation Notes - {{noteTableData.length}} </h4></span>
                            </mat-panel-title>                                           
                            </mat-expansion-panel-header>
                            <div fxLayout="column">
                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                        <div fxLayoutGap="10px">
                                            <strong class="noMarginHeader">Results ({{noteTableData.length}})</strong>                                                  
                                            <mat-form-field style="width:200px;">
                                                <input (keyup)="filterNoteTable()" placeholder="Filter Results" [(ngModel)]="filterNoteString" matInput/>                            
                                            </mat-form-field>  
                                            
                                            <mat-form-field style="width:100px">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="startNotePicker" placeholder="Date Range Start" [(ngModel)]="filterNoteStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterNoteTable()" (focus)="startNotePicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="startNotePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startNotePicker></mat-datepicker>
                                            </mat-form-field>

                                            <mat-form-field style="width:100px">
                                                <mat-label>End Date</mat-label>
                                                <input matInput [matDatepicker]="endNotePicker" placeholder="Date Range End" [(ngModel)]="filterNoteEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterNoteTable()" (focus)="endNotePicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="endNotePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endNotePicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>                                     
                                        <div>                        
                                            <button mat-raised-button color="primary" (click)="editNoteTableEntry(null)">
                                                <mat-icon>add</mat-icon> Add Note
                                            </button>                                        
                                        </div>
                                    </div>
                
                                    <br />
                        
                                    <mat-table [dataSource]="noteDataSource" matSort #NoteTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="noteTableColumns"></mat-header-row>                  
                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: noteTableColumns" (click)="editNoteTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                            
                                        <ng-container *ngFor="let cellData of noteTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                <ng-container [ngSwitch]="cellData.type">
                                                    <ng-container *ngSwitchCase="'date'">
                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'bool'">
                                                        <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <span style="white-space: pre-wrap;">{{ displayLimit(row[cellData.columnName], "256") }}</span>
                                                    </ng-container>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container> 
                
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let row" fxFlex="7">
                                                <div style="display: flex; justify-content: space-between; width: 100px;">
                                                    <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteNoteEntry(row); $event.stopPropagation()">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                    </mat-table>
                                                    
                                    <br />
                                    <mat-paginator [pageSize]="noteTablePageSize" [pageSizeOptions]="[5, 10, 50, noteTableData.length]" #NoteTablePaginator="matPaginator"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                              </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <span><h4>ATP Accreditation Deficiencies - {{deficiencyTableData.length}}</h4></span>
                                </mat-panel-title>                                           
                            </mat-expansion-panel-header>
                            <div fxLayout="column">
                                <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                        <div fxLayoutGap="10px">
                                            <strong class="noMarginHeader">Results ({{deficiencyTableData.length}})</strong> 
                                                
                                            <mat-form-field style="width:200px;">
                                                <input (keyup)="filterDeficiencyTable()" placeholder="Filter Results" [(ngModel)]="filterDeficiencyString" matInput/>                            
                                            </mat-form-field> 
                                            
                                            <mat-form-field style="width:150px">
                                                <mat-select multiple placeholder="Filter Resolved" [formControl]="filterDeficiencyResolved" (selectionChange)="filterDeficiencyTable()">
                                                    <mat-option *ngFor="let type of deficiencyBoolList" [value]="type">
                                                        {{ type }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field style="width:100px">
                                                <mat-label>Start Date</mat-label>
                                                <input matInput [matDatepicker]="startDeficiencyPicker" placeholder="Date Range Start" [(ngModel)]="filterDeficiencyStartDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterDeficiencyTable()" (focus)="startDeficiencyPicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="startDeficiencyPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #startDeficiencyPicker></mat-datepicker>
                                            </mat-form-field>

                                            <mat-form-field style="width:100px">
                                                <mat-label>End Date</mat-label>
                                                <input matInput [matDatepicker]="endDeficiencyPicker" placeholder="Date Range End" [(ngModel)]="filterDeficiencyEndDate" [ngModelOptions]="{standalone: true}" (dateChange)="filterDeficiencyTable()" (focus)="endDeficiencyPicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="endDeficiencyPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #endDeficiencyPicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>                                     
                                        <div>                        
                                            <button mat-raised-button color="primary" (click)="editDeficiencyTableEntry(null)">
                                                <mat-icon>add</mat-icon> Add Deficiency
                                            </button>                                        
                                        </div>
                                    </div>
                
                                    <br />
                        
                                    <mat-table [dataSource]="deficiencyDataSource" matSort #DeficiencyTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                        <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="deficiencyTableColumns"></mat-header-row>                  
                                        <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: deficiencyTableColumns" (click)="editDeficiencyTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                            
                                        <ng-container *ngFor="let cellData of deficiencyTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                            <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                                <ng-container [ngSwitch]="cellData.type">
                                                    <ng-container *ngSwitchCase="'date'">
                                                        {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'bool'">
                                                        <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                    </ng-container>
                                                    <ng-container *ngSwitchDefault>
                                                        <span style="white-space: pre-wrap;">{{ displayLimit(row[cellData.columnName], "256") }}</span>
                                                    </ng-container>
                                                </ng-container>
                                            </mat-cell>
                                        </ng-container> 
                
                                        <ng-container matColumnDef="actions">
                                            <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                            <mat-cell *matCellDef="let row" fxFlex="7">
                                                <div style="display: flex; justify-content: space-between; width: 100px;">
                                                    <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteDeficiencyEntry(row); $event.stopPropagation()">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </mat-cell>
                                        </ng-container>
                                    </mat-table>
                                                    
                                    <br />
                                    <mat-paginator [pageSize]="deficiencyTablePageSize" [pageSizeOptions]="[5, 10, 50, deficiencyTableData.length]" #DeficiencyTablePaginator="matPaginator"
                                        showFirstLastButtons></mat-paginator>
                                </div>
                              </div>
                            </mat-expansion-panel>
                    </mat-accordion>               
                </ng-container>
            </mat-tab>

            <mat-tab *ngIf="this.accreditationData.id !== 0">
                <ng-template mat-tab-label>   
                    Payments            
                </ng-template>
                <ng-container>     
                      
                    <app-manage-payments [@slideLeft]
                                                   [entityId]="this.accreditationData.id.toString()"
                                                   [entityType]="paymentService.ATPAccreditationEntityType"
                                                   [allowMultiplePayments]='true'
                                                   [editingPayment]="canEditPayments"
                                                   (notifyParent)="paymentUpdated($event)"
                                                   [allowDelete]='true'
                                                   [cardColor]="cardColor"
                                                   [changeIndicator]="changeIndicator">
                              </app-manage-payments>  
                </ng-container>
            </mat-tab>
        </mat-tab-group>     
    </div>
    <mat-card [style.background-color]="uiData.atp.color" fxLayout="row wrap" fxLayoutGap="8px">
        <button mat-raised-button color="primary" aria-label="Save" (click)="savePrompt()">
            {{accreditationData.id !== 0 ? 'Save' : 'Add'}}
        </button>
        <button mat-raised-button mat-dialog-close="Exit" aria-label="Exit">Exit</button>
    </mat-card>
</div>