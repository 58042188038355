  <section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>
  
  <div [ngStyle]="{'--entity-color' : uiData.firm.color}" *ngIf="!loading">    
    <mat-card [style.background-color]="uiData.firm.color">
        <h2 class="noMarginHeader">
            <mat-icon>{{uiData.firm.icon}}</mat-icon> {{firm.id + ': ' + firm.name}}
        </h2>
    </mat-card>    

    <mat-dialog-content class="scroll-container">
      <br />
      <div fxLayout="column">
        <mat-card>
          <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
            <mat-tab>
              <ng-template mat-tab-label>                
                  Firm Information
              </ng-template>
              <ng-container>
                <mat-card class="custom-mat-card">
                  <mat-card-title>
                      <div>
                          <span fxFlex>Firm Information</span>
                      </div>
                  </mat-card-title>
                  <mat-card-content>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <strong>ID: {{firm.id}}</strong>              
                      <form [formGroup]="firmForm">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">                                                                               
                            <mat-form-field>
                                <input matInput placeholder="Firm Name" formControlName="name" >                                    
                            </mat-form-field>                                                                             
                            <mat-form-field>
                                <input matInput placeholder="Certification Number" formControlName="certificationNumber" >                                    
                            </mat-form-field> 
                            <mat-form-field>
                              <mat-label>Services Available</mat-label>
                              <mat-select  formControlName="servicesAvailable">   
                                  <mat-option *ngFor="let status of getFirmServiceForTypeValues()" [value]="status">{{ status }}</mat-option>                                                                                 
                              </mat-select>
                            </mat-form-field>          
                            <span><strong>Private Firm</strong></span>
                            <mat-checkbox  formControlName="isPrivate">
                                <span class="mat-checkbox-label">
                                    Private Firm
                                </span>
                            </mat-checkbox>                                                                               
                          </div> 
                          <div fxLayout="column" fxLayoutGap="8px" fxFlex="50">                                                                               
                            <mat-form-field>
                                <input matInput placeholder="Property Management License #" formControlName="propertyManagementLicense" >                                    
                            </mat-form-field>                                                                             
                            <mat-form-field>
                                <input matInput placeholder="Construction Contractors Board #" formControlName="ccb" >                                    
                            </mat-form-field>                     
                            <mat-form-field>
                                <input matInput [matDatepicker]="dobPicker" placeholder="Construction Contractors Board Expiration" formControlName="ccbExpirationDate" (focus)="dobPicker.open()">
                                <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                                <mat-datepicker #dobPicker></mat-datepicker>
                                <mat-error *ngIf="firmForm.get('ccbExpirationDate').invalid">
                                    Please enter your Date of Birth
                                </mat-error>
                            </mat-form-field>  
                            <span><strong>Firm Inactive</strong></span>
                            <mat-checkbox  formControlName="inactive">
                                <span class="mat-checkbox-label">
                                    Firm Inactive
                                </span>
                            </mat-checkbox>                                                                                         
                          </div> 
                        </div>
                      </form>
                    </div>
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>                
                  <mat-icon>{{uiData.contact.icon}}</mat-icon> &nbsp; Contact Information({{addressTableData.length + contactInformationTableData.length}})
              </ng-template>
              <ng-container>                  
                  <div fxLayout="row" fxLayout.lt-md="column" fxFlex>
                    <mat-card class="custom-mat-card" fxFlex="100">
                      <mat-accordion multi >
                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span><h4>Addresses - {{addressTableData.length}}</h4></span>
                            </mat-panel-title>                                           
                          </mat-expansion-panel-header>
                          <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div fxLayoutGap="5px">
                                        <strong class="noMarginHeader">Results ({{addressTableData.length}})</strong>                                           
                                        <mat-form-field style="width:200px;">
                                          <input (keyup)="filterAddressTable()" placeholder="Filter Results" [(ngModel)]="filterAddressString" matInput/>                            
                                        </mat-form-field> 
                                        
                                        <mat-form-field style="width:150px">
                                          <mat-select multiple placeholder="Filter Address Type" [formControl]="filterAddressType" (selectionChange)="filterAddressTable()">
                                              <mat-option *ngFor="let type of addressTypeList" [value]="type">
                                                  {{ type }}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>

                                      <mat-form-field style="width:150px">
                                        <mat-select multiple placeholder="Filter Physical" [formControl]="filterAddressPhysical" (selectionChange)="filterAddressTable()">
                                            <mat-option *ngFor="let type of addressBoolList" [value]="type">
                                                {{ type }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field style="width:150px">
                                      <mat-select multiple placeholder="Filter Mailing" [formControl]="filterAddressMailing" (selectionChange)="filterAddressTable()">
                                          <mat-option *ngFor="let type of addressBoolList" [value]="type">
                                              {{ type }}
                                          </mat-option>
                                      </mat-select>
                                  </mat-form-field>
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editAddressTableEntry(null)">
                                            <mat-icon>add</mat-icon> Add Address
                                        </button>                                        
                                    </div>
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="addressDataSource" matSort #AddressTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="addressTableColumns"></mat-header-row>                  
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: addressTableColumns" (click)="editAddressTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of addressTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="7">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteAddressEntry(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="addressTablePageSize" [pageSizeOptions]="[5, 10, 50, addressTableData.length]" #AddressTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                          </div>
                        </mat-expansion-panel>

                        <mat-expansion-panel>
                          <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span><h4>Contact Information - {{contactInformationTableData.length}}</h4></span>
                            </mat-panel-title>                                           
                          </mat-expansion-panel-header>
                          <div fxLayout="column">
                            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                                <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                    <div fxLayoutGap="5px">
                                        <strong class="noMarginHeader">Results ({{contactInformationTableData.length}})</strong>                                               
                                        <mat-form-field style="width:200px;">
                                          <input (keyup)="filterContactInformationTable()" placeholder="Filter Results" [(ngModel)]="filterContactInformationString" matInput/>                            
                                        </mat-form-field>      

                                        <mat-form-field style="width:150px">
                                          <mat-select multiple placeholder="Filter Contact Type" [formControl]="filterContactInformationType" (selectionChange)="filterContactInformationTable()">
                                              <mat-option *ngFor="let type of contactInformationTypeList" [value]="type">
                                                  {{ type }}
                                              </mat-option>
                                          </mat-select>
                                      </mat-form-field>
                                    </div>                                     
                                    <div>                        
                                        <button mat-raised-button color="primary" (click)="editContactInformationTableEntry(null)">
                                            <mat-icon>add</mat-icon> Add Contact Information
                                        </button>                                        
                                    </div>
                                </div>
            
                                <br />
                    
                                <mat-table [dataSource]="contactInformationDataSource" matSort #ContactInformationTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row fxLayout="row" fxLayout.lt-md="column" *matHeaderRowDef="contactInformationTableColumns"></mat-header-row>                  
                                    <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: contactInformationTableColumns" (click)="editContactInformationTableEntry(row); $event.stopPropagation()" title="{{row.fileName}}"></mat-row>                                      
                                                        
                                    <ng-container *ngFor="let cellData of contactInformationTableFormat; let i = index" [matColumnDef]="cellData.columnName">
                                        <mat-header-cell mat-sort-header [fxFlex]="cellData.size" *matHeaderCellDef>{{ cellData.displayName }}</mat-header-cell>
                                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}">
                                            <ng-container [ngSwitch]="cellData.type">
                                                <ng-container *ngSwitchCase="'date'">
                                                    {{ row[cellData.columnName] | date: 'MM/dd/yyyy' }}
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'bool'">
                                                    <mat-checkbox [checked]="row[cellData.columnName]"></mat-checkbox>
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                    {{ displayLimit(row[cellData.columnName], cellData.size) }}
                                                </ng-container>
                                            </ng-container>
                                        </mat-cell>
                                    </ng-container> 
            
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef fxFlex="7">Actions</mat-header-cell>
                                        <mat-cell *matCellDef="let row" fxFlex="7">
                                            <div style="display: flex; justify-content: space-between; width: 100px;">
                                                <button mat-icon-button color="warn" style="width: 50px;" (click)="deleteContactInformationEntry(row); $event.stopPropagation()">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </mat-cell>
                                    </ng-container>
                                </mat-table>
                                                
                                <br />
                                <mat-paginator [pageSize]="contactInformationTablePageSize" [pageSizeOptions]="[5, 10, 50, contactInformationTableData.length]" #ContactInformationTablePaginator="matPaginator"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                          </div>
                        </mat-expansion-panel>
                      </mat-accordion>                        
                    </mat-card>
                  </div>                        
              </ng-container>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>                
                  <mat-icon>{{uiData.certificate.icon}}</mat-icon> &nbsp; Certificates ({{firmFilteredCertificates.length}})
              </ng-template>
              <ng-container>
                <mat-card class="custom-mat-card">
                  <mat-card-title>
                      <div fxLayout="row" fxLayout.lt-lg="column">
                          <span fxFlex>Certificates</span>
                      </div>
                  </mat-card-title>
                  <mat-card-content>
                      <div fxLayout="column">
                      <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                          <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                              <div fxLayout="column">
                                  <strong class="noMarginHeader">Results ({{firmFilteredCertificates.length}})</strong> 
                                  <div fxLayout="row" fxLayout.lt-md="column">
                                    <mat-form-field style="width:200px;">
                                      <input placeholder="Filter Certificates" type="text" (input)="updateCertificateDebounceString($event)" matInput/>                                                                                                         
                                    </mat-form-field>  
                                    &nbsp;&nbsp;
                                    <mat-form-field style="width:200px;">
                                      <mat-label>Filter Certificate Stage</mat-label>
                                      <mat-select (selectionChange)="filterCertificateTable()" [formControl]="certificateStageFilter">
                                        <mat-option value="Initial">Initial</mat-option>
                                        <mat-option value="Refresher">Refresher</mat-option>
                                        <mat-option value="Both">Both</mat-option>
                                      </mat-select>
                                    </mat-form-field>    
                                  </div>
                              </div>                                     
                          </div>

                          <br />
              
                          <mat-table [dataSource]="certificatesDataSource" matSort #CertificateTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                              <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="certificateTable.layout.columns.concat('actions')"></mat-header-row>                  
                              <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: certificateTable.layout.columns.concat('actions')" (click)="editCertificate(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                  
                              <ng-container *ngFor="let cellData of certificateTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                  <mat-header-cell [fxFlex]="cellData.size" mat-sort-header *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{ cellData.displayName }}</mat-header-cell>
                                  <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                      <ng-container [ngSwitch]="cellData.type">
                                          <ng-container *ngSwitchCase="'date'">
                                            <div *ngIf="isMobile" fxLayout="row">  
                                              <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                              <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                            </div>
                                            <div *ngIf="!isMobile">  
                                                {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                            </div>
                                          </ng-container>
                                          <ng-container *ngSwitchCase="'bool'">
                                            <div *ngIf="isMobile" fxLayout="row">
                                              <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                              <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                            </div>
                                            <div *ngIf="!isMobile">
                                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                            </div>  
                                          </ng-container>
                                          <ng-container *ngSwitchDefault>
                                            <div *ngIf="isMobile" fxLayout="row">                                    
                                              <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                              <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                            </div>
                                            <div *ngIf="!isMobile">
                                                {{displayLimit(row[cellData.columnName], cellData.size)}}
                                            </div>  
                                          </ng-container>
                                      </ng-container>
                                  </mat-cell>
                              </ng-container> 

                              <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                                <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                  <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                    <span><strong>Action: </strong></span> &nbsp;&nbsp;
                                    <button mat-raised-button color="primary" style="width: 55px;" (click)="openApplication(null, true, true, row); $event.stopPropagation()"> 
                                      {{canRenewCertificate(row) ? 'View' : 'Renew'}}
                                    </button>&nbsp; 
                                    <button mat-raised-button color="primary" (click)="viewPrintCertificateModal(row); $event.stopPropagation()" *ngIf="row.codeType == 'RRP Firm' || row.codeType == 'LBP Firm'"> 
                                      Print
                                    </button>
                                  </div>
                                  <div *ngIf="!isMobile">
                                      <button mat-raised-button color="primary" style="width: 55px; padding: 1px;" (click)="openApplication(null, true, true, row); $event.stopPropagation()">
                                        {{canRenewCertificate(row) ? 'View' : 'Renew'}}
                                      </button>&nbsp;
                                      <button mat-raised-button color="primary" (click)="viewPrintCertificateModal(row); $event.stopPropagation()" *ngIf="row.codeType == 'RRP Firm' || row.codeType == 'LBP Firm'"> 
                                        Print
                                      </button>
                                  </div> 
                                </mat-cell>
                              </ng-container>
                          </mat-table>
                                          
                          <br />
                          <mat-paginator [pageSize]="certificateTablePageSize" [pageSizeOptions]="[5, 10, 50, firmFilteredCertificates.length]" #CertificateTablePaginator="matPaginator"
                              showFirstLastButtons></mat-paginator>
                      </div>
                  </div>
                  </mat-card-content>
                </mat-card>
              </ng-container>
            </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>                
                    <mat-icon>description</mat-icon> &nbsp; Applications ({{filteredApplications.length}})
                </ng-template>
                <ng-container>
                  <mat-card class="custom-mat-card">
                    <mat-card-title>
                        <div fxLayout="row">
                            <span fxFlex>Applications</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                      <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;" fxLayout="column">
                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">                          
                          <div fxLayout="column">
                            <strong class="noMarginHeader">Results ({{filteredApplications.length}})</strong>
                            <div fxLayout="row" fxLayout.lt-md="column">                            
                              <mat-form-field style="width:200px;">
                                <input placeholder="Filter Results" type="text" (input)="updateApplicationsDebounceString($event)" matInput/>                            
                              </mat-form-field>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <mat-form-field style="width:200px">
                                  <mat-select multiple placeholder="Filter by Status" [formControl]="applicationSelectedStatus" (selectionChange)="filterApplicationTable()">
                                    <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">
                                      {{ status }}
                                    </mat-option>
                                  </mat-select>
                              </mat-form-field>                 
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <mat-form-field style="width:200px">
                                  <mat-select multiple placeholder="Filter by Type" [formControl]="applicationSelectedType" (selectionChange)="filterApplicationTable()">
                                    <mat-option *ngFor="let type of getApplicationTypeValues()" [value]="type">
                                      {{ type }}
                                    </mat-option>
                                  </mat-select>
                              </mat-form-field> 
                            </div>
                          </div>
                          <div>                        
                            <button mat-raised-button color="primary" (click)="openApplication(null, true)">
                                <mat-icon>add</mat-icon> Add Application
                            </button>
                          </div>
                        </div>
                        <br />
        
                        <mat-table [dataSource]="applicationDataSource" matSort #ApplicationTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                            <ng-container *ngFor="let cellData of firmApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                    <ng-container *ngIf="cellData.type === 'date'">
                                      <div *ngIf="isMobile" fxLayout="row">  
                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                        <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                      </div>
                                      <div *ngIf="!isMobile">  
                                        {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                      </div>   
                                    </ng-container>
                                    <ng-container *ngIf="cellData.type !== 'date'">                            
                                        <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                          <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                            <div *ngIf="isMobile" fxLayout="row">  
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                <span>{{row[cellData.columnName]}}</span>
                                            </div>
                                            <div *ngIf="!isMobile">  
                                                {{row[cellData.columnName]}}
                                            </div>                                    
                                          </div>
                                        </ng-container>
                                        <ng-template #notStatus>
                                          <div *ngIf="isMobile" fxLayout="row">                                    
                                            <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                            <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                          </div>
                                          <div *ngIf="!isMobile">
                                              {{displayLimit(row[cellData.columnName], cellData.size)}}
                                          </div>  
                                        </ng-template>
                                    </ng-container>
                                </mat-cell>
                            </ng-container>                    
                          
                            <!-- Button column -->
                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                                <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                  <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                    <span><strong>Action: </strong></span>
                                    <button mat-icon-button color="primary" (click)="openApplication(row, true)">
                                        <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                                    </button>
                                  </div>
                                  <div *ngIf="!isMobile">
                                      <button mat-icon-button color="primary" (click)="openApplication(row, true)">
                                          <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                                      </button>
                                  </div> 
                                </mat-cell>
                            </ng-container>
                                                
                            <!-- Column definitions -->
                            <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="firmApplicationTable.layout.columns.concat('actions')"></mat-header-row>
                            <mat-row fxLayout="row" fxLayout.lt-md="column" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: firmApplicationTable.layout.columns.concat('actions')"></mat-row>
                          </mat-table>
                          
                        <br />
                        <mat-paginator [pageSize]="applicationPageSize" [pageSizeOptions]="[5, 10, 50, filteredApplications.length]" #ApplicationTablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>                
                    <mat-icon>{{uiData.credential.icon}}</mat-icon> &nbsp; Credentials ({{firmFilteredCredentials.length}})
                </ng-template>
                <ng-container>
                  <mat-card class="custom-mat-card">
                    <mat-card-title>
                        <div fxLayout="row" fxLayout.lt-lg="column">
                            <span fxFlex>Credentials</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <div fxLayout="column">
                        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                            <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                <div fxLayout="column">
                                    <strong class="noMarginHeader">Results ({{firmFilteredCredentials.length}})</strong> 
                                    <div fxLayout="row">
                                      <mat-form-field style="width:200px;">
                                        <input placeholder="Filter Results" type="text" (input)="updateCredentialDebounceString($event)" matInput/>                            
                                      </mat-form-field>      
                                    </div>
                                </div>                                     
                                <div>                        
                                  <button mat-raised-button color="primary" (click)="editCredential(null)">
                                      <mat-icon>add</mat-icon> Add Credential
                                  </button>
                                </div>
                            </div>
  
                            <br />
                
                            <mat-table [dataSource]="credentialDataSource" matSort #CredentialTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="credentialTable.layout.columns.concat('Action')"></mat-header-row>                  
                                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: credentialTable.layout.columns.concat('Action')" (click)="editCredential(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                    
                                <ng-container *ngFor="let cellData of credentialTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                  <mat-header-cell [fxFlex]="cellData.size" mat-sort-header *matHeaderCellDef [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{ cellData.displayName }}</mat-header-cell>
                                  <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                    <ng-container *ngIf="cellData.columnName === 'staffIds'; else otherCases">
                                      <div *ngIf="row[cellData.columnName] && row[cellData.columnName].length > 0">
                                          <div *ngIf="isMobile" fxLayout="row">  
                                              <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                              <span>{{row[cellData.columnName][0]?.display}}</span>
                                          </div>
                                          <div *ngIf="!isMobile">  
                                              {{row[cellData.columnName][0]?.display}}
                                          </div>
                                      </div>
                                    </ng-container>
                                      <ng-template #otherCases>
                                          <ng-container [ngSwitch]="cellData.type">
                                              <ng-container *ngSwitchCase="'date'">
                                                  <div *ngIf="isMobile" fxLayout="row">  
                                                      <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                      <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                                  </div>
                                                  <div *ngIf="!isMobile">  
                                                      {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                                  </div>              
                                              </ng-container>
                                              <ng-container *ngSwitchCase="'bool'">
                                                  <div *ngIf="isMobile" fxLayout="row">
                                                      <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                      <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                  </div>
                                                  <div *ngIf="!isMobile">
                                                      <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                                  </div>   
                                              </ng-container>
                                              <ng-container *ngSwitchDefault>
                                                  <div *ngIf="isMobile" fxLayout="row">                                    
                                                      <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                      <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                                  </div>
                                                  <div *ngIf="!isMobile">
                                                      {{displayLimit(row[cellData.columnName], cellData.size)}}
                                                  </div>  
                                              </ng-container>
                                          </ng-container>
                                      </ng-template>
                                  </mat-cell>
                              </ng-container>

                                <ng-container matColumnDef="Action">
                                  <mat-header-cell *matHeaderCellDef fxFlex="7" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Action</mat-header-cell>
                                  <mat-cell *matCellDef="let row" fxFlex="7" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                                    <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                      <span><strong>Action: </strong></span>
                                      <button title="Delete {{row['Name']}}" mat-icon-button color="warn" aria-label="Delete Person" (click)="deleteCredential(row); $event.stopPropagation()">
                                        <mat-icon>delete</mat-icon>
                                      </button>
                                    </div>
                                    <div *ngIf="!isMobile">
                                      <button title="Delete {{row['Name']}}" mat-icon-button color="warn" aria-label="Delete Person" (click)="deleteCredential(row); $event.stopPropagation()">
                                        <mat-icon>delete</mat-icon>
                                      </button>
                                    </div>                        
                                  </mat-cell>
                              </ng-container>
                            </mat-table>
                                            
                            <br />
                            <mat-paginator [pageSize]="credentialTablePageSize" [pageSizeOptions]="[5, 10, 50, firmFilteredCredentials.length]" #CredentialTablePaginator="matPaginator"
                                showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </mat-tab>

              <mat-tab>
                <ng-template mat-tab-label>                
                    <mat-icon>{{uiData.staff.icon}}</mat-icon> &nbsp; Staff ({{firmFilteredStaff.length}})
                </ng-template>
                <ng-container>
                  <mat-card class="custom-mat-card">
                    <mat-card-title>
                        <div fxLayout="row" fxLayout.lt-lg="column">
                            <span fxFlex>Staff</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <div fxLayout="column">
                        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                            <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                <div fxLayout="column">
                                    <strong class="noMarginHeader">Results ({{firmFilteredStaff.length}})</strong> 
                                    <div fxLayout="row">
                                      <mat-form-field style="width:200px;">
                                        <input placeholder="Filter Results" type="text" (input)="updateStaffDebounceString($event)" matInput/>                                                      
                                      </mat-form-field>     
                                    </div>
                                </div>                                     
                                <div>                        
                                  <button mat-raised-button color="primary" (click)="editStaff(null)">
                                      <mat-icon>add</mat-icon> Add Staff
                                  </button>
                                </div>
                            </div>

                            <br />
                
                            <mat-table [dataSource]="staffDataSource" matSort #StaffTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="staffTable.layout.columns.concat('Actions')"></mat-header-row>                  
                                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: staffTable.layout.columns.concat('Actions');" (click)="editStaff(row); $event.stopPropagation()" title="View/Edit {{row.firstName + ' ' + row.lastName}}"></mat-row>                                      
                                                    
                                <ng-container *ngFor="let cellData of staffTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                    <mat-header-cell [fxFlex]="cellData.size" mat-sort-header *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{ cellData.displayName }}</mat-header-cell>
                                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                        <ng-container [ngSwitch]="cellData.type">
                                            <ng-container *ngSwitchCase="'date'">
                                              <div *ngIf="isMobile" fxLayout="row">  
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                              </div>
                                              <div *ngIf="!isMobile">  
                                                  {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                              </div>  
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'bool'">
                                              <div *ngIf="isMobile" fxLayout="row"> 
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp; 
                                                <mat-checkbox [checked]="row[cellData.columnName]" disabled></mat-checkbox>
                                              </div>
                                              <div *ngIf="!isMobile">  
                                                <mat-checkbox [checked]="row[cellData.columnName]" disabled></mat-checkbox>
                                              </div>                                              
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                              <div *ngIf="isMobile" fxLayout="row">                                    
                                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                <span>{{ displayLimit(row[cellData.columnName], 13) }}</span>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  {{displayLimit(row[cellData.columnName], cellData.size)}}
                                              </div> 
                                            </ng-container>
                                        </ng-container>
                                    </mat-cell>
                                </ng-container> 
                                
                                <ng-container matColumnDef="Actions">
                                  <mat-header-cell *matHeaderCellDef fxFlex="10" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '2px'} : {}">Actions</mat-header-cell>
                                  <mat-cell *matCellDef="let row" fxFlex="10" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '2px'} : {}">
                                    <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                      <span><strong>Actions: </strong></span>
                                      <button title="Delete {{row['Name']}}" mat-icon-button color="warn" aria-label="Delete Person" (click)="deleteStaff(row); $event.stopPropagation()">
                                        <mat-icon>delete</mat-icon>
                                      </button>
                                      <button *ngIf="!row.isPOC" title="Add Staff Member as a Point of Contact" mat-icon-button color="primary" (click)="updateStaffMemberPOCStatus(row); $event.stopPropagation()" aria-label="Add Staff Member as a Point of Contact">
                                        <mat-icon>person_add</mat-icon>
                                      </button>
                                      <button *ngIf="row.isPOC" title="Remove Staff Member as a Point of Contact" mat-icon-button color="primary" (click)="updateStaffMemberPOCStatus(row); $event.stopPropagation()" aria-label="Remove Staff Member as a Point of Contact">
                                        <mat-icon>person_remove</mat-icon>
                                      </button>
                                    </div>
                                    <div *ngIf="!isMobile" fxLayout="row">
                                      <button title="Delete {{row['Name']}}" mat-icon-button color="warn" aria-label="Delete Person" (click)="deleteStaff(row); $event.stopPropagation()">
                                        <mat-icon>delete</mat-icon>
                                      </button>
                                      <button *ngIf="!row.isPOC" title="Add Staff Member as a Point of Contact" mat-icon-button color="primary" (click)="updateStaffMemberPOCStatus(row); $event.stopPropagation()" aria-label="Add Staff Member as a Point of Contact">
                                        <mat-icon>person_add</mat-icon>
                                      </button>
                                      <button *ngIf="row.isPOC" title="Remove Staff Member as a Point of Contact" mat-icon-button color="primary" (click)="updateStaffMemberPOCStatus(row); $event.stopPropagation()" aria-label="Remove Staff Member as a Point of Contact">
                                        <mat-icon>person_remove</mat-icon>
                                      </button>
                                    </div>    
                                  </mat-cell>
                                </ng-container>
                            </mat-table>
                                            
                            <br />
                            <mat-paginator [pageSize]="staffTablePageSize" [pageSizeOptions]="[5, 10, 50, firmFilteredStaff.length]" #StaffTablePaginator="matPaginator"
                                showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </mat-tab>

              <mat-tab *ngIf="false">
                <ng-template mat-tab-label>                
                    <mat-icon>account_circle</mat-icon> &nbsp; Administrators
                </ng-template>
                <ng-container>
                  <mat-card class="custom-mat-card">
                    <mat-card-title>
                        <div fxLayout="row" fxLayout.lt-lg="column">
                            <span fxFlex>Administrators</span>
                        </div>
                    </mat-card-title>
                    <mat-card-content>
                        <div fxLayout="column">
                        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                            <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                <div fxLayout="column">
                                  <strong class="noMarginHeader">Results ({{firmFilteredAdministrators.length}})</strong> 
                                  <div fxLayout="row" fxLayout.lt-md="column">
                                    <mat-form-field style="width:200px;">
                                      <input placeholder="Filter Results" type="text" (input)="updateAdministratorDebounceString($event)" matInput/>                                                      
                                    </mat-form-field>  
                                    &nbsp;&nbsp;
                                    <mat-form-field style="width:200px;">
                                      <mat-label>Filter Administrator Status</mat-label>
                                      <mat-select (selectionChange)="filterAdministratorTable()" [formControl]="administratorStatusFilter">
                                        <mat-option value="Active">Active</mat-option>
                                        <mat-option value="Inactive">Inactive</mat-option>
                                        <mat-option value="Both">Both</mat-option>
                                      </mat-select>
                                    </mat-form-field>    
                                  </div>  
                                </div>                                     
                                <div>                        
                                  <button mat-raised-button color="primary" (click)="editAdministrator(null)">
                                      <mat-icon>add</mat-icon> Add Administrator
                                  </button>
                                </div>
                            </div>

                            <br />
                
                            <mat-table [dataSource]="administratorDataSource" matSort #AdministratorTableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="administratorTable.layout.columns"></mat-header-row>                  
                                <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; columns: administratorTable.layout.columns;" (click)="editAdministrator(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>                                      
                                                    
                                <ng-container *ngFor="let cellData of administratorTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                    <mat-header-cell [fxFlex]="cellData.size" mat-sort-header *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{ cellData.displayName }}</mat-header-cell>
                                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                        <ng-container [ngSwitch]="cellData.type">
                                            <ng-container *ngSwitchCase="'date'">
                                              <div *ngIf="isMobile" fxLayout="row">  
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                              </div>
                                              <div *ngIf="!isMobile">  
                                                  {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                              </div>      
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'bool'">
                                              <div *ngIf="isMobile" fxLayout="row">
                                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                              </div>   
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                              <div *ngIf="isMobile" fxLayout="row">                                    
                                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                <span>{{ displayLimit(row[cellData.columnName], 14) }}</span>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  {{displayLimit(row[cellData.columnName], cellData.size)}}
                                              </div>    
                                            </ng-container>
                                        </ng-container>
                                    </mat-cell>
                                </ng-container>                                                                                                                                                      
                            </mat-table>
                                            
                            <br />
                            <mat-paginator [pageSize]="administratorTablePageSize" [pageSizeOptions]="[5, 10, 50, firmFilteredAdministrators.length]" #AdministratorTablePaginator="matPaginator"
                                showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                    </mat-card-content>
                  </mat-card>
                </ng-container>
              </mat-tab>

               <!-- Payments tab -->   
               <mat-tab>
                <ng-template mat-tab-label>                
                    <mat-icon>{{uiData.payment.icon}}</mat-icon> &nbsp; Payments
                </ng-template>
                <ng-container>
                    <div *ngIf="firm.id === 0" class="text-red-400"><strong><br />Firm Needs to be Saved</strong></div>
                            <div fxLayout="column" fxLayoutGap="8px" *ngIf="firm.id > 0">
                                <app-manage-payments [@slideLeft]
                                           [entityId]="firm.id.toString()"
                                           [entityType]="paymentService.FirmEntityType"
                                           [allowMultiplePayments]='true'
                                           [editingPayment]='true'
                                           [allowDelete]='true'
                                           [cardColor]="cardColor"
                                           [changeIndicator]="changeIndicator">
                      </app-manage-payments>                
                            </div>
                </ng-container>
            </mat-tab>   
            <!-- Complaints tab -->   
            <mat-tab>
              <ng-template mat-tab-label>                
                  <mat-icon>{{'create_new_folder'}}</mat-icon> Complaints ({{complaints.length}})
              </ng-template>
              <mat-card class="custom-mat-card">
                  <mat-card-title>
                      <div fxLayout="row">
                          <span>Complaints</span>
                      </div>
                  </mat-card-title>
                  <mat-card-content>
              <ng-container>
                  <div *ngIf="firm.id === 0" class="text-red-400"><strong><br />Firm Needs to be Saved</strong></div>
                          <div fxLayout="column" fxLayoutGap="8px" *ngIf="firm.id > 0">
                            <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                              <div></div>                                                        
                              <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
                                  <button mat-raised-button color="primary" title="Add Complaint" (click)="editComplaint(null)">
                                    <mat-icon>add</mat-icon> Add Complaint
                                  </button>                
                                </div>
                            </div>
                              <mat-table [dataSource]="complaintDataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                  <ng-container *ngFor="let cellData of complaintsTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                      <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                                      <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                                          <ng-container *ngIf="cellData.type === 'date'">
                                              <div *ngIf="isMobile" fxLayout="row">  
                                                  <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                  <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                              </div>
                                              <div *ngIf="!isMobile">  
                                                  {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                              </div>
                                            </ng-container>
                                            <ng-container *ngIf="cellData.type === 'boolean'">
                                              <div *ngIf="isMobile" fxLayout="row">
                                                  <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                  <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                              </div>                            
                                            </ng-container>
                                            <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                              <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                                  <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                                      <div *ngIf="isMobile" fxLayout="row">  
                                                          <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                                          <span>{{row[cellData.columnName]}}</span>
                                                      </div>
                                                      <div *ngIf="!isMobile">  
                                                          {{row[cellData.columnName]}}
                                                      </div>                                    
                                                  </div>
                                              </ng-container>
                                              <ng-template #notStatus> 
                                              <div *ngIf="isMobile" fxLayout="row">                                    
                                                  <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                                  <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                              </div>
                                              <div *ngIf="!isMobile">
                                                  {{displayLimit(row[cellData.columnName], cellData.size)}}
                                              </div> 
                                              </ng-template> 
                                            </ng-container>
                                      </mat-cell>
                                  </ng-container>    
                                  <!-- Column definitions -->
                                  <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintsTable.layout.columns"></mat-header-row>
                                  <mat-row fxLayout="row" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintsTable.layout.columns"
                                  (click)="editComplaint(row); $event.stopPropagation()" title="Edit {{row.Name}}"></mat-row>
                                </mat-table>            
                          </div>
              </ng-container>
          </mat-card-content>
          </mat-card>
          </mat-tab>    
          </mat-tab-group>
        </mat-card>
      </div>
    </mat-dialog-content>

    <mat-card [style.background-color]="uiData.firm.color">
      <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="saveChanges()">Save Changes</button>
      <button mat-raised-button (click)="exitComponent()">Exit</button>          
    </mat-card>
  </div>