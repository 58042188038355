import { Component, Inject, OnInit, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatExpansionPanel } from '@angular/material/expansion';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-match-student-uploads-to-existing',
  templateUrl: './match-student-uploads-to-existing.component.html',
  styleUrls: ['./match-student-uploads-to-existing.component.scss']
})
export class MatchStudentUploadsToExistingComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT;
  public isMobile: boolean = false;
  public loading: boolean = false;
  public columnNames: string[] = ['select', 'id', 'firstName', 'lastName', 'middleName', 'dateOfBirth', 'response'];
  public columnData: any = {
    select: { displayName: '', displaySize: 10, displayType: 'boolean' },
    id: { displayName: 'ID', displaySize: 10, displayType: 'number' },
    firstName: { displayName: 'First Name', displaySize: 15, displayType: 'string' },
    lastName: { displayName: 'Last Name', displaySize: 15, displayType: 'string' },
    middleName: { displayName: 'Middle Name', displaySize: 15, displayType: 'string' },
    dateOfBirth: { displayName: 'Date of Birth', displaySize: 15, displayType: 'date' },
    response: { displayName: 'Address', displaySize: 20, displayType: 'string' }
  };
  public dataSource: MatTableDataSource<any>[] = [];  

  @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

  constructor(
    public sharedService: SharedService,
    private dialogRef: MatDialogRef<MatchStudentUploadsToExistingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.data.students.sort((a: any, b: any) => {
      const keyA = `${a.firstName}${a.lastName}`;
      const keyB = `${b.firstName}${b.lastName}`;
      const matchesA = this.data.possibleMatches[keyA] || [];
      const matchesB = this.data.possibleMatches[keyB] || [];
      return matchesA.length === 0 ? 1 : matchesB.length === 0 ? -1 : 0;
    });

    this.data.students.forEach((student: any) => {
      const key = `${student.firstName}${student.lastName}`;
      const matches = this.data.possibleMatches[key] || [];
      this.dataSource.push(new MatTableDataSource(matches));
    });
  }

  openNextPanel(index: number): void {
    const panelsArray = this.panels.toArray();
  
    for (let i = index; i < panelsArray.length; i++) {
      const currentPanel = panelsArray[i];
      const student = this.data.students[i];
  
      if ((!student.selectedPerson && !currentPanel.disabled) && this.dataSource[i].data.length > 0) {
        currentPanel.open();
        break;
      }
    }
  }
  
  updateSelection(row: any, event: any, index: number): void {
    const student = this.data.students[index];      
    student.selectedPerson = row?.id ? row : null;
    this.cdr.detectChanges();

    const currentPanel = this.panels.toArray()[index];
    if (currentPanel) {
      currentPanel.close();
    }

    this.openNextPanel(index + 1);    
  }
  
  clearSelection(index: number, event: any): void {
    event.stopPropagation();
    const student = this.data.students[index];
    student.selectedPerson = null;
    this.cdr.detectChanges();    
  }

  returnResults() {
    this.dialogRef.close(this.data);
  }
}