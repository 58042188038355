import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileDisplayName' })
export class FileDisplayNamePipe implements PipeTransform {
  transform(fileName: string, maxLength: number = 20): string {
    if (!fileName) {
      return '';
    }

    if (fileName.length > maxLength) {
      const extension = fileName.split('.').pop();
      const fileNameWithoutExtension = fileName.substring(0, fileName.length - extension.length - 1);
      const truncatedFileName = fileNameWithoutExtension.slice(0, maxLength - extension.length - 4);
      return `${truncatedFileName}...${extension}`;
    }

    return fileName;
  }
}
