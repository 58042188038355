<div *ngIf="sharedService.loading || loading">
    <div id="globalLoader" class="global-loader" [ngStyle]="{ 'background-color': sharedService.darkMode ? '#333' : '#fff' }">
        <h1>Loading</h1>
    </div>
</div>
<div fxLayout="column">    
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
            <mat-card [style.background-color]="uiData.complaint.color" fxFlex="100">
                <h2 class="noMarginHeader">
                    <mat-icon>{{'create_new_folder'}}</mat-icon> {{'Complaints'}}
                </h2>
            </mat-card>
        </div>
    </div>

    <mat-card>
        <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
            <div fxLayoutGap="5px" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
                <strong class="noMarginHeader">Results ({{filteredComplaints.length}})</strong>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <mat-form-field style="width:200px;">
                    <input placeholder="Filter by name" [(ngModel)]="filterString" matInput (keydown.enter)="filterTable()"/>                    
                </mat-form-field>            
                <mat-form-field style="width:200px">
                    <mat-select placeholder="Filter by Inactive Status" [formControl]="filteredStatus">
                        <mat-option *ngFor="let status of getComplaintStatuses()" [value]="status">
                            {{ status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
                <mat-form-field style="width:200px">
                    <mat-select placeholder="Filter by Type" [formControl]="filteredComplaintType">
                        <mat-option *ngFor="let type of getComplaintTypes()" [value]="type">
                            {{ type }}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 

                <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxFlex>
                    <button mat-raised-button color="primary" (click)="filterTable()">Search</button>                
                    <button mat-raised-button (click)="clearFilters()">Reset Filters</button> 
                    <div fxFlex="1 1 auto"></div>
                    <button mat-raised-button color="primary" title="Add Complaint" (click)="editComplaint(null)">
                      <mat-icon>add</mat-icon> Add Complaint
                    </button>                
                </div>
            </div>           
            <br />
            <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                <ng-container *ngFor="let cellData of complaintsTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                    <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                    <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" matTooltip="{{row[cellData.columnName]}}">
                        <ng-container *ngIf="cellData.type === 'date'">
                            <div *ngIf="isMobile" fxLayout="row">  
                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                            </div>
                            <div *ngIf="!isMobile">  
                                {{(row[cellData.columnName] | date: 'MM/dd/yyyy')}}
                            </div>
                          </ng-container>
                          <ng-container *ngIf="cellData.type === 'boolean'">
                            <div *ngIf="isMobile" fxLayout="row">
                                <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                            </div>
                            <div *ngIf="!isMobile">
                                <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                            </div>                            
                          </ng-container>
                          <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                            <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                    <div *ngIf="isMobile" fxLayout="row">  
                                        <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                        <span>{{row[cellData.columnName]}}</span>
                                    </div>
                                    <div *ngIf="!isMobile">  
                                        {{row[cellData.columnName]}}
                                    </div>                                    
                                </div>
                            </ng-container>
                            <ng-template #notStatus> 
                            <div *ngIf="isMobile" fxLayout="row">                                    
                                <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                            </div>
                            <div *ngIf="!isMobile">
                                {{displayLimit(row[cellData.columnName], cellData.size)}}
                            </div> 
                            </ng-template> 
                          </ng-container>
                    </mat-cell>
                </ng-container>                    
              
                <!-- Button column -->
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                        <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                            <span><strong>Action: </strong></span>
                            <button mat-icon-button color="primary" (click)="editComplaint(row); $event.stopPropagation()">
                                <mat-icon>{{'edit'}}</mat-icon>
                            </button>
                        </div>
                        <div *ngIf="!isMobile">
                            <button mat-icon-button color="primary"  (click)="editComplaint(row); $event.stopPropagation()">
                                <mat-icon>{{'edit'}}</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
                                    
                <!-- Column definitions -->
                <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="complaintsTable.layout.columns.concat('actions')"></mat-header-row>
                <mat-row fxLayout="row" (click)="editComplaint(row); $event.stopPropagation()" fxLayout.lt-md="column" *matRowDef="let row; index as i; columns: complaintsTable.layout.columns.concat('actions')"></mat-row>
              </mat-table>
              
            <br />
            <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, 5]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
        </div>              
    </mat-card>   
</div>
