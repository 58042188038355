import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { IFirm } from 'app/models/firms/firm';
import { FirmService } from 'app/services/firm/firm-service';
import { FirmAddEditComponent } from './add-edit/firm-add-edit.component';
import { FormControl } from '@angular/forms';
import { FIRM_SERVICES, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MergeFirmComponent } from './merge-firm/merge-firm.component';
import { IFirmFilter } from 'app/models/filters/firmFilter';
import { IFirmCertificate } from 'app/models/firms/firmCertificate';
import { AddEditFirmCertificateComponent } from './add-edit/add-edit-firm-certificate/add-edit-firm-certificate.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss',]
})

export class FirmComponent implements OnInit {
  public isMobile: boolean = false;
  public uiData = LEAD_BASED_PAINT;
  public includeCCB: boolean = false;
  public filterString: string = '';
  public filteredFirms = [];  
  public filteredActive = new FormControl('Both');
  public filteredFirmType = new FormControl('Both');
  public expirationStartDate = new FormControl(new Date());
  public expirationEndDate = new FormControl(new Date());
  public selectedFirms: IFirm[] = [];
  public firmFilter: IFirmFilter = {
    filterString: null,
    filterStatus: null,
    filterType: null,
    expirationStartDate: null,
    expirationEndDate: null,  
    includeCCB: false,  
  }

  processing: boolean = false;
  loading: boolean = true;
  pageSize = 50;  
  firms: IFirm[] = []
  
  public dataSource: MatTableDataSource<any>;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  firmTable = {        
    layout:{
      columns:['select', 'id', 'inactive', 'name', 'ccb', 'ccbExpirationDate','certificationNumber', 'certificates'],
      container:[
        {displayName:'',columnName:'select', type:'select', size:'5'},
        {displayName:'Firm Id',columnName:'id', type:'string', size:'7'},
        {displayName:'Inactive',columnName:'inactive', type:'boolean', size:'6'},
        {displayName:'Firm Name',columnName:'name', type:'string', size:'11'},        
        {displayName:'CCB/PML',columnName:'ccb', type:'string', size:'10'},          
        {displayName:'CCB Expiration Date',columnName:'ccbExpirationDate', type:'date', size:'10'},     
        {displayName:'Certification Number',columnName:'certificationNumber', type:'string', size:'10'},          
        {displayName:'Certificates',columnName:'certificates', type:'subtable', size:'41'},
      ],
      data: []      
    }
  }

  subtableColumns = ['codeType', 'expirationDate', 'actions'];
  subtableDisplayData = [
    {displayName:'Type',columnName:'codeType', type:'string', size:'56'},
    {displayName:'Expiration',columnName:'expirationDate', type:'date', size:'30'},            
  ];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public commonService: CommonDataService, 
    private firmService: FirmService,
    private toastr: ToastrService
  ) 
  {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  ngOnInit(): void {
    this.loadFilters();
    this.loadFirms();
  }

  exportToExcel() {
    const exportData = [];
      
    this.filteredFirms.forEach(firm => {
      firm.certificates.forEach(cert => {        
        const row = {
          Name: firm.name,     
          CCBorPML: firm.ccb,
          CCBExpiration: firm.ccbExpirationDate?.split('T')[0],          
          Certification: cert.codeType,       
          Expiration: cert.expirationDate?.split('T')[0],
        };          
        exportData.push(row);
      });
    });
      
    exportData.sort((a, b) => a.Name.localeCompare(b.Name));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
      
    ws['!cols'] = [
      { wpx: 350 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 80 },
      { wpx: 80 },
    ];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.filterString !== '' ? this.filterString.replace(/\s+/g, '') + '.xlsx' : 'exportedFirmCertificates.xlsx');
  }

  loadFilters(){
    this.filterString = this.commonService.firmManagementFilter.filterString;
    this.filteredActive = this.commonService.firmManagementFilter.filteredActive;
    this.expirationStartDate = this.commonService.firmManagementFilter.expirationStartDate;
    this.expirationEndDate = this.commonService.firmManagementFilter.expirationEndDate;
    this.filteredFirmType = this.commonService.firmManagementFilter.filteredFirmType;
    this.includeCCB = this.commonService.firmManagementFilter.includeCCB;
  }

  updateSelected(selectedFirm: IFirm){
    const index = this.selectedFirms.findIndex(firm => firm.id === selectedFirm.id);
    if (index !== -1){
      this.selectedFirms.splice(index, 1);
    } else {
      this.selectedFirms.push(selectedFirm);
    }    
  }

  isSelected(selectedFirm: IFirm): boolean {    
    return this.selectedFirms.some(firm => firm.id === selectedFirm.id);
  }

  mergeFirms(){
    const dialogRef = this.dialog.open(MergeFirmComponent, {
      width: '90%',
      autoFocus: false,
      data: this.selectedFirms,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {  
      if(result){
        this.selectedFirms = [];
        this.refreshFirms();
      }
    });
  }

  getMergeTooltip(): string {
    if (this.selectedFirms.length > 0) {
        return 'Merge: ' + this.selectedFirms.map(firm => `${firm.id}: ${firm.name}`).join(', ');
    }
    return 'Merges selected Firms';
  }

  loadFirms() {    
    this.commonService.firmManagementFilter.filterString = this.filterString;
    this.commonService.firmManagementFilter.filteredActive = this.filteredActive;
    this.commonService.firmManagementFilter.expirationStartDate = this.expirationStartDate;
    this.commonService.firmManagementFilter.expirationEndDate = this.expirationEndDate;
    this.commonService.firmManagementFilter.filteredFirmType = this.filteredFirmType;
    this.commonService.firmManagementFilter.includeCCB = this.includeCCB;

    this.firmFilter.filterString = this.filterString;
    this.firmFilter.filterStatus = this.filteredActive?.value;
    this.firmFilter.filterType = this.filteredFirmType?.value;
    this.firmFilter.expirationStartDate = this.expirationStartDate?.value?.toISOString().substring(0,10);
    this.firmFilter.expirationEndDate = this.expirationEndDate?.value?.toISOString().substring(0,10);
    this.firmFilter.includeCCB = this.includeCCB;
    this.refreshFirms();    
  }

  refreshFirms(){
    this.firmService.getFilteredFirms(this.firmFilter).subscribe(
      result => {        
        this.firms = result.sort((a, b) => b.id - a.id);             
        this.filteredFirms = this.firms;
        this.updateDataSource();
        this.loading = false;
      },
      error => {
        this.toastr.error(error.message);
        this.loading = false;
      },
    ); 
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredFirms);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editFirm(firm: IFirm) {    
    const dialogRef = this.dialog.open(FirmAddEditComponent, {
      width: '90%',
      data: firm,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  editCertificate(certificate: IFirmCertificate) {        
    const dialogRef = this.dialog.open(AddEditFirmCertificateComponent, {
      width: this.isMobile ?'90%':'30%',
      data: {certId: certificate.id},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
    });
  }

  getServiceTypes(){
    return Object.values(FIRM_SERVICES);
  }

  getFirmActiveTypes(){
    return ['Active','InActive'];
  }

  clearFilters(){
    this.filterString = '';
    this.filteredActive.setValue('Active');
    this.expirationStartDate.reset();
    this.expirationEndDate.reset();
    this.filteredFirmType.setValue('');
    this.includeCCB = false;
    this.loadFirms();
  }

  isObjectOrArray(value: any): boolean {
    return value && (Array.isArray(value) || typeof value === 'object');
  }

  handleFirmRowClick(row: any, event: any){    
    if(!event.target.className.includes("header")){
      this.editFirm(row);
    }
  }
}
