    <div *ngIf="sharedService.loading || loading">
        <div id="globalLoader" class="global-loader" [ngStyle]="{ 'background-color': sharedService.darkMode ? '#333' : '#fff' }">
            <h1>Loading</h1>
        </div>
    </div>

    <div fxLayout="column">
        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
            <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
                <mat-card [style.background-color]="uiData.firm.color" fxFlex="100">
                    <h2 class="noMarginHeader">
                        <mat-icon>{{firmApplicationTable.icon}}</mat-icon> {{firmApplicationTable.title}}
                    </h2>
                </mat-card>
            </div>
        </div>

        <mat-card>
            <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                    <div fxLayoutGap="5px">
                        <strong class="noMarginHeader">Results ({{filteredApplications.length}})</strong>
                        
                        <mat-form-field style="width:130px;">
                            <input (keyup)="filterTable()" placeholder="Filter UserID,Id"  [(ngModel)]="filterString" matInput/>                    
                        </mat-form-field>
                        
                        <mat-form-field style="width:180px">
                            <mat-select multiple placeholder="Filter by Status" [formControl]="selectedStatus" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let status of getStatusTypeValues()" [value]="status">
                                {{ status }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>                 
                        
                        <mat-form-field style="width:180px">
                            <mat-select multiple placeholder="Filter by Type" [formControl]="selectedType" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let type of getApplicationTypeValues()" [value]="type">
                                {{ type }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field>                             
                        
                        <mat-form-field style="width:180px">
                            <mat-select placeholder="Expiring/Outstanding" [formControl]="selectedRenewal" (selectionChange)="filterTable()">
                            <mat-option *ngFor="let type of getApplicationFilterValues()" [value]="type">
                                {{ type }}
                            </mat-option>
                            </mat-select>
                        </mat-form-field> 
                        
                        <mat-form-field style="width:180px">                                                                
                            <input (dateChange)="filterTable()" matInput [matDatepicker]="applicationDateStartPicker" placeholder="Application Start Date" [formControl]="applicationDateFilterStart">
                            <mat-datepicker-toggle matSuffix [for]="applicationDateStartPicker"></mat-datepicker-toggle>
                            <button matSuffix mat-icon-button aria-label="Clear date" (click)="applicationDateFilterStart.reset(); filterTable()">
                                <mat-icon style="color: red;">close</mat-icon>
                            </button>  
                            <mat-datepicker #applicationDateStartPicker></mat-datepicker>
                        </mat-form-field>   
                        
                        <mat-form-field style="width:180px">                                                                
                            <input (dateChange)="filterTable()" matInput [matDatepicker]="applicationDateEndPicker" placeholder="Application End Date" [formControl]="applicationDateFilterEnd">
                            <mat-datepicker-toggle matSuffix [for]="applicationDateEndPicker"></mat-datepicker-toggle>
                            <button matSuffix mat-icon-button aria-label="Clear date" (click)="applicationDateFilterEnd.reset(); filterTable()">
                                <mat-icon style="color: red;">close</mat-icon>
                            </button>  
                            <mat-datepicker #applicationDateEndPicker></mat-datepicker>
                        </mat-form-field>                                                         
                    </div>
                    <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
                        <button mat-raised-button (click)="clearFilters()">Reset Filters</button>
                        <button mat-raised-button color="primary" title="Add Firm Application" (click)="openApplication(null,true)">
                          <mat-icon>add</mat-icon> Add Firm Application
                        </button>                
                    </div>                  
                </div>
                <br />

                <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                    <ng-container *ngFor="let cellData of firmApplicationTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                        <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
                        <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" matTooltip="{{row[cellData.columnName]}}" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                            <ng-container *ngIf="cellData.type === 'date'">
                                <div *ngIf="isMobile" fxLayout="row">  
                                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                    <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                                </div>
                                <div *ngIf="!isMobile">  
                                    {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                                </div>  
                            </ng-container>
                            <ng-container *ngIf="cellData.type !== 'date'">                            
                                <ng-container *ngIf="cellData.columnName === 'status'; else notStatus">
                                    <div class="status-card" [ngStyle]="{'--status-color': statusColor(row[cellData.columnName])}">
                                        <div *ngIf="isMobile" fxLayout="row">  
                                            <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                                            <span>{{row[cellData.columnName]}}</span>
                                        </div>
                                        <div *ngIf="!isMobile">  
                                            {{row[cellData.columnName]}}
                                        </div>                                    
                                    </div>
                                </ng-container>
                                <ng-template #notStatus>
                                    <div *ngIf="isMobile" fxLayout="row">                                    
                                        <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                                        <span>{{ displayLimit(row[cellData.columnName], cellData.size) }}</span>
                                    </div>
                                    <div *ngIf="!isMobile">
                                        {{displayLimit(row[cellData.columnName], cellData.size)}}
                                    </div> 
                                </ng-template>
                            </ng-container>
                        </mat-cell>
                    </ng-container>                    
                  
                    <!-- Button column -->
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                        <mat-cell *matCellDef="let row" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                            <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;"> 
                                <span><strong>Action: </strong></span>
                                <button mat-icon-button color="primary" (click)="openApplication(row,true); $event.stopPropagation()">
                                    <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                                </button>
                            </div>
                            <div *ngIf="!isMobile">
                                <button mat-icon-button color="primary" (click)="openApplication(row,true); $event.stopPropagation()">
                                    <mat-icon>{{row['status'] === 'Approved' ? 'remove_red_eye' : 'edit'}}</mat-icon>
                                </button>
                            </div>   
                        </mat-cell>
                    </ng-container>
                                        
                    <!-- Column definitions -->
                    <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="firmApplicationTable.layout.columns.concat('actions')"></mat-header-row>
                    <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="openApplication(row,true); $event.stopPropagation()" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: firmApplicationTable.layout.columns.concat('actions')"></mat-row>
                  </mat-table>
                  
                <br />
                <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, filteredApplications.length]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
            </div>              
        </mat-card>   
    </div>