<div fxLayout="column">
  <mat-card [style.background-color]="uiData.individual.color">
      <mat-card-title>
          {{'Add Affiliation'}}
      </mat-card-title>
  </mat-card>
  <div class="scroll-container">    
    <mat-form-field style="width: 100%;">
      <mat-label>Select Affiliation</mat-label>
      <input matInput type="text" placeholder="Type to filter" [(ngModel)]="selectedEntity" [matAutocomplete]="auto" name="selectedFirm" required (input)="filterEntities($event)">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let firm of filteredEntityList" [value]="firm.name">{{firm.name}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>  
  </div>
  <mat-card [style.background-color]="uiData.individual.color" fxLayout="row wrap" fxLayoutGap="8px">    
    <button mat-raised-button color="primary" [disabled]="!selectedEntity" (click)="onSubmit()">Add</button>
    <button mat-raised-button (click)="onCancel()">Cancel</button>        
  </mat-card>
</div>