import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IComplaint } from 'app/models/complaint/complaint';
import { CommonDataService } from 'app/services/common/common-data.service';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { SharedService } from 'app/services/core/shared.service';
import { AddEditComplaintComponent } from './add-edit-complaint/add-edit-complaint.component';
import { getStatusColor, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IComplaintFilter } from 'app/models/filters/complaintFilter';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent {
   public statusColor = getStatusColor;
    public isMobile: boolean = false;
    public loading: boolean = true;
    public pageSize: number = 50;  
    public complaints: IComplaint[] = [];
    public dataSource: MatTableDataSource<any>;
    public filterString: string = '';
    public filteredComplaints = [];
    public filteredStatus = new FormControl('Open');
    public filteredComplaintType = new FormControl('All');
    public uiData = LEAD_BASED_PAINT;

    private filter: IComplaintFilter = {
      filterString: '',
      filterStatus: 'Open',
      filterType: 'All'
    }

    @ViewChild('TablePaginator') paginator: MatPaginator;
    @ViewChild('TableSort') sort: MatSort;
    
    complaintsTable = {
      icon: 'recent_actors',
      title:'Complaints',
      layout:{
        columns:['id', 'complainteeName', 'complainantName', 'status', 'complaintDate', 'lastUpdatedBy'],
        container:[
          {displayName:'Id',columnName:'id', type:'string', size:'5'},
          {displayName:'Complaint Source Name',columnName:'complainteeName', type:'string', size:'20'},
          {displayName:'Complaint Target Name',columnName:'complainantName', type:'string', size:'20'},        
          {displayName:'Status',columnName:'status', type:'string', size:'10'},          
          {displayName:'Complaint Date',columnName:'complaintDate', type:'date', size:'8'},     
          {displayName:'Last Updated By',columnName:'lastUpdatedBy', type:'string', size:'15'}, 
        ],
        data: []      
      }
    }
  
  constructor(private breakpointObserver: BreakpointObserver,
    public sharedService: SharedService,
    public dialog: MatDialog,
    public toastr: ToastrService,
    public commonService: CommonDataService,
    public complaintService: ComplaintService) 
  {
    this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      });
      this.loadComplaints();
  }

  loadComplaints(): void {
    this.filterString = this.commonService.complaintsFilter.filterString;
    this.filteredStatus = this.commonService.complaintsFilter.filteredStatus;
    this.filteredComplaintType = this.commonService.complaintsFilter.filteredComplaintType;
    this.refreshComplaints();
  }

  refreshComplaints(){
    this.filter.filterStatus = this.filteredStatus.value;
    this.filter.filterString = this.filterString;
    this.filter.filterType = this.filteredComplaintType.value;

    this.complaintService.getComplaints(this.filter).subscribe(result=>{      
      this.complaints = result;    
      this.filteredComplaints = result;  
      this.updateDataSource();
      this.loading = false;
    },error=>{
      this.toastr.error('Error loading complaints:', error.error);
    })
  }

  updateDataSource(){
    this.dataSource = new MatTableDataSource<any>(this.filteredComplaints);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  getComplaintStatuses(){
    return ['Open','Closed','Both'];
  }

  getComplaintTypes(){
    return ['Atp','Firm','Individual','All'];
  }

  clearFilters(){
    this.filterString = '';    
    this.filteredStatus.setValue('Open');
    this.filteredComplaintType.setValue('All');
    this.filterTable();
  }

  filterTable() { 
    this.commonService.complaintsFilter.filterString = this.filterString;
    this.commonService.complaintsFilter.filteredStatus = this.filteredStatus;
    this.commonService.complaintsFilter.filteredComplaintType = this.filteredComplaintType;
    
    this.refreshComplaints(); 
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  editComplaint(complaint: IComplaint) {    
    if(complaint != null && complaint.id > 0){
      this.complaintService.getComplaintById(complaint.id).subscribe(result=>{   
        const dialogRef = this.dialog.open(AddEditComplaintComponent, {
          width: '90%',
          data: {complaint : result },
          panelClass: this.sharedService.darkMode ? "theme-dark" : ""
        });
        dialogRef.afterClosed().subscribe(result => {  
          this.loadComplaints();    
        });      
      },error=>{  
      });   
      }else{ const dialogRef = this.dialog.open(AddEditComplaintComponent, {
        width: '90%',
        data: {complaint : null },
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
      });
      dialogRef.afterClosed().subscribe(result => {  
        this.loadComplaints();    
      });
    }   
  }
}
