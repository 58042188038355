import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AtpService } from 'app/services/atp/atp.service';
import { ToastrService } from 'ngx-toastr';
import { AddEditAtpCourseComponent } from '../add-edit-atp-course/add-edit-atp-course.component';
import { ACCREDITATION_ENDORSEMENT, ACCREDITATION_STAGE, ANIMATIONS, ATP_ACCREDITATION_STATUS, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAtpAccreditation } from 'app/models/atp/atpAccreditation';
import { UntypedFormGroup, UntypedFormControl, FormControl } from '@angular/forms';
import { IAtp } from 'app/models/atp/atp';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IAtpAccreditationDocument } from 'app/models/atp/atpAccreditationDocument';
import { IDocumentFile } from 'app/models/document/document-file';
import { CommonDataService } from 'app/services/common/common-data.service';
import { AddEditAtpDeficiencyComponent } from '../add-edit-atp-deficiency/add-edit-atp-deficiency.component';
import { AddEditAtpNoteComponent } from '../add-edit-atp-note/add-edit-atp-note.component';
import { SharedService } from 'app/services/core/shared.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PaymentService } from 'app/components/payments/services/payments.service';
import { IPayment } from 'app/components/payments/models/payment';
import { AddDocumentComponent } from 'app/components/common/document/add-document.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-add-edit-atp-accreditation',
  templateUrl: './add-edit-atp-accreditation.component.html',
  styleUrls: ['./add-edit-atp-accreditation.component.scss'],
  animations: ANIMATIONS,
  encapsulation: ViewEncapsulation.None
})
export class AddEditAtpAccreditationComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT;
  public accreditationDataForm = null;
  public accreditationData: IAtpAccreditation = null;  
  public cardColor: string = this.sharedService.gold + '20';
  public changeIndicator: number = 0;
  public canEditPayments: boolean = true;
  public isMobile: boolean = false;

  public documentTableColumns = ['id', 'fileName', 'fileDescription', 'actions']
  public documentTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'File Name',columnName:'fileName', type:'string', size:'30'},
    {displayName:'File Description',columnName:'fileDescription', type:'string', size:'40'},
  ];
  public documentTableData = [];
  public documentFilteredTableData = []
  public documentTablePageSize = 5;
  public filterDocumentString: string = '';  
  public documentDataSource: MatTableDataSource<any>;
  @ViewChild('DocumentTablePaginator') documentPaginator: MatPaginator;
  @ViewChild('DocumentTableSort') documentSort: MatSort;

  public noteTableColumns = ['id', 'contactType', 'notes', 'date', 'actions']
  public noteTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Contact Type',columnName:'contactType', type:'string', size:'15'},
    {displayName:'Description',columnName:'notes', type:'string', size:'53'},
    {displayName:'Date',columnName:'date', type:'date', size:'15'},    
  ];
  public noteTableData = [];
  public noteTableFilteredData = [];
  public noteTablePageSize = 5;
  public filterNoteString: string = '';  
  public filterNoteStartDate = null;
  public filterNoteEndDate = null;  
  public noteDataSource: MatTableDataSource<any>;
  @ViewChild('NoteTablePaginator') notePaginator: MatPaginator;
  @ViewChild('NoteTableSort') noteSort: MatSort;

  public deficiencyTableColumns = ['id', 'deficiencyType', 'description', 'isResolved', 'date', 'actions']
  public deficiencyTableFormat = [        
    {displayName:'Id',columnName:'id', type:'number', size:'10'}, 
    {displayName:'Deficiency Type',columnName:'deficiencyType', type:'string', size:'15'},
    {displayName:'Description',columnName:'description', type:'string', size:'38'},
    {displayName:'Resolved',columnName:'isResolved', type:'bool', size:'15'},
    {displayName:'Date',columnName:'date', type:'date', size:'15'},    
  ];
  public deficiencyTableData = [];
  public deficiencyTableFilteredData = [];
  public deficiencyTablePageSize = 5;
  public filterDeficiencyString: string = '';  
  public filterDeficiencyResolved = new FormControl([]);
  public filterDeficiencyStartDate = null;
  public filterDeficiencyEndDate = null;  
  public deficiencyBoolList = Object.values(['Unchecked','Checked']);
  public deficiencyDataSource: MatTableDataSource<any>;
  @ViewChild('DeficiencyTablePaginator') deficiencyPaginator: MatPaginator;
  @ViewChild('DeficiencyTableSort') deficiencySort: MatSort;

  constructor(private toastr: ToastrService,
    private atpService: AtpService,
    public commonService: CommonDataService,
    private dialogRef: MatDialogRef<AddEditAtpCourseComponent>,
    public dialog: MatDialog,
    public sharedService: SharedService,
    public paymentService: PaymentService,
    @Inject(MAT_DIALOG_DATA) public data:{atp:IAtp, accreditation:IAtpAccreditation},
    private breakpointObserver: BreakpointObserver,) {
      this.breakpointObserver.observe([
        Breakpoints.Handset,
        Breakpoints.Tablet,
        Breakpoints.Small,
      ]).subscribe(result => {
        this.isMobile = result.matches;
      }); 
    }

  ngOnInit(): void {
    this.setCourseDataFormFields();
    this.loadData();
  }

  loadData(){
    if(this.data.accreditation !== null){
      this.accreditationData = this.data.accreditation;
      this.refreshTableDocuments();
      this.refreshDeficiencies();
      this.refreshNotes();
    }
    else{
      this.accreditationData = {
        id: 0,
        atp: this.data.atp,   
        accreditationStage: '',
        accreditationEndorsementType: '',
        trainingManagerId: null,
        applicationDateSigned: null,
        dateOfLetter: null,
        epaAccredited: false,
        status: 'Incomplete',
        expirationDate: null,
        dateApplicationCompleted: null,
        applicationReceivedDate: null,
        addPaymentForNew: false
      };     
    }

    for (const controlName in this.accreditationData) {
      if (this.accreditationDataForm.controls.hasOwnProperty(controlName)) {        
        this.accreditationDataForm.controls[controlName].setValue(this.accreditationData[controlName]);          
      }
    }  
  }

  refreshTableDocuments(){
    this.atpService.getATPAccreditationDocuments(this.accreditationData.id).subscribe(result=>{
      this.documentTableData = result;
      this.filterDocumentTable();
    },error=>{
      this.toastr.error("There was an error getting the documents: ", error);
    })
  }

  setCourseDataFormFields(){       
    this.accreditationDataForm = new UntypedFormGroup({
      accreditationStage: new UntypedFormControl(""),
      accreditationEndorsementType: new UntypedFormControl(""), 
      status: new UntypedFormControl(""), 
      applicationDateSigned: new UntypedFormControl(null), 
      dateOfLetter: new UntypedFormControl(null),          
      dateApplicationCompleted: new UntypedFormControl(null),  
      expirationDate: new UntypedFormControl(null),  
      applicationReceivedDate: new UntypedFormControl(null), 
      addPaymentForNew: new UntypedFormControl(false),   
    }); 
  }

  getAccreditationStages(){
    return Object.values(ACCREDITATION_STAGE);
  }

  getAccreditationEndorsements(){
    return Object.values(ACCREDITATION_ENDORSEMENT);
  }

  getAccreditationStatus(){
    return Object.values(ATP_ACCREDITATION_STATUS);
  }

  updateAccrediationData(): void {    
    for (const controlName in this.accreditationData) {
      if (this.accreditationDataForm.controls.hasOwnProperty(controlName)) {
        this.accreditationData[controlName] = this.accreditationDataForm.controls[controlName].value;        
      }
    } 
  }

  savePrompt(){
    let isNew = this.accreditationData.id === 0;
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: isNew ? 'Are you sure you want to <strong>Add</strong> this Accreditation?' : 'Are you sure you want to <strong>Save</strong> the Accreditation?',title: isNew ? 'Add the Accreditation' : 'Save the Accreditation'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.saveData();
      }
    })
  }

  saveData(){
    this.updateAccrediationData();
    let isNew = this.accreditationData.id === 0;
    this.atpService.saveATPAccreditation(this.accreditationData).subscribe(result=>{
      this.accreditationData = result;
      if(isNew && this.accreditationData.addPaymentForNew) {
        this.toastr.success("Success.  Verify Payment Information.");
      }  
      else{
        this.toastr.success("Success.");
      }    
    },error=>{
      this.toastr.error("There was an error saving the Accreditation: ", error)
    })
  }

  filterDocumentTable(): void {
    this.documentFilteredTableData = this.documentTableData;
    
    this.documentFilteredTableData = this.documentFilteredTableData.filter(x => {      
      return x.id?.toString().includes(this.filterDocumentString) ||      
        x.fileName?.toString().toLowerCase().includes(this.filterDocumentString.toLowerCase())        
    });

    this.updateDocumentDataSource();
  }
  updateDocumentDataSource(){    
    this.documentDataSource = new MatTableDataSource<any>(this.documentFilteredTableData);
    this.documentDataSource.paginator = this.documentPaginator;
    this.documentDataSource.sort = this.documentSort;    
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

  editDocument(row: any){
    const dialogRef = this.dialog.open(AddDocumentComponent, {
      width: this.isMobile ? '90%' : '30%',
      data: {document: row, entity: 'atpAccreditation', entityRef: this.accreditationData},
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {      
        this.refreshTableDocuments();      
    });
  }    
  
  async onFileSelected(event: any) {   
    try{ 
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, '').replace(/.txt$/, ''));

      if (file) {
        const fileData = await this.commonService.getFileSerializedData(dirtyFile);
      
        let docFile: IDocumentFile = {
          id: 0,
          file: fileData.serializedData,
        }

        const accreditationDocument: IAtpAccreditationDocument = {
          id: 0,
          atpAccreditation: this.accreditationData,
          document: docFile,
          fileName: file.name,
          fileType: fileData.fileType,
        }
        
        this.saveAccreditationDocument(accreditationDocument)
      }   
    } catch (error) {
      this.toastr.error("An error occurred while processing the file:", error);
    } 
  }

  saveAccreditationDocument(accreditationDocument: IAtpAccreditationDocument){    
    this.commonService.saveDocumentFile(accreditationDocument.document).subscribe(result=>{
      accreditationDocument.document = result;
      this.atpService.saveATPAccreditationDocument(accreditationDocument).subscribe(result=>{
        accreditationDocument.id = result.id
        this.refreshTableDocuments();
      },error => {
        this.toastr.error("An error occurred while processing the file: ", error);
      });
    },error=>{
      this.toastr.error("An error occurred while processing the file: ", error);
    })
  }

  clearFile(row: any) {      
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> this Accreditation Document?',title: 'Delete the Accreditation Dcoument'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPAccreditationDocument(row.id).subscribe(result=>{
          this.commonService.deleteDocumentFile(row.document.id).subscribe(result=>{
            this.refreshTableDocuments();
          }, error=>{
            this.toastr.error("An error occurred while deleting the document: ", error);
          });
        },
        error=>{
          this.toastr.error("An error occurred while deleting the accreditation document: ", error);
        });                 
      }
    })
  }

  async downloadFile(row: any) {            
    this.commonService.downloadFileStream(row);
  }  

  refreshNotes(){
    this.atpService.getATPAccreditationNotes(this.accreditationData.id).subscribe(result=>{
      this.noteTableData = result; 
      this.filterNoteTable();     
    }, error=>{
      this.toastr.error("There was an error getting the ATP Accreditation Notes: ", error)
    })
  }

  refreshDeficiencies(){
    this.atpService.getATPAccreditationDeficiencies(this.accreditationData.id).subscribe(result=>{
      this.deficiencyTableData = result;   
      this.filterDeficiencyTable();
    }, error=>{
      this.toastr.error("There was an error getting the ATP Accreditation Deficiencies: ", error)
    })
  }

  editNoteTableEntry(row: any){
    const dialogRef = this.dialog.open(AddEditAtpNoteComponent, {    
      width: '70%',    
      data: {note: row, atp: null, accreditation: this.accreditationData}
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshNotes();
      }    
    });  
  }
  deleteNoteEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Note with ID: <strong>' + row.id + '</strong>?',title: 'Delete the Note'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPNote(row.id).subscribe(result=>{
          this.toastr.success('Note deleted successfully');
          this.refreshNotes();
        },error=>{this.toastr.error('Error deleting Note: ', error)})
      }
    })
  }

  filterNoteTable(){
    this.noteTableFilteredData = this.noteTableData

    this.noteTableFilteredData = this.noteTableFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterNoteString) ||      
        x.contactType.toString().toLowerCase().includes(this.filterNoteString.toLowerCase()) ||
        x.notes.toString().toLowerCase().includes(this.filterNoteString.toLowerCase())
    });

    if (this.filterNoteStartDate) {     
      this.noteTableFilteredData = this.noteTableFilteredData.filter(x => new Date(x.date) >= this.filterNoteStartDate);
    }

    if (this.filterNoteEndDate) {
      this.noteTableFilteredData = this.noteTableFilteredData.filter(x => new Date(x.date) <= this.filterNoteEndDate);
    } 

    this.updateNoteDataSource();
  }

  updateNoteDataSource(){    
    this.noteDataSource = new MatTableDataSource<any>(this.noteTableFilteredData);
    this.noteDataSource.paginator = this.notePaginator;
    this.noteDataSource.sort = this.noteSort;    
  }

  editDeficiencyTableEntry(row: any){
    const dialogRef = this.dialog.open(AddEditAtpDeficiencyComponent, {    
      width: '70%',    
      data: {deficiency: row, atp: null, accreditation: this.accreditationData}
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.refreshDeficiencies();
      }    
    });  
  }
  deleteDeficiencyEntry(row: any){
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message: 'Are you sure you want to <strong>Delete</strong> the Deficiency: <strong>' + row.id + '</strong>?',title: 'Delete the Deficiency'},
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.atpService.deleteATPDeficiency(row.id).subscribe(result=>{
          this.toastr.success('Deficiency deleted successfully');
          this.refreshDeficiencies();
        },error=>{this.toastr.error('Error deleting deficiency: ', error)})
      }
    })
  }

  filterDeficiencyTable(){
    this.deficiencyTableFilteredData = this.deficiencyTableData;

    this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => {      
      return x.id?.toString().includes(this.filterDeficiencyString) ||      
        x.deficiencyType.toString().toLowerCase().includes(this.filterDeficiencyString.toLowerCase()) ||
        x.description.toString().toLowerCase().includes(this.filterDeficiencyString.toLowerCase())
    });

    if(this.filterDeficiencyResolved.value.length > 0){
      this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => 
          (this.filterDeficiencyResolved.value.includes('Unchecked') && x.isResolved === false) ||
          (this.filterDeficiencyResolved.value.includes('Checked') && x.isResolved === true)
      );
    }

    if (this.filterDeficiencyStartDate) {     
      this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => new Date(x.date) >= this.filterDeficiencyStartDate);
    }

    if (this.filterDeficiencyEndDate) {
      this.deficiencyTableFilteredData = this.deficiencyTableFilteredData.filter(x => new Date(x.date) <= this.filterDeficiencyEndDate);
    } 

    this.updateDeficiencyDataSource();
  }
  updateDeficiencyDataSource(){    
    this.deficiencyDataSource = new MatTableDataSource<any>(this.deficiencyTableFilteredData);
    this.deficiencyDataSource.paginator = this.deficiencyPaginator;
    this.deficiencyDataSource.sort = this.deficiencySort;    
  }

  paymentUpdated(payments: IPayment[]) {
    console.log('payments update');
  }
}
