import { trigger, transition, style, animate } from "@angular/animations";

export const LEAD_BASED_PAINT = {
    'course': 
    {
        'icon':'business_center'
    },
    'endorsement':
    {
        'icon':'how_to_vote'
    },
    'credential':
    {
        'icon':'card_membership'
    },
    'certificate':
    {
        'icon':'receipt'
    },
    'firm':
    {
        'icon':'business',
        'color':'#86D6C0',        
    },
    'individual':
    {
        'icon':'recent_actors',
        'color':'#ACA2D0',
    },
    'atp':
    {
        'icon':'school',
        'color':'#628ef4',
    },
    'lbpa':
    {
        'icon':'store_mall_directory',
        'color':'#ffbe2e',        
    },
    'rrp':
    {
        'icon':'build',
        'color':'#EC891D',        
    },
    'complaint':
    {
        'icon':'comment',
        'color':'#ff685d'
    },
    'payment':
    {
        'icon':'attach_money'
    },
    'apply':
    {
        'icon':'book'
    },
    'staff':
    {
        'icon':'people'
    },
    'address':
    {
        'icon':'location_on'
    },
    'contact':
    {
        'icon':'contact_mail'
    },
    'name':
    {
        'icon':'label'
    },
    'id':
    {
        'icon':'info'
    },
    'calendar':
    {
        'icon':'event'
    },
    'training':
    {
        'icon':'fitness_center'
    },
    'testing':
    {
        'icon':'playlist_add_check'
    },
    'notes': 
    {
        'icon':'notes'
    },
};

export function getStatusColor(status: string): string {
    switch (status) {
        case APPLICATION_STATUS.submitted:
            return '#FFBF00';
        case APPLICATION_STATUS.inProgress:
            return '#bcbcbc';
        case APPLICATION_STATUS.cancelled:
            return '#cc0000';
        case APPLICATION_STATUS.approved:
         return '#8fce00';
        case APPLICATION_STATUS.inReview:
            return '#2986cc';
        case APPLICATION_STATUS.actionNeeded:
            return '#e69138';
        case "Closed":
            return '#E1C16E';
        case "Open":
            return '#2986cc';
        default:
            return '#ffffff';
    }
};

export const APPLICATION_STATUS = {    
    cancelled: "Cancelled",
    inProgress: "In Progress",
    submitted: "Submitted",    
    inReview: "In Review",
    actionNeeded: "Action Needed",    
    approved: "Approved",
}

export const FIRM_SERVICES = {
    inspections: "Inspections",
    riskAssessments: "Risk Assessments",
    clearanceTesting: "Clearance Testing",
    leadHazardScreening: "Lead Hazard Screening",
    abatement: "Abatement",
}

export const FIRM_CREDENTIALS = {
    inspections: "Inspections",    
    clearanceTesting: "Clearance Testing",
    leadHazardScreening: "Lead Hazard Screening",
    riskAssessments: "Risk Assessments",        
    abatement: "Abatement",
}

export const FIRM_CREDENTIAL_MAPPING = {
    "Inspections" : ["Inspector", "Risk Assessor"],    
    "Clearance Testing" : ["Inspector", "Risk Assessor"],
    "Lead Hazard Screening" : "Risk Assessor",
    "Risk Assessments" : "Risk Assessor",
    "Abatement" : "Supervisor"
}

export const FIRM_SERVICE_FOR = {
    internal: "Internal Only",
    residential: "Residential",
    commercial: "Commercial",
    both: "Both Residential and Commercial",  
    non: "Non-Residential",  
    unk: "Unknown",
}

export const FIRM_APPLICATION_TYPES = {
    rrp: "rrp",
    lbpa: "lbpa",
    link: "link"
}

export const STUDENT_CODE_TYPES = {
    worker: "Worker",
    supervisor: "Supervisor",
    projectDesigner: "Project Designer",    
    riskAssessor: "Risk Assessor",
    rrpRenovator: "RRP Renovator",
    inspector: "Inspector",
    dustSamplingTech: "Dust Sampling Technician",
}

export const CODE_TYPES = {
    worker: "Worker",
    supervisor: "Supervisor",
    projectDesigner: "Project Designer",    
    riskAssessor: "Risk Assessor",
    rrpRenovator: "RRP Renovator",
    inspector: "Inspector",
    dustSamplingTech: "Dust Sampling Technician",
    lbpFirm: "LBP Firm",
    rrpFirm: "RRP Firm"    
}

export const TESTING_CODE_TYPES = {
    supervisor: "Supervisor",
    riskAssessor: "Risk Assessor",
    inspector: "Inspector",    
}

export const STAGE_TYPES = {
    initial: "Initial",
    refresher: "Refresher",    
}

export const CERTIFICATE_STAGE_TYPES = {
    initial: "Initial",
    recert: "Recert",    
}

export const ACCREDITATION_STATUS = {    
    none: 'None',
    suspended: 'Suspended',
    expired: 'Expired',
    provisional: 'Provisional',
    full: 'Full',
}

export const AUDIT_TYPES = {
    inspection: "Inspection",
    complianceAssistance: "Compliance Assistance",
    recordkeeping: "Recordkeeping",
    preAccreditationSiteVisit: "Pre-accreditation Site Visit",
    postAccreditationSiteVisit: "Post-accreditation Site Visit",
    reaccreditationSiteVisit: "Reaccreditation Site Visit"
}

export const AUDIT_ORGANIZATIONS = {
    OHA: "OHA",
    EPA: "EPA",
    EPAAndOHA: "EPA and OHA"
}

export const ATP_ACCREDITATION_STATUS = {
    incomplete: "Incomplete",
    complete: "Complete",
    rejected: "Rejected"
}

export const AUDIT_LOCATION_TYPES = {
    permanent: "Permanent",
    nonPermanent: "Non-Permanent"
}

export const FIRM_CONTACT_TYPES = {
    office: "Office",
    personal: "Personal",
    satellite: "Satellite",
    work: "Work",
    representative: "Representative",
}

export const FIRM_ADDRESS_TYPES = {
    physical: "Physical",
    mailing: "Mailing",
    satellite: "Satellite",
    work: "Work",
    residence: "Residence",
}

export const ATP_ENDORSEMENT = {    
    renovatorInitialEndorsement: 'Renovator Initial Endorsement',
    renovatorInitialSpanishEndorsement: 'Renovator Initial Spanish Endorsement',
    renovatorInitialOnlineEndorsement: 'Renovator Initial Online Endorsement',
    renovatorRefresherEndorsement: 'Renovator Refresher Endorsement',
    renovatorRefresherSpanishEndorsement: 'Renovator Refresher Spanish Endorsement',
    renovatorRefresherOnlineEndorsement: 'Renovator Refresher Online Endorsement',
    dustSamplingInitialEndorsement: 'Dust Sampling Initial Endorsement',
    dustSamplingInitialSpanishEndorsement: 'Dust Sampling Initial Spanish Endorsement',
    dustSamplingInitialOnlineEndorsement: 'Dust Sampling Initial Online Endorsement',
    dustSamplingRefresherEndorsement: 'Dust Sampling Refresher Endorsement',
    dustSamplingRefresherSpanishEndorsement: 'Dust Sampling Refresher Spanish Endorsement',
    dustSamplingRefresherOnlineEndorsement: 'Dust Sampling Refresher Online Endorsement',
    riskAssessorInitialEndorsement: 'Risk Assessor Initial Endorsement',
    riskAssessorRefresherEndorsement: 'Risk Assessor Refresher Endorsement',
    inspectorInitialEndorsement: 'Inspector Initial Endorsement',
    inspectorRefresherEndorsement: 'Inspector Refresher Endorsement',
    supervisorInitialEndorsement: 'Supervisor Initial Endorsement',
    supervisorRefresherEndorsement: 'Supervisor Refresher Endorsement',
    projectDesignerInitialEndorsement: 'Project Designer Initial Endorsement',
    projectDesignerRefresherEndorsement: 'Project Designer Refresher Endorsement',
    abatementWorkerInitialEndorsement: 'Abatement Worker Initial Endorsement',
    abatementWorkerRefresherEndorsement: 'Abatement Worker Refresher Endorsement',
}

export const COURSE_DISCIPLINES = {
    dustSamplingTechnician: 'Dust Sampling Technician',
    inspector: 'Inspector',
    inspectorRiskAssessor: 'Inspector/Risk Assessor',
    projectDesigner: 'Project Designer',
    renovator: 'Renovator',
    renovatorHands: 'Renovator (Hands On)',
    riskAssessor: 'Risk Assessor',
    supervisor: 'Supervisor',
    worker: 'Worker',
    workerSupervisor: 'Worker/Supervisor',
}

export const COURSE_TYPES = {
    initial: "Initial",
    refresher: "Refresher",  
    initialRefresher: "Initial/Refresher",
}

export const COURSE_FORMAT = {
    inPerson: "In Person",
    videoConferenceLecture: "Videoconference Lecture",  
    handsOnExam: "Hands On/Exam",
    online: "Online",
}

export const COURSE_LANGUAGE = {
    english: "English",
    espanol: "Espanol",  
    other: "Other",
}

export const ACCREDITATION_STAGE = {
    reaccred: "Reaccred",
    initial: "Initial",      
}

export const ACCREDITATION_ENDORSEMENT = {
    renovatorInitial: 'RRP-Renovator (Initial)',
    renovatorInitialSpanish: 'RRP-Renovator (Initial/Spanish)',
    renovatorInitialOnline: 'RRP-Renovator (Initial/Online)',
    renovatorRefresher: 'RRP-Renovator (Refresher)',
    renovatorRefresherSpanish: 'RRP-Renovator (Refresher/Spanish)',
    renovatorRefresherOnline: 'RRP-Renovator (Refresher/Online)',
    dustSamplingInitial: 'RRP-Dust Sampling (Initial)',
    dustSamplingInitialSpanish: 'RRP-Dust Sampling (Initial/Spanish)',
    dustSamplingInitialOnline: 'RRP-Dust Sampling (Initial/Online)',
    dustSamplingRefresher: 'RRP-Dust Sampling (Refresher)',
    dustSamplingRefresherSpanish: 'RRP-Dust Sampling (Refresher/Spanish)',
    dustSamplingRefresherOnline: 'RRP-Dust Sampling (Refresher/Online)',
    inspectorInitial: 'LBPA-Inspector (Initial)',
    inspectorRefresher: 'LBPA-Inspector (Refresher)',
    supervisorInitial: 'LBPA-Supervisor (Initial)',
    supervisorRefresher: 'LBPA-Supervisor (Refresher)',
    projectDesignerInitial: 'LBPA-Project Designer (Initial)',
    projectDesignerRefresher: 'LBPA-Project Designer (Refresher)',
    abatementWorkerInitial: 'LBPA-Abatement Worker (Initial)',
    abatementWorkerRefresher: 'LBPA-Abatement Worker (Refresher)',
    riskAssessorInitial: 'LBPA-Risk Assessor (Initial)',
    riskAssessorRefresher: 'LBPA-Risk Assessor (Refresher)',
};

export const ANIMATIONS = [
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    
  ];