import { Component, AfterViewInit } from '@angular/core';
import { PreloaderService } from '@core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  constructor(private preloader: PreloaderService) {}

  ngAfterViewInit() {
    this.preloader.hide();
  }
}
