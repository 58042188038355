import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IGenerateCertificateNumber } from 'app/models/common/generateCertificateNumber';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirmation-dialogue-with-field',
  templateUrl: './confirmation-dialogue-with-field.component.html'  
})
export class ConfirmationDialogueWithFieldComponent {
  selectedControlNumber: string = '';
  selectedCertificateNumber: string = '';
  disableInput: boolean = false;
  selectedDate: Date | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,  
  private sanitizer: DomSanitizer,  
  private personService: PersonService,
  private firmService: FirmService,
  private toastr: ToastrService) {    
    this.selectedDate = this.data?.selectedDate ? new Date(this.data.selectedDate) : new Date();   
    this.dateChange(this.selectedDate);    
  }  
  
  dateChange(event: any) {    
    let selectedDate: Date;
    const dateFormat = 'MM/DD/YYYY';
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    
    if (event === null) {
      return;
    } else if (event instanceof Date) {
      selectedDate = event;
    } else if (moment.isMoment(event) && event.isValid()) {
      selectedDate = event.toDate();
    } else if (event.value && dateRegex.test(event.value) && this.isValidDateFormat(event.value)) {
      selectedDate = new Date(event.value);
    } else {
      return;
    }

    this.selectedDate = selectedDate;
    var certificateData: IGenerateCertificateNumber = {
      codeType: this.data.codeType,
      issueDate: this.selectedDate.toISOString().split('T')[0]
    };

    if (this.data.type === 'firm') {
      this.firmService.getNextFirmCertificateNumber().subscribe(result => {
        this.selectedControlNumber = result.toString();
      }, error => { this.toastr.error("Error getting next control number", error); });
      this.firmService.generateFirmCertificateNumber(certificateData).subscribe(result => {
        this.selectedCertificateNumber = result;
      }, error => { this.toastr.error("Error generating firm certificate number"+ error.error); });
    } else {
      this.personService.getNextPersonCertificateNumber().subscribe(result => {
        this.selectedControlNumber = result.toString();
      }, error => { this.toastr.error("Error getting next person certificate number", error); });
      this.personService.generatePersonCertificateNumber(certificateData).subscribe(result => {
        this.selectedCertificateNumber = result.toString();
      }, error => { this.toastr.error("Error generating person certificate number"+ error.error); });
    }
  }

  isValidDateFormat(value: string): boolean {    
    const dateRegex = /^(0[1-9]|1[0-2])\/([0-2][1-9]|3[01])\/\d{4}$/;
    return dateRegex.test(value) && !isNaN(new Date(value).getTime());
  }

  sanitizedData(data:string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      data.replace(/\n/g, '<br>')
    );
  }
}
