import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from 'app/auth/auth.service';
import { IFirm } from 'app/models/firms/firm';
import { IFirmApplication } from 'app/models/firms/firm-application';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm-service';
import { PersonService } from 'app/services/person/person.service';
import { IPersonApplication } from 'app/models/People/person-application';
import { IPersonCertificate } from 'app/models/People/person-certificate';
import { PaymentService } from '../payments/services/payments.service';
import { IPayment } from '../payments/models/payment';
import { Router } from '@angular/router';
import { IDashboardFilter } from 'app/models/filters/dashBoardFilter';
import { ToastrService } from 'ngx-toastr';
import { IFirmDashboard } from 'app/models/firms/firmDashboard';
import { IPersonDashboard } from 'app/models/People/personDashboard';
import { CommonDataService } from 'app/services/common/common-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  styles: [],  
  providers: [],
})
export class DashboardComponent implements OnInit {
  public environment = environment;
  public firmFilterInterval: string = 'Weekly';
  public individualFilterInterval: string = 'Weekly';  

  public firmDashboardData: IFirmDashboard = {ccbExpireCount: 0, newApplicationCount: 0, applicationAwaitingReviewCount: 0, applicationRenewalCount: 0, applicationExpiringCount: 0, applicationOutstandingCount: 0}  
  public personDashboardData: IPersonDashboard = {certificatesExpiring: 0, newApplicationCount: 0, applicationAwaitingReviewCount: 0, applicationRenewalCount: 0, applicationExpiringCount: 0, applicationOutstandingCount: 0}

  public loading: boolean = true;
  public allFirms: Array<IFirm>;
  public allFirmApplications: Array<IFirmApplication>;
  public firmLoading: boolean = true;

  public allPersonCertificates: Array<IPersonCertificate>;
  public allPersonApplications: Array<IPersonApplication>;
  public personLoading: boolean = true;

  public paymentsPending: IPayment [] = [];
  public paymentsAwaitingClearance: IPayment [] = [];
  public processing: boolean = false;

  constructor(
    public sharedService: SharedService,
    public authService: AuthService,
    public firmService: FirmService,
    public personService: PersonService,
    private router: Router,
    public paymentService: PaymentService,
    private toastr: ToastrService,
    public commonService: CommonDataService
  ) {}

  ngOnInit(): void {
    this.loadData()
  }

  loadData(): void {
    this.onFirmFilterChanged();
    this.onIndividualFilterChanged();

    this.paymentService.getPaymentsPending().subscribe(result=>{
      this.paymentsPending = result;
    }, error =>{});

    this.paymentService.getPaymentsAwaitingClearance().subscribe(result=>{
      this.paymentsAwaitingClearance = result;
    }, error =>{})

    this.loading= false;
  }



  goToPendingPayments(): void {
    
    this.paymentService.searchForm.reset();
    this.paymentService.searchForm.get('status').setValue(this.paymentService.paymentPendingStatus);
    this.router.navigateByUrl('/payments');
  }

  goToAwaitingClearancePayments(): void {
    
    this.paymentService.searchForm.reset();
    this.paymentService.searchForm.get('status').setValue(this.paymentService.paymentAwaitingClearanceStatus);
    this.router.navigateByUrl('/payments');
  }

  onFirmFilterChanged() : void {
    this.firmLoading = true;
    let filter:IDashboardFilter = {filterString: this.firmFilterInterval};
    this.firmService.getDashboardFirmData(filter).subscribe(result=>{      
      this.firmDashboardData = result;
      this.firmLoading = false;
    },error=>{this.toastr.error('Error loading firm dashboard data', error)})    
  }
  onIndividualFilterChanged() : void { 
    this.personLoading = true;
    let filter:IDashboardFilter = {filterString: this.individualFilterInterval};
    this.personService.getDashboardPersonData(filter).subscribe(result=>{
      this.personDashboardData = result;
      this.personLoading = false;
    },error=>{this.toastr.error('Error loading individual dashboard data', error)})
  }

  getDateDifferenceInDays(date: string): number {
    if(date === null || date === undefined || date === '') return 0;
    let dateCreated = new Date(date);
    let currentDate = new Date();      

    let timeDifference = Math.abs(currentDate.getTime() - dateCreated.getTime());
    let daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
    
    return daysDifference
    
  }

  goToFirms(): void {
    let threshold = (this.firmFilterInterval === 'Weekly') ? 7 : (this.firmFilterInterval === 'Monthly') ? 30 : (this.firmFilterInterval === 'Quarterly') ? 90 : 365
    this.commonService.firmManagementFilter.filterString = '';
    this.commonService.firmManagementFilter.filteredActive.setValue('Active');    
    this.commonService.firmManagementFilter.expirationStartDate.setValue(new Date().toISOString().substring(0,10));
    this.commonService.firmManagementFilter.expirationEndDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)).toISOString().substring(0,10));
    this.router.navigateByUrl('/firm/management');
  }

  goToFirmApplications(value: string): void {
    let threshold = (this.firmFilterInterval === 'Weekly') ? 7 : (this.firmFilterInterval === 'Monthly') ? 30 : (this.firmFilterInterval === 'Quarterly') ? 90 : 365

    switch(value){
      case 'new':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue([]);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'review':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue(['Submitted']);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'renewal':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue([]);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Renewal');
        break;
      case 'expiring':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue([]);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + threshold));
        this.commonService.firmApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - 90));
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Expiring');
        break;
      case 'outstanding':
        this.commonService.firmApplicationFilter.filterString = '';
        this.commonService.firmApplicationFilter.selectedStatus.setValue([]);
        this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
        this.commonService.firmApplicationFilter.applicationDateFilterEnd = null;
        this.commonService.firmApplicationFilter.applicationDateFilterStart = null;
        this.commonService.firmApplicationFilter.selectedRenewal.setValue('Outstanding');
        break;
        case 'all':
          this.commonService.firmApplicationFilter.filterString = '';
          this.commonService.firmApplicationFilter.selectedStatus.setValue([]);
          this.commonService.firmApplicationFilter.selectedType.setValue(['rrp','lbpa']);
          this.commonService.firmApplicationFilter.applicationDateFilterEnd = null;
          this.commonService.firmApplicationFilter.applicationDateFilterStart = null;
          break;
    }

    this.router.navigateByUrl('/firm/applications');
  }

  goToIndividuals(): void {
    let threshold = (this.individualFilterInterval === 'Weekly') ? 7 : (this.individualFilterInterval === 'Monthly') ? 30 : (this.individualFilterInterval === 'Quarterly') ? 90 : 365
    this.commonService.individualManagementFilter.filterString = '';
    this.commonService.individualManagementFilter.filteredActive.setValue('Active');
    this.commonService.individualManagementFilter.expirationStartDate.setValue(new Date());
    this.commonService.individualManagementFilter.expirationEndDate.setValue(new Date(new Date().setDate(new Date().getDate() + threshold)));
    this.router.navigateByUrl('/individual/management');
  }

  goToIndividualApplications(value: string): void {
    let threshold = (this.individualFilterInterval === 'Weekly') ? 7 : (this.individualFilterInterval === 'Monthly') ? 30 : (this.individualFilterInterval === 'Quarterly') ? 90 : 365

    switch(value){
      case 'new':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue([]);        
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'review':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue(['Submitted']);        
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('');
        break;
      case 'renewal':        
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue([]);        
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + 1));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - threshold));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Renewal');
        break;
      case 'expiring':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue([]);        
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = new Date(new Date().setDate(new Date().getDate() + threshold));
        this.commonService.individualApplicationFilter.applicationDateFilterStart = new Date(new Date().setDate(new Date().getDate() - 90));
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Expiring');
        break;
      case 'outstanding':
        this.commonService.individualApplicationFilter.filterString = '';
        this.commonService.individualApplicationFilter.selectedStatus.setValue([]);        
        this.commonService.individualApplicationFilter.applicationDateFilterEnd = null;
        this.commonService.individualApplicationFilter.applicationDateFilterStart = null;
        this.commonService.individualApplicationFilter.selectedRenewal.setValue('Outstanding');
        break;
    }

    this.router.navigateByUrl('/individual/applications');
  }
}
