<div *ngIf="sharedService.loading || loading">
  <div id="globalLoader" class="global-loader" [ngStyle]="{ 'background-color': sharedService.darkMode ? '#333' : '#fff' }">
      <h1>Loading</h1>
  </div>
</div>
  
  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
    <div fxFlex="100" class="matero-col" fxLayoutAlign="start">
      <mat-card [style.background-color]="uiData.firm.color" fxFlex="100">
        <h2 class="noMarginHeader">
          <mat-icon>business</mat-icon> Firm Management
        </h2>
      </mat-card>
    </div>
  </div>

  <mat-card>
    <div [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
      <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start">
        <strong class="noMarginHeader">Results ({{filteredFirms.length}})</strong>
        
        <mat-form-field style="width:200px;">
            <input (keydown.enter)="loadFirms()" placeholder="Filter Name,CCB,Certificate" [(ngModel)]="filterString" matInput/>        
        </mat-form-field>
        
        <mat-form-field style="width:100px">
            <mat-select placeholder="Filter Inactive" [formControl]="filteredActive">
                <mat-option [value]="null">-- Select --</mat-option>
                <mat-option *ngFor="let status of getFirmActiveTypes()" [value]="status">
                    {{ status }}
                </mat-option>
            </mat-select>
        </mat-form-field> 
        
        <mat-form-field style="width:110px">
            <mat-select placeholder="Filter Firm Type" [formControl]="filteredFirmType">
                <mat-option [value]="null">-- Select --</mat-option>
                <mat-option *ngFor="let status of ['LBP Firm','RRP Firm']" [value]="status">
                    {{ status }}
                </mat-option>
            </mat-select>
        </mat-form-field> 
    
        <mat-form-field style="width:190px">                                                                
            <input (keydown.enter)="loadFirms()" matInput [matDatepicker]="expireStartPicker" placeholder="Expiring Start Date" [formControl]="expirationStartDate">
            <mat-datepicker-toggle matSuffix [for]="expireStartPicker"></mat-datepicker-toggle>
            <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationStartDate.reset();">
                <mat-icon style="color: red;">close</mat-icon>
            </button>   
            <mat-datepicker #expireStartPicker></mat-datepicker>
        </mat-form-field> 
        
        <mat-form-field style="width:180px">                                                                
            <input (keydown.enter)="loadFirms()" matInput [matDatepicker]="expireEndPicker" placeholder="Expiring End Date" [formControl]="expirationEndDate">
            <mat-datepicker-toggle matSuffix [for]="expireEndPicker"></mat-datepicker-toggle>
            <button matSuffix mat-icon-button aria-label="Clear date" (click)="expirationEndDate.reset();">
                <mat-icon style="color: red;">close</mat-icon>
            </button>   
            <mat-datepicker #expireEndPicker></mat-datepicker>
        </mat-form-field> 
    
        <mat-checkbox [(ngModel)]="includeCCB">Include Expiring CCBs</mat-checkbox>
        <div fxLayout="row wrap" fxLayoutGap="5px" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" fxFlex>
            <button mat-raised-button color="primary" (click)="loadFirms()">Search</button>                
            <button mat-raised-button (click)="clearFilters()">Reset Filters</button>
            <button mat-raised-button color="warn" [disabled]="selectedFirms.length < 2" (click)="mergeFirms()" [matTooltip]="getMergeTooltip()">
                <mat-icon>call_merge</mat-icon> Merge Firms {{selectedFirms.length > 0 ? '(' + selectedFirms.length + ')': ''}}
            </button>
            <div fxFlex="1 1 auto"></div>
            <button (click)="exportToExcel()" mat-raised-button color="primary"><mat-icon style="transform: rotate(270deg);">save_alt</mat-icon> Export</button>
        </div>
    </div>
      <br />
      <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
        <ng-container *ngFor="let cellData of firmTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
            <mat-header-cell mat-sort-header *matHeaderCellDef [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{cellData.displayName}}</mat-header-cell>
            <mat-cell *matCellDef="let row" [fxFlex]="cellData.size" [ngStyle]=" isMobile ? {'width': '100%', 'padding-left': '5px'} : {}" [matTooltip]="isObjectOrArray(row[cellData.columnName]) ? '' : row[cellData.columnName]">
              <ng-container *ngIf="cellData.type === 'subtable'">
                <mat-table [dataSource]="row.certificates" matSort #NestedTableSort="matSort" fxFlex="100%">
                  <ng-container *ngFor="let subCellData of subtableDisplayData; let j = index" [matColumnDef]="subCellData.columnName">
                    <mat-header-cell *matHeaderCellDef [fxFlex]="subCellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">{{ subCellData.displayName }}</mat-header-cell>
                    <mat-cell *matCellDef="let nestedRow" [fxFlex]="subCellData.size" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                      <ng-container *ngIf="subCellData.type === 'date'">
                        {{nestedRow[subCellData.columnName] | date:'MM/dd/yyyy'}}
                      </ng-container>
                      <ng-container *ngIf="subCellData.type !== 'date'">
                        {{nestedRow[subCellData.columnName]}}
                      </ng-container>
                    </mat-cell>
                  </ng-container>
                  <!-- Actions column -->
                  <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">Actions</mat-header-cell>
                    <mat-cell *matCellDef="let nestedRow" [ngStyle]="isMobile ? {'width': '100%', 'padding-left': '5px'} : {}">
                      <div *ngIf="isMobile" fxLayout="row" style="display: flex; justify-content: space-between; align-items: center;">
                        <span><strong>Action: </strong></span>
                        <button mat-icon-button color="primary" (click)="editCertificate(nestedRow); $event.stopPropagation()">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div>
                      <div *ngIf="!isMobile">
                        <button mat-icon-button color="primary" (click)="editCertificate(nestedRow); $event.stopPropagation()">
                          <mat-icon>edit</mat-icon>
                        </button>
                      </div>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="subtableColumns"></mat-header-row>
                  <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="editCertificate(nestedRow); $event.stopPropagation()" *matRowDef="let nestedRow; index as i; columns: subtableColumns"></mat-row>
                </mat-table>
                </ng-container>

                <ng-container *ngIf="cellData.type === 'date'">
                  <div *ngIf="isMobile" fxLayout="row">  
                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                    <span>{{(row[cellData.columnName] | date:'MM/dd/yyyy')}}</span>
                  </div>
                  <div *ngIf="!isMobile">  
                      {{(row[cellData.columnName] | date:'MM/dd/yyyy')}}
                  </div>
                </ng-container>
                <ng-container *ngIf="cellData.type === 'boolean'">
                  <div *ngIf="isMobile" fxLayout="row">
                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                    <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                  </div>
                  <div *ngIf="!isMobile">
                      <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                  </div>                            
                </ng-container>
                <ng-container *ngIf="cellData.type === 'select'">
                  <div *ngIf="isMobile" fxLayout="row">
                    <span><strong>{{cellData.displayName}}: </strong></span> &nbsp;
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="updateSelected(row)" [checked]="isSelected(row)"></mat-checkbox>
                  </div>
                  <div *ngIf="!isMobile">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="updateSelected(row)" [checked]="isSelected(row)"></mat-checkbox>
                  </div>
                </ng-container>
                <ng-container *ngIf="cellData.type !== 'subtable' && cellData.type !== 'date' && cellData.type !== 'boolean' && cellData.type !== 'select'">
                  <div *ngIf="isMobile" fxLayout="row">                                    
                    <span><strong>{{ cellData.displayName }}: </strong></span> &nbsp;
                    <span>{{ row[cellData.columnName] }}</span>
                  </div>
                  <div *ngIf="!isMobile">
                      {{row[cellData.columnName]}}
                  </div>  
                </ng-container>
            </mat-cell>
        </ng-container>                    
                            
        <!-- Column definitions -->
        <mat-header-row fxLayout="row" [fxHide.lt-md]="true" fxLayout.lt-md="column" *matHeaderRowDef="firmTable.layout.columns"></mat-header-row>
        <mat-row fxLayout="row" fxLayout.lt-md="column" (click)="handleFirmRowClick(row, $event); $event.stopPropagation()" [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: firmTable.layout.columns"></mat-row>
      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, filteredFirms.length]" #TablePaginator="matPaginator" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>