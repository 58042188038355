import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonDataService } from 'app/services/common/common-data.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogueComponent } from 'app/components/application/common/confirmation-dialogue/confirmation-dialogue.component';
import { IComplaint } from 'app/models/complaint/complaint';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ComplaintService } from 'app/services/complaint/complaint.service';
import { MatAccordion } from '@angular/material/expansion';
import { AddEditAssociationComponent } from '../add-edit-association/add-edit-association.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IComplaintDetail } from 'app/models/complaint/complaintDetail';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IComplaintOwnerTenant } from 'app/models/complaint/complaintOwnerTenant';
import { AddEditOwnerTenantComponent } from '../add-edit-ownertenant/add-edit-ownertenant.component';
import { IComplaintInspection } from 'app/models/complaint/complaintInspection';
import { IComplaintAction } from 'app/models/complaint/complaintAction';
import { AddEditInspectionComponent } from '../add-edit-inspection/add-edit-inspection.component';
import { AddEditActionComponent } from '../add-edit-action/add-edit-action.component';
import { IComplaintJurisdiction } from 'app/models/complaint/complaintJurisdiction';
import { IComplaintEnforcementCategory } from 'app/models/complaint/complaintEnforcementCategory';
import { IComplaintReferral } from 'app/models/complaint/complaintReferral';
import { AddEditReferralComponent } from '../add-edit-referral/add-edit-referral.component';
import { IComplaintAssociation } from 'app/models/complaint/complaintAssociation';
import { AuthService } from 'app/auth/auth.service';
import { PrintComplaintComponent } from '../print-complaint/print-complaint.component';


@Component({
  selector: 'app-add-edit-complaint',
  templateUrl: './add-edit-complaint.component.html',
  styleUrls: ['./add-edit-complaint.component.scss']
})


export class AddEditComplaintComponent {
  public isMobile: boolean = false;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public loading: boolean = true;
  public uiData: any = LEAD_BASED_PAINT;
  public dataSource: MatTableDataSource<any>;
  public otherJurisdiction: string;
  public otherEnforcementCategory: string;
  public complaintProblem: string;
  public complaintInvestigator: string;
  public complaintInvestigatorAssignedDate: string;
  public complaintStatus: string;
  public complaintId : number;
  public complaintDetail :IComplaintDetail= {
    complaintId: 0,
    complaintViolations: [],
    complaintJurisdictions: []
  };
  statusOptions = [  
    {name:'New', value:'New', checked:false},
    {name:'Open', value:'Open', checked:false},
    {name:'Closed', value:'Closed', checked:false}
 ];
  enforcementCategoryOptions = [
    {name:'Pre-renovation Education', value:'prerenovation-education', checked:false},
    {name:'Prohibited Activites', value:'prohibited-activites', checked:false},
    {name:'Containment', value:'containment', checked:false},
    {name:'Cleanup/Verification', value:'cleanup-verification', checked:false},
    {name:'Waste Containment/Disposal', value:'wastecontainment-disposal', checked:false},
    {name:'Documentation/Recordkeeping', value:'documentation-recordkeeping', checked:false}
  ];
  jurisdictionOptions = [
    {name:'CCB', value:'CCB', checked:false},
    {name:'HUD', value:'HUD', checked:false},
    {name:'OSHA', value:'OSHA', checked:false},
    {name:'EPA', value:'EPA', checked:false},
    {name:'DEQ', value:'DEQ', checked:false},
    {name:'OHA', value:'OHA', checked:false},
    {name:'Local', value:'Local', checked:false}
  ]
  public complaint: IComplaint = {
    id: 0, complainteeFirstName: '', complainantName: '', complaintDate: new Date(), status: '',
    lastUpdatedBy: '',
    lastUpdated: undefined,
    complainteeLastName: '',
    complainteePhone: '',
    complainteeEmail: '',
    complainteeAddress: '',
    complainteeCity: '',
    complainteeState: '',
    complainteeZip: '',
    complainteeEmployer: '',
    isAnyonymous: false,
    complaintAssociationName: '',
    complaintTargetPhone: '',
    complaintTargetEmail: '',
    complaintContractor1: '',
    complaintContractor2: '',
    complaintContractor3: '',
    contractorCCB1: '',
    contractorCCB2: '',
    contractorCCB3: '',
    otherWorker: '',
    complainantAddress: '',
    complainantCity: '',
    complainantState: '',
    complainantZip: '',
    complainantCounty: '',
    complaintAssociations: [],
    complaintProblem: '',
    complaintInvestigator: '',
    investigatorAssignedDate: undefined,
    complaintClosedDate: undefined,
    complaintNumber: '',
    complainteeName: '',
    associationTypes: []
  };
  public complaintJurisdictions: IComplaintJurisdiction[] = [];
  public complaintEnforcementCategories: IComplaintEnforcementCategory[] = [];
  public complaintForm = null;
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  public complaintInspections: IComplaintInspection[] = [];
  public complaintInspectionTable = 
  {
    layout:{
      columns:['inspectionDate','inspectionType','scope','inspectionDetails'],
      container:[
        {displayName:'Date of Inspection',columnName:'inspectionDate', type:'date', size:'20'},        
        {displayName:'Inspection Type',columnName:'inspectionType', type:'string', size:'25'},
        {displayName:'Scope',columnName:'scope', type:'string', size:'25'},
        {displayName:'Inspection Details',columnName:'inspectionDetails', type:'string', size:'35'}                
      ],
      data: []      
    }
  };
  public complaintInspectionTablePageSize = 5;

  public complaintReferrals: IComplaintReferral[] = [];
  public complaintReferralTable = 
  {
    layout:{
      columns:['complaintNumber','firstName','lastName','email','agencyName','agencyType','referralDate','agencyEmail','phoneNumber','fax'],
      container:[
        {displayName:'Complaint Number',columnName:'complaintNumber', type:'string', size:'15'},        
        {displayName:'First Name',columnName:'firstName', type:'string', size:'25'},
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'55'},
        {displayName:'Email',columnName:'email', type:'string', size:'20'},        
        {displayName:'Agency Name',columnName:'agencyName', type:'string', size:'25'},
        {displayName:'Agency Type',columnName:'agencyType', type:'string', size:'55'},
        {displayName:'Referral Date',columnName:'referralDate', type:'date', size:'20'},  
        {displayName:'Agency Email',columnName:'agencyEmail', type:'string', size:'20'},
        {displayName:'Phone',columnName:'phoneNumber', type:'string', size:'20'},        
        {displayName:'Fax',columnName:'fax', type:'string', size:'25'}               
      ],
      data: []      
    }
  };
  public complaintReferralTablePageSize = 5;

  public complaintActions: IComplaintAction[] = [];
  public complaintActionTable = 
  {
    layout:{
      columns:['actionDate','actionType'],
      container:[
        {displayName:'Date of Action',columnName:'actionDate', type:'date', size:'20'},        
        {displayName:'Type of Action',columnName:'actionType', type:'string', size:'25'}                
      ],
      data: []      
    }
  };
  public complaintActionTablePageSize = 5;

  public complaintOwnerTenants: IComplaintOwnerTenant[] = [];
  public complaintOwnerTenantTable = 
  {
    layout:{
      columns:['isOwner','firstName','lastName','email','phoneNumber','address','city','state','zip','cellNumber','fax'],
      container:[
        {displayName:'Is Owner',columnName:'isOwner', type:'bool', size:'5'},        
        {displayName:'First Name',columnName:'firstName', type:'string', size:'25'},
        {displayName:'Last Name',columnName:'lastName', type:'string', size:'55'},
        {displayName:'Email',columnName:'email', type:'string', size:'20'},        
        {displayName:'Phone',columnName:'phoneNumber', type:'string', size:'25'},
        {displayName:'Address',columnName:'address', type:'string', size:'55'},
        {displayName:'City',columnName:'city', type:'string', size:'20'},        
        {displayName:'State',columnName:'state', type:'string', size:'5'},
        {displayName:'Zip',columnName:'zip', type:'string', size:'20'},
        {displayName:'Cell',columnName:'cellNumber', type:'string', size:'20'},        
        {displayName:'Fax',columnName:'fax', type:'string', size:'25'}            
      ],
      data: []      
    }
  };
  public complaintOwnerTenantTablePageSize = 5;

  public complaintAssocations: IComplaintAssociation[] = [];
  complaintAssociationTable = {
    icon: 'recent_actors',
    title:'Complaint Assocations',
    layout:{
      columns:['id', 'type', 'name'],
      container:[
        {displayName:'Id',columnName:'id', type:'string', size:'20'},
        {displayName:'Type',columnName:'type', type:'string', size:'25'},
        {displayName:'Name',columnName:'name', type:'string', size:'30'}       
      ],
      data: []      
    }
  }
  public complaintAssocationTablePageSize = 5;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {complaint: IComplaint, associationId: number, associationType: string},
    private breakpointObserver: BreakpointObserver,
    public commonService: CommonDataService,
    private toastr: ToastrService,
    private complaintService: ComplaintService,
    public sharedService: SharedService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {     
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.setComplaintFormFields();  
    this.complaint =  this.data != null && this.data.complaint != null ? this.data.complaint : this.complaint;
    this.complaintId = this.data != null && this.data.complaint != null  ? this.complaint.id : 0
    this.loading = false;
    if(this.complaintId > 0){
      this.loadComplaintFields();   
      this.loading = false;       
      this.complaintProblem = this.complaint.complaintProblem;
      this.complaintInvestigator = this.complaint.complaintInvestigator;
      this.complaintInvestigatorAssignedDate = this.complaint.investigatorAssignedDate;
    } else{
      this.complaint.status = 'Open';      
      this.loadComplaintFields();      
    }
    if (this.complaintId !== 0){
      this.complaintProblem = this.complaint.complaintProblem;
      this.complaintInvestigator = this.complaint.complaintInvestigator ? this.complaint.complaintInvestigator : this.username();
      this.complaintInvestigatorAssignedDate = this.complaint.investigatorAssignedDate;
      this.getComplaintAssociations();
      this.getComplaintReferrals();
      this.getComplaintJurisdictions();
      this.getComplaintEnforcementCategories();
      this.getComplaintOwnerTenants();
      this.getComplaintInspections();
      this.getComplaintActions();      
    } 
  }

  username(): string {
    return this.authService.username;
  }

  getComplaintAssociations(){
    this.complaintService.getComplaintAssociations(this.complaintId).subscribe(result=>{        
      this.complaintAssocations = result;
      this.complaintAssociationTable.layout.data = this.complaintAssocations;
    },error=>{      
      this.toastr.error("There was an error getting the Complaint Associations: ", error)
    });
  }

  getComplaintReferrals(){
    this.complaintService.getComplaintReferrals(this.complaintId).subscribe(result=>{        
      this.complaintReferrals = result;
      this.complaintReferralTable.layout.data = this.complaintReferrals;
    },error=>{      
      this.toastr.error("There was an error getting the Complaint Referrals: ", error)
    });
  }

  resolveComplaint(): void {
      this.complaintStatus = 'Closed';
        this.complaintStatus = 'Closed';
        this.complaintForm.controls['status'].setValue('Closed');
        if (!this.complaint.complaintClosedDate) {
          this.complaintForm.controls['complaintClosedDate'].setValue(new Date());
          this.complaint.complaintClosedDate = new Date();
        }   
  }

  getComplaintOwnerTenants(){
    this.complaintService.getComplaintOwnerTenants(this.complaintId).subscribe(result=>{        
      this.complaintOwnerTenants = result;
      this.complaintOwnerTenantTable.layout.data = this.complaintOwnerTenants;
    },error=>{
      this.toastr.error("There was an error getting the Complaint Owner Tenants: ", error)
    });
  }

  getComplaintActions(){
    this.complaintService.getComplaintActions(this.complaintId).subscribe(result=>{        
      this.complaintActions = result;
      this.complaintActionTable.layout.data = this.complaintActions;
    },error=>{
      this.toastr.error("There was an error getting the Complaint Actions: ", error)
    });
  }

  getComplaintInspections(){
    this.complaintService.getComplaintInspections(this.complaintId).subscribe(result=>{        
      this.complaintInspections = result;
      this.complaintInspectionTable.layout.data = this.complaintInspections;
    },error=>{
      this.toastr.error("There was an error getting the Complaint Inspections: ", error)
    });
  }

  getComplaintJurisdictions(){
    this.complaintService.getComplaintJurisdictions(this.complaintId).subscribe(result=>{        
      this.complaintJurisdictions = result;
      if(this.complaintJurisdictions && this.complaintJurisdictions.length > 0){
        this.jurisdictionOptions.forEach(option => {
          if (this.complaintJurisdictions.some(jurisdiction => jurisdiction.other)) {
            this.otherJurisdiction = this.complaintJurisdictions.find(jurisdiction => jurisdiction.other).jurisdiction;
          }
          if (this.complaintJurisdictions.some(jurisdiction => jurisdiction.jurisdiction === option.value && !jurisdiction.other)) {
            option.checked = true;
          }
        });
      }
    },error=>{
      this.toastr.error("There was an error getting the Complaint jurisdictions: ", error)
    });
  }
  
  getComplaintEnforcementCategories(){
    this.complaintService.getComplaintEnforcementCategories(this.complaintId).subscribe(result=>{        
      this.complaintEnforcementCategories = result;
      if(this.complaintEnforcementCategories && this.complaintEnforcementCategories.length > 0){
        if (this.complaintEnforcementCategories.some(enfor => enfor.other)) {
          this.otherEnforcementCategory = this.complaintEnforcementCategories.find(enfor => enfor.other).enforcementCategory;
        }
        this.enforcementCategoryOptions.forEach(option => {
          if (this.complaintEnforcementCategories.some(enforcementCategory => enforcementCategory.enforcementCategory === option.value && !enforcementCategory.other)) {
            option.checked = true;
          }
        });
      }
    },error=>{
      this.toastr.error("There was an error getting the Complaint Enforcement Categories: ", error)
    });
  }

  deleteInspection(row: any): void {          
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message:'Are you sure you want to remove the Complaint Inspection/Followup/Narrative: <strong>' + row.inspectionDetails + '</strong>?', title: 'Remove Inspection/Followup/Narrative'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.complaintService.deleteComplaintInspection(row.id).subscribe(result=>{
          this.getComplaintInspections();
          this.toastr.success("Successfully removed Complaint Inspection/Followup/Narrative");
        },error=>{
          this.toastr.error("There was an error removing the Complaint Inspection/Followup/Narrative:: ", error);
        })
      }
    })  
  }

  deleteAction(row: any): void {          
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message:'Are you sure you want to remove the Complaint Action: <strong>' + row.actionType + '</strong>?', title: 'Remove Action'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.complaintService.deleteComplaintAction(row.id).subscribe(result=>{
          this.getComplaintActions();
          this.toastr.success("Successfully removed Complaint Action");
        },error=>{
          this.toastr.error("There was an error removing the Complaint Action:: ", error);
        })
      }
    })  
  }

  deleteOwnerTenant(row: any): void {          
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message:'Are you sure you want to remove the Complaint Owner/Tenant: <strong>' + row.firstName + '</strong>?', title: 'Remove Action'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.complaintService.deleteComplaintOwnerTenant(row.id).subscribe(result=>{
          this.getComplaintOwnerTenants();
          this.toastr.success("Successfully removed Complaint Owner/Tenant");
        },error=>{
          this.toastr.error("There was an error removing the Complaint Owner/Tenant:: ", error);
        })
      }
    })  
  }

  deleteReferral(row: any): void {          
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message:'Are you sure you want to remove the Complaint Referral: <strong>' + row.complaintNumber + '</strong>?', title: 'Remove Referral'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.complaintService.deleteComplaintReferral(row.id).subscribe(result=>{
          this.getComplaintReferrals();
          this.toastr.success("Successfully removed Complaint Referral");
        },error=>{
          this.toastr.error("There was an error removing the Complaint Referral:: ", error);
        })
      }
    })  
  }

  editComplaintReferral(row: any): void {    
    const dialogRef = this.dialog.open(AddEditReferralComponent, {        
      data: {complaintId: this.complaintId,  selected: row},
      width: '800px',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){        
        this.getComplaintReferrals();
      }    
    });
  }

  editComplaintInspection(row: any): void {    
    const dialogRef = this.dialog.open(AddEditInspectionComponent, {        
      data: {complaintId: this.complaintId,  selected: row},
      width: '800px',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){        
        this.getComplaintInspections();
      }    
    });
  }

  editAction(row: any): void {    
    const dialogRef = this.dialog.open(AddEditActionComponent, {        
      data: {complaintId: this.complaintId,  selected: row},
      width: '500px',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){        
        this.getComplaintActions();
      }    
    });
  }

  handleEnforcementCategorySelection(event: MatCheckboxChange): void {
    const selectedEnforcementCategory = event.source.value;
    // add the selected enforcement category to the complaintEnforcementCategories array
    if (event.checked) {
      if (this.complaintEnforcementCategories == undefined || this.complaintEnforcementCategories === null) {
        this.complaintEnforcementCategories = [];
      }
      this.complaintEnforcementCategories.push({
        enforcementCategory: selectedEnforcementCategory,
        id: 0,
        complaintId: this.complaintId,
        other: false,
        lastUpdatedBy: '',
        lastUpdated: undefined
      });
    } else {
      // Remove the selected enforcement category from the complaintEnforcementCategories array
      const index = this.complaintEnforcementCategories.findIndex(enforcementCategory => enforcementCategory.enforcementCategory === selectedEnforcementCategory);
      if (index > -1) {
        this.complaintEnforcementCategories.splice(index, 1);
      }
    }
  }

  handleJurisdictionsSelection(event: MatCheckboxChange): void {
    const selectedJurisdiction = event.source.value;
    // add the selected jurisdiction to the complaintJurisdictions array
    if (event.checked) {
      if (this.complaintJurisdictions == undefined || this.complaintJurisdictions === null) {
        this.complaintJurisdictions = [];
      }
      this.complaintJurisdictions.push({
        jurisdiction: selectedJurisdiction,
        id: 0,
        complaintId: this.complaintId,
        other: false,
        lastUpdatedBy: '',
        lastUpdated: undefined
      });
    } else {
      // Remove the selected jurisdiction from the complaintJurisdictions array
      const index = this.complaintJurisdictions.findIndex(jurisdiction => jurisdiction.jurisdiction === selectedJurisdiction);
      if (index > -1) {
        this.complaintJurisdictions.splice(index, 1);
      }
    }
  }

  editOwnerTenant(row: any): void {    
    const dialogRef = this.dialog.open(AddEditOwnerTenantComponent, {        
      data: {complaintId: this.complaintId,  selected: row},
      width: '800px',
      autoFocus: false,
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){        
        this.getComplaintOwnerTenants();
      }    
    });
  }

  setComplaintFormFields(){
    this.complaintForm = new UntypedFormGroup(
        {
          complainteeFirstName: new UntypedFormControl(""), 
          complainteeLastName: new UntypedFormControl(""), 
          complainteePhone: new UntypedFormControl(""),
          complainteeEmail: new UntypedFormControl(""),
          complainteeAddress: new UntypedFormControl(""),
          complainteeCity: new UntypedFormControl(""),   
          complainteeState: new UntypedFormControl(""), 
          complainteeEmployer: new UntypedFormControl(""), 
          complaintDate: new UntypedFormControl(new Date()), 
          complainantName: new UntypedFormControl(""),
          status: new UntypedFormControl(""), 
          complainteeZip: new UntypedFormControl(0),
          isAnyonymous:new UntypedFormControl(false),
          complaintAssociationName: new UntypedFormControl(""),
          complaintTargetPhone: new UntypedFormControl(""),
          complaintTargetEmail: new UntypedFormControl(""),
          complaintContractor1: new UntypedFormControl(""),
          complaintContractor2: new UntypedFormControl(""),
          complaintContractor3: new UntypedFormControl(""),
          contractorCCB1: new UntypedFormControl(""),   
          complainantAddress: new UntypedFormControl(""),
          complainantCity: new UntypedFormControl(""),
          complainantState: new UntypedFormControl(""),
          complainantZip: new UntypedFormControl(""),   
          complainantCounty: new UntypedFormControl(""),  
          contractorCCB2: new UntypedFormControl(""), 
          contractorCCB3: new UntypedFormControl(""), 
          otherWorker: new UntypedFormControl(""),
          complaintClosedDate: new UntypedFormControl(),
        }, 
        {
          validators: [     
          ]
        }
      );     
  } 

  loadComplaintFields(){
    for (const controlName in this.complaint) {      
        if (this.complaintForm.controls.hasOwnProperty(controlName)) {
          if(controlName === 'complainteeState' || controlName === 'complainantState'){
            if(!this.complaint[controlName])
              this.complaintForm.controls[controlName].setValue('OR');
          }else{
          this.complaintForm.controls[controlName].setValue(this.complaint[controlName]);     
          }   
        }
      }
  }
  
  updateComplaintAssociationTableDataSource(){    
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  } 

  updateComplaint(): void {    
    for (const controlName in this.complaint) {
        if (this.complaintForm.controls.hasOwnProperty(controlName)) {
          this.complaint[controlName] = this.complaintForm.controls[controlName].value;                
        }
      } 
  }

  updateComplaintDetail(): void {    
    this.complaint.complaintProblem = this.complaintProblem;
    this.complaint.investigatorAssignedDate = this.complaintInvestigatorAssignedDate;
    this.complaint.complaintInvestigator = this.complaintInvestigator; 
    this.complaintDetail.complaintId = this.complaintId;
   
    if(this.otherEnforcementCategory){
    let otherEnforcementCategory = this.complaintEnforcementCategories.find(enforcementCategory => enforcementCategory.other);
      if(otherEnforcementCategory){
        otherEnforcementCategory.enforcementCategory = this.otherEnforcementCategory;
      }else{
    this.complaintEnforcementCategories.push({
      enforcementCategory: this.otherEnforcementCategory,
      id: 0,
      complaintId: this.complaintId,
      other: true,
      lastUpdatedBy: '',
      lastUpdated: undefined
    });
  }
  }else{
    this.complaintEnforcementCategories = this.complaintEnforcementCategories.filter(enforcementCategory => !enforcementCategory.other);
  }
    if(this.otherJurisdiction){
      let otherJurisdiction = this.complaintJurisdictions.find(jurisdiction => jurisdiction.other);
      if(otherJurisdiction){
        otherJurisdiction.jurisdiction = this.otherJurisdiction;
      }else{
        this.complaintJurisdictions.push({
          jurisdiction: this.otherJurisdiction,
          id: 0,
          complaintId: this.complaintId,
          other: true,
          lastUpdatedBy: '',
          lastUpdated: undefined
        });
    }
    }else{
      this.complaintJurisdictions = this.complaintJurisdictions.filter(jurisdiction => !jurisdiction.other);
    }
    this.complaintDetail.complaintJurisdictions = this.complaintJurisdictions;
    this.complaintDetail.complaintViolations = this.complaintEnforcementCategories; 
  }
  
  onDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value.toISOString();
    this.complaint.complaintDate = new Date(selectedDate);    
  }
 
  onInvestigatorAssignedDateSelected(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value.toISOString();
    this.complaintInvestigatorAssignedDate = selectedDate;    
  }
  saveComplaint() {
    this.updateComplaint();  
    this.updateComplaintDetail();
    if(this.complaintId != 0){
      this.saveComplaintJurisdictionEnforcementCategories(); 
    }
    
    this.complaintService.saveComplaint(this.complaint).subscribe(result=>{
      if(this.complaintId == 0){
        this.saveComplaintAssociations(result.id);
      }
      this.complaint = result;
      this.complaintId = result.id;
    },error=>{
      this.toastr.error("An error occurred when trying to save the Complaint: ", error);
    }); 
  }

  saveComplaintAssociations(complaintId: any) {
    if(this.data.associationId > 0 && this.data.associationType){
      let assocationList :IComplaintAssociation[] = [];    
        let object:IComplaintAssociation = {
          id: 0,
          complaintId: complaintId,
          type: '',
          name: '',
          typeId: 0
        };
        object.type = this.data.associationType;
        object.typeId = this.data.associationId;
        assocationList.push(object);
      this.complaintService.saveComplaintAssociation(assocationList).subscribe(result=>{
        this.toastr.success("Successfully added Complaint Assocation")
      },error=>{
        this.toastr.error("An error occured saving the Complaint Assocation: ", error);
      }); 
    }  
  }

  saveComplaintJurisdictionEnforcementCategories() {
    this.complaintService.saveComplaintJurisdictionEnforcementCategories(this.complaintDetail).subscribe(result=>{      
      this.toastr.success("Successfully saved Complaint");
      this.complaintDetail.complaintJurisdictions = result.complaintJurisdictions;
      this.complaintJurisdictions = result.complaintJurisdictions;
      this.complaintDetail.complaintViolations = result.complaintViolations;       
      this.complaintEnforcementCategories = result.complaintViolations;
    },error=>{
      this.toastr.error("An error occurred when trying to save the Complaint Detail: ", error);
    });
  }

  displayLimit(text: any, limit: string){
    let newText = text;
    if (typeof text === 'string' && text.length > parseInt(limit)){
      newText = newText.substring(0, limit) + "...";
    }    
    return newText;
  }

 addAssociations(row: any): void {
    const dialogRef = this.dialog.open(AddEditAssociationComponent, {        
        data: { complaintId: this.complaint.id },
        width: '500px',
        autoFocus: false,
        panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {    
      if (result){
        this.getComplaintAssociations();
      }    
    });
  }

  exportPlan() {
    this.dialog.open(PrintComplaintComponent, {
      data: {
        selectedComplaint: this.complaint
      },
      role: 'dialog',
      ariaLabel: 'Complaint',
      minWidth: !this.sharedService.mobile ? '1000px' : '300px',
      maxWidth: !this.sharedService.mobile ? '980px' : '300px',
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
  }
  
  deleteAssociation(row: any): void {          
    const dialogRef = this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',      
      data: {message:'Are you sure you want to remove the Complaint Association: <strong>' + row.name + '</strong>?', title: 'Remove Association'},
      panelClass: this.sharedService.darkMode ? "theme-dark" : ""
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.complaintService.deleteComplaintAssociation(row).subscribe(result=>{
          this.getComplaintReferrals();
          this.toastr.success("Successfully removed Complaint Association");
        },error=>{
          this.toastr.error("There was an error removing the Complaint Association:: ", error);
        })
      }
    })  
  }
 
}
